import React, { useContext } from 'react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Badge,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import useOrganizationUserImportResults from 'data_sources/userImports/useOrganizationUserImportResults';
import { User } from 'types/user';
import ListSection from 'components/_layouts/ListSection';
import { UserImportCounts } from 'data_sources/userImports/enums';
import { UserImportResult } from 'types/user_import';

type DetailsInsertedProps = {
  bucketName: string;
  resultKey: string;
}

const columnHelper = createColumnHelper<User>();

export default function DetailsInserted({
  bucketName,
  resultKey,
}: DetailsInsertedProps) {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();

  const columns = [
    columnHelper.accessor('lastName', {
      cell: (info) => (
        <Stack>
          <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>{[
            info.row.original.firstName,
            info.row.original.middleName,
            info.row.original.lastName,
          ].join(' ')}</Typography>
          {info.row.original.email && (
            <Link href={`mailto:${info.row.original.email}`}>{info.row.original.email}</Link>
          )}
        </Stack>
      ),
      header: () => t('users.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('active', {
      cell: (info) => info.getValue() !== undefined ? (
        <Badge variant='inline' badgeContent={info.getValue() ? 'Active' : 'Inactive'} color={info.getValue() ? 'success' : 'error'} sx={{ textTransform: 'uppercase' }} />
      ) : '',
      header: () => t('users.properties.active'),
      size: 200,
    }),
  ];

  const resultsQuery = useOrganizationUserImportResults(currentOrganizationId ?? '', bucketName, resultKey);
  console.log('results', resultsQuery.data)
  return (
    <ListSection
      columns={columns}
      data={((resultsQuery.data ?? {}) as UserImportResult)[UserImportCounts.INSERTED]?.map((x) => x.user) ?? []}
      query={resultsQuery}
    />
  );
}
