import React, { useContext } from 'react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Link, Stack, Typography,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { ArchivedManualsContext } from '../context';
import { QuickSearch } from 'components/_atoms';
import datetimeHelper from 'helpers/datetimeHelper';
import ListView from 'components/_layouts/ListView';
import { ArchivedManualItem } from 'types/manual';

const columnHelper = createColumnHelper<ArchivedManualItem>();

const ArchivedManualsList: React.FC = () => {
  const t = useTranslations();
  const { manuals, manualsQuery, params, updateParams } = useContext(ArchivedManualsContext);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Stack>
          <Link href={`./${info.row.original.id}`}>
            <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>
              {info.getValue()}
            </Typography>
          </Link>
          <Typography>{`${datetimeHelper.formatDate(info.row.original.fromDate, { format: 'short' })} - ${datetimeHelper.formatDate(info.row.original.toDate, { format: 'short' })}`}</Typography>
        </Stack>
      ),
      header: () => t('policy.manuals.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('vertical.name', {
      cell: (info) => (
        <Typography>{info.getValue()}</Typography>
      ),
      header: () => t('policy.manuals.properties.vertical'),
      size: 200,
    }),
    columnHelper.accessor('manualType.name', {
      cell: (info) => (
        <Typography>{info.getValue()}</Typography>
      ),
      header: () => t('policy.manuals.properties.manualType'),
      size: 200,
    }),
  ];

  return (
    <ListView
      columns={columns}
      data={manuals}
      query={manualsQuery}
      title={t('policy.archivedManuals.title')}
      subtitle={t('policy.archivedManuals.subtitle')}
      toolbarLeft={<QuickSearch
        id='org_keyword_search'
        initialValue={params.q || ''}
        onChange={(val) => updateParams({ q: val })}
        placeholder={t('archivedManuals.search.placeholder')}
      />}
    />
  );
}

export default ArchivedManualsList;
