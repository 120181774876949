import React, { useCallback, useMemo, useContext } from "react";
import { QueryClient, UseInfiniteQueryResult, useQueryClient } from "react-query";
import * as Yup from 'yup';
import { AppContext } from "views/App/context";
import useUrlParams from "hooks/useUrlParams";
import useTranslations from "hooks/useTranslations";
import useUserImports from "data_sources/userImports/useOrganizationUserImports";
import useManageUserImports from "data_sources/userImports/useManageOrganizationUserImport";
import type { NewUserImportRequest } from "types/user_import";
import { ApiCollectionResponse } from "types/api_response";
import { ServerErrorResponse } from "data_sources/constants";
import { Job } from "types/job";

type UserImportsProviderProps = {
  children: React.ReactNode;
};

type UserImportsUrlParams = {
};

export type UserImportsProviderResponse = {
  clearParams: () => void;
  createUserImport: (
    data: NewUserImportRequest,
    onSuccess?: (jobId: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => void;
  newUserImportSchema: any;
  params: UserImportsUrlParams;
  queryClient: QueryClient | null;
  updateParams: (updatedParams: UserImportsUrlParams) => void;
  userImports: Job[];
  userImportsQuery: UseInfiniteQueryResult<ApiCollectionResponse<Job>, Error>;
}

const defaultParams: UserImportsUrlParams = {
};

export const UserImportsContext = React.createContext<UserImportsProviderResponse>({} as UserImportsProviderResponse);

const UserImportsProvider: ({ children }: UserImportsProviderProps) => any = ({ children }) => {
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const queryClient = useQueryClient();
  const t = useTranslations();
  const newUserImportSchema = Yup.object().shape({
    jobDescription: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      }),
    fileContents: Yup.object().required(`${t('userImports.properties.importFile')} is required`)
  });
  const [params, setParams] = useUrlParams(defaultParams);
  const updateParams = useCallback((updatedParams: UserImportsUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams: any = {
      ...params
    };
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null || newParams[key] === '') {
        delete newParams[key];
      }
    });

    return newParams;
  }, [params]);

  // const isFiltered = params !== defaultParams;

  const userImportData = useUserImports(orgId, queryParams);
  const {
    create: createUserImport,

  } = useManageUserImports();

  const userImports = useMemo(() => userImportData.data && userImportData.data.pages ?
    userImportData.data.pages.flatMap((x) => x.content) : []
    , [userImportData.data]);

  const handleCreateUserImport = useCallback((
    data: NewUserImportRequest,
    onSuccess?: (jobId: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    createUserImport(
      orgId,
      data,
      (jobId: string) => {
        queryClient.resetQueries(['organization_user_imports']);
        window.setTimeout(() => {
          console.log('resetting user import data')
          queryClient.resetQueries(['organization_user_imports']);
          if (onSuccess) onSuccess(jobId);
        }, 3000);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [createUserImport, orgId, queryClient]);

  const contextValue: UserImportsProviderResponse = useMemo(() => ({
    clearParams,
    createUserImport: handleCreateUserImport,
    params,
    queryClient,
    newUserImportSchema,
    updateParams,
    userImportsQuery: userImportData,
    userImports: userImports,
  }), [clearParams, handleCreateUserImport, newUserImportSchema, params, queryClient, updateParams, userImportData, userImports]);

  React.useEffect(() => {
    if (userImports.map((x) => x.status?.toUpperCase()).includes('PENDING') || userImports.map((x) => x.status?.toUpperCase()).includes('RUNNING')) {
      window.setTimeout(() => {
        queryClient.invalidateQueries(['organization_user_imports']);
      }, 30000)
    }
  }, [userImports, userImportData.data, queryClient]);

  return (
    <UserImportsContext.Provider value={contextValue}>
      {children}
    </UserImportsContext.Provider>
  )
};

export default UserImportsProvider;