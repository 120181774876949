import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  defaultDropAnimation,
  Modifier,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  removeChildrenOf,
  setProperty,
  findItemDeep,
} from '../../helpers/utilities';
import type { DraftManual, DraftPolicy, FlattenedItem, SensorContext, TreeItems } from 'types/manual';
import { SortableTreeItem } from './sortableTreeItem';
import draftManualHelper from '../../helpers/draftManualHelper';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
};

interface SortableTreeProps {
  collapsible?: boolean;
  items: TreeItems;
  onChange: (items: TreeItems) => void;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  onSectionChange: (updatedSection: FlattenedItem) => void;
  manual: DraftManual;
  policyData: DraftPolicy;
}

export function SortableTree({
  collapsible,
  items,
  onChange,
  indentationWidth = 20,
  indicator,
  removable,
  onSectionChange,
  manual,
  policyData,
}: SortableTreeProps) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: string | null;
    overId: string;
  } | null>(null);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
      []
    );

    return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
  }, [activeId, items]);

  const projected =
    activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth) : null;

  const sensors = useSensors(useSensor(PointerSensor));

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);

  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  const sensorContext = useRef<{
    items: FlattenedItem[];
    offset: number;
  }>({
    items: flattenedItems,
    offset: offsetLeft,
  });

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const getTreeSectionTitle = (item: any) => {
    return item.depth === 0
      ? `${draftManualHelper.getNumberManualNode(policyData, manual)}.${draftManualHelper.getEnabledSectionIndex(item.id, policyData as DraftPolicy)}`
      : `${draftManualHelper.getNumberManualNode(policyData, manual)}.${draftManualHelper.getEnabledSectionIndex(item.parentId as string, policyData as DraftPolicy)}.${draftManualHelper.getEnabledSubSectionIndex(item.parentId as string, item.id, policyData as DraftPolicy)}`;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={sortedIds}
        strategy={verticalListSortingStrategy}
      >
        {flattenedItems.map((item) => (
          <SortableTreeItem
            key={item.id}
            item={item}
            id={item.id}
            value={item.state === 'ENABLED' ? `${getTreeSectionTitle(item)}: ${item.title}` : `${item.title}`}
            depth={item.id === activeId && projected ? projected.depth : item.depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(item.collapsed && item.children.length)}
            onCollapse={collapsible && item.children.length ? () => handleCollapse(item.id) : undefined}
            onSectionChange={onSectionChange}
          />
        ))}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimation}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                item={activeItem}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                value={
                  activeItem.state === 'ENABLED'
                    ? `${getTreeSectionTitle(activeItem)}: ${activeItem.title}`
                    : `${activeItem.title}`
                }
                indentationWidth={indentationWidth}
                onSectionChange={() => {}}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart({ active }: DragStartEvent) {
    setActiveId(active.id as string);
    setOverId(active.id as string);

    const activeItem = flattenedItems.find(({ id }) => id === active.id);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: active.id as string,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id as string);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;

      const movingItem = findItemDeep(items, active.id as string);

      if (movingItem && !movingItem.isSubSection && movingItem.children.length > 0 && parentId !== null) {
        alert('Cannot move a section that has subsections into another section.');
        return;
      }

      if (depth > 1) {
        alert('Cannot move an item beyond the maximum allowed depth.');
        return;
      }

      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const newItems = buildTree(sortedItems);

      onChange(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function handleRemove(id: string) {
    const updatedItems = removeItem(items, id);
    onChange(updatedItems);
  }

  function handleCollapse(id: string) {
    const updatedItems = setProperty(items, id, 'collapsed', (value) => !value);
    onChange(updatedItems);
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

export default SortableTree;
