import React, { useCallback, useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { AppContext } from 'views/App/context';
import { ManualPermissions } from 'types/manual';
import useStickyState from 'hooks/useStickyState';

type DraftManualsProviderProps = {
  children: React.ReactNode;
};

export type DraftManualsProviderResponse = {
  manualSchema: any;
  manualPermissions: ManualPermissions | null;
  setManualPermissions: (manualPermissions: ManualPermissions | null) => void;
  orgId: string;
  manualHasPermissions: (manualId: string, keys: string[]) => boolean;
};

export const DraftManualsContext =
  React.createContext<DraftManualsProviderResponse>(
    {} as DraftManualsProviderResponse
  );

const DraftManualsProvider: ({
  children,
}: DraftManualsProviderProps) => any = ({ children }) => {
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const [manualPermissions, setManualPermissions] =
    useStickyState<ManualPermissions | null>('phx-manualPermissions', null);

  const manualHasPermissions = useCallback(
    (manualId: string, keys: string[]) => {
      let permissionKeys: string[] = [];
      if (manualPermissions && manualPermissions[manualId]) {
        permissionKeys = manualPermissions[manualId];
      }
      return keys.every((x) =>
        permissionKeys.map((x) => x.toLowerCase()).includes(x.toLowerCase())
      );
    },
    [manualPermissions]
  );

  const manualSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required('Name is required'),
  });

  const contextValue: DraftManualsProviderResponse = useMemo(
    () => ({
      manualPermissions,
      manualHasPermissions,
      setManualPermissions,
      manualSchema,
      orgId,
    }),
    [
      manualPermissions,
      manualHasPermissions,
      setManualPermissions,
      manualSchema,
      orgId,
    ]
  );

  return (
    <DraftManualsContext.Provider value={contextValue}>
      {children}
    </DraftManualsContext.Provider>
  );
};

export default DraftManualsProvider;
