import styled from '@emotion/styled';
import { Box, colors, TextField } from '@mui/material';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';

export const MuiContentEditable = styled(ContentEditable)({
  minHeight: 200,
  width: '100%',
  borderRadius: 5,
  position: 'relative',
  outline: 'none',
  '&[contenteditable=false]': {
    minHeight: 0,
  },
  '& .text-entity': {
    padding: '2px 4px',
    backgroundColor: colors.blue[50],
    borderRadius: 8,
    display: 'inline-flex',
    '&:before': {
      content: '"{{"',
    },
    '&:after': {
      content: '"}}"',
    },
  },
});

export const EditorPlaceholder = styled(Box)({
  position: 'absolute',
  top: 15,
  left: 10,
  userSelect: 'none',
  display: 'inline-block',
  pointerEvents: 'none',
});

export const ContentPageContainer = styled(Box)({
  padding: 36,
  maxHeight: 'calc(100vh - (2 * 64px))',
  minHeight: 0,
  overflowY: 'auto',
});

export const ContentPage = styled(Box)({
  margin: '0 auto',
  background: '#FFF',
  padding: 36,
  border: `solid 1px ${colors.grey[200]}`,
  width: 930,
  maxWidth: 'calc(100% - 4rem)',
  fontSize: 16,
  '& .MuiTypography-h5': {
    fontSize: 27,
  },
  '& .MuiTypography-h6': {
    fontSize: 18,
  },
});

export const TitleTextField = styled(TextField)({
  paddingTop: 0,
  '& .MuiOutlinedInput-root': {
    borderColor: `${colors.grey[50]} !important`,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 20,
    fontWeight: 600,
    padding: 8,
  },
});
