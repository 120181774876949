import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import parse from 'html-react-parser';
import { useQueryClient } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  CalendarMonth,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PlayArrow as PlayIcon,
  Pause as PauseIcon,
} from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import useIssuedManualAcknowledgementSchedules from 'data_sources/issuedManuals/useIssuedManualAcknowledgementSchedules';
import useManageAcknowledgementSchedules from 'data_sources/issuedManuals/useManageAcknowledgementSchedules';
import { IssuedManualsContext } from '../context';
import Loading from 'views/App/Portal/Loading';
import DetailView from 'components/_layouts/DetailView';
import PropertyList from 'components/_layouts/PropertyList';
import datetimeHelper from 'helpers/datetimeHelper';
import { ManualAcknowledgementSchedule } from 'types/acknowledgements';

const IssuedManualsAcknowledgementSchedules: React.FC = () => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const { manualHasPermissions } = useContext(IssuedManualsContext);
  const schedulesQuery = useIssuedManualAcknowledgementSchedules(id ?? '');
  const manualQuery = useIssuedManual(id);
  const confirm = useConfirm();

  const {
    update: updateManualAcknowledgementSchedule,
    destroy: deleteManualAcknowledgementSchedule,
  } = useManageAcknowledgementSchedules();

  const schedules = React.useMemo(
    () =>
      schedulesQuery.data && schedulesQuery.data.pages
        ? schedulesQuery.data.pages.flatMap((x) => x.content)
        : [],
    [schedulesQuery.data]
  );

  const updateStatus = (
    schedule: ManualAcknowledgementSchedule,
    status: 'ENABLED' | 'DISABLED'
  ) => {
    updateManualAcknowledgementSchedule(
      id ?? '',
      schedule.id,
      {
        dueDays: schedule.dueDays,
        frequency: schedule.frequency,
        groups: schedule.groups,
        policies: schedule.policies,
        startAt: schedule.startAt,
        status,
        manualId: id ?? '',
        id: schedule.id,
        title: schedule.title,
      },
      () => {
        enqueueSnackbar(
          parse(t(`policy.acknowledgementSchedules.new.updateConfirmation`)),
          {
            variant: 'success',
            autoHideDuration: 6000,
          }
        );

        queryClient.invalidateQueries([
          'issued_manual_acknowledgment_schedules',
        ]);
        navigate(`../${id}/acknowledgement-schedules`);
      },
      (errors) => {
        enqueueSnackbar(errors.message, {
          variant: 'error',
          autoHideDuration: 20000,
        });
      }
    );
  };

  const handleDeleteClick = (schedule: ManualAcknowledgementSchedule) => {
    confirm({
      cancellationButtonProps: {
        variant: 'outlined',
        color: 'neutral',
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      confirmationText: t('common.delete'),
      description: t('policy.acknowledgementSchedules.deleteConfirm'),
    })
      .then(() => {
        deleteManualAcknowledgementSchedule(
          id ?? '',
          schedule.id,
          () => {
            enqueueSnackbar(
              parse(t(`policy.acknowledgementSchedules.deleteConfirmation`)),
              {
                variant: 'success',
                autoHideDuration: 6000,
              }
            );

            queryClient.invalidateQueries([
              'issued_manual_acknowledgment_schedules',
            ]);
            navigate(`../${id}/acknowledgement-schedules`);
          },
          (errors) => {
            enqueueSnackbar(errors.message, {
              variant: 'error',
              autoHideDuration: 20000,
            });
          }
        );
      })
      .catch(() => {
        // don't do it
      });
  };

  return schedulesQuery.isFetching || schedulesQuery.isLoading ? (
    <Loading />
  ) : (
    <DetailView
      query={schedulesQuery}
      title={t('policy.acknowledgementSchedules.title')}
      subtitle={manualQuery.data?.name || ''}
      breadcrumbItems={[
        <Link
          key='b1'
          href='..'
        >
          {t('policy.issuedManuals.title')}
        </Link>,
        <Link
          key='b2'
          href={`../${id}`}
        >
          {manualQuery.data?.name || ''}
        </Link>,
        <Typography key='b3'>
          {t('policy.acknowledgementSchedules.title')}
        </Typography>,
      ]}
      actions={
        <Stack
          direction='row'
          spacing={2}
        >
          {manualHasPermissions(id ?? '', [
            'createManualAcknowledgementSchedule',
          ]) && (
            <Button
              href={`../${id}/new-acknowledgement-schedule`}
              variant='contained'
              color='primary'
            >
              {t('policy.acknowledgementSchedules.new.cta')}
            </Button>
          )}
        </Stack>
      }
    >
      <Stack spacing={2}>
        {schedules &&
          schedules.map((schedule) => (
            <Card key={schedule.id}>
              <CardHeader
                title={schedule.title}
                action={
                  <Stack
                    direction='row'
                    spacing={2}
                  >
                    {manualHasPermissions(id ?? '', [
                      'updateManualAcknowledgementSchedule',
                    ]) && (
                      <>
                        {schedule.status === 'DISABLED' ? (
                          <IconButton
                            onClick={() => updateStatus(schedule, 'ENABLED')}
                          >
                            <PlayIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => updateStatus(schedule, 'DISABLED')}
                          >
                            <PauseIcon />
                          </IconButton>
                        )}
                        <IconButton href={`./${schedule.id}/edit`}>
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                    {manualHasPermissions(id ?? '', [
                      'deleteManualAcknowledgementSchedule',
                    ]) && (
                      <IconButton onClick={() => handleDeleteClick(schedule)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                }
              />
              <CardContent>
                <PropertyList
                  columns={4}
                  items={[
                    {
                      label: t(
                        'policy.acknowledgementSchedules.frequency.title'
                      ),
                      value: t(
                        `policy.acknowledgementSchedules.frequency.${String(schedule.frequency)}`
                      ),
                    },
                    {
                      label: t('policy.acknowledgementSchedules.startAt'),
                      value: datetimeHelper.formatDate(schedule.startAt, {
                        missingDisplay: '--',
                      }),
                    },
                    {
                      label: t('policy.acknowledgementSchedules.dueDays'),
                      value: schedule.dueDays,
                    },
                    {
                      label: t(
                        'policy.acknowledgementSchedules.nextScheduleAt'
                      ),
                      value: datetimeHelper.formatDate(schedule.nextScheduleAt),
                    },
                    {
                      label: t('policy.acknowledgementSchedules.groups'),
                      value:
                        schedule.groups.length > 0
                          ? schedule.groups.map((x) => x.name).join(', ')
                          : '--',
                    },
                    {
                      label: t('policy.acknowledgementSchedules.policies'),
                      value:
                        schedule.policies.length > 0
                          ? schedule.policies
                              .map((x) => `${x.heading} : ${x.title}`)
                              .join(', ')
                          : '--',
                    },
                    {
                      label: t('policy.acknowledgementSchedules.status.title'),
                      value: (
                        <Badge
                          variant='inline'
                          badgeContent={t(
                            `policy.acknowledgementSchedules.status.${schedule.status}`
                          )}
                          color={
                            schedule.status === 'ENABLED' ? 'success' : 'info'
                          }
                          sx={{ textTransform: 'uppercase' }}
                        />
                      ),
                    },
                  ]}
                />
              </CardContent>
            </Card>
          ))}
        {!schedulesQuery.isFetching &&
          !schedulesQuery.isLoading &&
          schedules.length === 0 && (
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 250,
                    textAlign: 'center',
                  }}
                >
                  <Stack spacing={3}>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                        width: 150,
                        margin: '0 auto !important',
                        textAlign: 'center',
                        background: `radial-gradient(circle, #FFF 0%, #FFF 28%, #004B8725 29%, #FFF 60%)`,
                        borderRadius: 9999,
                      }}
                    >
                      <CalendarMonth
                        color='primary'
                        fontSize='large'
                      />
                    </Box>
                    <Typography variant='h6'>
                      {t('policy.acknowledgementSchedules.empty.title')}
                    </Typography>
                    <Typography>
                      {t('policy.acknowledgementSchedules.empty.subtitle')}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          )}
      </Stack>
    </DetailView>
  );
};

export default IssuedManualsAcknowledgementSchedules;
