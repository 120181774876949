import type {EditorThemeClasses} from 'lexical';

import './PhxEditorTheme.css';

const theme: EditorThemeClasses = {
  autocomplete: 'PhxEditorTheme__autocomplete',
  blockCursor: 'PhxEditorTheme__blockCursor',
  characterLimit: 'PhxEditorTheme__characterLimit',
  code: 'PhxEditorTheme__code',
  codeHighlight: {
    atrule: 'PhxEditorTheme__tokenAttr',
    attr: 'PhxEditorTheme__tokenAttr',
    boolean: 'PhxEditorTheme__tokenProperty',
    builtin: 'PhxEditorTheme__tokenSelector',
    cdata: 'PhxEditorTheme__tokenComment',
    char: 'PhxEditorTheme__tokenSelector',
    class: 'PhxEditorTheme__tokenFunction',
    'class-name': 'PhxEditorTheme__tokenFunction',
    comment: 'PhxEditorTheme__tokenComment',
    constant: 'PhxEditorTheme__tokenProperty',
    deleted: 'PhxEditorTheme__tokenProperty',
    doctype: 'PhxEditorTheme__tokenComment',
    entity: 'PhxEditorTheme__tokenOperator',
    function: 'PhxEditorTheme__tokenFunction',
    important: 'PhxEditorTheme__tokenVariable',
    inserted: 'PhxEditorTheme__tokenSelector',
    keyword: 'PhxEditorTheme__tokenAttr',
    namespace: 'PhxEditorTheme__tokenVariable',
    number: 'PhxEditorTheme__tokenProperty',
    operator: 'PhxEditorTheme__tokenOperator',
    prolog: 'PhxEditorTheme__tokenComment',
    property: 'PhxEditorTheme__tokenProperty',
    punctuation: 'PhxEditorTheme__tokenPunctuation',
    regex: 'PhxEditorTheme__tokenVariable',
    selector: 'PhxEditorTheme__tokenSelector',
    string: 'PhxEditorTheme__tokenSelector',
    symbol: 'PhxEditorTheme__tokenProperty',
    tag: 'PhxEditorTheme__tokenProperty',
    url: 'PhxEditorTheme__tokenOperator',
    variable: 'PhxEditorTheme__tokenVariable',
  },
  embedBlock: {
    base: 'PhxEditorTheme__embedBlock',
    focus: 'PhxEditorTheme__embedBlockFocus',
  },
  hashtag: 'PhxEditorTheme__hashtag',
  heading: {
    h1: 'PhxEditorTheme__h1',
    h2: 'PhxEditorTheme__h2',
    h3: 'PhxEditorTheme__h3',
    h4: 'PhxEditorTheme__h4',
    h5: 'PhxEditorTheme__h5',
    h6: 'PhxEditorTheme__h6',
  },
  hr: 'PhxEditorTheme__hr',
  image: 'editor-image',
  indent: 'PhxEditorTheme__indent',
  inlineImage: 'inline-editor-image',
  layoutContainer: 'PhxEditorTheme__layoutContainer',
  layoutItem: 'PhxEditorTheme__layoutItem',
  link: 'PhxEditorTheme__link',
  list: {
    checklist: 'PhxEditorTheme__checklist',
    listitem: 'PhxEditorTheme__listItem',
    listitemChecked: 'PhxEditorTheme__listItemChecked',
    listitemUnchecked: 'PhxEditorTheme__listItemUnchecked',
    nested: {
      listitem: 'PhxEditorTheme__nestedListItem',
    },
    olDepth: [
      'PhxEditorTheme__ol1',
      'PhxEditorTheme__ol2',
      'PhxEditorTheme__ol3',
      'PhxEditorTheme__ol4',
      'PhxEditorTheme__ol5',
    ],
    ul: 'PhxEditorTheme__ul',
  },
  ltr: 'PhxEditorTheme__ltr',
  mark: 'PhxEditorTheme__mark',
  markOverlap: 'PhxEditorTheme__markOverlap',
  paragraph: 'PhxEditorTheme__paragraph',
  quote: 'PhxEditorTheme__quote',
  rtl: 'PhxEditorTheme__rtl',
  table: 'PhxEditorTheme__table',
  tableAddColumns: 'PhxEditorTheme__tableAddColumns',
  tableAddRows: 'PhxEditorTheme__tableAddRows',
  tableCell: 'PhxEditorTheme__tableCell',
  tableCellActionButton: 'PhxEditorTheme__tableCellActionButton',
  tableCellActionButtonContainer:
    'PhxEditorTheme__tableCellActionButtonContainer',
  tableCellEditing: 'PhxEditorTheme__tableCellEditing',
  tableCellHeader: 'PhxEditorTheme__tableCellHeader',
  tableCellPrimarySelected: 'PhxEditorTheme__tableCellPrimarySelected',
  tableCellResizer: 'PhxEditorTheme__tableCellResizer',
  tableCellSelected: 'PhxEditorTheme__tableCellSelected',
  tableCellSortedIndicator: 'PhxEditorTheme__tableCellSortedIndicator',
  tableResizeRuler: 'PhxEditorTheme__tableCellResizeRuler',
  tableSelected: 'PhxEditorTheme__tableSelected',
  tableSelection: 'PhxEditorTheme__tableSelection',
  text: {
    bold: 'PhxEditorTheme__textBold',
    code: 'PhxEditorTheme__textCode',
    italic: 'PhxEditorTheme__textItalic',
    strikethrough: 'PhxEditorTheme__textStrikethrough',
    subscript: 'PhxEditorTheme__textSubscript',
    superscript: 'PhxEditorTheme__textSuperscript',
    underline: 'PhxEditorTheme__textUnderline',
    underlineStrikethrough: 'PhxEditorTheme__textUnderlineStrikethrough',
  },
};

export default theme;
