import React from 'react';
// import contentHelper from 'helpers/contentHelper';
import { Avatar, colors, Tooltip } from '@mui/material';
import parse from 'html-react-parser';
import {
  DraftManual,
  DraftPolicy,
  DraftPolicyItem,
  DraftPolicySection,
  DraftPolicySubSection,
  DraftPreface,
  DraftPrefaceItem,
} from 'types/manual';

export default class draftManualHelper {
  static getNumberManualNode = (node: any, manual: DraftManual) => {
    if (!manual || !node) return '';

    const chapterMap = new Map(
      manual.chapters.filter((ch) => ch.state !== 'DISABLED').map((chapter, index) => [chapter.id, index + 1])
    );
    const policyMap = new Map(
      manual.chapters
        .filter((ch) => ch.state !== 'DISABLED')
        .flatMap((chapter, chapterIndex) =>
          chapter.policies
            .filter((pol) => pol.state !== 'DISABLED')
            .map((policy, policyIndex) => [policy.id, `${(chapterIndex + 1) * 100 + policyIndex}`])
        )
    );
    return chapterMap.get(node.id)?.toString() || policyMap.get(node.id) || '';
  };

  static formatManualNodeTitle = (node: any, manual: DraftManual) => {
    let result = parse(node?.title ?? '');
    const num = node ? this.getNumberManualNode(node, manual) : '';
    if (num?.length > 0) {
      result = `${num}: ${result}`;
    }
    return result;
  };

  static getNumberPolicyNode = (node: any, policy: DraftPolicy) => {
    let result = '';
    if (policy?.state !== 'DISABLED' && policy.sections) {
      policy.sections
        .filter((s) => s.state !== 'DISABLED')
        .forEach((section, sectionIndex) => {
          if (section.id === node.id) {
            result = `${sectionIndex + 1}`;
          } else {
            section.subSections
              ?.filter((ss) => ss.state !== 'DISABLED')
              .forEach((subSection, subSectionIndex) => {
                if (subSection.id === node.id) {
                  result = [sectionIndex + 1, subSectionIndex + 1].join('.');
                }
              });
          }
        });
    }
    return result;
  };

  static formatPrefaceLockInfo = (preface: DraftPreface | DraftPrefaceItem) => {
    let result = '';
    if (preface.lock) {
      result = preface.lock.firstName
        ? [preface.lock.firstName, preface.lock.lastName].join(' ')
        : preface.lock.username;
    }
    return result;
  };

  static prefaceLockAvatar = (preface: DraftPreface | DraftPrefaceItem) => {
    let result = '';
    if (preface.lock) {
      result = preface.lock.firstName
        ? [preface.lock.firstName, preface.lock.lastName]
            .filter((x) => x !== undefined)
            .map((x) => x![0].toLocaleUpperCase())
            .join('')
        : preface.lock.username[0].toLocaleUpperCase();
    } else {
      return null;
    }
    return (
      <Tooltip title={draftManualHelper.formatPrefaceLockInfo(preface)}>
        <Avatar
          color='info'
          sx={{ width: 24, height: 24, mr: 2 }}
        >
          {result}
        </Avatar>
      </Tooltip>
    );
  };

  static formatPolicyNodeTitle = (node: any, policyNumber: string, policy: DraftPolicy) => {
    let result = parse(node?.title ?? '');
    const num = node ? this.getNumberPolicyNode(node, policy) : '';
    if (num?.length > 0) {
      result = `${policyNumber}.${num}: ${result}`;
    }
    return result;
  };

  static formatPolicyLockInfo = (policy: DraftPolicy | DraftPolicyItem) => {
    let result = '';
    if (policy.lock) {
      result = policy.lock.firstName ? [policy.lock.firstName, policy.lock.lastName].join(' ') : policy.lock.username;
    }
    return result;
  };

  static policyLockAvatar = (policy: DraftPolicy | DraftPolicyItem, compact = false) => {
    let result = '';
    if (policy.lock) {
      result = policy.lock.firstName
        ? [policy.lock.firstName, policy.lock.lastName]
            .filter((x) => x !== undefined)
            .map((x) => x![0].toLocaleUpperCase())
            .join('')
        : policy.lock.username[0].toLocaleUpperCase();
    } else {
      return null;
    }
    return (
      <Tooltip title={draftManualHelper.formatPolicyLockInfo(policy)}>
        <Avatar
          sx={{
            width: compact ? 20 : 36,
            height: compact ? 20 : 36,
            backgroundColor: colors.deepPurple[500],
            color: '#FFF',
            fontSize: compact ? 10 : 18,
          }}
        >
          {result}
        </Avatar>
      </Tooltip>
    );
  };

  static insertSectionAfter = (sectionId: string, newId: string, policyData: DraftPolicy) => {
    const sectionIndex = policyData.sections.findIndex((section) => section.id === sectionId);

    let tag: 'FEDERAL' | 'STATE' | 'BEST_PRACTICE' | 'DISCRETIONARY' | 'AGENCY_CONTENT' | 'ACCREDITATION' =
      'AGENCY_CONTENT';
    if (policyData.manualLevel === 'GLOBAL') tag = 'FEDERAL';
    if (policyData.manualLevel === 'JURISDICTION') tag = 'STATE';

    const newSection: DraftPolicySection = {
      id: newId,
      title: 'Section Title',
      content: '',
      subSections: [],
      state: 'ENABLED',
      agencyModified: true,
      editLevelTag: tag,
    };
    const updatedSections = [
      ...policyData.sections.slice(0, sectionIndex + 1),
      newSection,
      ...policyData.sections.slice(sectionIndex + 1),
    ];
    console.log('insert section', updatedSections);

    return {
      ...policyData,
      sections: updatedSections,
    };
  };

  static insertSubSectionAfter = (sectionId: string, newId: string, policyData: DraftPolicy, subsectionId?: string) => {
    let section: DraftPolicySection | null = null;
    let sectionIndex = -1;
    let subSectionIndex = -1;
    policyData.sections.forEach((s, ind) => {
      if (s.id === sectionId) {
        section = s;
        sectionIndex = ind;
        if (subsectionId) {
          subSectionIndex = section.subSections?.findIndex((subsection) => subsection.id === subsectionId) ?? -1;
        }
      }
    });

    let tag: 'FEDERAL' | 'STATE' | 'BEST_PRACTICE' | 'DISCRETIONARY' | 'AGENCY_CONTENT' | 'ACCREDITATION' =
      'AGENCY_CONTENT';
    if (policyData.manualLevel === 'GLOBAL') tag = 'FEDERAL';
    if (policyData.manualLevel === 'JURISDICTION') tag = 'STATE';

    const newSubSection: DraftPolicySubSection = {
      id: newId,
      title: 'Subsection Title',
      content: '',
      state: 'ENABLED',
      agencyModified: true,
      editLevelTag: tag,
    };
    const updatedSections = [
      ...policyData.sections.slice(0, sectionIndex),
      {
        ...policyData.sections[sectionIndex],
        subSections: [
          ...(policyData.sections[sectionIndex].subSections ?? []).slice(0, subSectionIndex + 1),
          newSubSection,
          ...(policyData.sections[sectionIndex].subSections ?? []).slice(subSectionIndex + 1),
        ],
      },
      ...policyData.sections.slice(sectionIndex + 1),
    ];

    return {
      ...policyData,
      sections: updatedSections,
    };
  };

  static getEnabledSectionIndex = (sectionId: string, policy: DraftPolicy) => {
    const enabledSections = policy.sections.filter((section) => section.state !== 'DISABLED');
    return enabledSections.findIndex((section) => section.id === sectionId) + 1;
  };

  static getEnabledSubSectionIndex(sectionId: string, subSectionId: string, policy: DraftPolicy) {
    const section = policy.sections.find((section) => section.id === sectionId);

    if (!section) return null;

    const enabledSubSections = (section.subSections ?? []).filter((subSection) => subSection.state !== 'DISABLED');
    return enabledSubSections.findIndex((subSection) => subSection.id === subSectionId) + 1;
  }
}
