import { LexicalNodeReplacement, ParagraphNode, TextNode, type Klass, type LexicalNode } from 'lexical';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';

import { CollapsibleContainerNode } from '../plugins/CollapsiblePlugin/CollapsibleContainerNode';
import { CollapsibleContentNode } from '../plugins/CollapsiblePlugin/CollapsibleContentNode';
import { CollapsibleTitleNode } from '../plugins/CollapsiblePlugin/CollapsibleTitleNode';
import { AutocompleteNode } from './AutocompleteNode';
import { EmojiNode } from './EmojiNode';
import { ImageNode } from './ImageNode';
import { InlineImageNode } from './InlineImageNode/InlineImageNode';
import { KeywordNode } from './KeywordNode';
import { LayoutContainerNode } from './LayoutContainerNode';
import { LayoutItemNode } from './LayoutItemNode';
import { MentionNode } from './MentionNode';
import { PageBreakNode } from './PageBreakNode';
import { PollNode } from './PollNode';
import { StickyNode } from './StickyNode';
import { TextEntityNode } from "./TextEntityNode";
import { PhxParagraphNode } from './PhxParagraphNode';
import { PhxLinkNode } from './PhxLinkNode';
import { PhxTextNode } from './PhxTextNode';
import { PhxHeadingNode } from './PhxHeadingNode';
import { PhxQuoteNode } from './PhxQuoteNode';
import { PhxCodeNode } from './PhxCodeNode';

const PhoenixNodes: Array<Klass<LexicalNode> | LexicalNodeReplacement> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  PollNode,
  StickyNode,
  ImageNode,
  InlineImageNode,
  MentionNode,
  EmojiNode,
  AutocompleteNode,
  KeywordNode,
  HorizontalRuleNode,
  MarkNode,
  CollapsibleContainerNode,
  CollapsibleContentNode,
  CollapsibleTitleNode,
  PageBreakNode,
  LayoutContainerNode,
  LayoutItemNode,
  TextEntityNode,
  PhxParagraphNode,
  {
    replace: ParagraphNode,
    with: (node: ParagraphNode) => {
      return new PhxParagraphNode();
    }
  },
  PhxLinkNode,
  {
    replace: LinkNode,
    with: (node: LinkNode) => {
      return new PhxLinkNode(
        node.__url,
        { rel: node.__rel, target: node.__target, title: node.__title },
      );
    }
  },
  PhxTextNode,
  {
    replace: TextNode,
    with: (node: TextNode) => {
      return new PhxTextNode(
        node.__text
      );
    }
  },
  PhxHeadingNode,
  {
    replace: HeadingNode,
    with: (node: HeadingNode) => {
      return new PhxHeadingNode(
        node.__tag,
      );
    }
  },
  PhxQuoteNode,
  {
    replace: QuoteNode,
    with: (node: QuoteNode) => {
      return new PhxQuoteNode();
    }
  },
  PhxCodeNode,
  {
    replace: CodeNode,
    with: (node: CodeNode) => {
      return new PhxCodeNode(node.__language);
    }
  },
];

export default PhoenixNodes;
