import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { enqueueSnackbar } from 'notistack';
import parse from 'html-react-parser';
import { useQueryClient } from 'react-query';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Close as RemoveIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import useIssuedManualGroups, {
  ManualGroup,
} from 'data_sources/issuedManuals/useIssuedManualGroups';
import useManageAcknowledgementSchedules from 'data_sources/issuedManuals/useManageAcknowledgementSchedules';
import { NewManualAcknowledgementScheduleRequest } from 'types/acknowledgements';
import { QuickSearch } from 'components/_atoms';

enum ScheduleFrequency {
  Once = 'ONCE',
  Monthly = 'MONTH',
  Quarterly = 'QUARTERYEAR',
  SemiAnnually = 'HALFYEAR',
  Annually = 'YEAR',
}

const NewAcknowledgementSchedule: React.FC = () => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const [groupSearch, setGroupSearch] = useState<string>('');
  const manualQuery = useIssuedManual(id || '');
  const manualGroupsQuery = useIssuedManualGroups(
    id || '0',
    { search: groupSearch },
    { enabled: !!id }
  );
  const [params, setParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>('settings');
  const [scheduleTitle, setScheduleTitle] = useState<string>('');
  const [startAt, setStartAt] = useState<Dayjs>(dayjs().add(1, 'day'));
  const [frequency, setFrequency] = useState<string>(ScheduleFrequency.Monthly);
  const [dueDays, setDueDays] = useState<number>(30);
  const [selectedGroups, setSelectedGroups] = useState<ManualGroup[]>([]);
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [groupRef, groupInView] = useInView();

  const { create: createManualAcknowledgementSchedule } =
    useManageAcknowledgementSchedules();

  const addPolicies = (policies: string[]) => {
    setSelectedPolicies((prev) => {
      return Array.from(new Set([...prev, ...policies])) as string[];
    });
  };

  React.useEffect(() => {
    if (groupInView) {
      manualGroupsQuery.fetchNextPage();
    }
  }, [groupInView, manualGroupsQuery]);

  const groupsTrigger = manualGroupsQuery.hasNextPage ? (
    <Stack key='groups-trigger' ref={groupRef} direction='row' spacing={4}>
      <Button
        onClick={() => manualGroupsQuery.fetchNextPage()}
        disabled={
          !manualGroupsQuery.hasNextPage || manualGroupsQuery.isFetchingNextPage
        }
      >
        {manualGroupsQuery.hasNextPage ? t('common.loadMore') : ''}
      </Button>
    </Stack>
  ) : null;

  useEffect(() => {
    const timer = window.setTimeout(() => {
      const sec = params.get('s');
      if (sec) {
        const el = document.getElementById(`polsec_${sec}`);
        if (el) {
          el.scrollIntoView(true);
        }
      }
    }, 2000);
    return () => {
      window.clearTimeout(timer);
    };
  }, [params]);

  const manualGroups = useMemo(() => {
    let result =
      manualGroupsQuery.data && manualGroupsQuery.data.pages
        ? manualGroupsQuery.data.pages.flatMap((x) => x.content)
        : [];
    return result;
  }, [manualGroupsQuery.data]);

  const handleSaveClick = () => {
    setIsSaving(true);
    const data: NewManualAcknowledgementScheduleRequest = {
      startAt: startAt.toISOString().split('T')[0],
      policies: selectedPolicies.map((x) => ({ id: x })),
      groups: selectedGroups.map((x) => ({ id: x.id })),
      dueDays: dueDays,
      frequency: frequency as keyof typeof ScheduleFrequency,
      title: scheduleTitle,
    };
    if (id) {
      createManualAcknowledgementSchedule(
        id,
        data,
        (jobId) => {
          setIsSaving(false);
          enqueueSnackbar(
            parse(
              t(`policy.acknowledgementSchedules.new.saveConfirmation`, '', {
                title: scheduleTitle ?? '',
              })
            ),
            {
              variant: 'success',
              autoHideDuration: 6000,
            }
          );

          queryClient.invalidateQueries([
            'issued_manual_acknowledgment_schedules',
          ]);
          navigate(`../${id}/acknowledgement-schedules`);
        },
        (errors) => {
          setIsSaving(false);
          enqueueSnackbar(errors.message, {
            variant: 'error',
            autoHideDuration: 20000,
          });
        }
      );
    }
  };

  const disableSave = () => {
    let disabled: boolean = isSaving;
    if (selectedGroups.length === 0 || selectedPolicies.length === 0)
      disabled = true;
    if (dueDays < 1) disabled = true;
    if (scheduleTitle.length < 1) disabled = true;

    return disabled;
  };

  return (
    <DetailView
      title={t('policy.acknowledgementSchedules.new.title')}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('policy.issuedManuals.title')}
        </Link>,
        <Link key='b2' href={`../${id}`}>
          {manualQuery.data?.name}
        </Link>,
        <Typography key='b5' component='span'>
          {t('policy.acknowledgementSchedules.new.title')}
        </Typography>,
      ]}
      query={manualQuery}
      toolbarLeft={
        <Stack direction='row' spacing={3}>
          {['settings', 'content', 'groups'].map((x) => (
            <Button
              key={x}
              onClick={() => setCurrentTab(x)}
              variant={x === currentTab ? 'contained' : 'text'}
            >
              <Stack direction='row' spacing={2}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t(`policy.acknowledgementSchedules.new.tabs.${x}`)}
                </Typography>
                {x === 'content' && (
                  <Badge
                    variant='inline'
                    badgeContent={selectedPolicies.length.toString()}
                  />
                )}
                {x === 'groups' && (
                  <Badge
                    variant='inline'
                    badgeContent={selectedGroups.length.toString()}
                  />
                )}
              </Stack>
            </Button>
          ))}
        </Stack>
      }
      toolbarRight={
        <Stack direction='row' spacing={3}>
          <Button variant='text' href={`../${id}/acknowledgement-schedules`}>
            {t('common.cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={handleSaveClick}
            disabled={disableSave()}
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      {currentTab === 'settings' && (
        <Card>
          <CardContent>
            <Grid container columnSpacing={4} rowGap={6}>
              <Grid xs={6} sm={6}>
                <TextField
                  id='title'
                  key='title'
                  label={t('policy.acknowledgementSchedules.new.fields.title')}
                  variant='outlined'
                  fullWidth
                  required
                  value={scheduleTitle}
                  onChange={({ target }) => setScheduleTitle(target.value)}
                  inputProps={{
                    maxLength: 60,
                  }}
                />
              </Grid>
              <Grid xs={6} sm={6}></Grid>
              <Grid xs={3} sm={3}>
                <DatePicker
                  label={t(
                    'policy.acknowledgementSchedules.new.fields.startAt'
                  )}
                  value={startAt}
                  disablePast
                  onChange={(newDate) => setStartAt(newDate ?? dayjs())}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid xs={9} sm={9}></Grid>

              <Grid xs={3} sm={3}>
                <FormControl key='frequency' fullWidth>
                  <InputLabel id='frequency-label' required>
                    {t('policy.acknowledgementSchedules.new.fields.recurrence')}
                  </InputLabel>
                  <Select
                    id='frequency'
                    label={t(
                      'policy.acknowledgementSchedules.new.fields.recurrence'
                    )}
                    fullWidth
                    defaultValue={frequency}
                    onChange={({ target }) => {
                      setFrequency(target.value);
                    }}
                  >
                    {Object.entries(ScheduleFrequency).map(([k, v]) => (
                      <MenuItem key={`frequency_${v}`} value={v}>
                        {t(`policy.acknowledgementSchedules.frequency.${v}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={9} sm={9}></Grid>
              <Grid xs={6} sm={6}>
                <TextField
                  id='due_days'
                  key='due_days'
                  label={t(
                    'policy.acknowledgementSchedules.new.fields.dueDays'
                  )}
                  variant='outlined'
                  value={dueDays.toString()}
                  onChange={({ target }) =>
                    setDueDays(
                      Number(target.value) > 0 ? Number(target.value) : 0
                    )
                  }
                  inputProps={{
                    pattern: '[0-9]*',
                    maxLength: 2,
                  }}
                />
              </Grid>
              <Grid xs={6} sm={6}></Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {currentTab === 'content' && (
        <Card>
          <CardContent>
            <Stack spacing={4}>
              {(manualQuery.data?.chapters ?? []).map((chapter) => (
                <Stack spacing={2} key={`chapter_${chapter.id}`}>
                  <Stack
                    direction='row'
                    spacing={2}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      {[chapter.key, chapter.title].join(': ')}
                    </Typography>
                    <Button
                      size='small'
                      variant='outlined'
                      color='neutral'
                      onClick={() => {
                        addPolicies(
                          (chapter.policies ?? []).map((x) => x.id) ?? []
                        );
                      }}
                    >
                      {t('policy.acknowledgementSchedules.selectAll')}
                    </Button>
                  </Stack>
                  {chapter.policies?.map((policy) => (
                    <Stack
                      direction='row'
                      spacing={2}
                      key={`policies_${policy.id}`}
                    >
                      <Checkbox
                        checked={
                          selectedPolicies.filter((x) => x === policy.id)
                            .length > 0
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.checked) {
                            addPolicies([policy.id]);
                          } else {
                            setSelectedPolicies((prev) =>
                              prev.filter((x) => x !== policy.id)
                            );
                          }
                        }}
                        value={policy.id}
                        name='selected_policies'
                        sx={{
                          p: 0,
                        }}
                      />
                      <Typography>
                        {[policy.key, policy.title].join(': ')}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              ))}
            </Stack>
          </CardContent>
        </Card>
      )}
      {currentTab === 'groups' && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 4,
          }}
        >
          <Card>
            <CardHeader
              title={'Groups With Access'}
              subheader={
                <QuickSearch
                  id='groups_search'
                  initialValue={groupSearch}
                  onChange={(val) => setGroupSearch(val)}
                  placeholder={t(
                    'policy.acknowledgements.groups.searchPlaceholder'
                  )}
                />
              }
            />
            <CardContent
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Fragment key={`groups_all`}>
                  <Checkbox
                    checked={selectedGroups.length === manualGroups.length}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        setSelectedGroups(manualGroups);
                      } else {
                        setSelectedGroups([]);
                      }
                    }}
                    value={[]}
                    name='selected_groups'
                    sx={{
                      p: 0,
                    }}
                  />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>All groups</Typography>
                  </Stack>
                </Fragment>
                {manualGroups.map((group) => (
                  <Fragment key={`groups_${group.id}`}>
                    <Checkbox
                      checked={
                        selectedGroups.filter((x) => x.id === group.id).length >
                        0
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked) {
                          setSelectedGroups((prev) => [...prev, group]);
                        } else {
                          setSelectedGroups((prev) =>
                            prev.filter((x) => x.id !== group.id)
                          );
                        }
                      }}
                      value={group.id}
                      name='selected_groups'
                      sx={{
                        p: 0,
                      }}
                    />
                    <Stack>
                      <Typography sx={{ fontWeight: 600 }}>
                        {group.name}
                      </Typography>
                      <Typography variant='caption'>
                        {group.description}
                      </Typography>
                    </Stack>
                  </Fragment>
                ))}
                {groupsTrigger}
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={'Selected Groups'} />
            <CardContent
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              {selectedGroups.length > 0 ? (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  {selectedGroups.map((group) => (
                    <Fragment key={`selected_groups_${group.id}`}>
                      <IconButton
                        onClick={() =>
                          setSelectedGroups((prev) =>
                            prev.filter((x) => x.id !== group.id)
                          )
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Stack>
                        <Typography sx={{ fontWeight: 600 }}>
                          {group.name}
                        </Typography>
                        <Typography variant='caption'>
                          {group.description}
                        </Typography>
                      </Stack>
                    </Fragment>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    minHeight: '40vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src='/img/policy/acknowledgements/empty_groups.png'
                      alt={t('policy.acknowledgements.groups.empty.title')}
                    />
                    <Typography variant='subtitle1'>
                      {t('policy.acknowledgements.groups.empty.title')}
                    </Typography>
                    <Typography variant='body2'>
                      {t('policy.acknowledgements.groups.empty.subtitle')}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
    </DetailView>
  );
};

export default NewAcknowledgementSchedule;
