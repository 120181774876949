import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  PASTE_COMMAND,
  LexicalCommand,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { useEffect } from 'react';

function OnPastePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) return;

    const unregisterPaste = editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND as LexicalCommand<ClipboardEvent>,
      (event: ClipboardEvent) => {
        event.preventDefault();

        const html = event.clipboardData?.getData('text/html');

        if (html) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');

          const elementsToRemove = doc.body.querySelectorAll(
            '*:not(b):not(strong):not(u):not(i):not(em):not(ul):not(ol):not(li):not(a):not(br):not(pre):not(code):not(p):not(div):not([style*="color"]):not([style*="background-color"]):not([style*="text-align"])'
          );

          elementsToRemove.forEach((element) => {
            while (element.firstChild) {
              element.parentNode?.insertBefore(element.firstChild, element);
            }
            element.parentNode?.removeChild(element);
          });

          const replaceMarginLeftWithTextIndent = (element: HTMLElement) => {
            const style = window.getComputedStyle(element);
            const marginLeft = style.getPropertyValue('margin-left');

            if (marginLeft && marginLeft.endsWith('px')) {
              const marginLeftValue = parseFloat(marginLeft);
              if (!isNaN(marginLeftValue) && marginLeftValue > 0) {
                element.style.textIndent = `${marginLeftValue}px`;
                element.style.marginLeft = '';
              }
            }
          };
          const blockElements = doc.body.querySelectorAll('p, div, pre, code');
          blockElements.forEach((block) => {
            replaceMarginLeftWithTextIndent(block as HTMLElement);
          });

          const nodes = $generateNodesFromDOM(editor, doc);
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              try {
                $insertNodes(nodes);
              } catch (error) {
                console.error('Error during paste operation:', error);
              }
            }
          });
        }

        return true;
      },
      1
    );

    return () => {
      unregisterPaste();
    };
  }, [editor]);

  return null;
}

export default OnPastePlugin;
