import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link, Stack } from '@mui/material';

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import PropertyList from 'components/_layouts/PropertyList';
import useOrganization from 'data_sources/organizations/useOrganization';
import useOrganizationUser from 'data_sources/users/useOrganizationUser';
import datetimeHelper from 'helpers/datetimeHelper';

export default function Overview() {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const userQuery = useOrganizationUser(currentOrganizationId || '', id || '');
  const [ssoOrg, setSsoOrg] = useState<boolean>(false);
  const orgQuery = useOrganization(currentOrganizationId || '');

  React.useEffect(() => {
    if (orgQuery.data && orgQuery.data?.cognitoPool?.authenticationType === 'SSO') {
      setSsoOrg(true);
    } else {
      setSsoOrg(false);
    }
  }, [orgQuery.data]);

  return (
    <Stack spacing={12}>
      <PropertyList
        columns={3}
        items={
          userQuery.data && userQuery.data.lastName
            ? [
                {
                  label: t('users.properties.email'),
                  value: userQuery.data?.email ? (
                    <Link
                      href={`mailto:${userQuery.data?.email}`}
                      target='_blank'
                    >
                      {' '}
                      {userQuery.data?.email}
                    </Link>
                  ) : (
                    '--'
                  ),
                },
                {
                  label: t('users.properties.username'),
                  value: userQuery.data?.username,
                },
                {
                  label: t('users.properties.organizationUserId'),
                  value: userQuery.data?.organizationUserId,
                },
                ...(ssoOrg
                  ? [
                      {
                        label: t('users.properties.ssoLinkValue'),
                        value: userQuery.data?.ssoLinkValue,
                      },
                    ]
                  : []),
                {
                  label: t('users.properties.jobTitle'),
                  value: userQuery.data?.jobTitle,
                },
                {
                  label: t('users.properties.alias'),
                  value: userQuery.data?.alias,
                },
                {
                  label: t('users.properties.phone'),
                  value: userQuery.data?.phone,
                },
                {
                  label: t('users.properties.createdAt'),
                  value: datetimeHelper.formatDate(userQuery.data?.createdAt),
                },
                {
                  label: t('users.properties.updatedAt'),
                  value: datetimeHelper.formatDate(userQuery.data?.updatedAt),
                },
              ]
            : []
        }
      />
    </Stack>
  );
}
