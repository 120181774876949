import { DraftPreface, LockInfo } from 'types/manual';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

type NewPrefaceRequest = {
  title: string;
};

export default function useManagePreface() {
  const create = async (
    manualId: string,
    data: NewPrefaceRequest,
    callback?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexiverse/prefaces/v1/manuals/${manualId}/prefaces`;
    await fetcher({
      url,
      method: 'post',
      body: data,
    })
      .then((data) => {
        if (callback) callback(data.id);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  const update = async (
    manualId: string,
    prefaceId: string,
    data: DraftPreface,
    callback?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexiverse/prefaces/v1/manuals/${manualId}/prefaces/${prefaceId}`;
    await fetcher({
      url,
      method: 'put',
      body: data,
    })
      .then((data) => {
        if (callback) callback(data.id);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  const getLock = async (
    manualId: string,
    prefaceId: string,
    callback?: (lockInfo: LockInfo) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexiverse/prefaces/v1/manuals/${manualId}/prefaces/${prefaceId}/lock`;
    await fetcher({
      url,
      method: 'post',
      body: {},
    })
      .then((data) => {
        if (callback) callback(data);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  const takeLock = async (
    manualId: string,
    prefaceId: string,
    callback?: (lockInfo: LockInfo) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexiverse/prefaces/v1/manuals/${manualId}/prefaces/${prefaceId}/lock`;
    await fetcher({
      url,
      method: 'patch',
      body: {},
    })
      .then((data) => {
        if (callback) callback(data);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  const removeLock = async (
    manualId: string,
    prefaceId: string,
    callback?: (lockInfo: LockInfo) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexiverse/prefaces/v1/manuals/${manualId}/prefaces/${prefaceId}/lock`;
    await fetcher({
      url,
      method: 'delete',
      body: {},
    })
      .then((data) => {
        if (callback) callback(data);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  return {
    create,
    getLock,
    removeLock,
    takeLock,
    update,
  };
}
