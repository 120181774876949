import { useInfiniteQuery } from 'react-query';

import { PendingAcknowledgement } from '../../types/acknowledgements';
import { ApiCollectionResponse } from '../../types/api_response';
import { baseApiUrl, fetcher, infiniteEmptyResults } from '../constants';
import { ServerErrorResponse } from '../constants';

export default function useUserPendingAcknowledgements<
  T extends PendingAcknowledgement,
>(
  userId: string,
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexipub/acknowledgements/v1/admin/users/${userId}`;

  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['user_pending_acknowledgements', userId, params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(
        Object.entries({
          ...params,
        })
          // eslint-disable-next-line no-unused-vars
          .filter(([_k, v]) => v != null)
          .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
      ).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.offset &&
          lastPage.offset < (lastPage.count || 0) &&
          lastPage.content.length > 0
        ) {
          const paramString = new URLSearchParams(
            Object.entries({
              ...params,
              offset: lastPage.offset,
            })
              // eslint-disable-next-line no-unused-vars
              .filter(([_k, v]) => v != null)
              .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
          ).toString();

          return `${url}?${paramString}`;
        }
      },
    }
  );
}
