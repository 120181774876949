import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { DraftManual } from 'types/manual';

export default function useDraftManual(
  id?: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexiverse-manual-api/v1/manuals/${id}`;
  return useQuery<DraftManual, Error>(
    ['draft_manual', id],
    async () => {
      const data = await fetcher({
        method: 'get',
        url,
      });
      return data;
    },
    {
      ...options,
      enabled: !!id,
      keepPreviousData: true,
    }
  );
}
