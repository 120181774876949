import { useInfiniteQuery } from 'react-query';

import { CursorCollectionResponse } from '../../types/api_response';
import { infiniteEmptyResults } from '../constants';
import { baseApiUrl, fetcher, getToken, ServerErrorResponse } from '../constants';
import { Notification } from 'types/notifications';

export default function useNotifications<T extends Notification>(
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/notifications/v1/admin`;
  const idToken = getToken();

  return useInfiniteQuery<CursorCollectionResponse<T>, Error>(
    ['notifications', idToken, params],
    async ({ pageParam, signal }): Promise<CursorCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: CursorCollectionResponse<T> = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal }
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
      getNextPageParam: (lastPage) => {
        if (lastPage.lastKey && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            lastKey: lastPage.lastKey,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

          return `${url}?${paramString}`;
        }
      }
    }
  );
}