import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  colors,
  Stack,
  Typography,
} from '@mui/material';

import { AppContext } from '../../context';
import useTranslations from 'hooks/useTranslations';
import useNotifications from "data_sources/notifications/useNotifications";
import errorHelper from 'helpers/errorHelper';
import NotificationItem from './item';
import NotificationDetail from './detail';
import { Notification } from 'types/notifications';
import useManageNotification from 'data_sources/notifications/useManageNotification';
import { useQueryClient } from 'react-query';
import { isString } from 'lodash';
import notificationsHelper from './notificationsHelper';


const parseData = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return { data: jsonString }
  }
}


export default function Notifications() {
  const { setCurrentModule, setMainNavCollapsed } = useContext(AppContext);
  const t = useTranslations();
  const navigate = useNavigate();
  const notificationsQuery = useNotifications();
  const { id } = useParams();
  const { updateState } = useManageNotification();
  const queryClient = useQueryClient();
  const [notificationGroups, setNotificationGroups] = useState<{ [key: string]: Notification[] }>({});

  useEffect(() => {
    setCurrentModule('dashboard');
    setMainNavCollapsed(true);
  }, [setCurrentModule, setMainNavCollapsed]);

  const notifications = React.useMemo(() => notificationsQuery.data && notificationsQuery.data.pages ?
    notificationsQuery.data.pages.flatMap((x) => x.content.map((x) => ({
      ...x,
      notificationData: isString(x.notificationData) ? parseData(x.notificationData) : x.notificationData,
    }))) : []
    , [notificationsQuery.data])



  useEffect(() => {
    setNotificationGroups(notificationsHelper.groupNotifications(notifications));
  }, [notifications]);

  const toggleRead = (notification: Notification) => {
    updateState(
      notification.id,
      notification.state === 'unread' ? 'read' : 'unread',
      () => {
        queryClient.invalidateQueries('notifications');
        queryClient.invalidateQueries('notifications_unread_count');
      }
    )
  };

  const handleItemClick = useCallback((notificationId: string) => {
    navigate(`/portal/notifications/${notificationId}`);
  }, [navigate]);

  const selectedNotification = notifications.find((x) => x.id === id);

  return (
    <Box
      className='notifications'
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        height: 'calc(100vh - 130px)',
        backgroundColor: '#FFF',
      }}
    >
      <Box
        sx={{
          borderRight: `solid 1px ${colors.grey[100]}`,
          minHeight: 0,
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <Box
          sx={{
            px: 4,
            py: 4,
            borderBottom: `solid 1px ${colors.grey[100]}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='subtitle1'>{t('notifications.title')}</Typography>
        </Box>
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {notificationsQuery.isError ? errorHelper.renderErrors(notificationsQuery) : (
            <>
              {Object.entries(notificationGroups).map(([group, items]) => items.length > 0 ? (
                <Stack key={group}>
                  <Typography
                    component='div'
                    variant='overline'
                    sx={{
                      px: 4,
                      py: 1,
                      borderBottom: `solid 1px ${colors.grey[100]}`,
                    }}
                  >
                    {t(`notifications.groups.${group}`)}
                  </Typography>
                  {items.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      item={notification}
                      selected={id === notification.id}
                      onClick={handleItemClick}
                      toggleRead={toggleRead}
                    />
                  ))}
                </Stack>
              ) : null)}

            </>
          )}
        </Box>
      </Box>
      <Box>
        {selectedNotification && <NotificationDetail notification={selectedNotification} toggleRead={toggleRead} />}
      </Box>
    </Box>
  );
}
