import React, { forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './TreeItem.module.css';
import { Handle } from './HandleComponent';

import { FlattenedItem } from 'types/manual';
import { Box, ListItem, Typography, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export interface Props extends HTMLAttributes<HTMLLIElement> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  item: any;
  value: string | JSX.Element;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
  onSectionChange: (updatedSection: FlattenedItem) => void;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      value,
      wrapperRef,
      onSectionChange,
      item,
      ...props
    },
    ref
  ) => {
    const handleToggleState = () => {
      onSectionChange({
        ...item,
        state: item.state === 'ENABLED' ? 'DISABLED' : 'ENABLED',
      });
    };

    return (
      <ListItem
        className={classNames(
          styles.Wrapper,
          clone && styles.clone,
          ghost && styles.ghost,
          indicator && styles.indicator,
          disableSelection && styles.disableSelection,
          disableInteraction && styles.disableInteraction
        )}
        ref={wrapperRef}
        style={
          {
            paddingTop: '0px',
            paddingBottom: '0px',
            '--spacing': `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        disableGutters
        {...props}
      >
        <Box
          className={classNames(styles.TreeItem, {
            [styles.disabled]: item.state === 'DISABLED',
          })}
          ref={ref}
          style={style}
          display='flex'
          alignItems='center'
          paddingY={1}
          paddingX={2}
          borderRadius={1}
          boxShadow={item.state === 'DISABLED' ? 'none' : 1}
          width={!clone ? '100%' : '40%'}
        >
          <Handle {...handleProps} />
          <Typography
            className={styles.Text}
            flexGrow={1}
            noWrap
          >
            {value}
          </Typography>
          {clone && childCount && childCount > 1 ? (
            <Box
              className={styles.Count}
              component='span'
            >
              {childCount}
            </Box>
          ) : null}
          {!clone && (
            <IconButton
              size='small'
              color='default'
              onClick={handleToggleState}
            >
              {item.state === 'DISABLED' ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          )}
        </Box>
      </ListItem>
    );
  }
);
