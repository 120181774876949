import React from "react";
import {
  IconButton,
  Drawer,
  Toolbar,
  Stack,
  Typography,
  Box,
  Link,
  Tooltip,
} from '@mui/material';
import {
  AttachFile,
  Close,
} from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import { Policy } from 'types/policy';
// import PropertyList, { PropertyListItem } from "components/_layouts/PropertyList";
import contentHelper, { PolicyAttachment } from "helpers/contentHelper";

type PolicyAttachmentsProps = {
  policy: Policy;
}

const PolicyAttachments: React.FC<PolicyAttachmentsProps> = ({
  policy,
}: PolicyAttachmentsProps) => {
  const t = useTranslations();
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);

  const attachments: PolicyAttachment[] = [];
  if (policy.sections) {
    policy.sections.forEach((s) => {
      const sAttachments = contentHelper.extractAttachments(s.content);
      sAttachments.forEach((x) => {
        attachments.push({
          sectionKey: s.key,
          section: s.title,
          attachmentKey: x.key,
          attachmentName: x.name,
        })
      });
      if (s.subSections) {
        s.subSections.forEach((ss) => {
          const ssAttachments = contentHelper.extractAttachments(ss.content);
          ssAttachments.forEach((x) => {
            attachments.push({
              sectionKey: ss.key,
              section: ss.title,
              attachmentKey: x.key,
              attachmentName: x.name,
            })
          });
        })
      }
    })
  }

  const toggleAttachmentsDrawer = () => {
    setShowDrawer((prev) => !prev);
  }

  return attachments.length > 0 ? (
    <>
      <Tooltip title={t('policy.manuals.properties.attachments')}>
        <IconButton onClick={toggleAttachmentsDrawer}>
          <AttachFile />
        </IconButton>
      </Tooltip>
      <Drawer
        className='right-panel'
        anchor='right'
        variant='persistent'
        open={showDrawer}
        PaperProps={{
          sx: {
            width: 350
          }
        }}
      >
        <Toolbar />
        <Toolbar className='phx-panel-header'>
          <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
            <Typography variant='h6'>{t('policy.manuals.properties.attachments')}</Typography>
          </Stack>
          <Stack direction='row' spacing={4}>
            <IconButton onClick={toggleAttachmentsDrawer}>
              <Close />
            </IconButton>
          </Stack>
        </Toolbar>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Stack spacing={6}>
            {attachments.map((attachment, ind) => (
              <Stack key={`attachment_${ind}`} spacing={2}>
                <Typography sx={{ fontWeight: 'bold' }}>{[attachment.sectionKey, attachment.section].join(': ')}</Typography>
                <Link
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    contentHelper.handleDownloadAttachment(attachment.attachmentKey, attachment.attachmentName || 'file');
                  }}
                >
                  <Stack direction='row' spacing={2}>
                    <AttachFile />
                    <Tooltip title={attachment.attachmentName || ''}>
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {attachment.attachmentName}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Link>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Drawer>
    </>
  ) : null;
}

export default PolicyAttachments;