import React, { useCallback } from 'react';
import DropdownColorPicker from '../../ui/DropdownColorPicker';
import { LexicalEditor } from 'lexical';
import { CHANGE_FONT_COLOR_COMMAND } from '../../nodes/PhxTextNode';

type FontColorProps = {
  editor: LexicalEditor;
  disabled?: boolean;
  fontColor: string;
};

export default function FontColorFormat({
  editor,
  disabled = false,
  fontColor,
}: FontColorProps): JSX.Element {
  const handleColorChange = useCallback(
    (color: string) => {
      editor.dispatchCommand(CHANGE_FONT_COLOR_COMMAND, color);
    },
    [editor]
  );

  return (
    <DropdownColorPicker
      disabled={disabled}
      buttonAriaLabel="Font Color Picker"
      buttonClassName="toolbar-item"
      buttonIconClassName="font-color-icon"
      color={fontColor}
      onChange={handleColorChange}
    />
  );
}
