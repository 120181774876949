import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  colors,
  InputAdornment,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import { Chapter } from '../types/manual';
import manualHelper from '../helpers/manualHelper';
import { TitleTextField } from '../styles';
import { ActionMenu } from 'components/_atoms';

type ChapterEditorProps = {
  id: string;
  onChange: (id: string, chapter: Chapter) => void;
  onSave: (id: string, chapter: Chapter) => void;
};

const ChapterEditor: React.FC<ChapterEditorProps> = ({
  id,
  onChange,
  onSave,
}: ChapterEditorProps) => {
  const [chapterData, setChapterData] = useState<Chapter | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const chapter = manualHelper.getChapter(id);
    // console.log('chapter-editor', chapter)
    setChapterData(chapter);
  }, [id]);

  const updateChapterData = (updatedData: any) => {
    setChapterData((prev) => ({ ...prev, ...updatedData }));
  };

  useEffect(() => {
    if (chapterData) onChange(id, chapterData);
  }, [id, onChange, chapterData]);

  return chapterData ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle1">{`Edit chapter: ${manualHelper.formatManualNodeTitle(chapterData)}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button onClick={() => onSave(id, chapterData)} variant="contained">
              Save
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        <Toolbar
          className="phx-toolbar"
          sx={{ gap: 4, borderRadius: '4px !important' }}
        >
          <Stack direction="row" spacing={4} sx={{ flexGrow: 1 }}>
            <TitleTextField
              id={`title_${chapterData.id}`}
              key={`title_${chapterData.id}`}
              label={false}
              variant="outlined"
              fullWidth
              value={chapterData?.title}
              onChange={({ target }) =>
                updateChapterData({ title: target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: 20,
                        fontWeight: 600,
                      },
                    }}
                  >
                    {manualHelper.getNumberManualNode(chapterData)}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" spacing={4}>
            <ActionMenu id={`action_menu_${id}`} items={[]} />
          </Stack>
        </Toolbar>
      </Box>
      <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
        <Box sx={{ minHeight: 0 }}>
          <Stack spacing={3}>
            {chapterData.policies.map((policy) => (
              <Box
                onClick={() => navigate(`policy:${policy.id}`)}
                key={policy.id}
                sx={{
                  cursor: 'pointer',
                  p: 4,
                  border: `solid 1px ${colors.grey[100]}`,
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: colors.grey[50],
                  },
                }}
              >
                {manualHelper.formatManualNodeTitle(policy)}
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default ChapterEditor;
