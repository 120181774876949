import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useLegacyArchivedManualVersion() {
  const retrieve = async (manualId: string, versionId: string, callback?: () => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexipub/manuals/archived/v1/legacy-manuals/${manualId}/versions/${versionId}`;
    await fetcher({
      url,
      method: 'post',
      body: {},
    }).then((data) => {
      if (callback) callback();
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    retrieve,
  }
}
