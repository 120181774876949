
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
} from '@mui/material';
import { $getRoot, $insertNodes, EditorState, LexicalEditor } from 'lexical';
import {
  $generateNodesFromDOM,
  $generateHtmlFromNodes,
} from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';

import { lexicalEditorConfig } from './editorConfig';
import AutocompletePlugin from './plugins/AutocompletePlugin';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import CommentPlugin from './plugins/CommentPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
// import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import PageBreakPlugin from './plugins/PageBreakPlugin';
import PollPlugin from './plugins/PollPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import ContentEditable from './ui/ContentEditable';
import PhxOnChangePlugin from './plugins/PhxOnChangePlugin';
import TextEntitiesPlugin from './plugins/TextEntityPlugin';

import type { Manual, ManualPreface } from '../ManualCompare/manual';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { TableContext } from './plugins/TablePlugin';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';

import './ui/styles.css';

export const MuiContentEditable = styled(ContentEditable)({
  minHeight: 200,
  width: "100%",
  padding: "0 8px",
  borderRadius: 5,
  paddingTop: 2,
  paddingLeft: 10,
  position: "relative",
  outline: "none",
  '& .text-entity': {
    padding: '2px 4px',
    backgroundColor: colors.blue[50],
    borderRadius: 8,
    display: 'inline-flex',
    '&:before': {
      content: '"{{"'
    },
    '&:after': {
      content: '"}}"'
    }
  }
});

export const placeHolderSx = {
  position: "absolute",
  top: 15,
  left: 10,
  userSelect: "none",
  display: "inline-block",
  pointerEvents: "none",
};

export default function Lexical() {
  const editorStateRef = useRef<EditorState | null>(null);
  const [preface, setPreface] = useState<ManualPreface>();

  function onChange(editor: LexicalEditor, editorState: any) {
    // console.log('editorState', JSON.stringify(editorState));
    editorStateRef.current = editorState;
    localStorage.setItem('lexical-poc', JSON.stringify(editorState.toJSON()));
    editor.update(() => {
      const htmlContent = $generateHtmlFromNodes(editor);
      localStorage.setItem('lexical-poc-html', htmlContent);
    })
  }

  function onCommentsChange(comments: any) {
    localStorage.setItem('lexical-poc-comments', JSON.stringify(comments))
  }
  let initialComments = [];
  try {
    const storedComments = localStorage.getItem('lexical-poc-comments');
    if (storedComments) {
      initialComments = JSON.parse(storedComments);
    }
  } catch { }

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | undefined>();
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport = window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  useEffect(() => {
    fetch('https://dj8ibq5dbhvjr.cloudfront.net/manuals/chambersburg.json')
      .then((resp) => resp.json())
      .then((manual: Manual) => {
        const preface = manual?.prefaces[0];
        if (preface) setPreface(preface)
      });
  }, []);

  return preface ? (
    <Box className='editor-shell' sx={{ p: 5 }}>
      <Card>
        <CardHeader
          title='Lexical Editor POC'
          sx={{
            backgroundColor: colors.grey[100],
            borderBottom: `solid 1px ${colors.grey[200]}`,
            p: 6,
          }}
        />
        <CardContent>
          <LexicalComposer initialConfig={{
            ...lexicalEditorConfig,
            editorState: (editor) => {
              const savedContent = localStorage.getItem('lexical-poc-html');
              if (savedContent) {
                const parser = new DOMParser();
                const content = parser.parseFromString(savedContent ?? '', 'text/html');
                const contentNodes = $generateNodesFromDOM(editor, content);
                console.log('onLoadPlugin', contentNodes)
                $getRoot().select();
                $insertNodes(contentNodes);
              } else {
                const parser = new DOMParser();
                const content = parser.parseFromString(preface?.content?.value ?? '', 'text/html');
                const contentNodes = $generateNodesFromDOM(editor, content);
                console.log('onLoadPlugin', contentNodes)
                $getRoot().select();
                $insertNodes(contentNodes);
              }
            }
          }}>
            <SharedHistoryContext>
              <TableContext>
                <SharedAutocompleteContext>
                  <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
                  <Divider />
                  <Box sx={{ position: "relative", background: "white" }}>
                    <RichTextPlugin
                      contentEditable={(
                        <div className="editor" ref={onRef}>
                          <MuiContentEditable />
                        </div>
                      )}
                      placeholder={<Box sx={placeHolderSx}>Enter some text...</Box>}
                      ErrorBoundary={LexicalErrorBoundary}
                    />
                    <DragDropPaste />
                    <AutoFocusPlugin />
                    <ClearEditorPlugin />
                    <ComponentPickerPlugin />
                    <EmojiPickerPlugin />
                    <AutoEmbedPlugin />

                    <MentionsPlugin />
                    <EmojisPlugin />
                    <HashtagPlugin />
                    <KeywordsPlugin />
                    <AutoLinkPlugin />
                    <CommentPlugin
                      initialComments={initialComments}
                      onCommentsChanged={onCommentsChange}
                    />
                    <PhxOnChangePlugin onChange={onChange} />
                    <HistoryPlugin />
                    <MarkdownShortcutPlugin />
                    <CodeHighlightPlugin />
                    <ListPlugin />
                    <CheckListPlugin />
                    <ListMaxIndentLevelPlugin maxDepth={7} />
                    <TablePlugin
                      hasCellMerge
                      hasCellBackgroundColor
                    />
                    <TableCellResizer />
                    <ImagesPlugin />
                    <InlineImagePlugin />
                    <LinkPlugin />
                    <PollPlugin />
                    <TabFocusPlugin />
                    <TabIndentationPlugin />
                    <CollapsiblePlugin />
                    <PageBreakPlugin />
                    <LayoutPlugin />
                    {floatingAnchorElem && !isSmallWidthViewport && (
                      <>
                        <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                        <FloatingLinkEditorPlugin
                          anchorElem={floatingAnchorElem}
                          isLinkEditMode={isLinkEditMode}
                          setIsLinkEditMode={setIsLinkEditMode}
                        />
                        <TableCellActionMenuPlugin
                          anchorElem={floatingAnchorElem}
                          cellMerge={true}
                        />
                        <FloatingTextFormatToolbarPlugin
                          anchorElem={floatingAnchorElem}
                          setIsLinkEditMode={setIsLinkEditMode}
                        />
                      </>
                    )}
                    <AutocompletePlugin />
                    <HorizontalRulePlugin />
                    <TextEntitiesPlugin />
                    <TreeViewPlugin />
                  </Box>
                </SharedAutocompleteContext>
              </TableContext>
            </SharedHistoryContext>
          </LexicalComposer>
        </CardContent>
      </Card>
    </Box>
  ) : null;
}
