import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { AppBar, Box, colors, IconButton, Stack, Toolbar, Typography } from '@mui/material';

// import useTranslations from 'hooks/useTranslations';
import { AppContext } from 'views/App/context';
import { DraftManualDetailContext } from './context';
import Loading from 'views/App/Portal/Loading';
import { ChevronLeft } from '@mui/icons-material';
import ToC from './ToC';
import ChapterEditor from './ChapterEditor';
import PolicyEditor from './PolicyEditor';
import { PolicyEditorProvider } from './PolicyEditor/context';
import PrefaceEditor from './PrefaceEditor';

const DraftManualsDetailPage: React.FC = () => {
  // const t = useTranslations();
  const { node } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsTakeover } = useContext(AppContext);
  const { manual, manualQuery } = useContext(DraftManualDetailContext);

  useEffect(() => {
    setIsTakeover(true);
    return () => {
      setIsTakeover(false);
    };
  }, [setIsTakeover]);

  const renderNode = (node?: string) => {
    if (!node) return null;

    const [type, id] = node.split(':');
    switch (type) {
      case 'preface':
        return <PrefaceEditor id={id} />;
      case 'chapter':
        return <ChapterEditor id={id} />;
      case 'policy':
        return (
          <PolicyEditorProvider
            key={id}
            id={id}
          >
            <PolicyEditor id={id} />
          </PolicyEditorProvider>
        );
    }
  };

  const handleBack = () => {
    navigate('/portal/policy/draft-manuals');
  };

  return manualQuery.isLoading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        maxHeight: '100vh',
        height: '100vh',
      }}
    >
      <AppBar
        color='primary'
        position='static'
        className='takeover-nav'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <Toolbar
          sx={{
            flexGrow: 1,
            px: 4,
            alignItems: 'center',
          }}
        >
          <Stack
            direction='row'
            spacing={2}
          >
            <IconButton onClick={() => handleBack()}>
              <ChevronLeft />
            </IconButton>
            <Typography variant='h3'>{parse(manual.name ?? '')}</Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'minmax(360px, 30vh) 1fr',
          maxHeight: 'calc(100vh - 64px)',
          overflowY: 'hidden',
        }}
      >
        <Box
          sx={{
            borderRight: `solid 1px ${colors.grey[100]}`,
          }}
        >
          <ToC />
        </Box>
        <Box
          sx={{
            backgroundColor: colors.grey[100],
          }}
        >
          {renderNode(node)}
        </Box>
      </Box>
    </Box>
  );
};

export default DraftManualsDetailPage;
