import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { buildTree, flattenTree, moveItem, isDescendant } from './utilities';
import { TreeItems, FlattenedItem } from '../types/policy';
import { pointerCollisionDetection } from './customCollisionDetection';
import TreeSectionEditor from '../SectionEditor/TreeSectionEditor';
import { Box } from '@mui/material';
import SortableTreeItem from './sortableTreeItem';

interface SortableTreeProps {
  items: TreeItems;
  onChange: (items: TreeItems) => void;
  indentationWidth?: number;
  policyData?: any;
}

const SortableTree: React.FC<SortableTreeProps> = ({ items, onChange, indentationWidth = 50, policyData }) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const flattenedItems: FlattenedItem[] = useMemo(() => {
    return flattenTree(items);
  }, [items]);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {}));

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  const announcements = {
    onDragStart({ active }: DragStartEvent) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }: DragMoveEvent) {
      return getMovementAnnouncement('moved', active.id, over?.id);
    },
    onDragOver({ active, over }: DragOverEvent) {
      return getMovementAnnouncement('moved over', active.id, over?.id);
    },
    onDragEnd({ active, over }: DragEndEvent) {
      return getMovementAnnouncement('dropped', active.id, over?.id);
    },
    onDragCancel({ active }: DragStartEvent) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={pointerCollisionDetection}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={sortedIds}
        strategy={verticalListSortingStrategy}
      >
        {flattenedItems.map((item) => (
          <SortableTreeItem
            key={item.id}
            id={item.id}
            policyData={policyData}
            section={item}
            sectionNumber={item.sectionIndex || ''}
            depth={item.depth}
            indentationWidth={indentationWidth}
            hasChildren={!!item.children && item.children.length > 0}
            onSectionChange={(updatedSection: FlattenedItem) => handleSectionChange(updatedSection)}
          />
        ))}
      </SortableContext>

      {createPortal(
        <DragOverlay>
          {activeId && activeItem ? (
            <Box
              sx={{
                padding: '8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#fafafa',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                opacity: 0.8,
              }}
            >
              <TreeSectionEditor
                section={activeItem}
                sectionNumber={activeItem.sectionNumber || ''}
                onChange={() => {}}
              />
            </Box>
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }

  function handleDragMove(event: DragMoveEvent) {
    setOffsetLeft(event.delta.x);
  }

  function handleDragOver(event: DragOverEvent) {
    setOverId(event.over?.id ?? null);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!active || !over) {
      resetState();
      return;
    }

    if (active.id === over.id) {
      resetState();
      return;
    }

    const overIdString = over.id.toString();
    let newParentId: string | null = null;
    let newDepth: number = 0;
    let position: 'before' | 'after' | 'inside' = 'after';

    if (overIdString.endsWith('-top') || overIdString.endsWith('-bottom')) {
      const targetId = overIdString.replace(/-(top|bottom)$/, '');
      if (isDescendant(active.id.toString(), targetId, items)) {
        alert('Cannot move a section to the top or bottom of its own descendant.');
        resetState();
        return;
      }
    }

    if (overIdString.endsWith('-top')) {
      const targetId = overIdString.replace('-top', '');
      const targetItem = flattenedItems.find((item) => item.id === targetId);

      if (!targetItem) {
        resetState();
        return;
      }

      newParentId = targetItem.parentId ?? null;
      newDepth = targetItem.depth;
      position = 'before';

      const activeIndex = flattenedItems.findIndex((item) => item.id === active.id);
      const targetIndex = flattenedItems.findIndex((item) => item.id === targetId);
      if (activeIndex !== -1 && activeIndex === targetIndex - 1) {
        resetState();
        return;
      }
    } else if (overIdString.endsWith('-bottom')) {
      const targetId = overIdString.replace('-bottom', '');
      const targetItem = flattenedItems.find((item) => item.id === targetId);

      if (!targetItem) {
        resetState();
        return;
      }

      newParentId = targetItem.parentId ?? null;
      newDepth = targetItem.depth;
      position = 'after';

      const activeIndex = flattenedItems.findIndex((item) => item.id === active.id);
      const targetIndex = flattenedItems.findIndex((item) => item.id === targetId);
      if (activeIndex !== -1 && activeIndex === targetIndex) {
        resetState();
        return;
      }
    } else if (overIdString.endsWith('-center')) {
      const targetId = overIdString.replace('-center', '');
      const targetItem = flattenedItems.find((item) => item.id === targetId);

      if (!targetItem) {
        resetState();
        return;
      }

      newParentId = targetItem.id;
      newDepth = targetItem.depth + 1;
      position = 'inside';

      if (isDescendant(active.id.toString(), newParentId, items)) {
        alert('Cannot move a section into its own descendant.');
        resetState();
        return;
      }

      if (newDepth > 1) {
        alert('Maximum depth of 1 exceeded. Subsections cannot have their own subsections.');
        resetState();
        return;
      }
    } else {
      const overItem = flattenedItems.find((item) => item.id === over.id);
      if (overItem) {
        newParentId = overItem.id;
        newDepth = overItem.depth + 1;
        position = 'inside';

        if (isDescendant(active.id.toString(), newParentId, items)) {
          alert('Cannot move a section into its own descendant.');
          resetState();
          return;
        }

        if (newDepth > 1) {
          alert('Maximum depth of 1 exceeded. Subsections cannot have their own subsections.');
          resetState();
          return;
        }
      }
    }

    let actualOverId: string | null = null;
    if (overIdString.endsWith('-top') || overIdString.endsWith('-bottom') || overIdString.endsWith('-center')) {
      actualOverId = overIdString.replace(/-(top|bottom|center)$/, '');
    } else {
      actualOverId = over.id.toString();
    }

    const hasChildren = flattenedItems.some((item) => item.parentId === active.id.toString());

    if (position === 'inside' && hasChildren) {
      alert('Cannot move a section with subsections to become a subsection of another section.');
      resetState();
      return;
    }

    const newFlattened = moveItem(flattenedItems, active.id.toString(), actualOverId, newParentId, newDepth, position);

    const newTree = buildTree(newFlattened);
    onChange(newTree);

    resetState();
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setActiveId(null);
    setOverId(null);
    setOffsetLeft(0);
  }

  function handleSectionChange(updatedSection: FlattenedItem) {
    const updateSectionInTree = (tree: TreeItems): TreeItems => {
      return tree.map((item) => {
        if (item.id === updatedSection.id) {
          return {
            ...item,
            title: updatedSection.title,
            content: updatedSection.content,
            sectionIndex: updatedSection.sectionIndex,
          };
        }
        if (item.children && item.children.length > 0) {
          return { ...item, children: updateSectionInTree(item.children) };
        }
        return item;
      });
    };

    const updatedTree = updateSectionInTree(items);
    onChange(updatedTree);
  }

  function getMovementAnnouncement(eventName: string, activeId: UniqueIdentifier, overId?: UniqueIdentifier) {
    if (overId) {
      return `${activeId} was ${eventName} over ${overId}`;
    }
    return;
  }
};

export default SortableTree;
