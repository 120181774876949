import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { User } from 'types/user';

export default function useOrganizationUser(
  organizationId: string,
  id: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users`;
  return useQuery<User, Error>(
    ['organization_user', organizationId, id],
    async () => {
      if (organizationId && id) {
        const data = await fetcher({
          method: 'get',
          url: `${url}/${id}`,
        });
        return data;
      }
    },
    {
      ...options,
      keepPreviousData: false,
      placeholderData: {} as User,
    }
  );
}
