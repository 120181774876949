import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import { TreeItem } from '..';

type TreeNodeProps = {
  hasChildren?: boolean;
  href?: string;
  item: TreeItem;
  level: number;
  onClick?: () => void;
  onToggle?: () => void;
  selected?: boolean;
};

const TreeNode: React.FC<TreeNodeProps> = ({
  hasChildren = false,
  item,
  level,
  onToggle,
  selected = false,
}: TreeNodeProps) => {
  const location = useLocation();
  return (
    <ListItemButton
      component='a'
      data-testid='tree-node'
      id={`module_subnav_${item.id}`}
      data-navitem={item.id}
      selected={item.href === location.pathname}
      sx={{ pl: level * 2, borderRadius: 2 }}
      {...{
        ...(item.href ? { href: item.href } : {}),
        ...(item.onClick ? { onClick: item.onClick } : {}),
      }}
    >
      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
      {item.label && <ListItemText primary={item.label} />}
      {hasChildren && (
        <>
          {selected ? (
            <ExpandLess data-testid='ExpandLessIcon' />
          ) : (
            <ExpandMore data-testid='ExpandMoreIcon' />
          )}
        </>
      )}
    </ListItemButton>
  );
};

export default TreeNode;
