import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { $isLinkNode } from '@lexical/link';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { $isHeadingNode } from '@lexical/rich-text';
import { $isTableNode } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  ElementFormatType,
  NodeKey,
  RangeSelection,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';

import { RedoOutlined, UndoOutlined } from '@mui/icons-material';

import { Box, IconButton, colors } from '@mui/material';

import useModal from '../../hooks/useModal';
import { getSelectedNode } from '../../utils/getSelectedNode';
import { IS_APPLE } from '../../utils/environment';

import { $isListNode, PhxListNode } from '../../nodes/PhxListNode';

import BlockFormatMenu from './BlockFormatMenu';
import ElementFormat from './ElementFormat';
import InsertMenu from './InsertMenu';
import LinkFormat from './LinkFormat';
import ListFormat from './ListFormat';
import TextFormat from './TextFormat';
import { INSERT_EXCALIDRAW_COMMAND } from '../ExcalidrawPlugin';
import { InsertTableDialog } from '../TablePlugin';

import { translate as t } from 'hooks/useTranslations';
import { InsertTextEntityDialog } from '../TextEntityPlugin/TextEntityDialog';
import FontColorFormat from './FontColorFormat';
import BackgroundColorFormat from './BgColorFormat';
import { getStyleObjectFromCSS } from '../ColorPlugin';

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

export const blockTypeToBlockName: any = {
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  'phx-paragraph': 'Normal',
  quote: 'Quote',
};

export const listTypeToBlockName: any = {
  alpha: 'Alpha List',
  bullet: 'Bulleted List',
  check: 'Check List',
  number: 'Numbered List',
};

function Divider(): JSX.Element {
  return (
    <Box
      sx={{
        borderRight: `solid 1px ${colors.grey[200]}`,
        height: '24px',
        width: 0,
        minWidth: 0,
        mx: 1,
      }}
    />
  );
}

export default function ToolbarPlugin({
  setIsLinkEditMode,
}: {
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [currentSelection, setCurrentSelection] =
    useState<RangeSelection | null>(null);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [rootType, setRootType] =
    useState<keyof typeof rootTypeToRootName>('root');
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(
    null
  );
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
  const [isCode, setIsCode] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isImageCaption, setIsImageCaption] = useState(false);
  const [fontColor, setFontColor] = useState<string>('#000000');
  const [bgColor, setBgColor] = useState<string>('#ffffff');

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setCurrentSelection(selection);
      if (activeEditor !== editor) {
        const rootElement = activeEditor.getRootElement();
        setIsImageCaption(
          !!rootElement?.parentElement?.classList.contains(
            'image-caption-container'
          )
        );
      } else {
        setIsImageCaption(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setIsCode(selection.hasFormat('code'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<PhxListNode>(
            anchorNode,
            PhxListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }

      if ($isTextNode(node)) {
        const styleString = node.getStyle();
        const styleObject = getStyleObjectFromCSS(styleString);
        setFontColor(styleObject['color'] || '#000000');
        setBgColor(styleObject['background-color'] || '#ffffff');
      } else {
        setFontColor('#000000');
        setBgColor('#ffffff');
      }

      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || 'left'
      );
    } else {
      setCurrentSelection(null);
    }
  }, [activeEditor, editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar();
    });
  }, [activeEditor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  const canViewerSeeInsertDropdown = !isImageCaption;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1px',
        alignItems: 'center',
        flexWrap: 'wrap',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: 'white',
        borderBottom: `solid 1px ${colors.grey[200]}`,
      }}
    >
      <IconButton
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        title={
          IS_APPLE
            ? `${t('contentEditor.toolbar.undo')} (⌘Z)`
            : `${t('contentEditor.toolbar.undo')} (Ctrl+Z)`
        }
        className='toolbar-item'
        aria-label={t('contentEditor.toolbar.undo')}
      >
        <UndoOutlined />
      </IconButton>
      <IconButton
        disabled={!canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        title={
          IS_APPLE
            ? `${t('contentEditor.toolbar.redo')} (⌘Y)`
            : `${t('contentEditor.toolbar.redo')} (Ctrl+Y)`
        }
        className='toolbar-item'
        aria-label={t('contentEditor.toolbar.redo')}
      >
        <RedoOutlined />
      </IconButton>
      <Divider />

      {activeEditor === editor && (
        <>
          <BlockFormatMenu
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={activeEditor}
          />
          <ListFormat
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={activeEditor}
          />
          <Divider />
          <TextFormat editor={activeEditor} disabled={!isEditable} />
          <LinkFormat
            editor={activeEditor}
            disabled={!isEditable}
            setIsLinkEditMode={setIsLinkEditMode}
          />
          <FontColorFormat
            editor={activeEditor}
            disabled={!isEditable}
            fontColor={fontColor}
          />
          <BackgroundColorFormat
            editor={activeEditor}
            disabled={!isEditable}
            bgColor={bgColor}
          />
        </>
      )}
      {canViewerSeeInsertDropdown && (
        <>
          <Divider />
          <InsertMenu
            disabled={!isEditable}
            editor={activeEditor}
            items={[
              {
                key: 'hr',
                label: t('contentEditor.toolbar.hr'),
                onClick: () => {
                  activeEditor.dispatchCommand(
                    INSERT_HORIZONTAL_RULE_COMMAND,
                    undefined
                  );
                },
              },
              {
                key: 'text_entity',
                label: t('contentEditor.toolbar.text_entity'),
                onClick: () => {
                  showModal(
                    t('contentEditor.toolbar.text_entity'),
                    (onClose) => (
                      <InsertTextEntityDialog
                        activeEditor={activeEditor}
                        onClose={onClose}
                      />
                    )
                  );
                },
              },
              {
                key: 'excalidraw',
                label: t('contentEditor.toolbar.excalidraw'),
                onClick: () => {
                  activeEditor.dispatchCommand(
                    INSERT_EXCALIDRAW_COMMAND,
                    undefined
                  );
                },
              },
              {
                key: 'table',
                label: t('contentEditor.toolbar.table'),
                onClick: () => {
                  showModal(
                    t('contentEditor.toolbar.insertTable'),
                    (onClose) => (
                      <InsertTableDialog
                        activeEditor={activeEditor}
                        onClose={onClose}
                      />
                    )
                  );
                },
              },
            ]}
          />
        </>
      )}
      <Divider />
      <ElementFormat
        disabled={!isEditable}
        value={elementFormat}
        editor={activeEditor}
      />
      {modal}
    </Box>
  );
}
