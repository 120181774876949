import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import {
  ArrowDropUp,
  ArrowDropDown,
} from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import { Policy } from 'types/policy';
import PropertyList, { PropertyListItem } from "components/_layouts/PropertyList";

type PolicyHeadersProps = {
  policy: Policy;
}

const policyHeaderKeys = [
  'effectiveDate',
  'revisedDate',
  'issuingAuthority',
];

const PolicyHeaders: React.FC<PolicyHeadersProps> = ({
  policy,
}: PolicyHeadersProps) => {
  const t = useTranslations();
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const headers: PropertyListItem[] = [];
  Object.entries(policy).forEach(([key, value]) => {
    // console.log('hdr', key, policyHeaderKeys.includes(key), value)
    if (policyHeaderKeys.includes(key) && value) {
      headers.push({
        label: t(`policy.stagedManuals.policyHeaders.${key}`),
        value: value?.toString(),
      });
    }
  });


  return headers.length > 0 ? (
    <Card>
      <CardHeader
        title={t('policy.stagedManuals.policyHeaders.title')}
        action={(
          <IconButton onClick={() => setCollapsed((prev) => !prev)}>
            {collapsed ? <ArrowDropDown /> : <ArrowDropUp />}
          </IconButton>
        )}
        sx={{ py: 1 }}
      />
      {!collapsed && (
        <CardContent sx={{ py: 1 }}>
          <PropertyList
            columns={3}
            items={headers}
          />
        </CardContent>
      )}
    </Card>
  ) : null;
}

export default PolicyHeaders;