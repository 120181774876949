import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Amplify, Hub } from 'aws-amplify';
import { ConfirmProvider } from 'material-ui-confirm';
import { enqueueSnackbar, MaterialDesignContent } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Box, IconButton, styled } from '@mui/material';
import { Close, Error, CheckCircle, Warning, Info } from '@mui/icons-material';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { queryDefaults } from '../../data_sources/constants';
import AuthUtil, { AmplifyConfig } from 'utils/AuthUtil';
import useTranslations from 'hooks/useTranslations';
import AppProvider from './context';
import ScrollToTop from './scrollToTop';
import Portal from './Portal';
import Login from './Login';
import CorsValidator from './CorsValidator';
import Authoring from './Prototypes/authoring';
import ManualDiffs from './Prototypes/ManualDiffs';
import ManualCompare from './Prototypes/ManualCompare';
import ManualViewer from './Prototypes/ManualViewer';
import Citations from './Prototypes/Citations';
import Translations from './Prototypes/Translations';
import Lexical from './Prototypes/lexical';
import TTS from './tools/TTS';
import ManualStructure from './Prototypes/authoring/ManualStructure';
import Buttons from './Prototypes/design_system/Buttons';

const queryClient = new QueryClient(queryDefaults);

function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close sx={{ color: '#FFF' }} />
    </IconButton>
  );
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent .MuiSvgIcon-root': {
    marginRight: 8,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2D7738',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#970C0C',
  },
}));

export default function App() {
  const navigate = useNavigate();
  const [userOrgId, setUserOrgId] = useState<string>('');
  const amplifyConfig = localStorage.getItem('phx-userpoolConfig')?.toString();
  const idToken = localStorage.getItem('phx-idToken')?.toString();
  if (amplifyConfig !== null) {
    const config = JSON.parse(amplifyConfig || '{}') as AmplifyConfig;
    Amplify.configure(config);
  } else {
    // console.log('amplifyConfig', amplifyConfig);
  }
  const t = useTranslations();

  useEffect(() => {
    if (amplifyConfig === null) return () => {};
    const listener = (data: any) => {
      // console.log('hub', JSON.stringify(data));
      switch (data.payload.event) {
        case 'signIn':
          console.log('user signed in');
          AuthUtil.updateSessionInfo((orgId) => {
            console.log('orgId', orgId);
            if (orgId) {
              // console.log('##', orgId)
              setUserOrgId(orgId);
              localStorage.setItem('phx-currentOrganizationId', orgId);
              const lastUrl = localStorage.getItem('phx-lastUrl');
              if (lastUrl) {
                const lastLoc = JSON.parse(lastUrl);
                if (lastLoc.pathname !== '/login' && lastLoc.pathname !== '/portal/dashboard') {
                  // disabling the redirect after login for now
                  /* navigate(
                    [lastLoc.pathname, lastLoc.search]
                      .filter((x) => x.length > 0)
                      .join('?')
                  ); */
                }
              }
            } else {
              console.log('org not found');
              enqueueSnackbar(
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('login.errors.noOrg'),
                  }}
                />,
                {
                  variant: 'error',
                  autoHideDuration: 10000,
                }
              );
              AuthUtil.clearStorage();
              navigate('/login');
            }
          });
          break;
        case 'signedOut':
          console.log('user signed out');
          break;
        case 'signIn_failure':
          console.log('user sign in failed', data.payload);
          break;
        case 'configured':
          // console.log('the Auth module is configured');
          break;
        case 'parsingCallbackUrl':
          // console.log('the Auth module is configured');
          break;
        case 'tokenRefresh':
          console.log('token refresh');
          AuthUtil.updateSessionInfo((orgId) => {
            if (orgId) {
              // console.log('##', orgId)
              setUserOrgId(orgId);
              window.location.reload();
            } else {
              console.log('token refresh session no org');
              AuthUtil.clearStorage();
              navigate('/login');
            }
          });
          break;
        case 'tokenRefresh_failure':
          console.log('token refresh failure');
          AuthUtil.clearStorage();
          navigate('/login');
          break;
        case 'cognitoHostedUI_failure':
          console.log('cognito failure');
          enqueueSnackbar(
            <div
              dangerouslySetInnerHTML={{
                __html: t('login.errors.noOrg'),
              }}
            />,
            {
              variant: 'error',
              autoHideDuration: 10000,
            }
          );
          navigate('/login');
          break;
        default:
          console.log('hub', JSON.stringify(data.payload.event));
      }
    };

    const hubCancel = Hub.listen('auth', listener);

    return () => {
      hubCancel();
    };
  }, [amplifyConfig, navigate, t]);

  if (idToken !== null && userOrgId === '') {
    AuthUtil.updateSessionInfo((orgId) => {
      if (orgId) {
        // console.log('##', orgId)
        setUserOrgId(orgId);
      } else {
        AuthUtil.clearStorage();
        navigate('/login');
      }
    });
  }

  useEffect(() => {
    if (!amplifyConfig && window.location.pathname.toLowerCase().startsWith('/portal')) {
      console.log('require config');
      navigate('/login');
    }
  }, [amplifyConfig, navigate]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider userOrgId={userOrgId}>
        <ConfirmProvider>
          <CookiesProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider
                action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                iconVariant={{
                  success: <CheckCircle />,
                  error: <Error />,
                  warning: <Warning />,
                  info: <Info />,
                }}
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                }}
              />
              <ScrollToTop />
              <Box component='main'>
                <Routes>
                  <Route
                    key='login'
                    path='/login/*'
                    element={<Login />}
                  />
                  <Route
                    key='callback'
                    path='/dashboard/*'
                    element={<Navigate to='/portal/dashboard' />}
                  />
                  <Route
                    key='portal_root'
                    path='/portal/*'
                    element={<Portal />}
                  />
                  <Route
                    key='cors_validator'
                    path='/cors-validator/*'
                    element={<CorsValidator />}
                  />
                  <Route
                    key='prototype_authoring'
                    path='/prototypes/authoring'
                    element={<Authoring />}
                  >
                    <Route
                      key='prototype_authoring_detail'
                      path=':node'
                      element={<Authoring />}
                    />
                  </Route>
                  <Route
                    key='prototype_authoring_manual_structure'
                    path='/prototypes/authoring/manual-structure'
                    element={<ManualStructure />}
                  />
                  <Route
                    key='prototype_manual_diffs'
                    path='/prototypes/manual-diffs/*'
                    element={<ManualDiffs />}
                  />
                  <Route
                    key='prototype_manual_compare'
                    path='/prototypes/manual-compare/*'
                    element={<ManualCompare />}
                  />
                  <Route
                    key='prototype_manual_viewer'
                    path='/prototypes/manual-viewer/*'
                    element={<ManualViewer />}
                  />
                  <Route
                    key='prototype_citations'
                    path='/prototypes/citations/*'
                    element={<Citations />}
                  />
                  <Route
                    key='prototype_translations'
                    path='/prototypes/translations/*'
                    element={<Translations />}
                  />

                  <Route
                    key='design_system'
                    path='/prototypes/design-system/*'
                  >
                    <Route
                      key='design_system_buttons'
                      path='buttons'
                      element={<Buttons />}
                    />
                  </Route>
                  <Route
                    key='prototype_lexical'
                    path='/prototypes/lexical/*'
                    element={<Lexical />}
                  />
                  <Route
                    key='tools_tts'
                    path='/tools/tts/*'
                    element={<TTS />}
                  />
                  <Route
                    path='*'
                    element={<Navigate to='/login' />}
                  />
                </Routes>
              </Box>
            </LocalizationProvider>
          </CookiesProvider>
        </ConfirmProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}
