import React, { useContext } from 'react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import useOrganizationUserImportResults from 'data_sources/userImports/useOrganizationUserImportResults';
import { User } from 'types/user';
import ListSection from 'components/_layouts/ListSection';
import { UserImportCounts } from 'data_sources/userImports/enums';
import { UserImportResult } from 'types/user_import';
import userImportHelper from '../userImportHelper';

type DetailsFailProps = {
  bucketName: string;
  resultKey: string;
}

type UserImportFailure = {
  user?: User;
  errMsg?: string;
}

const columnHelper = createColumnHelper<UserImportFailure>();

export default function DetailsFail({
  bucketName,
  resultKey,
}: DetailsFailProps) {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();

  const columns = [
    columnHelper.accessor('user', {
      cell: (info) => (
        <Stack>
          <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>{[
            info.row.original.user?.firstName,
            info.row.original.user?.middleName,
            info.row.original.user?.lastName,
          ].join(' ')}</Typography>
          {info.row.original.user?.email ? (
            <Link href={`mailto:${info.row.original.user?.email}`}>{info.row.original.user?.email}</Link>
          ) : 'Email missing'}
        </Stack>
      ),
      header: () => t('users.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('errMsg', {
      cell: (info) => (userImportHelper.renderErrors(info.getValue() || '{}')),
      header: () => t('common.error'),
      size: 9999,
    }),
  ];

  const resultsQuery = useOrganizationUserImportResults(currentOrganizationId ?? '', bucketName, resultKey);
  console.log('results', resultsQuery.data)
  return (
    <ListSection
      columns={columns}
      data={((resultsQuery.data ?? {}) as UserImportResult)[UserImportCounts.FAIL] ?? []}
      query={resultsQuery}
    />
  );
}
