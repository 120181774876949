import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { InsertLinkOutlined } from '@mui/icons-material';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';

import { getSelectedNode } from '../../utils/getSelectedNode';
import { sanitizeUrl } from '../../utils/url';
import { translate as t } from 'hooks/useTranslations';

type LinkFormatProps = {
  editor: LexicalEditor;
  disabled?: boolean;
  setIsLinkEditMode: Dispatch<boolean>;
};

export default function LinkFormat({
  editor,
  disabled = false,
  setIsLinkEditMode,
}: LinkFormatProps) {
  const [isLink, setIsLink] = useState(false);

  const $updateLinkToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    } else {
      setIsLink(false);
    }
  }, []);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateLinkToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateLinkToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
    } else {
      setIsLinkEditMode(false);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink, setIsLinkEditMode]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          let url: string | null;
          if (!isLink) {
            setIsLinkEditMode(true);
            url = sanitizeUrl('https://');
          } else {
            setIsLinkEditMode(false);
            url = null;
          }
          return editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor, isLink, setIsLinkEditMode]);

  return (
    <Box>
      <IconButton
        disabled={disabled}
        onClick={insertLink}
        className='toolbar-item'
        color={isLink ? 'primary' : 'neutral'}
        aria-label={t('contentEditor.toolbar.link.insert')}
        title={t('contentEditor.toolbar.link.insert')}
      >
        <InsertLinkOutlined />
      </IconButton>
    </Box>
  );
}
