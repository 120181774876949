import React from 'react';
import { Box, Button, colors, Stack, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import DetailView from 'components/_layouts/DetailView';

type ButtonSize = 'small' | 'medium' | 'large' | undefined;
type ButtonColor = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
type ButtonVariant = 'contained' | 'outlined' | 'text' | undefined;
type ButtonConfig = {
  variant?: ButtonVariant;
  color?: ButtonColor;
};

export default function Buttons() {
  const sizes: ButtonSize[] = ['small', 'medium', 'large'];
  const buttonTypes: { [key: string]: ButtonConfig } = {
    primary: {
      variant: 'contained',
      color: 'primary',
    },
    secondary: {
      variant: 'contained',
      color: 'secondary',
    },
    destructive: {
      variant: 'contained',
      color: 'error',
    },
    success: {
      variant: 'contained',
      color: 'success',
    },
    warning: {
      variant: 'contained',
      color: 'warning',
    },
    ghost: {
      variant: 'text',
      color: 'primary',
    },
  };

  return (
    <DetailView title={'Buttons'}>
      <Box
        sx={{
          table: {
            border: 'solid 1px #CCC',
            borderCollapse: 'collapse',
            width: '100%',
          },
          'th, td': {
            border: 'solid 1px #CCC',
            padding: 4,
          },
          th: {
            backgroundColor: '#EEE',
            fontWeight: 'bold',
          },
          pre: {
            backgroundColor: '#F5F5F5',
            border: 'solid 1px #CCC',
            p: 1,
            borderRadius: 1,
            m: 0,
            display: 'inline-flex',
          },
        }}
      >
        <table>
          <thead>
            <tr>
              <th>Variant</th>
              <th>Small</th>
              <th>Medium</th>
              <th>Large</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(buttonTypes).map(([key, value]) => (
              <tr>
                <td>
                  <h4>{key}</h4>
                </td>
                {sizes.map((size) => (
                  <td>
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'auto 1fr',
                      }}
                    >
                      <Box>
                        <Button
                          variant={value.variant}
                          color={value.color}
                          size={size}
                          startIcon={<ChevronLeft />}
                          endIcon={<ChevronLeft />}
                        >
                          Label
                        </Button>
                      </Box>
                      <Typography
                        variant='caption'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          backgroundColor: colors.grey[100],
                          border: `solid 1px ${colors.grey[200]}`,
                          borderRadius: 1,
                        }}
                      >
                        variant:{value.variant}, color:{value.color}, size:{size}, start & end icon
                      </Typography>
                      <Box>
                        <Button
                          variant={value.variant}
                          color={value.color}
                          size={size}
                          startIcon={<ChevronLeft />}
                          endIcon={<ChevronLeft />}
                          disabled
                        >
                          Label
                        </Button>
                      </Box>

                      <Typography
                        variant='caption'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          backgroundColor: colors.grey[100],
                          border: `solid 1px ${colors.grey[200]}`,
                          borderRadius: 1,
                        }}
                      >
                        variant:{value.variant}, color:{value.color}, size:{size}, disabled
                      </Typography>
                      <Box>
                        <Button
                          variant={value.variant}
                          color={value.color}
                          size={size}
                          startIcon={<ChevronLeft />}
                        >
                          Label
                        </Button>
                      </Box>

                      <Typography
                        variant='caption'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          backgroundColor: colors.grey[100],
                          border: `solid 1px ${colors.grey[200]}`,
                          borderRadius: 1,
                        }}
                      >
                        variant:{value.variant}, color:{value.color}, size:{size}, start icon only
                      </Typography>
                      <Box>
                        <Button
                          variant={value.variant}
                          color={value.color}
                          size={size}
                        >
                          Label
                        </Button>
                      </Box>

                      <Typography
                        variant='caption'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          backgroundColor: colors.grey[100],
                          border: `solid 1px ${colors.grey[200]}`,
                          borderRadius: 1,
                        }}
                      >
                        variant:{value.variant}, color:{value.color}, size:{size}, no icons
                      </Typography>
                      <Box>
                        <Button
                          className='icon-only'
                          variant={value.variant}
                          color={value.color}
                          size={size}
                        >
                          <ChevronLeft />
                        </Button>
                      </Box>

                      <Typography
                        variant='caption'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          backgroundColor: colors.grey[100],
                          border: `solid 1px ${colors.grey[200]}`,
                          borderRadius: 1,
                        }}
                      >
                        variant:{value.variant}, color:{value.color}, size:{size}, class: 'icon-only'
                      </Typography>
                      {size === 'small' && (
                        <>
                          <Box>
                            <Button
                              className='icon-only xs'
                              variant={value.variant}
                              color={value.color}
                              size='small'
                            >
                              <ChevronLeft />
                            </Button>
                            {` (XS version)`}
                          </Box>

                          <Typography
                            variant='caption'
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              p: 1,
                              backgroundColor: colors.grey[100],
                              border: `solid 1px ${colors.grey[200]}`,
                              borderRadius: 1,
                            }}
                          >
                            variant:{value.variant}, color:{value.color}, size:{size}, class: 'icon-only xs'
                          </Typography>
                        </>
                      )}
                    </Box>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </DetailView>
  );
}
