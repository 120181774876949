import { useInfiniteQuery } from 'react-query';
import { baseApiUrl, fetcher, infiniteEmptyResults, ServerErrorResponse } from 'data_sources/constants';
import { UserManualPermission } from 'types/permissions_response';
import { ApiCollectionResponse } from 'types/api_response';

export default function useUserManualPermissions<T extends UserManualPermission>(
  organizationId: string,
  userId: string,
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/user-permission/v1/organizations/${organizationId}/users/${userId}/manual-permissions`;
  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['user_manual_permissions', organizationId, userId, params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        size: 100,
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      getNextPageParam: (lastPage) => {
        if (lastPage.offset && (lastPage.offset < (lastPage.count || 0)) && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            offset: lastPage.offset,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

          return `${url}?${paramString}`;
        }
      }
    }
  );
}
