import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';

export type UserRolesResponseItem = {
  roleId: string;
  roleName: string;
}

export type UserRolesResponse = {
  roles: UserRolesResponseItem[];
}

export default function useUserRoles(
  organizationId: string,
  userId: string,
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users/${userId}/roles`;
  return useQuery<UserRolesResponse, Error>(
    ['user_roles', organizationId, userId],
    async ({ pageParam, signal }): Promise<UserRolesResponse> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: UserRolesResponse = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}
