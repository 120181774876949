import {
  LexicalNodeReplacement,
  ParagraphNode,
  TextNode,
  type Klass,
  type LexicalNode,
} from 'lexical';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';

import { AutocompleteNode } from './AutocompleteNode';
import { ExcalidrawNode } from './ExcalidrawNode';
import { PhxTextEntityNode } from './PhxTextEntityNode';
import { PhxParagraphNode } from './PhxParagraphNode';
import { PhxLinkNode } from './PhxLinkNode';
import { PhxListNode } from './PhxListNode/PhxListNode';
import { PhxListItemNode } from './PhxListNode/PhxListItemNode';
import { PhxTextNode } from './PhxTextNode';
import { PhxHeadingNode } from './PhxHeadingNode';
import { PhxQuoteNode } from './PhxQuoteNode';
import { PhxCodeNode } from './PhxCodeNode';

const PhoenixNodes: Array<Klass<LexicalNode> | LexicalNodeReplacement> = [
  AutocompleteNode,
  ExcalidrawNode,
  HeadingNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  HorizontalRuleNode,
  MarkNode,
  PhxTextEntityNode,
  PhxParagraphNode,
  {
    replace: ParagraphNode,
    with: (node: ParagraphNode) => {
      return new PhxParagraphNode();
    },
  },
  PhxLinkNode,
  {
    replace: LinkNode,
    with: (node: LinkNode) => {
      return new PhxLinkNode(node.__url, {
        rel: node.__rel,
        target: node.__target,
        title: node.__title,
      });
    },
  },
  PhxListNode,
  PhxListItemNode,
  PhxTextNode,
  {
    replace: TextNode,
    with: (node: TextNode) => {
      return new PhxTextNode(node.__text);
    },
  },
  PhxHeadingNode,
  {
    replace: HeadingNode,
    with: (node: HeadingNode) => {
      return new PhxHeadingNode(node.__tag);
    },
  },
  PhxQuoteNode,
  {
    replace: QuoteNode,
    with: (node: QuoteNode) => {
      return new PhxQuoteNode();
    },
  },
  PhxCodeNode,
  {
    replace: CodeNode,
    with: (node: CodeNode) => {
      return new PhxCodeNode(node.__language);
    },
  },
];

export default PhoenixNodes;
