import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQueryClient } from 'react-query';
import {
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";

import { AppContext } from '../../../../context';
// import useTranslations from 'hooks/useTranslations';
import useOrganizationGroups from 'data_sources/groups/useGroups';
import useRoleGroups from 'data_sources/roles/useRoleGroups';
import useManageRoleGroup from 'data_sources/roles/useManageRoleGroup';
import { Group } from 'types/group';
import Loading from 'views/App/Portal/Loading';
import errorHelper from 'helpers/errorHelper';

export default function Groups() {
  const { currentOrganizationId } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();
  // const t = useTranslations();
  const { id } = useParams();
  const groupsQuery = useOrganizationGroups(currentOrganizationId || '');
  const roleGroupsQuery = useRoleGroups(currentOrganizationId || '', id || '');
  const { addGroup, removeGroup } = useManageRoleGroup();

  const groups = React.useMemo(() => groupsQuery.data && groupsQuery.data.pages ?
    groupsQuery.data.pages.flatMap((x: any) => x.content) : []
    , [groupsQuery.data]);

  const roleGroups = React.useMemo(() => roleGroupsQuery.data && roleGroupsQuery.data.groups ?
    roleGroupsQuery.data.groups : []
    , [roleGroupsQuery.data]);

  const handleGroupChange = (group: Group, isEnabled: boolean) => {
    console.log('group change', group.name, isEnabled, group.id);
    setIsSaving(true);
    if (isEnabled) {
      addGroup(currentOrganizationId || '', id || '', group.id || '', () => {
        setIsSaving(false);
        queryClient.invalidateQueries(['role_groups', currentOrganizationId, id])
      });
    } else {
      removeGroup(currentOrganizationId || '', id || '', group.id || '', () => {
        setIsSaving(false);
        queryClient.invalidateQueries(['role_groups', currentOrganizationId, id])
      });
    }
  }
  if (groupsQuery.isError) {
    return errorHelper.renderErrors(groupsQuery);
  }

  if (roleGroupsQuery.isError) {
    return errorHelper.renderErrors(roleGroupsQuery);
  }

  return groupsQuery.isLoading || groupsQuery.isFetching ||
    roleGroupsQuery.isLoading || roleGroupsQuery.isFetching ? <Loading /> : (
    <Stack spacing={2}>
      {groups.map((group) => (
        <FormControlLabel key={group.id}
          sx={{
            opacity: isSaving ? 0.5 : 1,
            pointerEvents: isSaving ? 'none' : 'auto',
          }}
          control={(
            <Switch
              id={group.id}
              checked={roleGroups.map((x) => x?.groupId).includes(group?.id)}
              onChange={({ target }) => handleGroupChange(group, target.checked)}
            />
          )}
          label={group.name}
        />
      ))}
    </Stack>
  );
}
