import { useQuery } from 'react-query';
import {
  baseApiUrl,
  fetcher,
  ServerErrorResponse,
} from 'data_sources/constants';
import { Vertical } from 'types/vertical';

const url = `${baseApiUrl()}/lexiverse/verticals/v1/verticals`;

export const getVerticals = async (
  onError?: (errors: ServerErrorResponse) => void
) => {
  const respData = await fetcher({
    method: 'get',
    url: `${url}`,
  }).catch((err) => {
    console.log('error retrieving app permssions');
  });
  return respData;
};

export default function useVerticals(
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  return useQuery<Vertical[], Error>(
    ['verticals'],
    async () => getVerticals(),
    {
      ...options,
      keepPreviousData: false,
    }
  );
}
