import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useOrganizationRole from "data_sources/roles/useRole";
import useRoleUsers from "data_sources/roles/useRoleUsers";
import useRoleGroups from 'data_sources/roles/useRoleGroups';
import Overview from './Overview';
import Users from './Users';
import useUrlParams from 'hooks/useUrlParams';
import Groups from './Groups';

type Tab = {
  label: React.ReactNode;
  key: string;
  component: React.ReactNode;
}

type RoleUrlParams = {
  tab: string;
}

const defaultParams: RoleUrlParams = {
  tab: 'overview',
}

export default function RoleDetails() {
  const { currentOrganizationId, hasPermission } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const roleQuery = useOrganizationRole(currentOrganizationId || '', id || '');
  const [params, setParams] = useUrlParams(defaultParams);
  const roleUsersQuery = useRoleUsers(currentOrganizationId || '', id || '', {}, { enabled: !!hasPermission('listUsersInRole', currentOrganizationId) });
  const roleGroupsQuery = useRoleGroups(currentOrganizationId || '', id || '', {}, { enabled: !!hasPermission('listGroupsForRole', currentOrganizationId) });

  const tabs: { [key: string]: Tab } = {
    overview: {
      label: t('roles.details.tabs.details'),
      key: 'details',
      component: <Overview />,
    },
  }
  if (currentOrganizationId && hasPermission('listUsersInRole', currentOrganizationId) && hasPermission('listUsers', currentOrganizationId)) {
    tabs.users = {
      label: (
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: 600 }}>{t('roles.details.tabs.users')}</Typography>
          {roleUsersQuery.data?.pages[0] !== undefined && <Badge variant='inline' badgeContent={roleUsersQuery.data?.pages[0]?.count} />}
        </Stack>
      ),
      key: 'users',
      component: <Users />,
    }
  }
  if (currentOrganizationId && hasPermission('listGroupsForRole', currentOrganizationId) && hasPermission('listGroups', currentOrganizationId)) {
    tabs.groups = {
      label: (
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: 600 }}>{t('roles.details.tabs.groups')}</Typography>
          {roleGroupsQuery.data?.groups && <Badge variant='inline' badgeContent={roleGroupsQuery.data?.groups?.length?.toString()} />}
        </Stack>
      ),
      key: 'groups',
      component: <Groups />,
    }
  }

  const updateParams = React.useCallback((updatedParams: RoleUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  return (
    <DetailView
      title={(
        <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5'>
            {roleQuery.data?.name || ''}
          </Typography>
        </Stack>
      )}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('roles.title')}
        </Link>,
        <Typography key='b4'>{roleQuery.data?.name || ''}</Typography>
      ]}
      toolbarLeft={(
        <Stack direction='row' spacing={3}>
          {Object.entries(tabs).map(([key, tab]) => (
            <Button
              key={key}
              onClick={() => updateParams({ tab: key })}
              variant={(key === params.tab ? 'contained' : 'text')}
            >
              {tab.label}
            </Button>
          ))}
        </Stack>
      )}
      toolbarRight={(currentOrganizationId && (hasPermission('updateRole', currentOrganizationId))) ? (
        <Button
          variant="contained"
          href={`../edit/${roleQuery.data?.id}`}
        >
          {t('roles.actions.edit')}
        </Button>
      ) : null}
      query={roleQuery}
    >
      {tabs[params.tab]?.component}
    </DetailView >
  );
}
