import React from 'react';
import { GlobalStyles } from '@mui/material';

export const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        fontWeight: 400,
      },
      a: {
        color: '#004B87',
        textDecoration: 'none',
      },
      p: {
        margin: '0 0 1rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '.authenticator-ctr': {
        minHeight: '90vh',
      },
      '.amplify-accountsettings-changepassword': {
        '.amplify-button[type=submit]': {
          backgroundColor: '#004B87',
          color: '#FFFFFF',
        },
        '.amplify-button--disabled': {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        '.amplify-text--error': {
          color: 'rgb(239, 68, 68)',
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: 1.66,
          textAlign: 'left',
          margin: '-5px 14px 0px',
        },
      },
      '.MuiSimpleTreeView-root': {
        '& .MuiTreeItem-root .MuiTreeItem-content': {
          borderRadius: 0,
        },
      },
    }}
  />
);
