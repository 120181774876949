import React from 'react';
import { Box, Divider, IconButton, Drawer as MuiDrawer, Paper, Stack, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Loading from 'views/App/Portal/Loading';

interface RightSidebarProps {
  header: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  error?: string;
  onClose: () => void;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiPaper-root': {
    boxShadow: 'none',
  },
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '100%',
    }),
  },
}));

const RightSidebar = ({ header, children, open, isLoading, hasError, error, onClose }: RightSidebarProps) => {
  return (
    <Drawer
      className='right-nav-content'
      variant='permanent'
      open={open}
      anchor='right'
      sx={{
        backgroundColor: '#ffffff',
        width: open ? '30%' : '0%',
        height: '100%',
        transition: 'width 0.4s ease',
        borderRadius: 0,
        overflow: 'hidden',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      {hasError ? (
        <Typography color='error'>{error}</Typography>
      ) : isLoading ? (
        <Box sx={{ mx: 'auto' }}>
          <Loading />
        </Box>
      ) : (
        <Stack>
          <Toolbar
            className='phx-panel-header'
            sx={{ display: 'flex' }}
          >
            <Stack
              direction='row'
              spacing={4}
              sx={{ flexGrow: 1 }}
            >
              {header}
            </Stack>
            <Stack
              direction='row'
              spacing={4}
            >
              <IconButton
                aria-label='close'
                onClick={onClose}
                sx={{ ml: 'auto' }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Toolbar>
          <Stack
            sx={{
              pt: 4,
              mx: 4,
              mb: 4,
              gap: 4,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 64px)',
            }}
          >
            {children}
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};

export default RightSidebar;
