import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { OrganizationsContext } from '../context';
import useTranslations from 'hooks/useTranslations';
import FileUploader from 'components/_atoms/FileUploader';
import { NewOrganizationRequest } from 'types/organization';
import { type ServerErrorResponse } from 'data_sources/constants';
import { AUTH_TYPES } from '../context';
import Modal from 'components/_layouts/Modal';
import errorHelper from 'helpers/errorHelper';

const NewOrganization: React.FC = () => {
  const t = useTranslations();
  const { createOrganization, organizationSchema, validCountries } =
    useContext(OrganizationsContext);
  const [serverErrors, setServerErrors] = useState<ServerErrorResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const [currentCountry, setCurrentCountry] = useState('USA');
  const [showNewModal, setShowNewModal] = useState(false);
  const {
    control,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    trigger,
    watch,
  } = useForm<NewOrganizationRequest>({
    values: {
      name: '',
      city: '',
      country: 'USA',
      authenticationType: 'PASSWORD',
      minPasswordLength: 12,
      state: '',
    },
    resolver: yupResolver(organizationSchema),
  });

  const watchAuthenticationType = watch('authenticationType');
  const watchCountry = watch('country');

  useEffect(() => {
    setCurrentCountry(watchCountry);
  }, [watchCountry]);

  const handleSave: SubmitHandler<NewOrganizationRequest> = (
    values: NewOrganizationRequest
  ) => {
    setIsSaving(true);
    createOrganization(
      values,
      (guid: string) => {
        reset();
        setIsSaving(false);
        setShowNewModal(false);
      },
      (errors: ServerErrorResponse) => {
        setIsSaving(false);
        setServerErrors(errors);
      }
    );
  };

  const states = useMemo(() => {
    return (
      validCountries.find((c) => c.code3 === (currentCountry || 'USA'))
        ?.states || []
    );
  }, [currentCountry, validCountries]);

  return (
    <>
      <Button
        variant='contained'
        onClick={() => setShowNewModal(true)}
      >
        {t('organizations.actions.create')}
      </Button>
      <Modal
        title={t('organizations.new.title')}
        onSubmit={handleSubmit(handleSave)}
        size='md'
        actions={
          <>
            <Button
              color='neutral'
              href='..'
            >
              {t('common.cancel')}
            </Button>
            <Button
              onClick={() => {
                trigger();
              }}
              disabled={isSaving}
              component='button'
              type='submit'
              variant='contained'
            >
              {t('organizations.new.save')}
            </Button>
          </>
        }
        open={showNewModal}
        onClose={() => setShowNewModal(false)}
      >
        {errorHelper.renderAllErrors(serverErrors, errors)}
        <TextField
          id='name'
          key='name'
          label={t('organizations.properties.name')}
          variant='outlined'
          required
          autoFocus
          fullWidth
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : undefined}
        />
        <FormControl key='authentication_type'>
          <InputLabel
            id='authentication-type-label'
            required
          >
            {t('organizations.properties.authenticationType')}
          </InputLabel>
          <Select
            id='authentication_type'
            {...register('authenticationType')}
            label={t('organizations.properties.authenticationType')}
            defaultValue={AUTH_TYPES[0]}
          >
            {AUTH_TYPES.map((x) => (
              <MenuItem
                key={`authenticationType_${x}`}
                value={x}
              >
                {t(`organizations.authenticationTypes.${x}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {watchAuthenticationType === 'SSO' && (
          <Card>
            <CardHeader title={'SAML'} />
            <CardContent>
              <Stack spacing={3}>
                <TextField
                  id='samlMetadataURL'
                  key='samlMetadataURL'
                  label={t('organizations.properties.samlMetadataURL')}
                  variant='outlined'
                  fullWidth
                  {...register('samlMetadataURL')}
                  error={!!errors.samlMetadataURL}
                  helperText={
                    errors.samlMetadataURL
                      ? errors.samlMetadataURL.message
                      : undefined
                  }
                />
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  OR
                </Typography>
                <Controller
                  name='samlMetadataFile'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl key='samlMetadataFile'>
                      <FileUploader
                        onChange={onChange}
                        onError={(errors) => {
                          console.log('onError', errors);
                          if (errors && errors.length > 0) {
                            setError('samlMetadataFile', {
                              type: 'file-type-valid',
                              message: errors
                                .map((x) =>
                                  t(`organizations.errorMessages.${x}`)
                                )
                                .join('; '),
                            });
                          } else {
                            clearErrors('samlMetadataFile');
                          }
                        }}
                        placeholder="Drag 'n' drop or click to select a SAML file"
                        uploaderOptions={{
                          accept: {
                            'text/xml': ['.xml'],
                          },
                          maxSize: 5242880,
                          maxFiles: 1,
                        }}
                      />
                      {errors.samlMetadataFile && (
                        <FormHelperText error>
                          {errors.samlMetadataFile.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>
            </CardContent>
          </Card>
        )}
        <Grid
          container
          spacing={0}
          sx={{ gap: 4 }}
        >
          <Grid sx={{ flex: 1 }}>
            <TextField
              id='city'
              key='city'
              label={t('organizations.properties.city')}
              variant='outlined'
              required
              fullWidth
              {...register('city')}
              error={!!errors.city}
              helperText={errors.city ? errors.city.message : undefined}
            />
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Controller
              name='state'
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {states.length > 0 ? (
                    <FormControl
                      key='state_picker'
                      fullWidth
                      error={!!errors.state}
                    >
                      <InputLabel
                        id='state-label'
                        required
                      >
                        {t('organizations.properties.state')}
                      </InputLabel>
                      <Select
                        id='state_select'
                        label={t('organizations.properties.state')}
                        value={value}
                        onChange={onChange}
                        error={!!errors.state}
                      >
                        <MenuItem
                          key={`state_empty`}
                          value=''
                        >
                          --
                        </MenuItem>
                        {states.map((state: any) => (
                          <MenuItem
                            key={`state_${state.code}`}
                            value={state.code}
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.state && (
                        <FormHelperText error>
                          {errors.state.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <TextField
                      id='state'
                      key='state'
                      label={t('organizations.properties.state')}
                      variant='outlined'
                      required
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={!!errors.state}
                      helperText={
                        errors.state ? errors.state.message : undefined
                      }
                    />
                  )}
                </>
              )}
            />
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Controller
              name='country'
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  key='country'
                  fullWidth
                >
                  <InputLabel
                    id='country-label'
                    required
                  >
                    {t('organizations.properties.country')}
                  </InputLabel>
                  <Select
                    id='country'
                    label={t('organizations.properties.country')}
                    value={value}
                    onChange={onChange}
                    error={!!errors.country}
                  >
                    {validCountries.map((country) => (
                      <MenuItem
                        key={`country_${country.code3}`}
                        value={country.code3}
                      >
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.country && (
                    <FormHelperText error>
                      {errors.country.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <TextField
          id='salesforceId'
          key='salesforceId'
          label={t('organizations.properties.salesforceId')}
          variant='outlined'
          fullWidth
          {...register('salesforceId')}
          error={!!errors.salesforceId}
          helperText={
            errors.salesforceId ? errors.salesforceId.message : undefined
          }
        />
      </Modal>
    </>
  );
};

export default NewOrganization;
