import React from "react";
import contentHelper from "helpers/contentHelper";
import { Link, Typography, colors } from "@mui/material";
import { Manual } from "types/manual";

type PolicyCrossrefProps = {
  xrefKey: string;
  children: React.ReactNode;
}

const PolicyCrossref: React.FC<PolicyCrossrefProps> = ({ xrefKey, children }: PolicyCrossrefProps) => {
  const [manualLink, setManualLink] = React.useState<any>();
  const parts = xrefKey.split('/');
  // const orgId = parts[1];
  const scope = parts[3];
  const manualId = parts[4];
  const contentId = parts[6];

  const extractPath = React.useCallback((manualJson: Manual, targetId: string) => {
    let chapterId = '';
    let policyId = '';
    let sectionId = '';
    manualJson.chapters.forEach((chapter) => {
      if (chapter.policies) {
        chapter.policies.forEach((p) => {
          // console.log('policy', p.id, contentId);
          if (p.id === targetId) {
            policyId = p.id;
            chapterId = chapter.id;
          }
          if (p.sections) {
            p.sections.forEach((s) => {
              // console.log('section', s.id, contentId);
              if (s.id === targetId) {
                policyId = p.id;
                chapterId = chapter.id;
                sectionId = s.id;
              }
              if (s.subSections) {
                s.subSections.forEach((ss) => {
                  // console.log('subsection', ss.id, contentId);
                  if (ss.id === targetId) {
                    policyId = p.id;
                    chapterId = chapter.id;
                    sectionId = ss.id;
                  }
                })
              }
            })
          }
        })
      }
    });
    return {
      chapterId,
      policyId,
      sectionId,
    }
  }, [])

  React.useEffect(() => {
    contentHelper.handleRetrieveCrossref(xrefKey, (data) => {
      const { policyId, sectionId } = extractPath(data, contentId);
      // console.log('policyXref', chapterId, policyId, data);
      setManualLink(`/portal/policy/${scope}-manuals/${manualId}/policy/${policyId}?s=${sectionId}`)
    });
  }, [contentId, extractPath, manualId, scope, xrefKey]);

  return manualLink && manualLink.length > 0 ? (
    <>
      <Link href={manualLink} target='_blank' rel='noreferrer'>
        {children}
      </Link>
    </>
  ) : (
    <Typography component='span' sx={{ color: colors.grey[500] }}>{children}</Typography>
  )
}

export default PolicyCrossref