import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext } from "views/App/context";
import useTranslations from "hooks/useTranslations";
import FileUploader from "components/_atoms/FileUploader";
import { Form } from "components/_forms";
import { Organization, UpdateOrganizationAuthRequest } from "types/organization";
import useManageOrganizations from "data_sources/organizations/useManageOrganization";
import useOrganization from "data_sources/organizations/useOrganization";
import { AUTH_TYPES } from "../../Organizations/context";
import { type ServerErrorResponse } from "data_sources/constants";
import FormView from "components/_layouts/FormView";
import AttributeMapField from "./AttributeMapField";

const EditOrganizationAuthentication: React.FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentOrganizationId } = useContext(AppContext);
  const [serverErrors, setServerErrors] = useState<ServerErrorResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const organizationQuery = useOrganization(currentOrganizationId || '');

  const {
    updateAuth: updateOrganizationAuth
  } = useManageOrganizations();

  const updateOrganizationSchema = Yup.object().shape({
    authenticationType: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.authenticationType')} is required`),
  });

  const {
    clearErrors,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    trigger,
    watch,
  } = useForm<UpdateOrganizationAuthRequest>({
    values: {
      authenticationType: 'PASSWORD',
    },
    resolver: yupResolver(updateOrganizationSchema),
  });

  React.useEffect(() => {
    if (organizationQuery.data?.organization) {
      reset({
        authenticationType: organizationQuery.data.cognitoPool.authenticationType,
        attributeMap: organizationQuery.data.cognitoPool.attributeMap,
      })
    }
  }, [reset, organizationQuery.data]);

  const watchAuthenticationType = watch('authenticationType');
  const watchAttributeMap = watch('attributeMap');

  const handleSave: SubmitHandler<UpdateOrganizationAuthRequest> = (values: UpdateOrganizationAuthRequest) => {
    setIsSaving(true);
    console.log('values', values)
    if (currentOrganizationId) {
      updateOrganizationAuth(
        currentOrganizationId,
        values.authenticationType,
        values,
        (data: Organization) => {
          queryClient.invalidateQueries(['organization', currentOrganizationId]);
          navigate('..');
        },
        (errors: ServerErrorResponse) => {
          setIsSaving(false);
          setServerErrors(errors);
        }
      );
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FormView
        title={t('organizations.auth.title')}
        breadcrumbItems={[
          <Link key='b1' href='..'>
            {t('organizations.settings.title')}
          </Link>,
          <Typography key='b2'>
            {t('organizations.auth.title')}
          </Typography>
        ]}
        actions={(
          <>
            <Button color='neutral' href='..'>{t('common.cancel')}</Button>
            <Button
              onClick={() => {
                trigger();
              }}
              disabled={isSaving}
              component='button'
              type="submit"
              variant="contained"
            >
              {t('organizations.auth.save')}
            </Button>
          </>
        )}
        errors={serverErrors}
        query={organizationQuery}
      >
        <Controller
          name='authenticationType'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl key='authentication_type'>
              <InputLabel id="authentication-type-label" required>{t('organizations.properties.authenticationType')}</InputLabel>
              <Select
                id='authentication_type'
                {...register('authenticationType')}
                label={t('organizations.properties.authenticationType')}
                value={value}
                onChange={onChange}
              >
                {AUTH_TYPES.map((x) => (
                  <MenuItem key={`authenticationType_${x}`} value={x}>{t(`organizations.authenticationTypes.${x}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        {watchAuthenticationType === 'SSO' && (
          <>
            <Card>
              <CardHeader title={'SAML'} />
              <CardContent>
                <Stack spacing={3}>
                  <TextField
                    id='samlMetadataURL'
                    key='samlMetadataURL'
                    label={t('organizations.properties.samlMetadataURL')}
                    variant="outlined"
                    fullWidth
                    {...register('samlMetadataURL')}
                    error={!!errors.samlMetadataURL}
                    helperText={errors.samlMetadataURL ? errors.samlMetadataURL.message : undefined}
                  />
                  <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>OR</Typography>
                  <Controller
                    name='samlMetadataFile'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl key='samlMetadataFile'>
                        <FileUploader
                          onChange={onChange}
                          onError={(errors) => {
                            console.log('onError', errors)
                            if (errors && errors.length > 0) {
                              setError('samlMetadataFile', {
                                type: 'file-type-valid',
                                message: errors.map((x) => t(`organizations.errorMessages.${x}`)).join('; '),
                              })
                            } else {

                              clearErrors('samlMetadataFile');
                            }
                          }}
                          placeholder="Drag 'n' drop or click to select a SAML file"
                          uploaderOptions={{
                            accept: {
                              'text/xml': ['.xml']
                            },
                            maxSize: 5242880,
                            maxFiles: 1,
                          }}
                        />
                        {errors.samlMetadataFile && (
                          <FormHelperText error>{errors.samlMetadataFile.message}</FormHelperText>
                        )}
                      </FormControl>
                    )} />
                </Stack>
              </CardContent>
            </Card>
            {watchAttributeMap && Object.keys(watchAttributeMap).length > 0 && (
              <Controller
                name='attributeMap'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Card>
                    <CardHeader title={t('organizations.properties.attributeMap')} />
                    <CardContent>
                      <AttributeMapField
                        id='attribute_map'
                        initialValues={value ?? {}}
                        onChange={onChange}
                      />
                    </CardContent>
                  </Card>
                )}
              />
            )}
          </>
        )}
      </FormView>
    </Form>
  );
}


export default EditOrganizationAuthentication;