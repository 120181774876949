import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  DraftChapter,
  DraftManual,
  DraftPolicyItem,
  DraftPrefaceItem,
  FilterableItem,
  SidebarItem,
} from 'types/manual';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from 'views/App/context';
import { arrayMove } from '@dnd-kit/sortable';
import { ServerErrorResponse } from 'data_sources/constants';
import useDraftManual from 'data_sources/draftManuals/useDraftManual';
import useManageManual from 'data_sources/draftManuals/useManageManual';
import errorHelper from 'helpers/errorHelper';
import { enqueueSnackbar } from 'notistack';
import { ManualFilterOptions } from 'data_sources/draftManuals/enums';
import { useConfirm } from 'material-ui-confirm';
import useTranslations from 'hooks/useTranslations';

const DIALOG_TIMEOUT = 120000;
const FINAL_TIMEOUT = 60000;

interface ManualStructureContextProps {
  reorderedManual: DraftManual | null;
  filter: ManualFilterOptions;
  selectedChapter: string | null;
  isSaving: boolean;
  handleFilterChange: (event: any) => void;
  handleChapterClick: (chapterId: string) => void;
  handleDragEndPrefaces: (event: any) => void;
  handleDragEndChapters: (event: any) => void;
  handleDragEndPolicies: (event: any) => void;
  handleSave: () => Promise<void>;
  handleToggleState: (type: 'preface' | 'chapter' | 'policy', id: string, event: React.MouseEvent) => void;
  getFilteredItems: (type: 'prefaces' | 'chapters' | 'policies') => any;
  showDialog: boolean;
  handleDialogConfirm: () => void;
  sidebarItem: SidebarItem | null;
  setSidebarItem: (item: SidebarItem | null) => void;
}

const ManualStructureContext = createContext<ManualStructureContextProps | undefined>(undefined);

export const ManualStructureProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { update: updateManual, removeLock: removeLockManual } = useManageManual();
  const location = useLocation();
  const { setIsTakeover, currentProfile } = useContext(AppContext);
  const [reorderedManual, setReorderedManual] = useState<DraftManual | null>(null);
  const [filter, setFilter] = useState<ManualFilterOptions>(ManualFilterOptions.ALL);
  const [sidebarItem, setSidebarItem] = useState<SidebarItem | null>(null);
  const [selectedChapter, setSelectedChapter] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const mainTimerRef = useRef<NodeJS.Timeout | null>(null);
  const secondaryTimerRef = useRef<NodeJS.Timeout | null>(null);
  const confirm = useConfirm();
  const t = useTranslations();

  const { id } = useParams();
  const manualQuery = useDraftManual(id, {
    onSuccess: (data: DraftManual) => {
      setReorderedManual(data ?? ({} as DraftManual));
    },
  });

  useEffect(() => {
    setIsTakeover(true);
    return () => {
      setIsTakeover(false);
    };
  }, [setIsTakeover, location.pathname]);

  useEffect(() => {
    if (manualQuery.data && manualQuery.data?.lock?.userId !== currentProfile?.user?.id) {
      navigate(-1);
    }
    setReorderedManual(manualQuery.data ?? ({} as DraftManual));
  }, [manualQuery.data]);

  const clearTimers = () => {
    if (mainTimerRef.current) {
      clearTimeout(mainTimerRef.current);
    }
    if (secondaryTimerRef.current) {
      clearTimeout(secondaryTimerRef.current);
    }
  };

  const resetTimer = () => {
    clearTimers();
    mainTimerRef.current = setTimeout(() => {
      setShowDialog(true);
      secondaryTimerRef.current = setTimeout(() => {
        handleInactivityTimeout();
      }, FINAL_TIMEOUT);
    }, DIALOG_TIMEOUT);
  };

  const handleInactivityTimeout = () => {
    if (reorderedManual?.id) {
      removeLockManual(
        reorderedManual.id,
        () => {
          enqueueSnackbar(
            'No actions were performed for an extended period of time. You have been moved back to your previous page.',
            {
              variant: 'error',
              autoHideDuration: 6000,
            }
          );
          navigate(-1);
        },
        (error: ServerErrorResponse) => {
          errorHelper.enqueueErrors(error);
        }
      );
    }
  };

  useEffect(() => {
    resetTimer();
    return () => {
      clearTimers();
    };
  }, [reorderedManual]);

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value as ManualFilterOptions);
    resetTimer();
  };

  const handleChapterClick = (chapterId: string) => {
    if (selectedChapter !== chapterId) {
      setSelectedChapter(chapterId);
    } else {
      setSelectedChapter(null);
    }
    resetTimer();
  };

  const handleDragEndPrefaces = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setReorderedManual((prevManual) => {
        if (!prevManual) return prevManual;
        const oldIndex = prevManual.prefaces.findIndex((p) => p.id === active.id);
        const newIndex = prevManual.prefaces.findIndex((p) => p.id === over.id);
        const newPrefaces = arrayMove(prevManual.prefaces, oldIndex, newIndex);
        return { ...prevManual, prefaces: newPrefaces };
      });
    }
    resetTimer();
  };

  const handleDragEndChapters = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setReorderedManual((prevManual) => {
        if (!prevManual) return prevManual;
        const oldIndex = prevManual.chapters.findIndex((c) => c.id === active.id);
        const newIndex = prevManual.chapters.findIndex((c) => c.id === over.id);
        const newChapters = arrayMove(prevManual.chapters, oldIndex, newIndex);
        return { ...prevManual, chapters: newChapters };
      });
    }
    resetTimer();
  };

  const handleDragEndPolicies = (event: any) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    setReorderedManual((prevManual) => {
      if (!prevManual) return prevManual;

      const chapterContainingPolicy = prevManual.chapters.find((chapter) =>
        chapter.policies?.some((policy) => policy.id === active.id)
      );

      if (!chapterContainingPolicy) return prevManual;

      const oldIndex = chapterContainingPolicy.policies?.findIndex((policy) => policy.id === active.id);
      const newIndex = chapterContainingPolicy.policies?.findIndex((policy) => policy.id === over.id);

      const updatedManual = JSON.parse(JSON.stringify(prevManual));
      const updatedChapter = updatedManual.chapters.find((chapter: any) => chapter.id === chapterContainingPolicy.id);

      if (updatedChapter) {
        const reorderedPolicies = arrayMove(updatedChapter.policies, oldIndex || 0, newIndex || 0);
        updatedChapter.policies = reorderedPolicies;
      }

      return updatedManual;
    });
    resetTimer();
  };

  const handleSave = async () => {
    setIsSaving(true);
    if (reorderedManual) {
      setReorderedManual(reorderedManual);
      await updateManual(reorderedManual.id, reorderedManual);
      removeLockManual(
        reorderedManual.id,
        () => {
          queryClient.invalidateQueries(['draft_manual', reorderedManual.id]);
          enqueueSnackbar('Structure saved succesfully', {
            variant: 'success',
            autoHideDuration: 4000,
          });
          setIsSaving(false);
          navigate(-1);
        },
        (error: ServerErrorResponse) => {
          errorHelper.enqueueErrors(error);
          setIsSaving(false);
        }
      );
    }
  };

  const handleToggleState = async (type: 'preface' | 'chapter' | 'policy', id: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (type === 'chapter') {
      const chapter = reorderedManual?.chapters.find((c) => c.id === id);
      if (!chapter) return;

      if (chapter.state === 'ENABLED') {
        const enabledPolicies = chapter.policies?.filter((p) => p.state === 'ENABLED');
        if (enabledPolicies && enabledPolicies.length > 0) {
          confirm({
            description: 'Disabling this chapter will disable all policies within it. Do you want to proceed?',
            confirmationText: t('common.ok'),
            cancellationText: t('common.cancel'),
          })
            .then(() => {
              setReorderedManual((prevManual) => {
                if (!prevManual) return prevManual;
                const updatedManual = { ...prevManual };
                updatedManual.chapters = updatedManual.chapters.map((c) => {
                  if (c.id === id) {
                    return {
                      ...c,
                      state: 'DISABLED',
                      policies: c.policies?.map((p) => ({ ...p, state: 'DISABLED' })) || [],
                    };
                  }
                  return c;
                });
                return updatedManual;
              });
            })
            .catch(() => {
              event.preventDefault();
              return false;
            });
        } else {
          setReorderedManual((prevManual) => {
            if (!prevManual) return prevManual;
            const updatedManual = { ...prevManual };
            updatedManual.chapters = updatedManual.chapters.map((c) => {
              if (c.id === id) {
                return { ...c, state: 'DISABLED' };
              }
              return c;
            });
            return updatedManual;
          });
        }
      } else {
        setReorderedManual((prevManual) => {
          if (!prevManual) return prevManual;
          const updatedManual = { ...prevManual };
          updatedManual.chapters = updatedManual.chapters.map((c) => {
            if (c.id === id) {
              return {
                ...c,
                state: 'ENABLED',
                policies: c.policies?.map((p) => ({ ...p, state: 'ENABLED' })) || [],
              };
            }
            return c;
          });
          return updatedManual;
        });
      }
    } else if (type === 'policy') {
      const policyWithChapter = reorderedManual?.chapters
        .flatMap((c) => c.policies?.map((p) => ({ ...p, chapterId: c.id })) || [])
        .find((p) => p.id === id);
      if (!policyWithChapter) return;

      if (policyWithChapter.state === 'DISABLED') {
        setReorderedManual((prevManual) => {
          if (!prevManual) return prevManual;
          const updatedManual = { ...prevManual };
          updatedManual.chapters = updatedManual.chapters.map((c) => {
            if (c.id === policyWithChapter.chapterId) {
              return {
                ...c,
                state: 'ENABLED', // Enable the chapter if it's disabled
                policies:
                  c.policies?.map((p) => {
                    if (p.id === id) {
                      return { ...p, state: 'ENABLED' };
                    }
                    return p;
                  }) || [],
              };
            }
            return c;
          });
          return updatedManual;
        });
      } else {
        setReorderedManual((prevManual) => {
          if (!prevManual) return prevManual;
          const updatedManual = { ...prevManual };
          updatedManual.chapters = updatedManual.chapters.map((c) => {
            if (c.id === policyWithChapter.chapterId) {
              return {
                ...c,
                policies:
                  c.policies?.map((p) => {
                    if (p.id === id) {
                      return { ...p, state: 'DISABLED' };
                    }
                    return p;
                  }) || [],
              };
            }
            return c;
          });
          return updatedManual;
        });
      }
    } else if (type === 'preface') {
      setReorderedManual((prevManual) => {
        if (!prevManual) return prevManual;

        const updatedManual = JSON.parse(JSON.stringify(prevManual));

        updatedManual.prefaces = updatedManual.prefaces.map((preface: any) =>
          preface.id === id
            ? {
                ...preface,
                state: preface.state === 'ENABLED' ? 'DISABLED' : 'ENABLED',
              }
            : preface
        );

        return updatedManual;
      });
    }
    resetTimer();
  };

  const applyFilter = (items: FilterableItem[], filter: ManualFilterOptions) => {
    switch (filter) {
      case ManualFilterOptions.DISABLED:
        return items.filter((item) => item.state === ManualFilterOptions.DISABLED);
      case ManualFilterOptions.ENABLED:
        return items.filter((item) => item.state === ManualFilterOptions.ENABLED);
      case ManualFilterOptions.ALL:
      default:
        return items;
    }
  };

  const getFilteredItems = (type: 'prefaces' | 'chapters' | 'policies') => {
    if (!reorderedManual) return [];

    let items: (DraftPrefaceItem | DraftChapter | DraftPolicyItem)[] = [];

    switch (type) {
      case 'prefaces':
        items = reorderedManual.prefaces ?? [];
        break;
      case 'chapters':
        items = reorderedManual.chapters ?? [];
        break;
      case 'policies': {
        if (!selectedChapter) return [];
        const chapter = reorderedManual.chapters.find((c) => c.id === selectedChapter);
        items = chapter ? chapter.policies ?? [] : [];
        break;
      }
    }

    return applyFilter(items, filter);
  };

  const handleDialogConfirm = () => {
    setShowDialog(false);
    resetTimer();
  };

  const contextValue = {
    reorderedManual,
    filter,
    selectedChapter,
    isSaving,
    sidebarItem,
    handleFilterChange,
    handleChapterClick,
    handleDragEndPrefaces,
    handleDragEndChapters,
    handleDragEndPolicies,
    handleSave,
    handleToggleState,
    getFilteredItems,
    showDialog,
    handleDialogConfirm,
    setSidebarItem,
  };

  return <ManualStructureContext.Provider value={contextValue}>{children}</ManualStructureContext.Provider>;
};

export const useManualStructureContext = () => {
  const context = useContext(ManualStructureContext);
  if (!context) {
    throw new Error('useManualStructureContext must be used within a ManualStructureProvider');
  }
  return context;
};
