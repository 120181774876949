import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Cookies from 'js-cookie';

export default function useCookieState<T>(key: string, defaultValue: T, expiresSeconds: number = 3600000): [T, Dispatch<SetStateAction<T>>] {

  const [value, setValue] = useState<T>(() => {
    const stickyValue = Cookies.get(key) as string;

    return stickyValue ? JSON.parse(stickyValue) as T : defaultValue;
  });

  useEffect(() => {
    Cookies.set(key, JSON.stringify(value), { expires: (new Date(Date.now() + expiresSeconds)) });
  }, [expiresSeconds, key, value]);

  return [value, setValue];
}
