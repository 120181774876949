import React from 'react';
import parse, { domToReact, DOMNode } from 'html-react-parser';
import { enqueueSnackbar } from 'notistack';

import { Notification } from 'types/notifications';
import { baseApiUrl } from 'data_sources/constants';
import useTranslations from 'hooks/useTranslations';
import { Link, Stack } from '@mui/material';
import datetimeHelper from 'helpers/datetimeHelper';
import contentHelper from 'helpers/contentHelper';

export default class notificationsHelper {
  static getTranslationKey = (notification: Notification) => {
    let translationKey = `notifications.types.${notification.notificationType}`;
    if (notification.notificationType === 'archived_pdf_retrieval') {
      translationKey += `.${notification.notificationData?.success ?? true ? 'success' : 'failure'}`;
    }
    return translationKey;
  };
  static getSubject = (notification: Notification) => {
    const t = useTranslations();
    let interpolations = notification.notificationData;

    let subject = t(`${this.getTranslationKey(notification)}.subject`, 'Unknown notification', interpolations);

    return parse(subject ?? '', {
      replace(node: DOMNode, ind: number) {
        return notificationsHelper.handleContent(node, ind);
      },
    });
  };

  static getBody = (notification: Notification) => {
    const t = useTranslations();
    let interpolations = notification.notificationData;
    if (notification.notificationType === 'archived_pdf_retrieval') {
      interpolations.download_link = notification.notificationData.manualId
        ? `<a href="archivedManual:${encodeURIComponent(JSON.stringify(notification.notificationData))}">${t('notifications.types.archived_pdf_retrieval.success.download_link')}</a>`
        : '';
    }

    let body = t(`${this.getTranslationKey(notification)}.body`, 'Contact support for details', interpolations);

    return parse(body ?? '', {
      replace(node: DOMNode, ind: number) {
        return notificationsHelper.handleContent(node, ind);
      },
    });
  };

  static getBodyText = (notification: Notification) => {
    const t = useTranslations();
    let interpolations = notification.notificationData;
    if (notification.notificationType === 'archived_pdf_retrieval') {
      interpolations.download_link = notification.notificationData.manualId
        ? `${t('notifications.types.archived_pdf_retrieval.success.download_link')}`
        : '';
    }

    let body = t(`${this.getTranslationKey(notification)}.body`, 'Contact support for details', interpolations);

    const ctr = document.createElement('div');
    ctr.innerHTML = body;

    return ctr.innerText;
  };

  static getMessageType = (notification: Notification) => {
    const t = useTranslations();

    let messageType = t(`notifications.messageTypes.${notification.messageType}`);

    return messageType;
  };

  static getTimeStamp = (notification: Notification) => {
    return datetimeHelper.formatDateTime(notification.createdAt);
  };

  static groupNotifications = (notifications: Notification[]) => {
    const dayInMs = 24 * 60 * 60 * 1000;
    const tsToday = Date.parse(
      new Date().toLocaleDateString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
    );
    const tsYesterday = tsToday - dayInMs;
    const tsThisWeek = tsToday - new Date(tsToday).getDay() * dayInMs;
    const tsLastWeek = tsThisWeek - 7 * dayInMs;
    const notificationGroups: { [key: string]: Notification[] } = {
      unread: [],
      today: [],
      yesterday: [],
      thisWeek: [],
      lastWeek: [],
      older: [],
    };

    notifications
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt || ''))
      .forEach((n) => {
        const notificationData = {
          ...n,
        };
        if (notificationData.state === 'unread') {
          notificationGroups.unread.push(notificationData);
        } else if (Date.parse(notificationData.createdAt) >= tsToday) {
          notificationGroups.today.push(notificationData);
        } else if (Date.parse(notificationData.createdAt) >= tsYesterday) {
          notificationGroups.yesterday.push(notificationData);
        } else if (Date.parse(notificationData.createdAt) >= tsThisWeek) {
          notificationGroups.thisWeek.push(notificationData);
        } else if (Date.parse(notificationData.createdAt) >= tsLastWeek) {
          notificationGroups.lastWeek.push(notificationData);
        } else {
          notificationGroups.older.push(notificationData);
        }
      });
    return notificationGroups;
  };

  static handleContent = (node: DOMNode, ind: number) => {
    if (node.type === 'tag') {
      if (node.name === 'a') {
        if (node.attribs.href && node.attribs.href.startsWith('archivedManual:')) {
          const data = JSON.parse(decodeURIComponent(node.attribs.href.split(':')[1]));
          return (
            <Link
              onClick={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                await notificationsHelper.handleDownloadArchivedPdf(data);
              }}
            >
              <Stack
                component='span'
                direction='row'
                spacing={2}
                sx={{ display: 'inline-flex' }}
              >
                {domToReact(node.children as DOMNode[])}
              </Stack>
            </Link>
          );
        } else if (node.attribs.href && node.attribs.href.startsWith('s3://')) {
          console.log('attachment found', node.attribs.title, node.attribs.href);
          return (
            <Link
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                notificationsHelper.handleDownloadAttachment(
                  node.attribs.href.split('://')[1],
                  node.attribs.title || 'file'
                );
              }}
            >
              <Stack
                component='span'
                direction='row'
                spacing={2}
                sx={{ display: 'inline-flex' }}
              >
                {domToReact(node.children as DOMNode[])}
              </Stack>
            </Link>
          );
        } else {
          node.attribs.target = '_blank';
          node.attribs.rel = 'noopener noreferrer';
        }
      }
    }
    return node;
  };
  static handleDownloadAttachment = (key: string, filename: string) => {
    const idToken = localStorage.getItem('phx-idToken')?.toString();
    const url = `${baseApiUrl()}/notifications/v1/media?key=${encodeURIComponent(key)}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Api-Key': process.env.REACT_APP_API_GATEWAY_KEY || '',
        Authorization: idToken || '',
      },
    })
      .then((resp) => {
        console.log('attachment hdrs', resp.headers);
        return resp.blob();
      })
      .then((blob) => {
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  static handleDownloadArchivedPdf = async (data: any) => {
    const t = useTranslations();
    const idToken = localStorage.getItem('phx-idToken')?.toString();
    const url = `${baseApiUrl()}/lexipub/manuals/archived/v1/legacy-manuals/${data.manualId}/versions/${data.version}/download`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_GATEWAY_KEY || '',
          Authorization: idToken || '',
        },
      });
      const downloadData = await resp.json();
      console.log('download pdf data', downloadData);
      if (downloadData.url) {
        const resp = await fetch(downloadData.url, {
          method: 'GET',
        });
        const blob = await resp.blob();
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${contentHelper.parameterize(data.title)}_${data.version}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
      enqueueSnackbar(parse(t(`notifications.errors.downloadFailure`)), {
        variant: 'error',
        autoHideDuration: 6000,
      });
    }
  };
}
