import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import OpenAI from 'openai';
import { Marked } from '@ts-stack/markdown';
import parse from 'html-react-parser';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  colors,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import contentHelper from 'helpers/contentHelper';
import AudioPlayer from './AudioPlayer';
import useStickyState from 'hooks/useStickyState';
import { prompts } from './prompts';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { fetcher } from 'data_sources/constants';

const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];

export default function TTS() {

  const [textInput, setTextInput] = useState('');
  const [voice, setVoice] = useState(voices[0]);
  const [fileName, setFileName] = useState<string>('');
  const [apiKey, setApiKey] = useStickyState<string>('openaiKey', '');
  const [suggestion, setSuggestion] = useState<any>();
  const [mp3Content, setMp3Content] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const url = `https://client-api.dev.phoenix.lexipol.com/lexiverse/v1/tts`;

  const resetDefaults = () => {
    setFileName('');
    setMp3Content('');
  };

  const handleGenerate = async () => {
    const payload = { "model": "tts-1", "voice": voice, "input": textInput }
    setIsGenerating(true);
    await fetcher({
      url,
      method: 'POST',
      body: payload,
      options: {
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_GATEWAY_KEY || '',
          'Content-Type': 'application/json',
        } as HeadersInit,
      }
    }).then(async (data) => {
      const body = data.body;
      setMp3Content(`data:audio/mpeg;base64,${JSON.parse(body)}`);
      setIsGenerating(false);
    });
  };

  const handleDownload = async () => {
    let downloadFilename = fileName;
    if (downloadFilename.length === 0) downloadFilename = uuidv4();
    const href = mp3Content;
    if (!downloadFilename.endsWith('.mp3')) downloadFilename += '.mp3';
    // console.log('href', href)
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', downloadFilename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    resetDefaults();
    //enqueueSnackbar('Audio generated and downloaded. Check your downloads folder', { variant: 'success' });
  };

  const handleTtsSuggestions = async (tone?: string) => {
    if (!apiKey || apiKey.length === 0) return false;
    const openai = new OpenAI({
      apiKey: apiKey,
      dangerouslyAllowBrowser: true,
    });
    const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [];
    messages.push({
      role: 'system',
      content: prompts.ttsSystem(),
    });
    if (tone === 'professional') {
      messages.push({
        role: 'system',
        content: prompts.professionalSystem(),
      });
    }
    messages.push({
      role: 'user',
      content: textInput
    })

    const result = await openai.chat.completions.create({
      model: 'gpt-4o',
      response_format: { type: 'json_object' },
      messages: messages,
    });
    setSuggestion(JSON.parse(result.choices[0].message.content || '{}'));

    return true;
  }

  return (
    <Box sx={{ p: 5 }}>
      <Card>
        <CardHeader
          title='Text-to-Speech'
          sx={{
            backgroundColor: colors.grey[100],
            borderBottom: `solid 1px ${colors.grey[200]}`,
            p: 6,
          }}
        />
        <CardContent>
          <Grid container spacing={5}>
            <Grid md={6}>
              <Stack spacing={3}>
                <TextField
                  id='text_input'
                  key='text_input'
                  label='Text'
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={12}
                  value={textInput}
                  onChange={({ target }) => setTextInput(target.value)}
                />
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 5 }}>
                  <FormControl key='voice' fullWidth>
                    <InputLabel>Voice</InputLabel>
                    <Select
                      id='voice'
                      fullWidth
                      value={voice}
                      onChange={({ target }) => setVoice(target.value)}
                    >
                      {voices.map((x) => (
                        <MenuItem key={`voice_${x}`} value={x}>{contentHelper.humanize(x)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {isGenerating ? (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button
                      component='button'
                      onClick={handleGenerate}
                      variant='contained'
                      sx={{
                        width: '100%'
                      }}
                      disabled={isGenerating}
                    >
                      Generate
                    </Button>
                  )}
                </Box>
                <Box>
                  {apiKey && apiKey.length > 0 ? (
                    <Stack direction='row' spacing={3}>
                      <Button
                        component='button'
                        onClick={() => handleTtsSuggestions('professional')}
                        variant='outlined'
                        size='small'
                      >
                        Professional
                      </Button>
                      <Button
                        component='button'
                        onClick={() => handleTtsSuggestions()}
                        variant='outlined'
                        size='small'
                      >
                        Optimize TTS
                      </Button>
                    </Stack>
                  ) : (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 5 }}>
                      <TextField
                        id='api_key'
                        key='api_key'
                        label='Enter your OpenAI key to enable advanced functions'
                        variant="outlined"
                        fullWidth
                        value={apiKey}
                        onChange={({ target }) => setApiKey(target.value)}
                      />
                      <Box>
                        <Button
                          component='button'
                          onClick={handleDownload}
                          variant='outlined'
                          size='small'
                          sx={{
                            width: '100%'
                          }}
                        >
                          Save key
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
                {suggestion && (
                  <Card>
                    <CardContent>
                      {suggestion.revised_text ? (
                        <Stack spacing={2}>
                          <Stack
                            direction='row'
                            spacing={3}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              background: '#EEE',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                          >
                            <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
                              <Typography component='div'>{suggestion.revised_text}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={4} sx={{ justifySelf: 'end' }}>
                              <CopyToClipboard text={suggestion.revised_text}>
                                <IconButton>
                                  <ContentCopy />
                                </IconButton>
                              </CopyToClipboard>
                            </Stack>
                          </Stack>
                          {suggestion.explanation && (
                            <Alert
                              severity='info'>
                              <Typography variant='h6'>Explanation</Typography>
                              <Typography component='div'>{parse(Marked.parse(suggestion.explanation.toString()))}</Typography>
                            </Alert>
                          )}
                        </Stack>
                      ) : (
                        <pre>{JSON.stringify(suggestion, null, 2)}</pre>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Stack>
            </Grid>
            <Grid md={6}>
              {mp3Content && mp3Content.length > 0 && (
                <Stack spacing={3}>
                  <AudioPlayer content={mp3Content} />
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 5 }}>
                    <TextField
                      id='file_name'
                      key='file_name'
                      label='Download file name'
                      variant="outlined"
                      fullWidth
                      value={fileName}
                      onChange={({ target }) => setFileName(target.value)}
                      inputProps={{ maxLength: 32 }}
                    />
                    <Button
                      component='button'
                      onClick={handleDownload}
                      variant='contained'
                      sx={{
                        width: '100%'
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                </Stack>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
