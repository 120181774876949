import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DraftManual, ManualPermissions } from 'types/manual';
import useStickyState from 'hooks/useStickyState';
import useDraftManual from 'data_sources/draftManuals/useDraftManual';

type DraftManualDetailProviderProps = {
  children: React.ReactNode;
};

export type DraftManualDetailProviderResponse = {
  manualPermissions: ManualPermissions | null;
  setManualPermissions: (manualPermissions: ManualPermissions | null) => void;
  manualHasPermissions: (manualId: string, keys: string[]) => boolean;
  manualId: string;
  manual: DraftManual;
  manualQuery: any;
};

export const DraftManualDetailContext =
  React.createContext<DraftManualDetailProviderResponse>(
    {} as DraftManualDetailProviderResponse
  );

const DraftManualDetailProvider: ({
  children,
}: DraftManualDetailProviderProps) => any = ({ children }) => {
  const { id } = useParams();
  const manualQuery = useDraftManual(id, {
    onSuccess: (data: DraftManual) => {
      setManualPermissions((prev) => {
        prev = prev ?? {};
        prev[data.id] = (data.authorizations ?? []).map((x) => x.action).sort();
        return prev;
      });
    },
  });
  const [manualPermissions, setManualPermissions] =
    useStickyState<ManualPermissions | null>('phx-manualPermissions', null);

  const manualHasPermissions = useCallback(
    (manualId: string, keys: string[]) => {
      let permissionKeys: string[] = [];
      if (manualPermissions && manualPermissions[manualId]) {
        permissionKeys = manualPermissions[manualId];
      }
      return keys.every((x) =>
        permissionKeys.map((x) => x.toLowerCase()).includes(x.toLowerCase())
      );
    },
    [manualPermissions]
  );

  const manual = useMemo(() => {
    return manualQuery.data ?? ({} as DraftManual);
  }, [manualQuery.data]);

  const contextValue: DraftManualDetailProviderResponse = useMemo(
    () => ({
      manualPermissions,
      manualHasPermissions,
      setManualPermissions,
      manualId: id ?? '',
      manual,
      manualQuery,
    }),
    [
      manualPermissions,
      manualHasPermissions,
      setManualPermissions,
      id,
      manual,
      manualQuery,
    ]
  );

  return (
    <DraftManualDetailContext.Provider value={contextValue}>
      {children}
    </DraftManualDetailContext.Provider>
  );
};

export default DraftManualDetailProvider;
