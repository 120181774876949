import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { GridCellParams } from '@mui/x-data-grid-pro';
import useTranslations from 'hooks/useTranslations';
import { AppContext } from 'views/App/context';
import { ActionMenu, QuickSearch } from 'components/_atoms';
import GridView, { ColumnDef } from 'components/_layouts/GridView';
import { Permission } from 'types/permissions_response';
import NewOrganization from '../New';
import useOrganizations from 'data_sources/organizations/useOrganizations';
import { Organization } from 'types/organization';
import { OrganizationsContext } from '../context';
const OrganizationsList: React.FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { hasAppPermission } = useContext(AppContext);
  const { params, updateParams } = useContext(OrganizationsContext);
  const [organizationsData, setOrganizationsData] = useState<Organization[]>(
    []
  );
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
    error,
  } = useOrganizations<Organization>(params, {}, (errors) => {
    console.error('Error fetching organizations:', errors);
  });
  useEffect(() => {
    if (data && !isError) {
      const fetchedOrganizations =
        data.pages.flatMap((page) => page.content) || [];
      setOrganizationsData(fetchedOrganizations);
    }
  }, [data, isError]);
  const orgHasPermissions = useCallback(
    (keys: string[], permissions: Permission[]) => {
      const permissionKeys = permissions.map((x) => x.action.toLowerCase());
      return keys.every((x) => permissionKeys.includes(x.toLowerCase()));
    },
    []
  );
  const columns: ColumnDef = useMemo(
    () => ({
      name: {
        header: t('organizations.properties.name'),
        flex: 1.5,
        renderer: (params: GridCellParams) => {
          const row = params.row;
          if (!row) return null;
          return (
            <span
              onClick={() => {
                if (orgHasPermissions(['getOrg'], row.authorizations || [])) {
                  navigate(
                    `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/settings')}`
                  );
                }
              }}
              style={{ cursor: 'pointer', color: '#004B87', fontWeight: 600 }}
            >
              {row.name}
            </span>
          );
        },
      },
      location: {
        header: t('organizations.properties.location'),
        flex: 1,
      },
      userCount: {
        header: t('organizations.properties.userCount'),
        type: 'number',
        flex: 0.3,
        renderer: (params: GridCellParams) => {
          const row = params.row;
          if (!row) return null;
          return (
            <span
              onClick={() => {
                if (
                  orgHasPermissions(
                    ['getOrg', 'listUsers'],
                    row.authorizations || []
                  )
                ) {
                  navigate(
                    `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/users')}`
                  );
                }
              }}
              style={{ cursor: 'pointer', color: '#004B87', fontWeight: 600 }}
            >
              {row.userCount}
            </span>
          );
        },
      },
      roleCount: {
        header: t('organizations.properties.roleCount'),
        type: 'number',
        flex: 0.3,
        renderer: (params: GridCellParams) => {
          const row = params.row;
          if (!row) return null;
          return (
            <span
              onClick={() => {
                if (
                  orgHasPermissions(
                    ['getOrg', 'listRoles'],
                    row.authorizations || []
                  )
                ) {
                  navigate(
                    `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/roles')}`
                  );
                }
              }}
              style={{ cursor: 'pointer', color: '#004B87', fontWeight: 600 }}
            >
              {row.roleCount}
            </span>
          );
        },
      },
      groupCount: {
        header: t('organizations.properties.groupCount'),
        type: 'number',
        flex: 0.3,
        renderer: (params: GridCellParams) => {
          const row = params.row;
          if (!row) return null;
          return (
            <span
              onClick={() => {
                if (
                  orgHasPermissions(
                    ['getOrg', 'listGroups'],
                    row.authorizations || []
                  )
                ) {
                  navigate(
                    `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/groups')}`
                  );
                }
              }}
              style={{ cursor: 'pointer', color: '#004B87', fontWeight: 600 }}
            >
              {row.groupCount}
            </span>
          );
        },
      },
      actions: {
        header: '',
        type: 'actions',
        flex: 0.2,
        renderer: (params: GridCellParams) => {
          const row = params.row;
          if (!row) return null;
          const items = [
            ...(orgHasPermissions(
              ['getOrg', 'listUsers'],
              row.authorizations || []
            )
              ? [
                  {
                    key: `manage_users_${row.id}`,
                    label: t('organizations.actions.manageUsers'),
                    href: `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/users')}`,
                  },
                ]
              : []),
            ...(orgHasPermissions(
              ['getOrg', 'listRoles'],
              row.authorizations || []
            )
              ? [
                  {
                    key: `manage_roles_${row.id}`,
                    label: t('organizations.actions.manageRoles'),
                    href: `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/roles')}`,
                  },
                ]
              : []),
            ...(orgHasPermissions(
              ['getOrg', 'listGroups'],
              row.authorizations || []
            )
              ? [
                  {
                    key: `manage_groups_${row.id}`,
                    label: t('organizations.actions.manageGroups'),
                    href: `/portal/switch-org/${row.id}?redir=${encodeURIComponent('/portal/lexid/groups')}`,
                  },
                ]
              : []),
          ];
          return <ActionMenu id={`action_menu_${row.id}`} items={items} />;
        },
      },
    }),
    [t, navigate, orgHasPermissions]
  );
  const actions = useMemo(
    () => (hasAppPermission('createOrg') ? <NewOrganization /> : null),
    [hasAppPermission]
  );
  const transformedData = useMemo(
    () =>
      organizationsData.map((o) => ({
        ...o.organization,
        cognito: o.cognitoPool,
        location: `${o.organization.city}, ${o.organization.state}`,
      })),
    [organizationsData]
  );
  const gridViewQuery = useMemo(
    () => ({
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      isError,
      isLoading,
      error,
      data: {
        pages:
          data?.pages.map((page) => ({
            count: page.count,
            rows: page.content,
          })) || [],
      },
    }),
    [
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      isError,
      isLoading,
      error,
      data,
    ]
  );
  return (
    <GridView
      columns={columns}
      data={transformedData}
      query={gridViewQuery}
      title={t('organizations.title')}
      toolbarLeft={
        <QuickSearch
          id="org_keyword_search"
          initialValue={params.search || ''}
          onChange={(val) => updateParams({ search: val })}
          placeholder={t('organizations.search.placeholder')}
        />
      }
      toolbarRight={actions}
    />
  );
};
export default OrganizationsList;
