import React, { useContext } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { IssuedManualsContext } from '../context';
import { ActionMenu, QuickSearch } from 'components/_atoms';
import datetimeHelper from 'helpers/datetimeHelper';
import ListView from 'components/_layouts/ListView';
import { ManualItem } from 'types/manual';
import { ActionMenuItemProps } from 'components/_atoms/ActionMenu';

const columnHelper = createColumnHelper<ManualItem>();

const IssuedManualsList: React.FC = () => {
  const t = useTranslations();
  const { manuals, manualHasPermissions, manualsQuery, params, updateParams } =
    useContext(IssuedManualsContext);

  const manualActions: (manual: ManualItem) => ActionMenuItemProps[] = (
    manual
  ) => {
    const actions: ActionMenuItemProps[] = [];
    if (
      manualHasPermissions(manual.id, ['listManualAcknowledgementSchedule'])
    ) {
      actions.push({
        key: `schedules_${manual.id}`,
        label: t('policy.issuedManuals.actions.acknowledgementSchedules'),
        href: `./${manual.id}/acknowledgement-schedules`,
      });
    }
    return actions;
  };

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Link href={`./${info.row.original.id}`}>
          <Typography
            variant='h6'
            sx={{ fontWeight: 'semibold' }}
          >
            {info.getValue()}
          </Typography>
        </Link>
      ),
      header: () => t('policy.manuals.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('vertical.name', {
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => t('policy.manuals.properties.vertical'),
      size: 200,
    }),
    columnHelper.accessor('manualType.name', {
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => t('policy.manuals.properties.manualType'),
      size: 200,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => (
        <Typography>{datetimeHelper.formatDate(info.getValue())}</Typography>
      ),
      header: () => t('policy.manuals.properties.createdAt'),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) =>
        manualActions(info.row.original).length > 0 ? (
          <ActionMenu
            id={`action_menu_${info.row.original.id}`}
            items={manualActions(info.row.original)}
          />
        ) : null,
      header: () => '',
      size: 60,
    }),
  ];

  return (
    <ListView
      columns={columns}
      data={manuals}
      query={manualsQuery}
      title={t('policy.issuedManuals.title')}
      subtitle={t('policy.issuedManuals.subtitle')}
      toolbarLeft={
        <QuickSearch
          id='org_keyword_search'
          initialValue={params.q || ''}
          onChange={(val) => updateParams({ q: val })}
          placeholder={t('issuedManuals.search.placeholder')}
        />
      }
    />
  );
};

export default IssuedManualsList;
