import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import {
  FormatBoldOutlined,
  FormatItalicOutlined,
  FormatUnderlinedOutlined,
} from '@mui/icons-material';
import {
  LexicalEditor,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { IS_APPLE } from '../../utils/environment';
import { translate as t } from 'hooks/useTranslations';

type TextFormatProps = {
  editor: LexicalEditor;
  disabled?: boolean;
};

export default function TextFormat({
  editor,
  disabled = false,
}: TextFormatProps) {
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const $updateLinkToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection?.hasFormat('bold'));
      setIsItalic(selection?.hasFormat('italic'));
      setIsUnderline(selection?.hasFormat('underline'));
    } else {
      setIsBold(false);
      setIsItalic(false);
      setIsUnderline(false);
    }
  }, []);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateLinkToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateLinkToolbar]);

  return (
    <Box>
      <IconButton
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className='toolbar-item'
        color={isBold ? 'primary' : 'neutral'}
        title={
          IS_APPLE
            ? `${t('contentEditor.toolbar.format.bold')} (⌘B)`
            : `${t('contentEditor.toolbar.format.bold')} (Ctrl+B)`
        }
        aria-label={t('contentEditor.toolbar.format.bold')}
      >
        <FormatBoldOutlined />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className='toolbar-item'
        color={isItalic ? 'primary' : 'neutral'}
        title={
          IS_APPLE
            ? `${t('contentEditor.toolbar.format.italic')} (⌘I)`
            : `${t('contentEditor.toolbar.format.italic')} (Ctrl+I)`
        }
        aria-label={t('contentEditor.toolbar.format.italic')}
      >
        <FormatItalicOutlined />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className='toolbar-item'
        color={isUnderline ? 'primary' : 'neutral'}
        title={
          IS_APPLE
            ? `${t('contentEditor.toolbar.format.underline')} (⌘U)`
            : `${t('contentEditor.toolbar.format.underline')} (Ctrl+U)`
        }
        aria-label={t('contentEditor.toolbar.format.underline')}
      >
        <FormatUnderlinedOutlined />
      </IconButton>
    </Box>
  );
}
