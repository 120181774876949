import { useInfiniteQuery } from 'react-query';

import { CursorCollectionResponse } from '../../types/api_response';
import {
  baseApiUrl,
  fetcher,
  emptyResults,
  infiniteEmptyResults,
} from '../constants';
import { ServerErrorResponse } from '../constants';
import { Job } from 'types/job';
import { JobType } from './enums';

export default function useOrganizationUserImports<T extends Job>(
  organizationId: string,
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/phoenix/job-service/v1/organizations/${organizationId}/jobs/job-type/${JobType.ImportUsers.toString()}`;

  return useInfiniteQuery<CursorCollectionResponse<T>, Error>(
    ['organization_user_imports', organizationId, params],
    async ({ pageParam, signal }): Promise<CursorCollectionResponse<T>> => {
      const paramString = new URLSearchParams(
        Object.entries({
          limit: 10,
          ...params,
        })
          // eslint-disable-next-line no-unused-vars
          .filter(([_k, v]) => v != null)
          .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
      ).toString();
      const data: CursorCollectionResponse<T> = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      getNextPageParam: (lastPage) => {
        if (lastPage.lastKey && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(
            Object.entries({
              ...params,
              lastKey: lastPage.lastKey,
            })
              // eslint-disable-next-line no-unused-vars
              .filter(([_k, v]) => v != null)
              .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
          ).toString();

          return `${url}?${paramString}`;
        }
      },
    }
  );
}
