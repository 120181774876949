import React from 'react';
import { Lock as LockIcon } from '@mui/icons-material';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DraftChapter, DraftPolicyItem, DraftPrefaceItem, SidebarItem } from 'types/manual';

interface DraggableItemProps {
  item: DraftPrefaceItem | DraftPolicyItem | DraftChapter;
  title: string;
  itemType: 'preface' | 'chapter' | 'policy';
  isSelected?: boolean;
  handleToggleState: (type: 'preface' | 'chapter' | 'policy', id: string, event: React.MouseEvent) => void;
  handleChapterClick?: (id: string) => void;
  sidebarItem?: SidebarItem | null;
  setSidebarItem?: (item: SidebarItem | null) => void;
}
const DraggableItem = ({
  item,
  title,
  itemType,
  isSelected = false,
  handleToggleState,
  handleChapterClick,
  sidebarItem,
  setSidebarItem,
}: DraggableItemProps) => {
  const id = item.id;
  const isDisabled = item.state === 'DISABLED';
  const isLocked = itemType !== 'chapter' && (item as DraftPrefaceItem | DraftPolicyItem).isLocked;
  const lockedUser = itemType !== 'chapter' ? (item as DraftPrefaceItem | DraftPolicyItem).lock?.username : '';

  const handleClick = () => {
    if (itemType === 'chapter' && handleChapterClick) {
      handleChapterClick(id);
      return;
    }

    if (setSidebarItem)
      id === sidebarItem?.item.id
        ? setSidebarItem(null)
        : setSidebarItem({ item: item as DraftPrefaceItem | DraftPolicyItem, type: itemType as 'preface' | 'policy' });
  };

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        border: isSelected ? '2px solid #1976d2' : '1px solid #ddd',
        backgroundColor: isSelected ? '#bbdefb' : isDisabled ? '#f0f0f0' : 'white',
        color: isDisabled ? '#b4b4b4' : '#000',
        cursor: 'pointer',
      }}
      onClick={() => handleClick()}
    >
      <Typography>{title}</Typography>
      {isLocked && (
        <Tooltip title={lockedUser}>
          <LockIcon
            color='action'
            sx={{ marginLeft: 'auto', cursor: 'pointer' }}
          />
        </Tooltip>
      )}
      <IconButton>
        {!isDisabled ? (
          <VisibilityIcon onClick={(e) => handleToggleState(itemType, id, e)} />
        ) : (
          <VisibilityOffIcon onClick={(e) => handleToggleState(itemType, id, e)} />
        )}
      </IconButton>
    </Paper>
  );
};

export default DraggableItem;
