import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext } from "views/App/context";
import useTranslations from "hooks/useTranslations";
import { Form } from "components/_forms";
import { Organization, UpdateOrganizationRequest } from "types/organization";
import useManageOrganizations from "data_sources/organizations/useManageOrganization";
import useOrganization from "data_sources/organizations/useOrganization";
import { validCountries } from "../../Organizations/context";
import { ServerErrorResponse } from "data_sources/constants";
import FormView from "components/_layouts/FormView";

const EditOrganization: React.FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentOrganizationId } = useContext(AppContext);
  const [serverErrors, setServerErrors] = useState<ServerErrorResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const [currentCountry, setCurrentCountry] = useState('USA');
  const organizationQuery = useOrganization(currentOrganizationId);

  const {
    update: updateOrganization
  } = useManageOrganizations();

  const updateOrganizationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.name')} is required`),
    country: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.country')} is required`),
    state: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.state')} is required`),
    city: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.city')} is required`),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm<UpdateOrganizationRequest>({
    values: {
      name: '',
      city: '',
      country: 'USA',
      salesforceId: '',
      state: '',
    },
    resolver: yupResolver(updateOrganizationSchema),
  });

  React.useEffect(() => {
    if (organizationQuery.data?.organization) {
      reset({
        name: organizationQuery.data.organization.name,
        city: organizationQuery.data.organization.city || '',
        country: organizationQuery.data.organization.country || '',
        salesforceId: organizationQuery.data.organization.salesforceId || '',
        state: organizationQuery.data.organization.state || '',
      })
    }
  }, [reset, organizationQuery.data]);

  const watchCountry = watch('country');

  useEffect(() => {
    setCurrentCountry(watchCountry);
  }, [watchCountry]);

  const handleSave: SubmitHandler<UpdateOrganizationRequest> = (values: UpdateOrganizationRequest) => {
    setIsSaving(true);
    console.log('values', values)
    if (currentOrganizationId) {
      updateOrganization(
        currentOrganizationId,
        values,
        (data: Organization) => {
          queryClient.invalidateQueries(['organization', currentOrganizationId]);
          navigate('..');
        },
        (errors: ServerErrorResponse) => {
          setIsSaving(false);
          setServerErrors(errors);
        }
      );
    }
  }

  const states = useMemo(() => {
    return validCountries.find((c) => c.code3 === (currentCountry || 'USA'))?.states || [];
  }, [currentCountry]);

  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <FormView
          title={t('organizations.edit.title')}
          breadcrumbItems={[
            <Link key='b1' href='..'>
              {t('organizations.settings.title')}
            </Link>,
            <Typography key='b2'>
              {t('organizations.edit.title')}
            </Typography>
          ]}
          actions={(
            <>
              <Button color='neutral' href='..'>{t('common.cancel')}</Button>
              <Button
                onClick={() => {
                  trigger();
                }}
                disabled={isSaving}
                component='button'
                type="submit"
                variant="contained"
              >
                {t('organizations.edit.save')}
              </Button>
            </>
          )}
          errors={serverErrors}
          query={organizationQuery}
        >
          <TextField
            id='name'
            key='name'
            label={t('organizations.properties.name')}
            variant="outlined"
            required autoFocus
            fullWidth
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : undefined}
          />
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl key='country'>
                <InputLabel id="country-label" required>{t('organizations.properties.country')}</InputLabel>
                <Select
                  id='country'
                  label={t('organizations.properties.country')}
                  value={value}
                  onChange={onChange}
                  error={!!errors.country}
                >
                  {validCountries.map((country) => (
                    <MenuItem key={`country_${country.code3}`} value={country.code3}>{country.name}</MenuItem>
                  ))}
                </Select>
                {errors.country && (
                  <FormHelperText error>{errors.country.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            name='state'
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                {states.length > 0 ? (
                  <FormControl key='state_picker'>
                    <InputLabel id="state-label" required>{t('organizations.properties.state')}</InputLabel>
                    <Select
                      id='state_select'
                      label={t('organizations.properties.state')}
                      value={value}
                      onChange={onChange}
                      error={!!errors.state}
                    >
                      <MenuItem key={`state_empty`} value=''>--</MenuItem>
                      {states.map((state: any) => (
                        <MenuItem key={`state_${state.code}`} value={state.code}>{state.name}</MenuItem>
                      ))}
                    </Select>
                    {errors.state && (
                      <FormHelperText error>{errors.state.message}</FormHelperText>
                    )}
                  </FormControl>
                ) : (
                  <TextField
                    id='state'
                    key='state'
                    label={t('organizations.properties.state')}
                    variant="outlined"
                    required
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!errors.state}
                    helperText={errors.state ? errors.state.message : undefined}
                  />
                )}
              </>
            )}
          />
          <TextField
            id='city'
            key='city'
            label={t('organizations.properties.city')}
            variant="outlined"
            required
            fullWidth
            {...register('city')}
            error={!!errors.city}
            helperText={errors.city ? errors.city.message : undefined}
          />
          <TextField
            id='salesforceId'
            key='salesforceId'
            label={t('organizations.properties.salesforceId')}
            variant="outlined"
            fullWidth
            {...register('salesforceId')}
            error={!!errors.salesforceId}
            helperText={errors.salesforceId ? errors.salesforceId.message : undefined}
          />
        </FormView>
      </Form>
    </>
  );
}


export default EditOrganization;