import React, { Fragment, useContext, useEffect, useState } from 'react';
import parse, { DOMNode } from 'html-react-parser';
import { Box, Skeleton, Stack, Toolbar, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import manualHelper from '../helpers/draftManualHelper';
import contentHelper from 'helpers/contentHelper';
import { ContentPage, ContentPageContainer, TitleTextField } from '../styles';
import errorHelper from 'helpers/errorHelper';
import SectionEditor from '../SectionEditor';
import { usePolicyEditorContext } from './context';
import PolicyActions from './PolicyActions';
import PolicyContentFilter from './PolicyContentFilter';
import { DraftManualDetailContext } from '../context';
import PolicyAutosave from './PolicyAutosave';
import ModifyPolicyStructure from './_components/ModifyPolicyStructure';

type PolicyEditorProps = {
  id: string;
};

const PolicyEditor: React.FC<PolicyEditorProps> = ({ id }: PolicyEditorProps) => {
  const t = useTranslations();
  const [policyTitle, setPolicyTitle] = useState<string>('');
  const { manual, manualHasPermissions } = useContext(DraftManualDetailContext);
  const {
    autoSave,
    currentSection,
    filter,
    filteredSections,
    filterSubsections,
    handleInsertSectionAfter,
    handleInsertSubSectionAfter,
    handleSectionChange,
    handleSelectSection,
    isOpenModifyPolicy,
    policyData,
    policyMode,
    policyNumber,
    policyQuery,
    setFilter,
    setIsOpenModifyPolicy,
    updatePolicyData,
  } = usePolicyEditorContext();

  const handleTitleChange = (title: string) => {
    updatePolicyData({ title });
    setPolicyTitle(title);
  };

  useEffect(() => {
    if (policyData) {
      setPolicyTitle(policyData.title);
    }
  }, [policyData]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        maxHeight: 'calc(100vh - 64px)',
      }}
    >
      <Toolbar
        className='phx-panel-header'
        sx={{ justifyContent: 'space-between' }}
      >
        {policyData && policyMode === 'EDITING' ? (
          <>
            <PolicyContentFilter
              filter={filter}
              setFilter={setFilter}
            />
            <PolicyAutosave autoSave={autoSave} />
          </>
        ) : (
          <Box></Box>
        )}
        <Stack
          direction='row'
          spacing={4}
        >
          <PolicyActions />
        </Stack>
      </Toolbar>
      <ContentPageContainer>
        <ContentPage className='phx-policy'>
          {policyQuery.isLoading || policyQuery.isError ? (
            <Box sx={{ minHeight: 0 }}>
              {policyQuery?.isError && errorHelper.renderErrors(policyQuery)}
              {policyQuery && (policyQuery.isLoading || policyQuery.isFetching) && (
                <Stack spacing={2}>
                  <Skeleton
                    variant='rectangular'
                    height={28}
                    width='50%'
                  />
                  <Stack>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                  </Stack>
                </Stack>
              )}
            </Box>
          ) : (
            <Stack spacing={4}>
              {policyQuery.data && policyData ? (
                <>
                  <Stack>
                    <Typography variant='overline'>
                      Policy {manualHelper.getNumberManualNode(policyData, manual)}
                    </Typography>
                    {policyData && policyMode === 'EDITING' && !isOpenModifyPolicy ? (
                      <TitleTextField
                        id={`title_${policyData.id}`}
                        key={`title_${policyData.id}`}
                        label={false}
                        variant='outlined'
                        fullWidth
                        value={policyTitle}
                        onChange={(e) => handleTitleChange(e.target.value)}
                      />
                    ) : (
                      <Typography
                        component='div'
                        variant='h5'
                      >
                        {manualHelper.formatManualNodeTitle(policyQuery.data, manual)}
                      </Typography>
                    )}
                  </Stack>
                  {isOpenModifyPolicy ? (
                    <>
                      {policyData?.sections && (
                        <ModifyPolicyStructure
                          onClose={() => setIsOpenModifyPolicy(false)}
                          sections={policyData.sections}
                          policyData={policyData}
                          manual={manual}
                          updatePolicyData={updatePolicyData}
                          onChange={(updatedSection) => policyData && handleSectionChange(updatedSection)}
                        />
                      )}
                    </>
                  ) : (
                    <Stack spacing={4}>
                      <Box sx={{ minHeight: 0, overflow: 'auto' }}>
                        <Stack spacing={policyMode === 'EDITING' ? 0 : 5}>
                          {filteredSections()?.map((section) => (
                            <Fragment key={section.id}>
                              {policyMode === 'EDITING' ? (
                                <>
                                  <Box
                                    sx={{
                                      p: 0,
                                      minHeight: 0,
                                    }}
                                  >
                                    <Box sx={{ minHeight: 0 }}>
                                      <Stack spacing={3}>
                                        <SectionEditor
                                          key={section.id}
                                          section={section}
                                          sectionNumber={`${manualHelper.getNumberManualNode(policyData, manual)}.${manualHelper.getEnabledSectionIndex(section.id, policyData)}`}
                                          onChange={(updatedSection) => handleSectionChange(updatedSection)}
                                          isEditable={
                                            !!(currentSection === section.id) &&
                                            policyMode === 'EDITING' &&
                                            section.state !== 'DISABLED'
                                          }
                                          viewOnly={!!(policyMode !== 'EDITING' || section.state === 'DISABLED')}
                                          isDisabled={section.state === 'DISABLED'}
                                          insertSection={() => handleInsertSectionAfter(section.id)}
                                          insertSubSection={() => handleInsertSubSectionAfter(section.id)}
                                          setCurrentSection={handleSelectSection}
                                        />
                                      </Stack>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <Box>
                                  <Stack spacing={1}>
                                    <Typography
                                      className={`title_${section.id}`}
                                      component='div'
                                      variant='h6'
                                    >
                                      {manualHelper.formatPolicyNodeTitle(section, policyNumber, policyData)}
                                    </Typography>
                                    <Box sx={{ minHeight: 0, overflow: 'auto' }}>
                                      {parse(section.content ?? '', {
                                        replace(node: DOMNode, ind: number) {
                                          return contentHelper.handleContent(node, ind);
                                        },
                                      })}
                                    </Box>
                                  </Stack>
                                </Box>
                              )}

                              {filterSubsections(section.subSections).map((subSection, subSectionIndex) => (
                                <Box key={subSection.id}>
                                  {policyMode === 'EDITING' ? (
                                    <SectionEditor
                                      key={subSection.id}
                                      section={subSection}
                                      isSubSection
                                      isLastItem={subSectionIndex === section.subSections.length - 1}
                                      sectionNumber={`${manualHelper.getNumberManualNode(policyData, manual)}.${manualHelper.getEnabledSectionIndex(section.id, policyData)}.${manualHelper.getEnabledSubSectionIndex(section.id, subSection.id, policyData)}`}
                                      onChange={(updatedSection) => policyData && handleSectionChange(updatedSection)}
                                      isEditable={
                                        !!(currentSection === subSection.id) &&
                                        policyMode === 'EDITING' &&
                                        subSection.state !== 'DISABLED'
                                      }
                                      viewOnly={!!(policyMode !== 'EDITING' || subSection.state === 'DISABLED')}
                                      isDisabled={section.state === 'DISABLED' || subSection.state === 'DISABLED'}
                                      insertSection={() => handleInsertSectionAfter(section.id)}
                                      insertSubSection={() => handleInsertSubSectionAfter(section.id, subSection.id)}
                                      setCurrentSection={handleSelectSection}
                                    />
                                  ) : (
                                    <Box sx={{ ml: 5 }}>
                                      <Stack spacing={1}>
                                        <Typography
                                          className={`title_${subSection.id}`}
                                          component='div'
                                          variant='h6'
                                        >
                                          {manualHelper.formatPolicyNodeTitle(subSection, policyNumber, policyData)}
                                        </Typography>
                                        <Box
                                          sx={{
                                            minHeight: 0,
                                            overflow: 'auto',
                                          }}
                                        >
                                          {parse(subSection.content ?? '', {
                                            replace(node: DOMNode, ind: number) {
                                              return contentHelper.handleContent(node, ind);
                                            },
                                          })}
                                        </Box>
                                      </Stack>
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Fragment>
                          ))}
                        </Stack>
                      </Box>
                    </Stack>
                  )}
                </>
              ) : null}
            </Stack>
          )}
        </ContentPage>
      </ContentPageContainer>
    </Box>
  );
};

export default PolicyEditor;
