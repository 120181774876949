import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import OrganizationsProvider from "./context";
import { AppContext } from '../../../context';
import OrganizationsList from './List';
import OrganizationsNew from './New';

const Organizations: React.FC = () => {
  const { hasAppPermission } = React.useContext(AppContext);

  return (
    <OrganizationsProvider>
      <Routes>
        <Route path='' element={<OrganizationsList />} />
        <Route path='new' element={hasAppPermission('createOrg') ? <OrganizationsNew /> : <Navigate to='/dashboard' />} />
      </Routes>
    </OrganizationsProvider>
  );
}


export default Organizations;
