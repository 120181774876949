import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalEditor,
  LexicalNode,
  SerializedTextNode,
  TextNode,
  createCommand,
  isHTMLElement,
} from 'lexical';

export const CHANGE_FONT_COLOR_COMMAND = createCommand<string>();
export const CHANGE_BACKGROUND_COLOR_COMMAND = createCommand<string>();

export class PhxTextNode extends TextNode {
  static getType(): string {
    return 'phx-text';
  }

  static clone(node: PhxTextNode): PhxTextNode {
    return new PhxTextNode(node.__text, node.__key);
  }

  isSimpleText(): boolean {
    return this.__type === 'phx-text' && this.__mode === 0;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);
    if (element) {
      if (
        element &&
        isHTMLElement(element) &&
        element.getAttribute('style') === 'white-space: pre-wrap;'
      )
        element.removeAttribute('style');
    }

    return {
      element,
    };
  }

  static importJSON(serializedNode: SerializedTextNode): PhxTextNode {
    const node = super.importJSON(serializedNode);
    return node;
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: 'phx-text',
      version: 1,
    };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        return {
          conversion: convertPhxTextElement,
          priority: 1,
        };
      },
    };
  }
}
// Helper function for DOM conversion
function convertPhxTextElement(domNode: HTMLElement): DOMConversionOutput {
  const textContent = domNode.textContent || '';
  const style = domNode.getAttribute('style') || '';
  const node = $createPhxTextNode(textContent);
  node.setStyle(style);
  return { node };
}

export function $createPhxTextNode(text: string): PhxTextNode {
  return $applyNodeReplacement(new PhxTextNode(text));
}

export function $isPhxTextNode(
  node: LexicalNode | null | undefined
): node is PhxTextNode {
  return node instanceof PhxTextNode;
}
