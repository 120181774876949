import {
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  NodeKey,
} from 'lexical';

import {
  LinkNode,
  SerializedLinkNode,
  SerializedAutoLinkNode,
} from '@lexical/link';

export type PhxLinkAttributes = {
  rel?: null | string;
  target?: null | string;
  title?: null | string;
};

export class PhxLinkNode extends LinkNode {

  static getType(): string {
    return 'phx-link';
  }

  static clone(node: PhxLinkNode): PhxLinkNode {
    return new PhxLinkNode(
      node.__url,
      { rel: node.__rel, target: node.__target, title: node.__title },
      node.__key,
    );
  }

  constructor(url: string, attributes: PhxLinkAttributes = {}, key?: NodeKey) {
    super(key ?? '');
    const { target = null, rel = null, title = null } = attributes;
    this.__url = url;
    this.__target = target;
    this.__rel = rel;
    this.__title = title;
  }

  createDOM(config: EditorConfig): HTMLAnchorElement {
    const element = document.createElement('a');
    element.href = this.sanitizeUrl(this.__url);
    if (this.__target !== null) {
      element.target = this.__target;
    }
    if (this.__rel !== null) {
      element.rel = this.__rel;
    }
    if (this.__title !== null) {
      element.title = this.__title;
    }
    return element;
  }

  updateDOM(
    prevNode: PhxLinkNode,
    anchor: HTMLAnchorElement,
    config: EditorConfig,
  ): boolean {
    const url = this.__url;
    const target = this.__target;
    const rel = this.__rel;
    const title = this.__title;
    if (url !== prevNode.__url) {
      anchor.href = url;
    }

    if (target !== prevNode.__target) {
      if (target) {
        anchor.target = target;
      } else {
        anchor.removeAttribute('target');
      }
    }

    if (rel !== prevNode.__rel) {
      if (rel) {
        anchor.rel = rel;
      } else {
        anchor.removeAttribute('rel');
      }
    }

    if (title !== prevNode.__title) {
      if (title) {
        anchor.title = title;
      } else {
        anchor.removeAttribute('title');
      }
    }
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return super.importDOM();
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    return {
      element,
    };
  }

  static importJSON(serializedNode: SerializedLinkNode | SerializedAutoLinkNode): PhxLinkNode {
    const node = super.importJSON(serializedNode)
    return node as PhxLinkNode;
  }

  exportJSON(): SerializedLinkNode | SerializedAutoLinkNode {
    return {
      ...super.exportJSON(),
      type: 'phx-link',
      version: 1,
    };
  }
}
