import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalEditor,
  LexicalNode,
  SerializedTextNode,
  TextNode,
  createCommand,
  isHTMLElement,
} from 'lexical';

export const CHANGE_FONT_COLOR_COMMAND = createCommand<string>();
export const CHANGE_BACKGROUND_COLOR_COMMAND = createCommand<string>();

export class PhxTextNode extends TextNode {
  static getType(): string {
    return 'phx-text';
  }

  static clone(node: PhxTextNode): PhxTextNode {
    return new PhxTextNode(node.__text, node.__key);
  }

  isSimpleText(): boolean {
    return this.__type === 'phx-text' && this.__mode === 0;
  }
  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    if (element && isHTMLElement(element)) {
      if (element.getAttribute('style') === 'white-space: pre-wrap;') {
        element.removeAttribute('style');
      }
      if (element.tagName === 'B') {
        const nestedStrong = element.querySelector('strong');
        if (nestedStrong) {
          nestedStrong.classList.add(...element.classList);
          if (element.hasAttribute('style')) {
            nestedStrong.setAttribute('style', element.getAttribute('style')!);
          }

          element.replaceWith(nestedStrong);
          return { element: nestedStrong };
        }
      } else if (element.tagName === 'I') {
        const nestedEm = element.querySelector('em');

        if (nestedEm) {
          nestedEm.classList.add(...element.classList);
          if (element.hasAttribute('style')) {
            nestedEm.setAttribute('style', element.getAttribute('style')!);
          }
          element.replaceWith(nestedEm);
          return { element: nestedEm };
        } else {
          const nestedBold = element.querySelector('b');

          if (nestedBold?.tagName === 'B') {
            const nestedStrong = element.querySelector('strong');
            if (nestedStrong) {
              const emElement = document.createElement('em');
              emElement.classList.add(...element.classList);
              if (element.hasAttribute('style')) {
                emElement.setAttribute('style', element.getAttribute('style')!);
              }

              nestedStrong.classList.add(...element.classList);
              emElement.appendChild(nestedStrong);

              element.replaceWith(emElement);
              return { element: emElement };
            }
          }
        }
      } else if (element.tagName === 'U') {
        const nestedItalic = element.querySelector('i');
        const nestedBold = element.querySelector('b');

        const uElement = document.createElement('u');
        uElement.classList.add(...element.classList);
        if (element.hasAttribute('style')) {
          uElement.setAttribute('style', element.getAttribute('style')!);
        }
        if (nestedItalic && nestedBold) {
          const emElement =
            nestedItalic.querySelector('em') || document.createElement('em');
          emElement.classList.add(...nestedItalic.classList);
          const strongElement =
            nestedBold.querySelector('strong') ||
            document.createElement('strong');
          strongElement.classList.add(...nestedBold.classList);

          if (!strongElement.innerHTML) {
            strongElement.innerHTML = nestedBold.innerHTML;
          }
          emElement.appendChild(strongElement);
          uElement.appendChild(emElement);

          element.replaceWith(uElement);
          return { element: uElement };
        }
      }
    }

    return { element };
  }

  static importJSON(serializedNode: SerializedTextNode): PhxTextNode {
    const node = super.importJSON(serializedNode);
    return node;
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: 'phx-text',
      version: 1,
    };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        return {
          conversion: convertPhxTextElement,
          priority: 1,
        };
      },
    };
  }
}
// Helper function for DOM conversion
function convertPhxTextElement(domNode: HTMLElement): DOMConversionOutput {
  const textContent = domNode.textContent || '';
  const style = domNode.getAttribute('style') || '';
  const node = $createPhxTextNode(textContent);
  node.setStyle(style);
  return { node };
}

export function $createPhxTextNode(text: string): PhxTextNode {
  return $applyNodeReplacement(new PhxTextNode(text));
}

export function $isPhxTextNode(
  node: LexicalNode | null | undefined
): node is PhxTextNode {
  return node instanceof PhxTextNode;
}
