import React from "react";
import {
  List,
} from '@mui/material';

import TreeBranch from "./Branch";


export type TreeItem = {
  children: TreeItem[];
  icon?: any;
  id: string;
  label?: string;
  selected?: boolean;
  href?: string;
  onClick?: () => void;
}

export type TreeViewProps = {
  data: TreeItem[];
  selectedPath?: string;
  darkMode?: boolean;
}

const TreeView: React.FC<TreeViewProps> = ({
  data = [],
  selectedPath,
  darkMode = false,
}: TreeViewProps) => {
  // console.log('selectedPath', selectedPath)
  return (
    <List
      sx={{
        p: 0,
        width: '100%',
        maxWidth: 360,
        bgcolor: darkMode ? 'transparent' : 'background.paper',
        color: darkMode ? '#FFF' : 'currentcolor'
      }}
      component="nav"
      aria-labelledby="tree-view"
    >
      {data.map((item) => <TreeBranch key={item.id} item={item} level={1} />)}
    </List>
  );
}


export default TreeView;