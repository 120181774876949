import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import DraftManualProvider from './context';
import DraftManualsList from './List';
import DraftManualsDetail from './Detail';
import ManualStructure from './Detail/ManualStructure';
import { ManualStructureProvider } from './Detail/ManualStructure/context';

const DraftManuals: React.FC = () => {
  return (
    <DraftManualProvider>
      <Routes>
        <Route
          path=''
          element={<DraftManualsList />}
        />
        <Route
          path=':id'
          element={<DraftManualsDetail />}
        >
          <Route
            path=':node'
            element={<DraftManualsDetail />}
          />
        </Route>
        <Route
          path=':id/manual-structure'
          element={
            <ManualStructureProvider>
              <ManualStructure />
            </ManualStructureProvider>
          }
        />
      </Routes>
    </DraftManualProvider>
  );
};

export default DraftManuals;
