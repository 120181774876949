import React, { useCallback, useEffect, useRef } from 'react';

type PolicyAutosaveProps = {
  autoSave: () => Promise<void>;
};

const AUTOSAVE_INTERVAL = 300000;

const PolicyAutosave: React.FC<PolicyAutosaveProps> = React.memo(({ autoSave }) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Initialize timerRef

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const resetTimer = useCallback(() => {
    clearTimer();
    timerRef.current = setTimeout(() => {
      autoSave(); // Call autoSave function
      resetTimer();
    }, AUTOSAVE_INTERVAL);
  }, [autoSave, clearTimer]);

  useEffect(() => {
    resetTimer();

    return () => clearTimer(); // Cleanup on unmount
  }, []);

  return null;
});

export default PolicyAutosave;
