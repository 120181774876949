import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQueryClient } from 'react-query';
import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
// import useTranslations from 'hooks/useTranslations';
import useGroups from 'data_sources/groups/useGroups';
import useUserUserGroups from 'data_sources/users/useUserUserGroups';
import useManageUserUserGroup from 'data_sources/users/useManageUserUserGroup';
import { Group } from 'types/group';
import Loading from 'views/App/Portal/Loading';
import errorHelper from 'helpers/errorHelper';

export default function Groups() {
  const { currentOrganizationId } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();
  // const t = useTranslations();
  const { id } = useParams();
  const groupsQuery = useGroups(currentOrganizationId || '');
  const userGroupsQuery = useUserUserGroups(currentOrganizationId || '', id || '');
  const { addGroup, removeGroup } = useManageUserUserGroup();

  const groups = React.useMemo(() => groupsQuery.data && groupsQuery.data.pages ?
    groupsQuery.data.pages.flatMap((x: any) => x.content) : []
    , [groupsQuery.data]);

  const userGroups = React.useMemo(() => userGroupsQuery.data && userGroupsQuery.data.groups ?
    userGroupsQuery.data.groups : []
    , [userGroupsQuery.data]);

  const handleGroupChange = (group: Group, isEnabled: boolean) => {
    console.log('group change', group.name, isEnabled, group.id);
    setIsSaving(true);
    if (isEnabled) {
      addGroup(currentOrganizationId || '', id || '', group.id || '', () => {
        setIsSaving(false);
        queryClient.invalidateQueries(['user_user_groups', currentOrganizationId, id])
      });
    } else {
      removeGroup(currentOrganizationId || '', id || '', group.id || '', () => {
        setIsSaving(false);
        queryClient.invalidateQueries(['user_user_groups', currentOrganizationId, id])
      });
    }
  }
  if (groupsQuery.isError) {
    return errorHelper.renderErrors(groupsQuery);
  }

  if (userGroupsQuery.isError) {
    return errorHelper.renderErrors(userGroupsQuery);
  }

  return groupsQuery.isLoading || groupsQuery.isFetching ||
    userGroupsQuery.isLoading || userGroupsQuery.isFetching ? <Loading /> : (
    <Stack spacing={2}>
      {groups.map((group) => (
        <FormControlLabel key={group.id}
          sx={{
            opacity: isSaving ? 0.5 : 1,
            pointerEvents: isSaving ? 'none' : 'auto',
          }}
          control={(
            <Switch
              id={group.id}
              checked={userGroups.map((x) => x?.groupId).includes(group?.id)}
              onChange={({ target }) => handleGroupChange(group, target.checked)}
            />
          )}
          label={(
            <Stack>
              <Typography sx={{ fontWeight: 600 }}>{group.name}</Typography>
              {group.description?.length > 0 && <Typography variant='caption'>{group.description}</Typography>}
            </Stack>
          )}
        />
      ))}
    </Stack>
  );
}
