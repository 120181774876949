import { Role } from '../../types/role';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageOrganizationRole() {
  const create = async (organizationId: string, data: Role, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-role/v1/organizations/${organizationId}/roles`;
    await fetcher({
      method: 'post',
      url,
      body: data,
    }).then((respData) => {
      if (callback) callback(respData.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const update = async (organizationId: string, id: string, data: Role, callback?: (data: Role) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-role/v1/organizations/${organizationId}/roles/${id}`;
    await fetcher({
      method: 'put',
      url,
      body: data,
    }).then((respData) => {
      if (callback) callback(respData);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    create,
    update,
  }
}