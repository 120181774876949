import React, { useCallback, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import { GroupsContext } from '../context';
import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useGroup from "data_sources/groups/useGroup";
import useGroupRoles from 'data_sources/groups/useGroupRoles';
import useGroupUsers from 'data_sources/groups/useGroupUsers';
import useGroupManuals from 'data_sources/groups/useGroupManuals';
import Overview from './Overview';
import Users from './Users';
import useUrlParams from 'hooks/useUrlParams';
import Roles from './Roles';
import Manuals from './Manuals';

type Tab = {
  label: React.ReactNode;
  key: string;
  component: React.ReactNode;
}

type GroupUrlParams = {
  tab: string;
}

const defaultParams: GroupUrlParams = {
  tab: 'overview',
}

export default function GroupDetails() {
  const { currentOrganizationId, hasAppPermission, hasPermission } = useContext(AppContext);
  const { deleteGroup } = useContext(GroupsContext);
  const t = useTranslations();
  const { id } = useParams();
  const navigate = useNavigate();
  const groupQuery = useGroup(currentOrganizationId, id);
  const [params, setParams] = useUrlParams(defaultParams);
  const groupRolesQuery = useGroupRoles(currentOrganizationId || '', id || '', {}, { enabled: !!hasPermission('listRolesForGroup', currentOrganizationId) });
  const groupUsersQuery = useGroupUsers(currentOrganizationId || '', id || '', {}, { enabled: !!hasPermission('listUsersInGroup', currentOrganizationId) });
  const groupManualsQuery = useGroupManuals(currentOrganizationId || '', id || '', {}, { enabled: !!hasPermission('listManualPermissions', currentOrganizationId) });

  const tabs: { [key: string]: Tab } = {
    overview: {
      label: t('groups.details.tabs.details'),
      key: 'details',
      component: <Overview />,
    },
  }
  if (currentOrganizationId && hasPermission('listUsersInGroup', currentOrganizationId) && hasPermission('listUsers', currentOrganizationId)) {
    tabs.users = {
      label: (
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: 600 }}>{t('groups.details.tabs.users')}</Typography>
          {groupUsersQuery.data?.pages[0] !== undefined && <Badge variant='inline' badgeContent={groupUsersQuery.data?.pages[0]?.count} />}
        </Stack>
      ),
      key: 'users',
      component: <Users />,
    }
  }

  if (currentOrganizationId && hasPermission('listRolesForGroup', currentOrganizationId) && hasPermission('listRoles', currentOrganizationId)) {
    tabs.roles = {
      label: (
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: 600 }}>{t('groups.details.tabs.roles')}</Typography>
          {groupRolesQuery.data?.roles && <Badge variant='inline' badgeContent={groupRolesQuery.data?.roles.length.toString()} />}
        </Stack>
      ),
      key: 'roles',
      component: <Roles />,
    }
  }

  if (currentOrganizationId && hasPermission('listManualPermissions', currentOrganizationId) && hasAppPermission('listIssuedManuals')) {
    tabs.manuals = {
      label: (
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: 600 }}>{t('groups.details.tabs.manuals')}</Typography>
          {groupManualsQuery.data?.manuals && <Badge variant='inline' badgeContent={(groupManualsQuery.data?.manuals ?? []).filter((m) => !!m.persona).length.toString()} />}
        </Stack>
      ),
      key: 'manuals',
      component: <Manuals roleId={groupQuery.data?.childRoleId ?? ''} />,
    }
  }

  const updateParams = React.useCallback((updatedParams: GroupUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const renderActions = useCallback(() => {
    if (!hasPermission('deleteGroup', currentOrganizationId)) return null;
    return (
      <Button
        color='error'
        variant="contained"
        onClick={() => deleteGroup(id!, () => navigate('..'))}
      >
        {t('groups.actions.delete')}
      </Button>
    )
  }, [currentOrganizationId, deleteGroup, hasPermission, id, navigate, t])

  return (
    <DetailView
      title={(
        <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5'>
            {groupQuery.data?.name || ''}
          </Typography>
        </Stack>
      )}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('groups.title')}
        </Link>,
        <Typography key='b4'>{groupQuery.data?.name || ''}</Typography>
      ]}
      actions={renderActions()}
      toolbarLeft={(
        <Stack direction='row' spacing={3}>
          {Object.entries(tabs).map(([key, tab]) => (
            <Button
              key={key}
              onClick={() => updateParams({ tab: key })}
              variant={(key === params.tab ? 'contained' : 'text')}
            >
              {tab.label}
            </Button>
          ))}
        </Stack>
      )}
      toolbarRight={(currentOrganizationId && (hasPermission('updateGroup', currentOrganizationId))) ? (
        <Button
          variant="contained"
          href={`../edit/${groupQuery.data?.id}`}
        >
          {t('groups.actions.edit')}
        </Button>
      ) : null}
      query={groupQuery}
    >
      {tabs[params.tab]?.component}
    </DetailView >
  );
}
