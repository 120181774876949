import { arrayMove } from '@dnd-kit/sortable';
import type { FlattenedItem, TreeItem, TreeItems } from 'types/manual';

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

const MAX_DEPTH = 1;

function getDragDepth(offset: number, indentationWidth: number): number {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items: FlattenedItem[],
  activeId: string,
  overId: string,
  dragOffset: number,
  indentationWidth: number
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;

  const clampedDepth = Math.min(Math.max(projectedDepth, 0), MAX_DEPTH);

  return { depth: clampedDepth, parentId: getParentId(clampedDepth, previousItem, newItems, overItemIndex) };

  function getParentId(
    depth: number,
    previousItem: FlattenedItem | undefined,
    newItems: FlattenedItem[],
    overItemIndex: number
  ): string | null {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function flatten(items: TreeItems, parentId: string | null = null, depth = 0): FlattenedItem[] {
  return items.reduce<FlattenedItem[]>((acc, item, index) => {
    const { children, ...rest } = item;

    const currentItem: FlattenedItem = {
      ...rest,
      parentId,
      depth,
      index,
      children: [],
    };

    return [...acc, currentItem, ...flatten(children, item.id, depth + 1)];
  }, []);
}

export function flattenTree(items: TreeItems): FlattenedItem[] {
  return flatten(items);
}

export function buildTree(flatList: FlattenedItem[]): TreeItems {
  const root: TreeItem = {
    id: 'root',
    title: '',
    content: '',
    state: 'ENABLED',
    agencyModified: false,
    editLevelTag: 'FEDERAL',
    parentId: null,
    depth: -1,
    sectionNumber: '',
    isSubSection: false,
    subSections: [],
    collapsed: false,
    children: [],
  };

  const nodes: Record<string, TreeItem> = { [root.id]: root };

  flatList.forEach((item) => {
    nodes[item.id] = { ...item, children: [] };
  });

  flatList.forEach((item) => {
    const parent = nodes[item.parentId ?? 'root'];
    if (parent) {
      parent.children.push(nodes[item.id]);
    }
  });

  return root.children;
}

export function findItem(items: TreeItem[], itemId: string): TreeItem | undefined {
  return items.find(({ id }) => id === itemId);
}

export function findItemDeep(items: TreeItems, itemId: string): TreeItem | undefined {
  for (const item of items) {
    if (item.id === itemId) {
      return item;
    }

    if (item.children.length) {
      const child = findItemDeep(item.children, itemId);
      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

export function removeItem(items: TreeItems, id: string): TreeItems {
  const newItems: TreeItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id);
    }

    newItems.push(item);
  }

  return newItems;
}

export function setProperty<T extends keyof TreeItem>(
  items: TreeItems,
  id: string,
  property: T,
  setter: (value: TreeItem[T]) => TreeItem[T]
): TreeItems {
  return items.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        [property]: setter(item[property]),
      };
    }

    if (item.children.length) {
      return {
        ...item,
        children: setProperty(item.children, id, property, setter),
      };
    }

    return item;
  });
}

function countChildren(items: TreeItem[], count = 0): number {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getChildCount(items: TreeItems, id: string): number {
  if (!id) {
    return 0;
  }

  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf(items: FlattenedItem[], ids: string[]): FlattenedItem[] {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}
