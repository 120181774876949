import { Box, Card, CardMedia } from "@mui/material";
import React from "react";
import { exportToSvg } from "@excalidraw/excalidraw";

type ExcalidrawProps = {
  excalidrawJson: string;
  alt?: string;
}

const ExcalidrawImage: React.FC<ExcalidrawProps> = ({ excalidrawJson, alt }: ExcalidrawProps) => {
  const [content, setContent] = React.useState<string>('');

  React.useEffect(() => {
    // console.log('excalidrawJson', excalidrawJson)
    exportToSvg(JSON.parse(excalidrawJson)).then((svg) => {
      // console.log('exportToSvg', svg.outerHTML);
      setContent(`data:image/svg+xml;utf8,${encodeURIComponent(svg.outerHTML)}`);
    });
  }, [excalidrawJson]);

  return content && content.length > 0 ? (
    <Card
      sx={{
        mx: 'auto',
        display: 'flex',
      }}
    >
      <CardMedia
        className='excalidraw-image'
        component='img'
        sx={{
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
          mx: 'auto'
        }}
        src={content}
        title={alt || ''}
      />
    </Card>
  ) : (
    <Box />
  )
}

export default ExcalidrawImage