import { InfiniteData } from 'react-query';
import { ApiCollectionResponse } from '../types/api_response';
import AuthUtil from 'utils/AuthUtil';

export const getEnv = () => {
  if (window.location.host.includes('localhost')) {
    return 'dev';
  }
  const matches = /portal.(qa|int|dev|stage).phoenix.lexipol.com/gm.exec(window.location.host);
  return matches && matches.length > 1 ? matches[1] : 'prod';
};

export const baseApiUrl = () => {
  const env = getEnv();
  // console.log('env', env)
  switch (env) {
    case 'qa':
      return process.env.REACT_APP_API_BASE_URL_QA;
    case 'dev':
      return process.env.REACT_APP_API_BASE_URL_DEV;
    case 'int':
      return process.env.REACT_APP_API_BASE_URL_INT;
    case 'stage':
      return process.env.REACT_APP_API_BASE_URL_STAGE;
    case 'prod':
      return process.env.REACT_APP_API_BASE_URL_PROD;
    default:
      return localStorage.getItem('apiBaseUrl') || '';
  }
};

export const defaultHeaders: (options?: any) => HeadersInit = (options?: { forceError?: boolean }) =>
  ({
    'X-Api-Key': options?.forceError ? 'xx' : process.env.REACT_APP_API_GATEWAY_KEY,
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('phx-idToken')?.toString(),
  }) as HeadersInit;

export const getToken = () => localStorage.getItem('phx-idToken')?.toString();

export const queryDefaults = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, // 1 hour
      keepPreviousData: true,
    },
  },
};

export type FetcherArgs = {
  url: string;
  method?: string;
  body?: any;
  options?: RequestInit;
  anonymousAllowed?: boolean;
};
export const fetcher = ({
  url,
  method = 'get',
  body,
  options = {},
  anonymousAllowed = false,
}: FetcherArgs): Promise<any> => {
  const sessionExpired: boolean = AuthUtil.idTokenExpired();
  console.log('fetcher', anonymousAllowed, sessionExpired);
  if (!anonymousAllowed && sessionExpired) {
    console.log('sessionExpired:reject');
    return Promise.reject();
  }

  return fetch(url, {
    method: method.toUpperCase(),
    ...(method !== 'get' && { body: JSON.stringify(body) }),
    headers: defaultHeaders(),
    ...options,
  }).then(async (r) => {
    if (!r.ok) {
      const data = await r.json();
      throw new Error(JSON.stringify(data));
    }
    if (r.status === 204) {
      return true;
    } else {
      const data = await r.json();
      // console.log('fetcher:json', data)
      return data;
    }
  });
};

export const blobFetcher = ({
  url,
  method = 'get',
  body,
  options = {},
  anonymousAllowed = false,
}: FetcherArgs): Promise<any> => {
  return fetch(url, {
    method,
    ...(method !== 'get' && { body: JSON.stringify(body) }),
    headers: defaultHeaders(),
    ...options,
  }).then((r) => r.blob());
};

export const emptyResults: ApiCollectionResponse<any> = {
  content: [],
  count: 0,
};

export const infiniteEmptyResults: InfiniteData<ApiCollectionResponse<any>> = {
  pages: [
    {
      content: [],
      count: 0,
    },
  ],
  pageParams: [],
};

export const testResponse: object = {
  data: [],
  error: {},
  isError: false,
  isFetched: true,
  isFetching: false,
  isInitialLoading: false,
  isLoading: false,
  isSuccess: true,
};

export type ServerError = {
  code: number;
  detail: string;
  message: string;
};

export type ServerErrorResponse = {
  detail?: string;
  errorDetails: ServerError[];
  errorInformationCode?: number;
  errorType?: string;
  errorMessage?: string;
  message?: string;
  requestId?: string;
};

type ErrorConfig = {
  onError?: (errors: ServerErrorResponse) => void;
};

export const handleError = (err: Error, config: ErrorConfig = {}) => {
  let serverError = {
    message: 'An unexpected error occurred.',
  };

  try {
    const parsed = JSON.parse(err.message);
    serverError = {
      ...parsed,
      message: parsed.message || serverError.message,
    };
  } catch (parseError) {
    console.log('Failed to parse error message as JSON:', parseError, err);
  }

  if (config.onError) {
    config.onError(serverError as ServerErrorResponse);
  }
};
