import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  TextNode,
} from 'lexical';
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils';

import {
  $createTextEntityNode,
  PhxTextEntityNode,
} from '../../nodes/PhxTextEntityNode';
import { Box, Card, CardContent, Typography, colors } from '@mui/material';

export const INSERT_TEXT_ENTITY: LexicalCommand<undefined> = createCommand();

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentTextEntitiesRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentTextEntitiesRegex.PUNCTUATION;

const TRIGGERS = ['$'].join('');

// Chars we expect to see in a textEntity (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const TriggersTextEntitiesRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$'
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignTextEntitiesRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$'
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

const textEntitiesCache = new Map();

type TextEntityOption = {
  key: string;
  data?: Record<string, any>;
};

class TextEntityTypeaheadOption extends MenuOption {
  key: string;
  data?: Record<string, any>;

  constructor(key: string, data?: Record<string, string>) {
    super(key);
    this.key = key;
    this.data = data;
  }
}

const dummyTextEntitiesData: Array<TextEntityOption> = [
  { key: 'ORGANIZATION_NAME', data: { id: 1, label: 'Organization Name' } },
  { key: 'VERTICAL', data: { id: 2, label: 'Vertical Name' } },
  { key: 'JURISDICTION', data: { id: 3, label: 'Jurisdiction Name' } },
  { key: 'JURISDICTION2', data: { id: 4, label: 'Jurisdiction Name 2' } },
  { key: 'RIGHT', data: { id: 5, label: 'Right Name' } },
];

const dummyLookupService = {
  search(
    string: string,
    callback: (results: Array<TextEntityTypeaheadOption>) => void
  ): void {
    setTimeout(() => {
      const results: Array<TextEntityTypeaheadOption> = dummyTextEntitiesData
        .filter((textEntity) =>
          textEntity.key.toLowerCase().includes(string.toLowerCase())
        )
        .map((x) => x as TextEntityTypeaheadOption);
      callback(results);
    }, 500);
  },
};

function useTextEntityLookupService(textEntityString: string | null) {
  const [results, setResults] = useState<Array<TextEntityTypeaheadOption>>([]);

  useEffect(() => {
    const cachedResults = textEntitiesCache.get(textEntityString);

    if (textEntityString == null) {
      setResults([]);
      return;
    }

    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    textEntitiesCache.set(textEntityString, null);
    dummyLookupService.search(textEntityString, (newResults) => {
      textEntitiesCache.set(textEntityString, newResults);
      setResults(newResults);
    });
  }, [textEntityString]);

  return results;
}

function checkForTriggerTextEntities(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  let match = TriggersTextEntitiesRegex.exec(text);

  if (match === null) {
    match = AtSignTextEntitiesRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      /* console.log('te:checkForTriggerTextEntities', {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      }) */

      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForTriggerTextEntities(text, 0);
}

function TextEntitiesTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: TextEntityTypeaheadOption;
}) {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <Box
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      sx={{
        padding: '4px 8px',
        borderBottom: `solid 1px ${colors.grey[50]}`,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: colors.blue[100],
        },
      }}
    >
      <Typography>{option.key}</Typography>
    </Box>
  );
}

export default function TextEntitiesPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const results = useTextEntityLookupService(queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const options = useMemo(
    () => results.slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [results]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: TextEntityTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const textEntityNode = $createTextEntityNode(
          selectedOption.key,
          selectedOption.data
        );
        if (nodeToReplace) {
          nodeToReplace.replace(textEntityNode);
        }
        textEntityNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForTextEntityMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      // console.log('checkForTextEntityMatch', slashMatch, text)
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  // console.log('te:query', queryString)

  useEffect(() => {
    if (!editor.hasNodes([PhxTextEntityNode])) {
      throw new Error(
        'TextEntitiesPlugin: TextEntityNode is not registered on editor'
      );
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_TEXT_ENTITY,
        () => {
          const selection = $getSelection();

          if (!$isRangeSelection(selection)) {
            return false;
          }

          const focusNode = selection.focus.getNode();
          if (focusNode !== null) {
            const textNode = $createTextNode('$');
            $insertNodeToNearestRoot(textNode);
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [checkForTextEntityMatch, editor]);

  return (
    <LexicalTypeaheadMenuPlugin<TextEntityTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForTextEntityMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        anchorElementRef.current && results.length
          ? ReactDOM.createPortal(
              <Box>
                <Card
                  sx={{
                    display: 'flex',
                    minWidth: 240,
                  }}
                >
                  <CardContent
                    sx={{
                      p: 0,
                      paddingBottom: '0px !important',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      maxHeight: 300,
                      overflowY: 'auto',
                    }}
                  >
                    {options.map((option, i: number) => (
                      <TextEntitiesTypeaheadMenuItem
                        index={i}
                        isSelected={selectedIndex === i}
                        onClick={() => {
                          setHighlightedIndex(i);
                          selectOptionAndCleanUp(option);
                        }}
                        onMouseEnter={() => {
                          setHighlightedIndex(i);
                        }}
                        key={option.key}
                        option={option}
                      />
                    ))}
                  </CardContent>
                </Card>
              </Box>,
              anchorElementRef.current
            )
          : null
      }
    />
  );
}
