import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import lodashDebounce from 'lodash/debounce';

type QuickSearchProps = {
  id: string;
  initialValue?: string;
  onChange: (value: string) => void;
  onChangeDelay?: number;
  placeholder: string | undefined;
};

const QuickSearch: React.FC<QuickSearchProps> = ({
  id,
  initialValue = '',
  onChange,
  onChangeDelay = 500,
  placeholder,
}) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [showClear, setShowClear] = useState(
    initialValue.length > 0 ? 'flex' : 'none'
  );

  const debouncedOnChange = useCallback(
    lodashDebounce((val) => {
      onChange(val);
    }, onChangeDelay),
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowClear(event.target.value === '' ? 'none' : 'flex');
    setSearchQuery(event.target.value);
  };

  const handleClear = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    debouncedOnChange(searchQuery);
  }, [debouncedOnChange, searchQuery]);

  useEffect(() => {
    setSearchQuery(initialValue);
  }, [initialValue]);

  return (
    <TextField
      id={id}
      data-testid='test-quick-search'
      size='small'
      variant='outlined'
      value={searchQuery}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton style={{ display: showClear }} onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{ p: 0 }}
    />
  );
};

export default QuickSearch;
