import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, getToken, ServerErrorResponse } from './constants';
import { ProfileResponse } from 'types/profile_response';

const url = `${baseApiUrl()}/lexid/admin-user-auth/v1/profile`;

export const getProfile = async (onError?: (errors: ServerErrorResponse) => void) => {
  const idToken = getToken();
  if (!(idToken && idToken.length > 0)) return null;
  const data = await fetcher({
    method: 'get',
    url: `${url}`,
  }).catch((err) => {
    console.log('error retrieving profile info')
  });
  return data;
}

export default function useProfile(
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const idToken = getToken();
  return useQuery<ProfileResponse, Error>(
    ['profile', idToken],
    async () => getProfile(onError),
    {
      ...options,
      keepPreviousData: false,
      enabled: !!(idToken && idToken.length > 0)
    }
  );
}
