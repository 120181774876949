import React from 'react';
import { Button, IconButton, Link, Menu, MenuItem, styled, alpha, MenuProps } from '@mui/material';
import { MoreHoriz as MenuIcon, ArrowDropDown } from '@mui/icons-material';

export type ActionMenuItemProps = {
  href?: string;
  key: string;
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
};
export type ActionMenuProps = {
  id: string;
  btnText?: string;
  btnProps?: any;
  items: ActionMenuItemProps[];
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      color: theme.palette.text.secondary,
      fontWeight: 'normal',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ActionMenu: React.FC<ActionMenuProps> = ({ id, btnText, items = [], btnProps }: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {btnText ? (
        <Button
          id={id}
          data-testid='action-menu-trigger'
          variant='contained'
          onClick={handleClick}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          endIcon={<ArrowDropDown />}
          {...btnProps}
        >
          {btnText}
        </Button>
      ) : (
        <IconButton
          id={id}
          data-testid='action-menu-trigger'
          onClick={handleClick}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon />
        </IconButton>
      )}
      <StyledMenu
        id='action-menu'
        data-testid='action-menu-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'action-menu-button',
        }}
      >
        {items.map((item) =>
          item.href ? (
            <Link
              key={item.key}
              data-testid={`${id}-${item.key}`}
              href={item.href}
              sx={{ display: 'flex', px: 2 }}
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  if (item.onClick) {
                    e.preventDefault();
                    item.onClick();
                  }
                }}
                disabled={item.disabled}
                sx={{ justifyContent: 'center', width: '100%' }}
                dense
              >
                {item.label}
              </MenuItem>
              {item.icon}
            </Link>
          ) : (
            <Link
              key={item.key}
              sx={{ display: 'flex', px: 2 }}
            >
              <MenuItem
                key={item.key}
                onClick={(e) => {
                  handleClose();
                  if (item.onClick) {
                    e.preventDefault();
                    item.onClick();
                  }
                }}
                disabled={item.disabled}
                sx={{ justifyContent: 'center', width: '100%' }}
              >
                {item.label}
              </MenuItem>
              {item.icon}
            </Link>
          )
        )}
      </StyledMenu>
    </>
  );
};

export default ActionMenu;
