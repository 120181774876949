import { useInfiniteQuery } from 'react-query';

import { CitationResponse } from './citations';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';

export default function useCitations(
  params: any = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexiverse/v1/citations`;

  return useInfiniteQuery<CitationResponse, Error>(
    ['citations', params],
    async ({ pageParam, signal }): Promise<CitationResponse> => {
      if (!params.term || params.term.toString().length < 3) {
        return {} as CitationResponse;
      }
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const respData = await fetcher({
        method: 'get',
        url: pageParam ?? `${url}?${paramString}`,
        options: { signal },
      });
      const currParams = new URLSearchParams(new URL(pageParam ?? `${url}?${paramString}`).search);
      // console.log('currParams', currParams)

      const data: CitationResponse = (respData ? {
        pageNumber: Number(currParams.get('pageNumber') ?? 1),
        pageSize: Number(currParams.get('pageSize') ?? 25),
        ...respData
      } : {}) as CitationResponse;

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        // console.log('lastpage', lastPage)
        if (lastPage.pageNumber && (lastPage.pageNumber < Math.ceil((lastPage.count! / lastPage.pageSize!))) && lastPage.citations.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            pageNumber: lastPage.pageNumber + 1,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
          // console.log('next page', paramString)
          return `${url}?${paramString}`;
        }
      }
    }
  );
}