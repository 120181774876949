import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { Box, CircularProgress } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import './styles.css';

import TopNav from './TopNav';
import MainNav from './MainNav';
import Footer from './Footer';
import ChangePassword from './ChangePassword';
import SwitchOrganization from './SwitchOrganization';
import Dashboard from './Dashboard';
import Notifications from './Notifications';
import Error from './Error';
import LexID from './LexID';
import Policy from './Policy';
import { AppContext } from '../context';
import useTranslations from 'hooks/useTranslations';
import AuthUtil from 'utils/AuthUtil';

const Portal: React.FC = () => {
  const {
    appPermissions,
    orgPermissions,
    isMobile,
    isRoot,
    isTakeover,
    setUserOrganizationId,
  } = useContext(AppContext);
  const t = useTranslations();

  const validPortalUser = () => {
    let isValid = true;
    if (AuthUtil.idTokenExpired()) {
      console.log('invalid - expired token!');
      isValid = false;
      AuthUtil.clearStorage();
    }
    // console.log('validPortalUser', isRoot, appPermissions, orgPermissions)
    if (appPermissions === null) isValid = false;
    if (orgPermissions === null && !isRoot) isValid = false;
    return isValid;
  };

  return (
    <Authenticator
      className='authenticator-ctr'
      formFields={{
        signIn: {
          username: {
            label: t('login.username'),
            placeholder: t('login.usernamePlaceholder'),
            defaultValue:
              localStorage.getItem('phx-username')?.toString() || '',
          },
        },
        setupTOTP: {
          username: {
            label: t('login.username'),
            placeholder: t('login.usernamePlaceholder'),
          },
        },
        resetPassword: {
          username: {
            label: t('login.username'),
            placeholder: t('login.usernamePlaceholder'),
          },
        },
      }}
      hideSignUp
    >
      {({ signOut, user }) => {
        if (user) {
          // console.log('index', user)
          AuthUtil.updateSessionInfo((orgId) => {
            // console.log('index updateSession', orgId)
            if (orgId) {
              // console.log('setUserOrganizationId', orgId)
              setUserOrganizationId(orgId);
            }
          });
        }

        return validPortalUser() ? (
          <Box
            className='portal'
            sx={{
              display: 'grid',
              gridTemplateRows: isTakeover ? '1fr' : 'auto 1fr 50px',
              maxHeight: '100vh',
              height: '100vh',
            }}
          >
            {!isTakeover && <TopNav signOut={signOut} />}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isTakeover ? '1fr' : 'auto 1fr',
                width: '100%',
                minHeight: 0,
              }}
            >
              {!isMobile && !isTakeover && <MainNav signOut={signOut} />}
              <Box
                component='main'
                sx={{
                  flexGrow: 1,
                  overflow: 'auto',
                  minHeight: 0,
                }}
              >
                <Routes>
                  <Route
                    key='switch_organizations'
                    path='/switch-org/:id?'
                    element={<SwitchOrganization />}
                  />
                  <Route
                    key='change_password'
                    path='/change-password/*'
                    element={<ChangePassword />}
                  />
                  <Route
                    key='error'
                    path='/error/*'
                    element={<Error />}
                  />
                  <Route
                    id='dashboard'
                    path='/dashboard/*'
                    element={<Dashboard />}
                  />
                  <Route
                    id='notifications'
                    path='/notifications/:id?'
                    element={<Notifications />}
                  />
                  <Route
                    id='notifications_other'
                    path='/notifications/*'
                    element={<Notifications />}
                  />
                  <Route
                    id='lexid_root'
                    path='/lexid/*'
                    element={<LexID />}
                  />
                  <Route
                    id='policy_root'
                    path='/policy/*'
                    element={<Policy />}
                  />
                </Routes>
              </Box>
            </Box>
            {!isTakeover && <Footer />}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              minHeight: '40vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        );
      }}
    </Authenticator>
  );
};
export default Portal;
