import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import UsersProvider from "./context";
import { AppContext } from '../../../context';
import UsersList from './List';
import NewUser from './New';
import EditUser from './Edit';
import UserDetails from './Details';

const Users: React.FC = () => {
  const { enforcePermissions } = React.useContext(AppContext);

  return (
    <UsersProvider>
      <Routes>
        <Route path='' element={enforcePermissions(['listUsers'], <UsersList />)} />
        <Route path='new' element={enforcePermissions(['createUser'], <NewUser />)} />
        <Route path='edit/:id' element={enforcePermissions(['updateUser'], <EditUser />)} />
        <Route path=':id' element={enforcePermissions(['getUser'], <UserDetails />)} />
      </Routes>
    </UsersProvider>
  );
}


export default Users;
