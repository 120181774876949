import React from 'react';
import { useInView } from 'react-intersection-observer';
import parse from 'html-react-parser';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ColumnMeta, RowData } from '@tanstack/react-table';
import {
  Box,
  Breadcrumbs,
  colors,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Alert,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import errorHelper from 'helpers/errorHelper';
import Loading from 'views/App/Portal/Loading';
import { isArray } from 'lodash';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
    className?: string;
  }
}

export type ListViewProps = {
  breadcrumbItems?: React.ReactNode[];
  children?: React.ReactNode;
  columns?: ColumnDef<any, any>[];
  data: any[] | null;
  query: any;
  hideHeaders?: boolean;
  rowAction?: (data: any) => void;
  subtitle?: string | null;
  title: React.ReactNode;
  toolbarLeft?: React.ReactNode;
  toolbarRight?: React.ReactNode;
  isFiltered?: boolean;
};

const ListView: React.FC<ListViewProps> = ({
  breadcrumbItems = [],
  children = [],
  columns = [],
  data,
  hideHeaders = false,
  query,
  rowAction = () => {},
  subtitle = null,
  title,
  toolbarLeft = null,
  toolbarRight = null,
  isFiltered = false,
}: ListViewProps) => {
  const t = useTranslations();
  const { ref, inView } = useInView();

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (inView) {
      query.fetchNextPage();
    }
  }, [inView, query]);

  const infiniteTrigger = query.hasNextPage ? (
    <Stack ref={ref} direction='row' spacing={4}>
      <Button
        onClick={() => query.fetchNextPage()}
        disabled={!query.hasNextPage || query.isFetchingNextPage}
      >
        {query.hasNextPage ? t('common.loadMore') : ''}
      </Button>
    </Stack>
  ) : null;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
      }}
    >
      <Box
        className='page-header'
        sx={{
          px: 5,
          py: 4,
          borderBottom: 1,
          borderColor: colors.grey[100],
          backgroundColor: '#FFF',
          zIndex: 1000,
        }}
      >
        <Stack spacing={1}>
          {breadcrumbItems && breadcrumbItems.length > 0 && (
            <Breadcrumbs aria-label='breadcrumb'>{breadcrumbItems}</Breadcrumbs>
          )}
          <Typography variant='h1'>{title}</Typography>
          {subtitle && (
            <Typography variant='body1'>{parse(subtitle)}</Typography>
          )}
        </Stack>
      </Box>
      <Box sx={{ p: 4 }}>
        {(toolbarLeft || toolbarRight) && (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={3}
            className={`phx-toolbar${isMobile ? '--mobile' : ''}`}
          >
            <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
              {toolbarLeft}
            </Stack>
            {toolbarRight && (
              <Stack direction='row' spacing={4} sx={{ justifySelf: 'end' }}>
                {toolbarRight}
              </Stack>
            )}
          </Stack>
        )}
      </Box>
      <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
        {query.isError ? (
          errorHelper.renderErrors(query)
        ) : (
          <TableContainer
            component={Box}
            sx={{
              backgroundColor: colors.grey[100],
              border: 'solid 1px',
              borderColor: colors.grey[200],
              borderRadius: 3,
              clipPath: 'inset(1px round 4px)',
              p: 1,
              minHeight: 0,
              overflow: 'auto',
            }}
          >
            <Table
              sx={{
                backgroundColor: '#FFF',
              }}
            >
              {isMobile || hideHeaders ? null : (
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell
                          align={header.column.columnDef.meta?.align}
                          key={header.id}
                          width={
                            header.column.getSize() === 9999
                              ? 'auto'
                              : header.column.getSize()
                          }
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
              )}
              <TableBody>
                {((!query.isFetching && !query.isLoading) ||
                  (data && data?.length > 0)) && (
                  <>
                    {isArray(data) && data.length > 0 ? (
                      <>
                        {table.getRowModel().rows.map((row) =>
                          isMobile ? (
                            <TableRow key={row.id}>
                              <TableCell key={`main_cell_${row.id}`}>
                                <Stack spacing={1}>
                                  {row
                                    .getVisibleCells()
                                    .filter(
                                      (x) => x.column.columnDef.id !== 'actions'
                                    )
                                    .map((cell, ind) => {
                                      const hdr =
                                        table.getHeaderGroups()[0].headers[ind];
                                      return (
                                        <Stack
                                          key={cell.id}
                                          direction='row'
                                          spacing={2}
                                        >
                                          {cell.column.columnDef.header &&
                                            ind > 0 && (
                                              <Typography
                                                variant='body2'
                                                sx={{ color: colors.grey[700] }}
                                              >
                                                {flexRender(
                                                  hdr.column.columnDef.header,
                                                  hdr.getContext()
                                                )}
                                                :
                                              </Typography>
                                            )}
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </Stack>
                                      );
                                    })}
                                </Stack>
                              </TableCell>
                              {row
                                .getVisibleCells()
                                .filter(
                                  (x) => x.column.columnDef.id === 'actions'
                                )
                                .map((cell, ind) => {
                                  return (
                                    <TableCell
                                      key={`action_cell_${row.id}`}
                                      width={60}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          ) : (
                            <TableRow key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <TableCell
                                  align={cell.column.columnDef.meta?.align}
                                  key={cell.id}
                                  width={
                                    cell.column.getSize() === 9999
                                      ? 'auto'
                                      : cell.column.getSize()
                                  }
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          )
                        )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length} align='center'>
                          <Alert severity='info'>
                            <Typography>
                              {isFiltered
                                ? t('common.listView.noMatching')
                                : t('common.listView.noResults')}
                            </Typography>
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
                {query && (query.isLoading || query.isFetching) && (
                  <TableRow>
                    <TableCell colSpan={columns.length} align='center'>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {infiniteTrigger}
          </TableContainer>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default ListView;
