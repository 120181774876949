import React, { useContext } from 'react';
import { AccountSettings } from '@aws-amplify/ui-react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { AppContext } from '../../context';

export default function ChangePassword() {
  const t = useTranslations();
  const navigate = useNavigate();
  const { currentProfile, setMainNavCollapsed } = useContext(AppContext);
  console.log('currentProfile', currentProfile);
  setMainNavCollapsed(true);
  if (!currentProfile || !currentProfile.user?.authenticationType || currentProfile.user?.authenticationType === 'SSO') {
    console.log('navigate to dashboard (change password not allowed)')
    navigate('/portal/dashboard');
    return null;
  }
  const handleSuccess = () => {
    console.log('Password changed successfully');
    enqueueSnackbar(t('changePassword.success'), { variant: 'success' });
    console.log('navigate to dashboard (change password success)')
    navigate('/portal/dashboard');
  };
  const handleError = (error: Error) => {
    console.log('Error changing password', error);
    enqueueSnackbar(error.message, { variant: 'error' });
  };

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateRows: '1fr',
      height: '100%',
    }}>
      <Box className='change-password' sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Card sx={{ width: 400, maxWidth: '100%', p: 6 }}>
          <CardContent>
            <Stack spacing={5}>
              <Stack spacing={2} sx={{ alignItems: 'center' }}>
                <img src='/lexipol_logo.svg' alt='Lexipol' style={{ display: 'inline-block', width: 48 }} />
                <Typography variant='h4'>{t('changePassword.title')}</Typography>
              </Stack>
              <AccountSettings.ChangePassword
                onSuccess={handleSuccess}
                onError={handleError}
              />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
