import React, { useCallback, useEffect, useState } from 'react';
import { Button, Badge, Stack, Tooltip, Avatar } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';
import { usePolicyEditorContext } from './context';
import draftManualHelper from '../helpers/draftManualHelper';
import useTranslations from 'hooks/useTranslations';

type PolicyActionsProps = {};

const PolicyActions: React.FC<PolicyActionsProps> = (props) => {
  const t = useTranslations();
  const {
    disableSave,
    gettingLock,
    handleAcquireLockClick,
    handleModifyClick,
    handleSave,
    isOpenModifyPolicy,
    policyData,
    policyMode,
    setIsOpenModifyPolicy,
  } = usePolicyEditorContext();

  switch (policyMode) {
    case 'EDITABLE':
      return (
        <Button
          disabled={gettingLock}
          onClick={handleModifyClick}
        >
          {t('common.modify')}
        </Button>
      );
    case 'EDITING':
      return (
        <Stack
          direction='row'
          spacing={3}
        >
          {policyMode === 'EDITING' && (
            <Button
              onClick={() => setIsOpenModifyPolicy(!isOpenModifyPolicy)}
              sx={{ ml: 2 }}
              size='small'
              color='neutral'
              variant='outlined'
            >
              {isOpenModifyPolicy ? t('policy.sections.closeStructure') : t('policy.sections.editStructure')}
            </Button>
          )}
          {!isOpenModifyPolicy && (
            <Button
              onClick={() => handleSave()}
              disabled={disableSave(policyData)}
            >
              {t('common.save')}
            </Button>
          )}
        </Stack>
      );
    case 'LOCKED':
      return (
        <Stack
          direction='row'
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center' }}
          key='locked'
        >
          {policyData?.lock && <>{draftManualHelper.policyLockAvatar(policyData)}</>}
          <Button
            onClick={handleAcquireLockClick}
            disabled={gettingLock}
            startIcon={<LockIcon />}
          >
            {t('common.modify')}
          </Button>
        </Stack>
      );
    case 'DISABLED':
      return (
        <Badge
          variant='inline'
          badgeContent={t('common.disabled')}
          color='warning'
          sx={{ textTransform: 'uppercase' }}
        />
      );
    default:
      return null;
  }
};

export default PolicyActions;
