import React, { useState } from 'react';
import jp from 'jsonpath';
import pluralize from 'pluralize';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

type IntegrationResponseParams = {
  'method.response.header.Access-Control-Allow-Origin'?: string;
  'method.response.header.Access-Control-Allow-Methods'?: string;
  'method.response.header.Access-Control-Allow-Headers'?: string;
};

type ApiMethods = {
  get?: object;
  options?: object;
  post?: object;
  put?: object;
  delete?: object;
};

type AnalysisResult = {
  resultType: string;
  name: string;
  warnings: string[];
  errors: string[];
};

export default function CorsValidator() {
  const [resource, setResource] = useState('organization');
  const [spec, setSpec] = useState('{}');
  const [debug, setDebug] = useState<AnalysisResult[]>([]);

  const appendDebug = (result: AnalysisResult) => {
    setDebug((prev) => [...prev, result]);
  };

  const handleAnalyze = () => {
    const json = JSON.parse(spec && spec.length > 2 ? spec : '{}');
    setDebug([]);
    if (json && json.paths) {
      Object.entries(json.paths).forEach(([path, pathVal]) => {
        validatePath(path);
        const methods = pathVal as ApiMethods;
        if (methods.options) {
          validateOptions(path, pathVal);
        } else {
          appendDebug({
            resultType: 'OPTIONS missing',
            name: path,
            warnings: [],
            errors: ['Could not find OPTIONS defined for path'],
          });
        }
        if (methods.get) {
          validateGet(path, pathVal);
        }
        if (methods.post) {
          validatePost(path, pathVal);
        }
        if (methods.put) {
          validatePut(path, pathVal);
        }
      });
    }
  };

  const validatePath = (path: string) => {
    const expectedPath = `/${pluralize(resource)}`;
    const x: AnalysisResult = {
      resultType: 'path',
      name: path,
      warnings: [],
      errors: [],
    };
    if (!path.startsWith(expectedPath)) {
      // x.warnings.push(`Expected path to start with: '${expectedPath}'`);
    }
    appendDebug(x);
  };

  const validateOptions = (name: string, path: any) => {
    const x: AnalysisResult = {
      resultType: 'OPTIONS',
      name: name,
      warnings: [],
      errors: [],
    };
    if (path.options.security) {
      x.errors.push(
        'OPTIONS method should not require API KEY or authorization'
      );
    }
    const successHeaders = jp.query(
      path,
      jp.stringify(['$', 'options', 'responses', '200', 'headers'])
    );

    if (successHeaders) {
      [
        'Access-Control-Allow-Origin',
        'Access-Control-Allow-Methods',
        'Access-Control-Allow-Headers',
      ].forEach((key) => {
        // console.log('!!', key, jp.stringify(['$', 'options', 'responses', '200', 'headers', key]), jp.query(path, jp.stringify(['$', '.', '200', 'headers', key])));
        if (
          jp.query(
            path,
            jp.stringify(['$', 'options', 'responses', '200', 'headers', key])
          ).length === 0
        ) {
          x.errors.push(`${key} header is required on OPTIONS`);
        }
      });
    } else {
      x.errors.push('Success (200) headers not found');
    }
    const integrationResponseParams = jp.query(
      path,
      jp.stringify([
        '$',
        'options',
        'x-amazon-apigateway-integration',
        'responses',
        'default',
        'responseParameters',
      ])
    )[0];
    // console.log('!!', integrationResponseParams);
    if (integrationResponseParams) {
      const respParams: IntegrationResponseParams =
        integrationResponseParams as IntegrationResponseParams;
      if (
        !respParams['method.response.header.Access-Control-Allow-Headers'] ||
        !respParams[
          'method.response.header.Access-Control-Allow-Headers'
        ].includes(
          'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token'
        )
      ) {
        x.errors.push(
          `Access-Control-Allow-Headers should be 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token'`
        );
      }
      if (
        !respParams['method.response.header.Access-Control-Allow-Origin'] ||
        respParams['method.response.header.Access-Control-Allow-Origin'] !==
          "'*'"
      ) {
        x.errors.push(`Access-Control-Allow-Origin should be '*'`);
      }
      const expectedMethods = Object.keys(path).map((k) => k.toUpperCase());
      expectedMethods.forEach((m) => {
        if (
          !respParams['method.response.header.Access-Control-Allow-Methods'] ||
          !respParams[
            'method.response.header.Access-Control-Allow-Methods'
          ].includes(m)
        ) {
          x.errors.push(`Access-Control-Allow-Methods should include '${m}'`);
        }
      });
    } else {
      x.errors.push('Integration Response Parameters not found');
    }

    appendDebug(x);
  };

  const validateGet = (name: string, path: any) => {
    const x: AnalysisResult = {
      resultType: 'GET',
      name: name,
      warnings: [],
      errors: [],
    };
    const security = jp.value(path, jp.stringify(['$', 'get', 'security']));
    console.log('security', security);
    if (!security || security.length < 2) {
      x.errors.push(
        `GET method should require API KEY and authorization. currently has: ${security ? security.map((s: any) => Object.keys(s)[0]).join(', ') : 'Security is missing'}`
      );
    }
    const successHeaders = jp.query(
      path,
      jp.stringify(['$', 'get', 'responses', '200', 'headers'])
    );

    if (successHeaders) {
      ['Access-Control-Allow-Origin'].forEach((key) => {
        if (
          jp.query(
            path,
            jp.stringify(['$', 'get', 'responses', '200', 'headers', key])
          ).length === 0
        ) {
          x.errors.push(`${key} header is required on GET`);
        }
      });
    } else {
      x.errors.push('Success (200) headers not found');
    }
    const contentSchema = jp.value(
      path,
      jp.stringify([
        '$',
        'get',
        'responses',
        '200',
        'content',
        'application/json',
      ])
    );
    // console.log('contentSchema', JSON.stringify(contentSchema));
    if (
      JSON.stringify(contentSchema) ===
      '{"schema":{"$ref":"#/components/schemas/Empty"}}'
    ) {
      x.warnings.push(
        'Empty response schema detected. Please provide the schema for the response.'
      );
    }
    const integrationResponseParams = jp.query(
      path,
      jp.stringify([
        '$',
        'get',
        'x-amazon-apigateway-integration',
        'responses',
        'default',
        'responseParameters',
      ])
    )[0];
    // console.log('!!', integrationResponseParams);
    if (integrationResponseParams) {
      const respParams: IntegrationResponseParams =
        integrationResponseParams as IntegrationResponseParams;
      if (
        !respParams['method.response.header.Access-Control-Allow-Origin'] ||
        respParams['method.response.header.Access-Control-Allow-Origin'] !==
          "'*'"
      ) {
        x.errors.push(`Access-Control-Allow-Origin should be '*'`);
      }
    } else {
      x.errors.push('Integration Response Parameters not found');
    }

    appendDebug(x);
  };

  const validatePost = (name: string, path: any) => {
    const x: AnalysisResult = {
      resultType: 'POST',
      name: name,
      warnings: [],
      errors: [],
    };
    const security = jp.value(path, jp.stringify(['$', 'post', 'security']));
    console.log('security', security);
    if (!security || security.length < 2) {
      x.errors.push(
        `POST method should require API KEY and authorization. currently has: ${(security || []).map((s: any) => Object.keys(s)[0]).join(', ')}`
      );
    }
    const successHeaders = jp.query(
      path,
      jp.stringify(['$', 'post', 'responses', '200', 'headers'])
    );

    if (successHeaders) {
      ['Access-Control-Allow-Origin'].forEach((key) => {
        if (
          jp.query(
            path,
            jp.stringify(['$', 'post', 'responses', '200', 'headers', key])
          ).length === 0
        ) {
          x.errors.push(`${key} header is required on POST`);
        }
      });
    } else {
      x.errors.push('Success (200) headers not found');
    }
    const requestSchema = jp.value(
      path,
      jp.stringify(['$', 'post', 'requestBody', 'content', 'application/json'])
    );
    // console.log('requestSchema', JSON.stringify(requestSchema));
    if (
      !requestSchema ||
      JSON.stringify(requestSchema) ===
        '{"schema":{"$ref":"#/components/schemas/Empty"}}'
    ) {
      x.warnings.push(
        'Empty request schema detected. Please provide the schema for the request.'
      );
    }
    const contentSchema = jp.value(
      path,
      jp.stringify([
        '$',
        'post',
        'responses',
        '200',
        'content',
        'application/json',
      ])
    );
    // console.log('contentSchema', JSON.stringify(contentSchema));
    if (
      JSON.stringify(contentSchema) ===
      '{"schema":{"$ref":"#/components/schemas/Empty"}}'
    ) {
      x.warnings.push(
        'Empty response schema detected. Please provide the schema for the response.'
      );
    }
    const integrationResponseParams = jp.query(
      path,
      jp.stringify([
        '$',
        'post',
        'x-amazon-apigateway-integration',
        'responses',
        'default',
        'responseParameters',
      ])
    )[0];
    // console.log('!!', integrationResponseParams);
    if (integrationResponseParams) {
      const respParams: IntegrationResponseParams =
        integrationResponseParams as IntegrationResponseParams;
      if (
        !respParams['method.response.header.Access-Control-Allow-Origin'] ||
        respParams['method.response.header.Access-Control-Allow-Origin'] !==
          "'*'"
      ) {
        x.errors.push(`Access-Control-Allow-Origin should be '*'`);
      }
    } else {
      x.errors.push('Integration Response Parameters not found');
    }

    appendDebug(x);
  };

  const validatePut = (name: string, path: any) => {
    const x: AnalysisResult = {
      resultType: 'PUT',
      name: name,
      warnings: [],
      errors: [],
    };
    const security = jp.value(path, jp.stringify(['$', 'put', 'security']));
    console.log('security', security);
    if (!security || security.length < 2) {
      x.errors.push(
        `PUT method should require API KEY and authorization. currently has: ${security.map((s: any) => Object.keys(s)[0]).join(', ')}`
      );
    }
    const successHeaders = jp.query(
      path,
      jp.stringify(['$', 'put', 'responses', '200', 'headers'])
    );

    if (successHeaders) {
      ['Access-Control-Allow-Origin'].forEach((key) => {
        if (
          jp.query(
            path,
            jp.stringify(['$', 'put', 'responses', '200', 'headers', key])
          ).length === 0
        ) {
          x.errors.push(`${key} header is required on PUT`);
        }
      });
    } else {
      x.errors.push('Success (200) headers not found');
    }
    const requestSchema = jp.value(
      path,
      jp.stringify(['$', 'put', 'requestBody', 'content', 'application/json'])
    );
    // console.log('requestSchema', JSON.stringify(requestSchema));
    if (
      !requestSchema ||
      JSON.stringify(requestSchema) ===
        '{"schema":{"$ref":"#/components/schemas/Empty"}}'
    ) {
      x.warnings.push(
        'Empty request schema detected. Please provide the schema for the request.'
      );
    }
    const contentSchema = jp.value(
      path,
      jp.stringify([
        '$',
        'put',
        'responses',
        '200',
        'content',
        'application/json',
      ])
    );
    // console.log('contentSchema', JSON.stringify(contentSchema));
    if (
      JSON.stringify(contentSchema) ===
      '{"schema":{"$ref":"#/components/schemas/Empty"}}'
    ) {
      x.warnings.push(
        'Empty response schema detected. Please provide the schema for the response.'
      );
    }
    const integrationResponseParams = jp.query(
      path,
      jp.stringify([
        '$',
        'put',
        'x-amazon-apigateway-integration',
        'responses',
        'default',
        'responseParameters',
      ])
    )[0];
    // console.log('!!', integrationResponseParams);
    if (integrationResponseParams) {
      const respParams: IntegrationResponseParams =
        integrationResponseParams as IntegrationResponseParams;
      if (
        !respParams['method.response.header.Access-Control-Allow-Origin'] ||
        respParams['method.response.header.Access-Control-Allow-Origin'] !==
          "'*'"
      ) {
        x.errors.push(`Access-Control-Allow-Origin should be '*'`);
      }
    } else {
      x.errors.push('Integration Response Parameters not found');
    }

    appendDebug(x);
  };

  React.useEffect(() => {
    handleAnalyze();
  }, [resource, spec]);

  const bgColor = (result: AnalysisResult) => {
    if (result.errors.length > 0) {
      return colors.red[50];
    } else if (result.warnings.length > 0) {
      return colors.amber[100];
    } else {
      return colors.green[50];
    }
  };

  return (
    <Box sx={{ p: 5 }}>
      <Card>
        <CardHeader
          title='CORS Validator (openapi spec)'
          sx={{
            backgroundColor: colors.grey[100],
            borderBottom: `solid 1px ${colors.grey[200]}`,
            p: 6,
          }}
        />
        <CardContent>
          <Grid container spacing={5}>
            <Grid md={6}>
              <Stack spacing={3}>
                <TextField
                  id='resource'
                  key='resource'
                  label='Resource'
                  variant='outlined'
                  fullWidth
                  value={resource}
                  onChange={({ target }) => setResource(target.value)}
                />
                <TextField
                  id='spec'
                  key='spec'
                  label='openapi JSON'
                  variant='outlined'
                  fullWidth
                  multiline
                  minRows={25}
                  maxRows={25}
                  value={spec}
                  inputProps={{
                    style: {
                      fontFamily: 'courier',
                      fontWeight: 'bold',
                      fontSize: 14,
                    },
                  }}
                  onChange={({ target }) => setSpec(target.value)}
                />
              </Stack>
            </Grid>
            <Grid md={6}>
              <Stack spacing={1}>
                {debug.map((x, i) => (
                  <Card
                    key={`result_${i}`}
                    variant='outlined'
                    sx={{ background: bgColor(x), p: 2 }}
                  >
                    <Stack>
                      <Typography sx={{ fontWeight: 600 }}>
                        {x.resultType}: {x.name}
                      </Typography>
                      {x.errors.map((err, i2) => (
                        <Typography key={`error_${i}_${i2}`}>
                          <b>Error:</b> {err}
                        </Typography>
                      ))}
                      {x.warnings.map((w, i2) => (
                        <Typography key={`warning_${i}_${i2}`}>
                          <b>Warning:</b> {w}
                        </Typography>
                      ))}
                    </Stack>
                  </Card>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            component='button'
            onClick={handleAnalyze}
            variant='contained'
            sx={{
              width: '100%',
            }}
          >
            Analyze
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
