import React from "react";
import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Box } from "@mui/material";

type AudioPlayerProps = {
  content: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  content,
}: AudioPlayerProps) => {
  console.log('content', content.length)
  return content.length > 0 ? (
    <Box>
      <ReactAudioPlayer
        autoPlay
        src={content}
      />
    </Box>
  ) : null;
}

export default AudioPlayer;