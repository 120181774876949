/* eslint-disable class-methods-use-this */
import contentHelper from './contentHelper';
import parse from 'html-react-parser';

export default class manualHelper {
  static convertManual = (manual: any) => {
    const result = {
      id: manual.guid,
      organizationId: manual.organizationGuid,
      name: contentHelper.decodeHtmlEntities(manual.name.value),
      vertical: {
        key: manual.vertical.key,
        name: manual.vertical.value,
      },
      manualType: {
        key: manual.manualType.key,
        name: manual.manualType.value,
      },
      createdAt: manual.createDateTime,
      stagedAt: manual.stageDateTime,
      prefaces: manual.prefaces.map((preface: any) => ({
        id: preface.guid,
        title: preface.title.value,
        content: preface.content.value,
      })),
      chapters: manual.chapters.map((chapter: any) => ({
        id: chapter.guid,
        key: chapter.heading,
        title: contentHelper.decodeHtmlEntities(chapter.title.value),
        policies: chapter.policies.map((policy: any) => ({
          id: policy.guid,
          key: policy.heading,
          title: contentHelper.decodeHtmlEntities(policy.title.value),
          effectiveDate: policy.effectiveDate,
          revisedDate: policy.revisedDate,
          issuingAuthority: policy.issuingAuthority,
          sections: policy.sections.map((section: any) => ({
            id: section.guid,
            key: section.heading,
            title: contentHelper.decodeHtmlEntities(section.title.value),
            content: section.content.value,
            subSections: section.subSections.map((subSection: any) => ({
              id: subSection.guid,
              key: subSection.heading,
              title: contentHelper.decodeHtmlEntities(subSection.title.value),
              content: subSection.content.value,
            })),
          })),
        })),
      })),
      attachments: manual.attachments.map((a: any) => ({
        id: a.guid,
        title: a.title,
        mimeType: a.mimeType,
      })),
    };

    return result;
  };
}
