import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, IconButton, Popover } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import useNotificationsUnreadCount from 'data_sources/notifications/useNotificationsUnreadCount';
import NotificationsPopover from './Popover';

type NotificationsNavIconProps = {
  onClick?: () => void;
};

const NotificationsNavIcon: React.FC<NotificationsNavIconProps> = ({
  onClick,
}: NotificationsNavIconProps) => {
  const t = useTranslations();
  const [showPopover, setShowPopover] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const notificationsUnreadQuery = useNotificationsUnreadCount();
  const navigate = useNavigate();

  const togglePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPopover((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  return notificationsUnreadQuery.data ? (
    <>
      <IconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          notificationsUnreadQuery.data.count > 0
            ? togglePopover(e)
            : navigate('/portal/notifications')
        }
        sx={{
          color: '#FFF',
        }}
      >
        {notificationsUnreadQuery.data.count > 0 ? (
          <Badge
            badgeContent={notificationsUnreadQuery.data.count}
            color='error'
          >
            <NotificationsIcon />
          </Badge>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>
      <Popover
        className='right-panel'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showPopover}
        onClose={handleClose}
      >
        <NotificationsPopover onClose={handleClose} />
      </Popover>
    </>
  ) : null;
};

export default NotificationsNavIcon;
