import React, { useContext } from 'react';
import numeral from 'numeral';
import { createColumnHelper } from '@tanstack/react-table';
import { Link, MenuItem, Select, Stack, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { RolesContext } from '../context';
import { AppContext } from 'views/App/context';
import { ActionMenu, QuickSearch } from 'components/_atoms';
import ListView from 'components/_layouts/ListView';
import { Role } from 'types/role';
import { SORT_OPTIONS } from '../context';

const columnHelper = createColumnHelper<Role>();

const RolesList: React.FC = () => {
  const t = useTranslations();
  const { currentOrganizationId, hasPermission } = useContext(AppContext);
  const { roles, rolesQuery, params, updateParams } = useContext(RolesContext);

  const columns = [
    columnHelper.accessor('organizationRoleId', {
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: t('roles.properties.organizationRoleId'),
      size: 2,
    }),
    columnHelper.accessor('name', {
      cell: (info) => (
        <Stack>
          <Link href={`./${info.row.original.id}`}>
            <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>
              {info.row.original.name}
            </Typography>
          </Link>
          {info.row.original.description && (
            <Typography variant='body2'>
              {info.row.original.description}
            </Typography>
          )}
        </Stack>
      ),
      header: () => t('roles.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('totalUserCount', {
      cell: (info) => (
        <Typography>
          {(info.getValue() || 0) >= 0
            ? numeral(info.getValue()).format('0,0')
            : '--'}
        </Typography>
      ),
      header: t('roles.properties.userCount'),
      size: 2,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) =>
        currentOrganizationId &&
        hasPermission('updateRole', currentOrganizationId) ? (
          <ActionMenu
            id={`action_menu_${info.row.original.id}`}
            items={[
              {
                key: `edit_${info.row.original.id}`,
                label: t('common.edit'),
                href: `./edit/${info.row.original.id}`,
              },
            ]}
          />
        ) : null,
      header: () => '',
      size: 60,
    }),
  ];

  const actions = null;

  return (
    <ListView
      columns={columns}
      data={roles}
      query={rolesQuery}
      title={t('roles.title')}
      toolbarRight={actions}
    ></ListView>
  );
};

export default RolesList;
