import { Organization, NewOrganizationRequest, UpdateOrganizationRequest, UpdateOrganizationAuthRequest } from '../../types/organization';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageOrganizations() {
  const create = async (data: NewOrganizationRequest, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-org/v1/organizations`;
    const newOrg = {
      ...data,
      samlMetadataFile: data.samlMetadataFile?.data,
    }
    await fetcher({
      method: 'post',
      url,
      body: newOrg,
    }).then((data) => {
      if (callback) callback(data.guid);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const update = async (guid: string, data: UpdateOrganizationRequest, callback?: (data: Organization) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-org/v1/organizations/${guid}`;
    await fetcher({
      method: 'put',
      url,
      body: data,
    }).then((data) => {
      if (callback) callback(data.guid);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const updateAuth = async (guid: string, target: string, data: UpdateOrganizationAuthRequest, callback?: (data: Organization) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-org/v1/organizations/${guid}/authentication-type?target=${target}`;
    const authData = {
      samlMetadataURL: data.samlMetadataURL,
      samlMetadataFile: data.samlMetadataFile?.data,
      attributeMap: data.attributeMap,
    };
    await fetcher({
      method: 'put',
      url,
      body: authData,
    }).then((data) => {
      if (callback) callback(data.guid);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const enforceAuth = async (orgId: string, authenticationType: string, callback?: (jobId: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${orgId}/users/authentication-type?authenticationType=${authenticationType}`;
    await fetcher({
      method: 'put',
      url,
    }).then((data) => {
      if (callback) callback(data.jobId);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    create,
    enforceAuth,
    update,
    updateAuth,
  }
}