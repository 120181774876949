import React from 'react';
import { Dashboard, Description, Settings } from '@mui/icons-material';

export type NavItemDef = {
  key: string;
  icon?: any;
  label?: string;
  path: string;
  children?: NavItemDef[];
  permissions?: string[];
  appPermissions?: string[];
  sameOrg?: boolean;
  orgContext?: boolean;
  rootOnly?: boolean;
  nonRoot?: boolean;
};

export const NAV_ITEMS: NavItemDef[] = [
  {
    key: 'dashboard',
    icon: <Dashboard />,
    label: 'common.dashboard',
    path: 'dashboard',
  },
  {
    key: 'lexid',
    icon: <Settings />,
    label: 'common.lexID',
    path: 'lexid',
    children: [
      {
        key: 'organization.settings',
        label: 'common.navItems.lexid.organizationSettings',
        path: 'settings',
        permissions: ['getOrg'],
      },
      {
        key: 'organization.users',
        label: 'common.navItems.lexid.manageUsers',
        path: 'users',
        permissions: ['listUsers'],
        nonRoot: true,
      },
      {
        key: 'organization.user_imports',
        label: 'common.navItems.lexid.userImports',
        path: 'user-imports',
        permissions: ['listJobsImportUsers'],
        nonRoot: true,
      },
      {
        key: 'organization.roles',
        label: 'common.navItems.lexid.manageRoles',
        path: 'roles',
        permissions: ['listRoles'],
        nonRoot: true,
      },
      {
        key: 'organization.groups',
        label: 'common.navItems.lexid.manageGroups',
        path: 'groups',
        permissions: ['listGroups'],
        nonRoot: true,
      },
      {
        key: 'organizations',
        label: 'common.navItems.lexid.manageOrganizations',
        path: 'organizations',
        appPermissions: ['listOrgs'],
        sameOrg: true,
      },
    ],
  },
  {
    key: 'policy',
    icon: <Description />,
    label: 'common.policy',
    path: 'policy',

    children: [
      {
        key: 'policy.draft-manuals',
        label: 'common.navItems.policy.draftManuals',
        path: 'draft-manuals',
        appPermissions: ['listDraftManuals'],
      },
      {
        key: 'policy.issued-manuals',
        label: 'common.navItems.policy.issuedManuals',
        path: 'issued-manuals',
        appPermissions: ['listIssuedManuals'],
      },
      {
        key: 'policy.archived-manuals',
        label: 'common.navItems.policy.archivedManuals',
        path: 'archived-manuals',
        appPermissions: ['listArchivedManuals'],
      },
      {
        key: 'policy.legacy-archived-manuals',
        label: 'common.navItems.policy.legacyArchivedManuals',
        path: 'legacy-archived-manuals',
        appPermissions: ['listArchivedManuals'],
      },
    ],
  },
];
