import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageGroupUser() {
  const addUser = async (organizationId: string, userId: string, groupId: string, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/user-group/v1/organizations/${organizationId}/groups/${groupId}/users/${userId}`;
    await fetcher({
      method: 'post',
      url,
      body: {},
    }).then((data) => {
      if (callback) callback(data.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const removeUser = async (organizationId: string, userId: string, groupId: string, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/user-group/v1/organizations/${organizationId}/groups/${groupId}/users/${userId}`;
    await fetcher({
      method: 'delete',
      url,
      body: {},
    }).then(() => {
      if (callback) callback(userId);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    addUser,
    removeUser,
  }
}