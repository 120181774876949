import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import parse, { DOMNode } from 'html-react-parser';
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  colors,
} from '@mui/material';
import {
  ArrowDropUp,
  ArrowDropDown,
  ContentCopy,
} from '@mui/icons-material';

import {
  CitationManual,
} from './citations';
import contentHelper from 'helpers/contentHelper';

export type CitationItemProps = {
  item: CitationManual;
  initiallyCollapsed?: boolean;
}



const CitationItem = React.memo(({
  item,
  initiallyCollapsed = false,
}: CitationItemProps) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(initiallyCollapsed || false);

  return (
    <Card>
      <CardHeader
        title={(
          <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant='h6'
              sx={{
                cursor: 'pointer',
                p: 1,
                '&:hover': {
                  background: colors.blue[50],
                  borderRadius: 1,
                }
              }}
            >
              {contentHelper.decodeHtmlEntities(item.manualName)}
            </Typography>
            <Badge variant='inline' badgeContent={item.citations.length} color='neutral' />
          </Stack>
        )}
        action={(
          <IconButton onClick={() => setCollapsed((prev) => !prev)}>
            {collapsed ? <ArrowDropDown /> : <ArrowDropUp />}
          </IconButton>
        )}
        sx={{ borderBottom: 'solid 1px #DDD' }}
      />
      {!collapsed && (
        <CardContent>
          <Stack spacing={2}>
            {item.citations.map((hit, i2) => (
              <Stack key={`item_${i2}`}>
                <Stack
                  key={`${i2}_${item.key}_${hit.label}`}
                  direction='row'
                  spacing={3}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#EEE',
                    borderRadius: '5px',
                    padding: '10px',
                  }}
                >
                  <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{hit.label}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={4} sx={{ justifySelf: 'end' }}>
                    <CopyToClipboard text={`${hit.label}}`}>
                      <IconButton>
                        <ContentCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    p: 3,
                    '& h5': {
                      fontSize: '0.9rem',
                      margin: '0 0 2px',
                      fontWeight: 'bold'
                    },
                    '& *': {
                      fontSize: '0.75rem'
                    },
                    '& p, & ul': {
                      marginBottom: '2px'
                    }
                  }}
                >
                  {parse(`<h5>${hit.policyName}</h5><div>${hit.content}</div>`, {
                    replace(node: DOMNode, ind: number) {
                      return contentHelper.handleContent(node, ind);
                    }
                  })}
                </Box>
              </Stack>
            ))}
          </Stack>
        </CardContent>
      )}
    </Card>
  );
});

export default CitationItem;
