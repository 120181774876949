import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import numeral from 'numeral';
import {
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import PropertyList from 'components/_layouts/PropertyList';
import useOrganizationRole from "data_sources/roles/useRole";
import useRolePermissions from "data_sources/roles/useRolePermissions";
import datetimeHelper from 'helpers/datetimeHelper';
import Loading from 'views/App/Portal/Loading';
import errorHelper from 'helpers/errorHelper';

export default function Overview() {
  const { currentOrganizationId, hasPermission } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const roleQuery = useOrganizationRole(currentOrganizationId || '', id || '');
  const permissionsQuery = useRolePermissions(currentOrganizationId || '', id || '');

  return (
    <Stack spacing={12}>
      <PropertyList
        columns={3}
        items={roleQuery.data && roleQuery.data.name ? [
          {
            label: t('roles.properties.name'),
            value: roleQuery.data?.name,
          },
          {
            label: t('roles.properties.description'),
            value: roleQuery.data?.description,
          },
          {
            label: t('roles.properties.userCount'),
            value: numeral(roleQuery?.data.totalUserCount).format('0,0'),
          },
          {
            label: t('roles.properties.createdAt'),
            value: datetimeHelper.formatDate(roleQuery.data?.createdAt),
          },
          {
            label: t('roles.properties.updatedAt'),
            value: datetimeHelper.formatDate(roleQuery.data?.updatedAt),
          },
        ] : []}
      />
      {currentOrganizationId && hasPermission('listRolePermissions', currentOrganizationId) && (
        <Stack spacing={2}>
          <Toolbar className='phx-toolbar'>
            <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
              <Typography variant='h6'>{t('roles.details.permissions')}</Typography>
            </Stack>
            <Stack direction='row' spacing={4}>
              {''}
            </Stack>
          </Toolbar>
          {permissionsQuery.isError && errorHelper.renderErrors(permissionsQuery)}
          {!permissionsQuery.isLoading && !permissionsQuery.isFetching ? (
            <PropertyList
              columns={1}
              items={(permissionsQuery.data ?? []).map((permission) => ({
                label: t(`permissions.actions.${permission.action}.name`),
                value: t(`permissions.actions.${permission.action}.description`),
              }))}
            />
          ) : (
            <Loading />
          )}
        </Stack>
      )}
    </Stack>
  );
}
