import HtmlDiff from 'htmldiff-js';
import {
  Manual,
  ManualChapter,
  Policy,
  Section,
} from "./manual";
import {
  ManualChapterComparison,
  ManualComparison,
  ManualPrefaceComparison,
  PolicyComparison,
  SectionComparison,
  SubSectionComparison,
} from './manual_comparison';
import { get, intersection, isArray, isPlainObject } from 'lodash';


export default class manualComparer {
  static compare = (source: Manual, target: Manual) => {
    const sourceManual = this.scrubManual(source);
    const targetManual = this.scrubManual(target);
    const results: ManualComparison = {
      prefaces: [],
      chapters: [],
      name: HtmlDiff.execute(sourceManual.name.value, targetManual.name.value),
    }
    results.prefaces = this.comparePrefaces(sourceManual, targetManual)
    results.chapters = this.compareChapters(sourceManual, targetManual)

    return results;
  }

  static comparePrefaces = (source: Manual, target: Manual) => {
    const results: ManualPrefaceComparison[] = [];
    const prefaces: any = {};
    source.prefaces.forEach((x) => prefaces[x.title.value] = { ...prefaces[x.title.value], source: x })
    target.prefaces.forEach((x) => prefaces[x.title.value] = { ...prefaces[x.title.value], target: x })

    Object.values(prefaces).forEach((pr: any) => {
      const result: ManualPrefaceComparison = {
        guid: pr.source?.guid ?? '',
        title: HtmlDiff.execute(this.formatLabel(pr.source), this.formatLabel(pr.target)),
        comparison: '',
        content: HtmlDiff.execute(pr.source?.content?.value ?? '', pr.target?.content?.value ?? '')
      }
      if (pr.source && pr.target) {
        const diffs = this.compareNodes(pr.source, pr.target);
        result.diffs = diffs;
        // console.log('pr diffs', diffs.length, diffs)
        if (diffs.length < 1) {
          result.comparison = 'same'
        } else {
          result.comparison = 'diff'
        }
      } else if (pr.source && !pr.target) {
        result.comparison = 'removed'
      } else {
        result.comparison = 'added'
        result.guid = pr.target.guid
      }
      results.push(result)
    })

    return results;
  }

  static compareChapters = (source: Manual, target: Manual) => {
    const results: ManualChapterComparison[] = [];
    const chapters: any = {};
    const sourceJurisIds = source.chapters.map((x) => x.jurisdictionGuid);
    const targetJurisIds = target.chapters.map((x) => x.jurisdictionGuid);
    const sourceIds = source.chapters.map((x) => x.guid);
    const targetIds = target.chapters.map((x) => x.guid);
    let matchMode = 'title.value';
    if (intersection(sourceIds, targetIds).length > 0) matchMode = 'guid';
    if (intersection(sourceJurisIds, targetJurisIds).length > 0) matchMode = 'jurisdictionGuid';
    // console.log('chapter match mode', matchMode)

    source.chapters.forEach((x: any) => chapters[get(x, matchMode)] = { ...chapters[get(x, matchMode)], source: x })
    target.chapters.forEach((x: any) => chapters[get(x, matchMode)] = { ...chapters[get(x, matchMode)], target: x })

    Object.values(chapters).forEach((ch: any) => {
      const result: ManualChapterComparison = {
        guid: ch.source?.jurisdictionGuid ?? ch.source?.guid ?? '',
        title: HtmlDiff.execute(this.formatLabel(ch.source), this.formatLabel(ch.target)),
        comparison: '',
        policies: this.comparePolicies(ch.source, ch.target)
      }
      if (ch.source && ch.target) {
        let diffs = [
          ...this.compareNodes(ch.source, ch.target),
          ...result.policies.flatMap((x) => x.diffs ?? []),
        ];
        result.diffs = diffs;
        // console.log('diffs', diffs.length, diffs);\
        if (diffs.length < 1) {
          result.comparison = 'same';
        } else {
          result.comparison = 'diff';
        }
      } else if (ch.source && !ch.target) {
        result.comparison = 'removed';
      } else {
        result.comparison = 'added'
        result.guid = ch.target?.jurisdictionGuid ?? ch.target?.guid
      }
      results.push(result);
    })

    return results.sort(this.sortNodes);
  }

  static comparePolicies = (source: ManualChapter, target: ManualChapter) => {
    const results: PolicyComparison[] = [];
    const policies: any = {};

    const sourceJurisIds = source && source.policies ? source.policies.map((x) => x.jurisdictionGuid) : [];
    const targetJurisIds = target && target.policies ? target.policies.map((x) => x.jurisdictionGuid) : [];
    const sourceIds = source && source.policies ? source.policies.map((x) => x.guid) : [];
    const targetIds = target && target.policies ? target.policies.map((x) => x.guid) : [];
    let matchMode = 'title.value';
    if (intersection(sourceIds, targetIds).length > 0) matchMode = 'guid';
    if (intersection(sourceJurisIds, targetJurisIds).length > 0) matchMode = 'jurisdictionGuid';
    // console.log('policy match mode', matchMode)

    if (source?.policies) source.policies.forEach((x: any) => policies[get(x, matchMode)] = { ...policies[get(x, matchMode)], source: x })
    if (target?.policies) target.policies.forEach((x: any) => policies[get(x, matchMode)] = { ...policies[get(x, matchMode)], target: x })

    Object.values(policies).forEach((pol: any) => {
      const result: PolicyComparison = {
        guid: pol.source?.jurisdictionGuid ?? pol.source?.guid ?? '',
        title: HtmlDiff.execute(this.formatLabel(pol.source), this.formatLabel(pol.target)),
        comparison: '',
        sections: this.compareSections(pol.source, pol.target)
      }
      if (pol.source && pol.target) {
        let diffs = [
          ...this.compareNodes(pol.source, pol.target),
          ...result.sections.flatMap((x) => x.diffs ?? []),
        ];
        result.diffs = diffs;
        // console.log('diffs', diffs.length, diffs);\
        if (diffs.length < 1) {
          result.comparison = 'same';
        } else {
          result.comparison = 'diff';
        }
      } else if (pol.source && !pol.target) {
        result.comparison = 'removed';
      } else {
        result.comparison = 'added'
        result.guid = pol.target?.jurisdictionGuid ?? pol.target?.guid
      }
      results.push(result);
    })

    return results.sort(this.sortNodes);
  }

  static compareSections = (source: Policy, target: Policy) => {
    const results: SectionComparison[] = [];
    const sections: any = {};

    const sourceJurisIds = source && source.sections ? source.sections.map((x) => x.jurisdictionGuid) : [];
    const targetJurisIds = target && target.sections ? target.sections.map((x) => x.jurisdictionGuid) : [];
    const sourceIds = source && source.sections ? source.sections.map((x) => x.guid) : [];
    const targetIds = target && target.sections ? target.sections.map((x) => x.guid) : [];
    let matchMode = 'title.value';
    if (intersection(sourceIds, targetIds).length > 0) matchMode = 'guid';
    if (intersection(sourceJurisIds, targetJurisIds).length > 0) matchMode = 'jurisdictionGuid';
    // console.log('sections match mode', matchMode)

    if (source?.sections) source.sections.forEach((x: any) => sections[get(x, matchMode)] = { ...sections[get(x, matchMode)], source: x })
    if (target?.sections) target.sections.forEach((x: any) => sections[get(x, matchMode)] = { ...sections[get(x, matchMode)], target: x })

    Object.values(sections).forEach((sec: any) => {
      // if (sec.source) sec.source.content.value = this.scrubContent(sec.source.content?.value)
      // if (sec.target) sec.target.content.value = this.scrubContent(sec.target.content?.value)
      const result: SectionComparison = {
        guid: sec.source?.jurisdictionGuid ?? sec.source?.guid ?? '',
        title: HtmlDiff.execute(this.formatLabel(sec.source), this.formatLabel(sec.target)),
        comparison: '',
        content: HtmlDiff.execute(sec.source?.content?.value ?? '', sec.target?.content?.value ?? ''),
        subSections: this.compareSubSections(sec.source, sec.target)
      }
      if (sec.source && sec.target) {
        let diffs = [
          ...this.compareNodes(sec.source, sec.target),
          ...result.subSections.flatMap((x) => x.diffs ?? []),
        ];
        result.diffs = diffs;
        // console.log('diffs', diffs.length, diffs);\
        if (diffs.length < 1) {
          result.comparison = 'same';
        } else {
          result.comparison = 'diff';
        }
      } else if (sec.source && !sec.target) {
        result.comparison = 'removed';
      } else {
        result.comparison = 'added'
        result.guid = sec.target?.jurisdictionGuid ?? sec.target?.guid
      }
      results.push(result);
    })

    return results.sort(this.sortNodes);
  }

  static compareSubSections = (source: Section, target: Section) => {
    const results: SubSectionComparison[] = [];
    const subSections: any = {};

    const sourceJurisIds = source && source.subSections ? source.subSections.map((x) => x.jurisdictionGuid) : [];
    const targetJurisIds = target && target.subSections ? target.subSections.map((x) => x.jurisdictionGuid) : [];
    const sourceIds = source && source.subSections ? source.subSections.map((x) => x.guid) : [];
    const targetIds = target && target.subSections ? target.subSections.map((x) => x.guid) : [];
    let matchMode = 'title.value';
    if (intersection(sourceIds, targetIds).length > 0) matchMode = 'guid';
    if (intersection(sourceJurisIds, targetJurisIds).length > 0) matchMode = 'jurisdictionGuid';
    // console.log('subSections match mode', matchMode)

    if (source?.subSections) source.subSections.forEach((x: any) => subSections[get(x, matchMode)] = { ...subSections[get(x, matchMode)], source: x })
    if (target?.subSections) target.subSections.forEach((x: any) => subSections[get(x, matchMode)] = { ...subSections[get(x, matchMode)], target: x })

    Object.values(subSections).forEach((sec: any) => {
      if (sec.source) sec.source.content.value = this.scrubContent(sec.source.content?.value)
      if (sec.target) sec.target.content.value = this.scrubContent(sec.target.content?.value)
      const result: SubSectionComparison = {
        guid: sec.source?.jurisdictionGuid ?? sec.source?.guid,
        title: HtmlDiff.execute(this.formatLabel(sec.source), this.formatLabel(sec.target)),
        comparison: '',
        content: HtmlDiff.execute(sec.source?.content?.value ?? '', sec.target?.content?.value ?? ''),
      }
      if (sec.source && sec.target) {
        const diffs = this.compareNodes(sec.source, sec.target);
        result.diffs = diffs;
        if (diffs.length > 1) {
          // console.log('diffs', diffs.length, diffs);
        }
        if (diffs.length <= 1) {
          result.comparison = 'same';
        } else {
          result.comparison = 'diff';
        }
      } else if (sec.source && !sec.target) {
        result.comparison = 'removed';
      } else {
        result.comparison = 'added'
        result.guid = sec.target?.jurisdictionGuid ?? sec.target?.guid
      }
      results.push(result);
    })

    return results.sort(this.sortNodes);
  }

  static formatLabel = (node: any) => {
    const parts = [];
    if (node?.heading) parts.push(node.heading);
    parts.push(node?.title?.value ?? '');
    return parts.join(': ');
  }

  static compareNodes = (source: any, target: any) => {
    // clone nodes
    const sourceNode = this.cloneNode(source);
    const targetNode = this.cloneNode(target);
    // console.log('compare', JSON.stringify(sourceNode) === JSON.stringify(targetNode), sourceNode, targetNode)
    // get diffs
    let diffs: string[] = [];
    if ((sourceNode?.title?.value || targetNode?.title?.value) && sourceNode?.title?.value !== targetNode?.title?.value) {
      diffs = [
        ...diffs,
        HtmlDiff.execute(sourceNode?.title?.value, targetNode?.title?.value)
      ];
    }
    if ((sourceNode?.content?.value || targetNode?.content?.value) && JSON.stringify(sourceNode?.content?.value) !== JSON.stringify(targetNode?.content?.value)) {
      diffs = [
        ...diffs,
        HtmlDiff.execute(sourceNode?.content?.value, targetNode?.content?.value)
      ];
    }

    return diffs;
  }

  static cloneNode = (node: any, scrub: boolean = true) => {
    const n = JSON.parse(JSON.stringify(node));
    if (scrub) {
      const newNode: any = {};
      const blacklistKeys = ['guid', 'checkSum']
      if (isPlainObject(n)) {
        Object.entries(n).forEach(([k, v]) => {
          if (!blacklistKeys.includes(k)) {
            let val = v;
            if (isPlainObject(v)) val = this.cloneNode(v);
            if (isArray(v)) val = v.map((x) => this.cloneNode(x));

            newNode[k] = val;
          }
        })
      }
      return newNode;
    }
    return n;
  }

  static scrubManual = (manual: Manual) => {
    if (!manual) return manual;
    manual.prefaces.forEach((pr) => {
      if (pr.content) pr.content.value = this.scrubContent(pr.content?.value)
    })
    manual.chapters.forEach((ch) => {
      (ch.policies || []).forEach((pol) => {
        pol.sections.forEach((sec) => {
          if (sec.content) sec.content.value = this.scrubContent(sec.content?.value)
          sec.subSections.forEach((ss) => {
            if (ss.content) ss.content.value = this.scrubContent(ss.content?.value)
          })
        })
      })
    })
    return manual;
  }

  static scrubContent = (content: string) => {
    const ctr = document.createElement('div');
    ctr.innerHTML = content;
    const badEls = ctr.querySelectorAll('lexindex');
    badEls.forEach((x) => x.remove());
    const links = ctr.querySelectorAll('a');
    links.forEach((l) => {
      if (l.href.startsWith('xref://') || l.href.startsWith('s3://')) l.href = '';
    })
    if (content !== ctr.innerHTML) {
      // console.log('scrubbed', [ctr.innerHTML, content])
    }
    return ctr.innerHTML;
  }

  static sortNodes = (a: any, b: any) => {
    const aTitleText = this.getNodeNumber(a.title);
    const bTitleText = this.getNodeNumber(b.title);
    return (aTitleText < bTitleText) ? -1 : (aTitleText > bTitleText ? 1 : 0);
  }

  static getNodeNumber = (title: string) => {
    const titleText = this.getTitleText(title);
    const titleNumber = titleText.split(':')[0];
    return titleNumber ?? 'zzz';
  }

  static getTitleText = (title: string) => {
    const div = document.createElement('div');
    div.innerHTML = title;
    return div.innerText;
  }

}