import React, { useCallback } from 'react';
import { LexicalEditor } from 'lexical';
import { CHANGE_BACKGROUND_COLOR_COMMAND } from '../../nodes/PhxTextNode';
import DropdownColorPicker from '../../ui/DropdownColorPicker';

type BackgroundColorProps = {
  editor: LexicalEditor;
  disabled?: boolean;
  bgColor: string;
};

export default function BackgroundColorFormat({
  editor,
  disabled = false,
  bgColor,
}: BackgroundColorProps): JSX.Element {
  const handleBgColorChange = useCallback(
    (color: string) => {
      editor.dispatchCommand(CHANGE_BACKGROUND_COLOR_COMMAND, color);
    },
    [editor]
  );

  return (
    <DropdownColorPicker
      disabled={disabled}
      buttonAriaLabel="Background Color Picker"
      buttonClassName="toolbar-item"
      buttonIconClassName="bg-color-icon"
      color={bgColor}
      onChange={handleBgColorChange}
    />
  );
}
