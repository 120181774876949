import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import LegacyArchivedManualProvider from "./context";
import LegacyArchivedManualsList from './List';
import LegacyArchivedManualsVersions from './Versions';

const LegacyArchivedManuals: React.FC = () => {

  return (
    <LegacyArchivedManualProvider>
      <Routes>
        <Route path='' element={<LegacyArchivedManualsList />} />
        <Route path=':id' element={<LegacyArchivedManualsVersions />} />
      </Routes>
    </LegacyArchivedManualProvider>
  );
}


export default LegacyArchivedManuals;
