import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Stack,
} from '@mui/material';

// import useCitations from './useCitations';
import { QuickSearch } from 'components/_atoms';
import useHashState from 'hooks/useHashState';
import useStickyState from 'hooks/useStickyState';
import DetailView from 'components/_layouts/DetailView';
import CitationItem from './Item';
import {
  CitationData,
  CitationResult,
} from './citations'
import { fetcher } from 'data_sources/constants';

export default function Citations() {
  const location = useLocation();
  const [term, setTerm] = useHashState<string>('term', '');
  const [citations, setCitations] = useStickyState<CitationResult[]>('citation_data', []);
  const [citationsKeys, setCitationsKeys] = useStickyState<string[]>('citation_keys', []);
  const [results, setResult] = React.useState<CitationResult[]>([]);

  React.useEffect(() => {
    fetcher({
      url: 'https://citations-apc234hf.s3.us-west-1.amazonaws.com/citations.json',
    }).then((respData) => {
      const data: CitationData = respData;
      setCitations(data.citations);
      setCitationsKeys(data.citations.map((x) => x.key));
    }).catch((err) => {
      console.log('error', err);
    });
  }, [setCitations, setCitationsKeys]);

  React.useEffect(() => {
    if (term && term.length > 0) {
      const matchingKeys = citationsKeys.filter((key) => key.toLowerCase().includes((term || '').toLowerCase()));
      const matchingResults: CitationResult[] = citations.filter((citation) => matchingKeys.includes(citation.key));
      setResult(matchingResults);
    } else {
      setResult([]);
    }
  }, [citations, citationsKeys, term, location]);

  React.useEffect(() => {
    console.log('location', term, location)
  }, [location, term])

  return (
    <DetailView
      title={'Citations'}
    >
      <Stack spacing={3}>
        <QuickSearch
          id='citation_search'
          initialValue={term || ''}
          onChange={(val) => setTerm(val)}
          placeholder='Search citations'
        />
        <Stack spacing={1}>
          {results.map((citation, i1) => (
            <CitationItem
              item={citation}
              key={`${i1}_${citation.key}`}
              isCollapsed={results.length > 1}
              onCitationClick={() => setTerm(citation.key)}
            />
          ))}
        </Stack>
      </Stack>
    </DetailView>
  );
}
