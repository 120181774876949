import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { Role } from 'types/role';

export default function useOrganizationRole(
  organizationId?: string,
  id?: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-role/v1/organizations/${organizationId}/roles`;
  return useQuery<Role, Error>(
    ['organization_role', organizationId, id],
    async () => {
      if (organizationId && id) {
        const data = await fetcher({
          method: 'get',
          url: `${url}/${id}`,
        });
        return data;
      }
    },
    {
      ...options,
      enabled: !!id,
      keepPreviousData: false,
      placeholderData: {} as Role,
    }
  );
}
