import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { enqueueSnackbar } from 'notistack';
import parse from 'html-react-parser';
import { useQueryClient } from 'react-query';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Link,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Close as RemoveIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import useIssuedManualGroups, {
  ManualGroup,
} from 'data_sources/issuedManuals/useIssuedManualGroups';
import useIssuedManualUsers, {
  ManualUser,
} from 'data_sources/issuedManuals/useIssuedManualUsers';
import useManageAcknowledgements from 'data_sources/issuedManuals/useManageAcknowledgements';
import { Policy } from 'types/policy';
import { ManualChapter } from 'types/manual';
import { CreatAdHocAcknowledgementRequest } from 'types/acknowledgements';
import { QuickSearch } from 'components/_atoms';

const NewAdhocAcknowledgement: React.FC = () => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const { id, policyId } = useParams();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState<Policy>();
  const [chapter, setChapter] = useState<ManualChapter>();
  const [groupSearch, setGroupSearch] = useState<string>('');
  const [userSearch, setUserSearch] = useState<string>('');
  const manualQuery = useIssuedManual(id || '');
  const manualGroupsQuery = useIssuedManualGroups(
    id || '0',
    { search: groupSearch },
    { enabled: !!id }
  );
  const manualUsersQuery = useIssuedManualUsers(
    id || '0',
    { search: userSearch },
    { enabled: !!id }
  );
  const [params, setParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>('settings');
  const [dueMode, setDueMode] = useState<'none' | 'date' | 'days'>('none');
  const [dueDate, setDueDate] = useState<Dayjs>(dayjs());
  const [dueDays, setDueDays] = useState<number>(0);
  const [selectedGroups, setSelectedGroups] = useState<ManualGroup[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<ManualUser[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [groupRef, groupInView] = useInView();
  const [userRef, userInView] = useInView();

  const { create: createAcknowledgement } = useManageAcknowledgements();

  React.useEffect(() => {
    if (groupInView) {
      manualGroupsQuery.fetchNextPage();
    }
  }, [groupInView, manualGroupsQuery]);

  const groupsTrigger = manualGroupsQuery.hasNextPage ? (
    <Stack key='groups-trigger' ref={groupRef} direction='row' spacing={4}>
      <Button
        onClick={() => manualGroupsQuery.fetchNextPage()}
        disabled={
          !manualGroupsQuery.hasNextPage || manualGroupsQuery.isFetchingNextPage
        }
      >
        {manualGroupsQuery.hasNextPage ? t('common.loadMore') : ''}
      </Button>
    </Stack>
  ) : null;

  React.useEffect(() => {
    if (userInView) {
      manualUsersQuery.fetchNextPage();
    }
  }, [userInView, manualUsersQuery]);

  const usersTrigger = manualUsersQuery.hasNextPage ? (
    <Stack key='users-trigger' ref={userRef} direction='row' spacing={4}>
      <Button
        onClick={() => manualUsersQuery.fetchNextPage()}
        disabled={
          !manualUsersQuery.hasNextPage || manualUsersQuery.isFetchingNextPage
        }
      >
        {manualUsersQuery.hasNextPage ? t('common.loadMore') : ''}
      </Button>
    </Stack>
  ) : null;

  useEffect(() => {
    if (manualQuery.data && manualQuery.data.chapters) {
      manualQuery.data.chapters.forEach((chapter) => {
        if (chapter.policies) {
          chapter.policies.forEach((p) => {
            if (p.id === policyId) {
              setPolicy(p);
              setChapter(chapter);
            }
          });
        }
      });
    }
  }, [manualQuery.data, policyId]);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      const sec = params.get('s');
      if (sec) {
        const el = document.getElementById(`polsec_${sec}`);
        if (el) {
          el.scrollIntoView(true);
        }
      }
    }, 2000);
    return () => {
      window.clearTimeout(timer);
    };
  }, [params]);

  const manualGroups = useMemo(() => {
    let result =
      manualGroupsQuery.data && manualGroupsQuery.data.pages
        ? manualGroupsQuery.data.pages.flatMap((x) => x.content)
        : [];
    return result;
  }, [manualGroupsQuery.data]);

  const manualUsers = useMemo(() => {
    let result =
      manualUsersQuery.data && manualUsersQuery.data.pages
        ? manualUsersQuery.data.pages.flatMap((x) => x.content)
        : [];
    return result;
  }, [manualUsersQuery.data]);

  const handleSaveClick = () => {
    setIsSaving(true);
    const data: CreatAdHocAcknowledgementRequest = {
      policies: [
        {
          id: policyId ?? '',
        },
      ],
      groups: selectedGroups.map((x) => ({ id: x.id })),
      users: selectedUsers.map((x) => ({ id: x.id })),
      dueAt: null,
      dueDays: null,
    };
    if (dueMode === 'date') {
      data.dueAt = dueDate.toISOString().split('T')[0];
    }
    if (dueMode === 'days') {
      data.dueDays = dueDays;
    }
    if (id) {
      createAcknowledgement(
        id,
        data,
        (jobId) => {
          setIsSaving(false);
          enqueueSnackbar(
            parse(
              t(`policy.acknowledgements.actions.saveConfirmation`, '', {
                title: policy?.title ?? '',
              })
            ),
            {
              variant: 'success',
              autoHideDuration: 6000,
            }
          );

          queryClient.invalidateQueries(['user_pending_acknowledgements']);
          navigate(`../${id}/policy/${policyId}`);
        },
        (errors) => {
          setIsSaving(false);
          enqueueSnackbar(errors.message, {
            variant: 'error',
            autoHideDuration: 20000,
          });
        }
      );
    }
  };

  const disableSave = () => {
    let disabled =
      selectedGroups.length + selectedUsers.length === 0 || isSaving;
    if (dueMode === 'days') {
      disabled = disabled || dueDays < 1;
    }
    if (dueMode === 'date') {
      disabled = disabled || dueDate.isBefore(dayjs());
    }

    return disabled;
  };

  return (
    <DetailView
      title={t('policy.issuedManuals.actions.newAdhocAcknowledgement')}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('policy.issuedManuals.title')}
        </Link>,
        <Link key='b2' href={`../${id}`}>
          {manualQuery.data?.name}
        </Link>,
        ...(chapter
          ? [
              <Link key='b3' href={`../${id}/chapter/${chapter.id}`}>
                {[chapter.key, chapter.title].join(': ')}
              </Link>,
            ]
          : []),
        <Link key='b4' href={`../${id}/policy/${policyId}`}>
          {policy ? [policy.key, policy.title].join(': ') : ''}
        </Link>,
        <Typography key='b5' component='span'>
          {t('policy.issuedManuals.actions.newAdhocAcknowledgement')}
        </Typography>,
      ]}
      query={manualQuery}
      toolbarLeft={
        <Stack direction='row' spacing={3}>
          {['settings', 'groups', 'users'].map((x) => (
            <Button
              key={x}
              onClick={() => setCurrentTab(x)}
              variant={x === currentTab ? 'contained' : 'text'}
            >
              <Stack direction='row' spacing={2}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t(`policy.acknowledgements.tabs.${x}`)}
                </Typography>
                {x === 'groups' && (
                  <Badge
                    variant='inline'
                    badgeContent={selectedGroups.length.toString()}
                  />
                )}
                {x === 'users' && (
                  <Badge
                    variant='inline'
                    badgeContent={selectedUsers.length.toString()}
                  />
                )}
              </Stack>
            </Button>
          ))}
        </Stack>
      }
      toolbarRight={
        <Stack direction='row' spacing={3}>
          <Button variant='text' href={`../${id}/policy/${policyId}`}>
            {t('common.cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={handleSaveClick}
            disabled={disableSave()}
          >
            {t('policy.acknowledgements.actions.save')}
          </Button>
        </Stack>
      }
    >
      {currentTab === 'settings' && (
        <Card>
          <CardHeader
            title={t('policy.acknowledgements.dueDate.title')}
            subheader={t('policy.acknowledgements.dueDate.description')}
          />
          <CardContent>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gap: 3,
                alignItems: 'center',
              }}
            >
              <Radio
                checked={dueMode === 'none'}
                onChange={() => setDueMode('none')}
                value='none'
                name='due_mode'
                inputProps={{
                  'aria-label': t('policy.acknowledgements.dueDate.none'),
                }}
                sx={{
                  p: 0,
                }}
              />
              <Stack>
                <Typography sx={{ fontWeight: 600, lineHeight: '40px' }}>
                  {t('policy.acknowledgements.dueDate.none')}
                </Typography>
              </Stack>
              <Radio
                checked={dueMode === 'days'}
                onChange={() => setDueMode('days')}
                value='days'
                name='due_mode'
                inputProps={{
                  'aria-label': t('policy.acknowledgements.dueDate.days'),
                }}
                sx={{
                  p: 0,
                }}
              />
              <Stack
                direction='row'
                spacing={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography sx={{ fontWeight: 600, lineHeight: '40px' }}>
                  {t('policy.acknowledgements.dueDate.days')}
                </Typography>
                <TextField
                  id='due_days'
                  key='due_days'
                  label={false}
                  variant='outlined'
                  disabled={dueMode !== 'days'}
                  value={dueDays.toString()}
                  onChange={({ target }) =>
                    setDueDays(
                      Number(target.value) > 0 ? Number(target.value) : 0
                    )
                  }
                  size='small'
                  sx={{ p: 0 }}
                  inputProps={{
                    pattern: '[0-9]*',
                    maxLength: 2,
                  }}
                />
              </Stack>
              <Radio
                checked={dueMode === 'date'}
                onChange={() => setDueMode('date')}
                value='date'
                name='due_mode'
                inputProps={{
                  'aria-label': t('policy.acknowledgements.dueDate.date'),
                }}
                sx={{
                  p: 0,
                }}
              />
              <Stack
                direction='row'
                spacing={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography sx={{ fontWeight: 600, lineHeight: '40px' }}>
                  {t('policy.acknowledgements.dueDate.date')}
                </Typography>
                <DatePicker
                  label={false}
                  value={dueDate}
                  disablePast
                  disabled={dueMode !== 'date'}
                  onChange={(newDate) => setDueDate(newDate ?? dayjs())}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: { p: 0 },
                    },
                  }}
                />
              </Stack>
            </Box>
          </CardContent>
        </Card>
      )}
      {currentTab === 'groups' && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 4,
          }}
        >
          <Card>
            <CardHeader
              title={'Groups With Access'}
              subheader={
                <QuickSearch
                  id='groups_search'
                  initialValue={groupSearch}
                  onChange={(val) => setGroupSearch(val)}
                  placeholder={t(
                    'policy.acknowledgements.groups.searchPlaceholder'
                  )}
                />
              }
            />
            <CardContent
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Fragment key={`groups_all`}>
                  <Checkbox
                    checked={selectedGroups.length === manualGroups.length}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        setSelectedGroups(manualGroups);
                      } else {
                        setSelectedGroups([]);
                      }
                    }}
                    value={[]}
                    name='selected_groups'
                    sx={{
                      p: 0,
                    }}
                  />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>All groups</Typography>
                  </Stack>
                </Fragment>
                {manualGroups.map((group) => (
                  <Fragment key={`groups_${group.id}`}>
                    <Checkbox
                      checked={
                        selectedGroups.filter((x) => x.id === group.id).length >
                        0
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked) {
                          setSelectedGroups((prev) => [...prev, group]);
                        } else {
                          setSelectedGroups((prev) =>
                            prev.filter((x) => x.id !== group.id)
                          );
                        }
                      }}
                      value={group.id}
                      name='selected_groups'
                      sx={{
                        p: 0,
                      }}
                    />
                    <Stack>
                      <Typography sx={{ fontWeight: 600 }}>
                        {group.name}
                      </Typography>
                      <Typography variant='caption'>
                        {group.description}
                      </Typography>
                    </Stack>
                  </Fragment>
                ))}
                {groupsTrigger}
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={'Selected Groups'} />
            <CardContent
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              {selectedGroups.length > 0 ? (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  {selectedGroups.map((group) => (
                    <Fragment key={`selected_groups_${group.id}`}>
                      <IconButton
                        onClick={() =>
                          setSelectedGroups((prev) =>
                            prev.filter((x) => x.id !== group.id)
                          )
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Stack>
                        <Typography sx={{ fontWeight: 600 }}>
                          {group.name}
                        </Typography>
                        <Typography variant='caption'>
                          {group.description}
                        </Typography>
                      </Stack>
                    </Fragment>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    minHeight: '40vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src='/img/policy/acknowledgements/empty_groups.png'
                      alt={t('policy.acknowledgements.groups.empty.title')}
                    />
                    <Typography variant='subtitle1'>
                      {t('policy.acknowledgements.groups.empty.title')}
                    </Typography>
                    <Typography variant='body2'>
                      {t('policy.acknowledgements.groups.empty.subtitle')}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      )}

      {currentTab === 'users' && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 4,
          }}
        >
          <Card>
            <CardHeader
              title={'Users With Access'}
              subheader={
                <QuickSearch
                  id='users_search'
                  initialValue={userSearch}
                  onChange={(val) => setUserSearch(val)}
                  placeholder={t(
                    'policy.acknowledgements.users.searchPlaceholder'
                  )}
                />
              }
            />
            <CardContent
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                {manualUsers.map((user) => (
                  <Fragment key={`users_${user.id}`}>
                    <Checkbox
                      checked={
                        selectedUsers.filter((x) => x.id === user.id).length > 0
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked) {
                          setSelectedUsers((prev) => [...prev, user]);
                        } else {
                          setSelectedUsers((prev) =>
                            prev.filter((x) => x.id !== user.id)
                          );
                        }
                      }}
                      value={user.id}
                      name='selected_users'
                      sx={{
                        p: 0,
                      }}
                    />
                    <Stack>
                      <Typography sx={{ fontWeight: 600 }}>
                        {[user.firstName, user.lastName]
                          .filter((x) => x && x.length > 0)
                          .join(' ')}
                      </Typography>
                      <Typography variant='caption'>{user.email}</Typography>
                    </Stack>
                  </Fragment>
                ))}
                {usersTrigger}
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={'Selected Users'} />
            <CardContent
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
              }}
            >
              {selectedUsers.length > 0 ? (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  {selectedUsers
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((user) => (
                      <Fragment key={`selected_users_${user.id}`}>
                        <IconButton
                          onClick={() =>
                            setSelectedUsers((prev) =>
                              prev.filter((x) => x.id !== user.id)
                            )
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Stack>
                          <Typography sx={{ fontWeight: 600 }}>
                            {[user.firstName, user.lastName]
                              .filter((x) => x && x.length > 0)
                              .join(' ')}
                          </Typography>
                          <Typography variant='caption'>
                            {user.email}
                          </Typography>
                        </Stack>
                      </Fragment>
                    ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    minHeight: '40vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src='/img/policy/acknowledgements/empty_users.png'
                      alt={t('policy.acknowledgements.users.empty.title')}
                    />
                    <Typography variant='subtitle1'>
                      {t('policy.acknowledgements.users.empty.title')}
                    </Typography>
                    <Typography variant='body2'>
                      {t('policy.acknowledgements.users.empty.subtitle')}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
    </DetailView>
  );
};

export default NewAdhocAcknowledgement;
