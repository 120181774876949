import React, { useContext, useState } from 'react';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Badge, Box, Button, CircularProgress, colors, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { FilterAltOutlined, Add as AddIcon, Lock as LockIcon } from '@mui/icons-material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

import useTranslations from 'hooks/useTranslations';
import useManageChapter from 'data_sources/draftManuals/useManageChapter';
import useManagePreface from 'data_sources/draftManuals/useManagePreface';
import useManagePolicy from 'data_sources/draftManuals/useManagePolicy';
import useStickyState from 'hooks/useStickyState';
import { DraftManualDetailContext } from './context';
import { ActionMenu } from 'components/_atoms';
import manualHelper from './helpers/draftManualHelper';
import errorHelper from 'helpers/errorHelper';
import useManageManual from 'data_sources/draftManuals/useManageManual';
import { AppContext } from 'views/App/context';
import { ServerErrorResponse } from 'data_sources/constants';
import { enqueueSnackbar } from 'notistack';
import draftManualHelper from './helpers/draftManualHelper';

const ToC: React.FC = () => {
  const t = useTranslations();
  const { currentProfile } = useContext(AppContext);
  const { manual, manualHasPermissions } = useContext(DraftManualDetailContext);
  const { node } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [expandedItems, setExpandedItems] = useStickyState<string[]>('phx-draft-manuals-toc-expanded', [] as string[]);
  const [isCreating, setIsCreating] = useState(false);
  const [gettingLock, setGettingLock] = useState<boolean>(false);

  const { create: createChapter } = useManageChapter();
  const { create: createPreface } = useManagePreface();
  const { create: createPolicy } = useManagePolicy();
  const { getLock } = useManageManual();

  const currentUserId = currentProfile?.user?.id;
  const isManualLocked = manual.isLocked;
  const manualLockOwnerId = manual.lock?.userId;
  const isLockedByCurrentUser = isManualLocked && manualLockOwnerId === currentUserId;

  const expandNodes = (ids: string[]) => {
    const expandedItemsSet = new Set<string>(expandedItems ?? []);
    if (!ids.every((x) => expandedItemsSet.has(x))) {
      setExpandedItems((prev) => {
        const prevSet = new Set<string>(prev ?? []);
        ids.forEach((x) => prevSet.add(x));
        return Array.from(prevSet);
      });
    }
  };
  const collapseNodes = (ids: string[]) => {
    const expandedItemsSet = new Set<string>(expandedItems ?? []);
    if (ids.some((x) => expandedItemsSet.has(x))) {
      setExpandedItems((prev) => {
        const prevSet = new Set<string>(prev ?? []);
        ids.forEach((x) => prevSet.delete(x));
        return Array.from(prevSet);
      });
    }
  };

  const toggleNode = (event: React.SyntheticEvent, itemId: string, isExpanded: boolean) => {
    if (isExpanded) {
      expandNodes([itemId]);
    } else {
      collapseNodes([itemId]);
    }
  };

  const handleNodeClick = (type: string, nodeId: string) => {
    const u = `${type}:${nodeId}`;
    navigate(u);
  };

  const selectedItem = (node?: string) => {
    if (!node) return null;

    const [type, id] = node.split(':');
    return id;
  };

  const handleCreateChapter = () => {
    if (isManualLocked && !isLockedByCurrentUser) {
      return enqueueSnackbar(t('draftManuals.toc.lockedBy', '', { username: manual?.lock?.username || '' }));
    }
    setIsCreating(true);
    createChapter(
      manual.id,
      { title: 'Chapter Title' },
      (chapterId) => {
        queryClient.invalidateQueries(['draft_manual', manual.id]);
        setIsCreating(false);
        handleNodeClick('chapter', chapterId);
      },
      (error) => {
        setIsCreating(false);
        errorHelper.enqueueErrors(error);
      }
    );
  };

  const handleCreatePreface = () => {
    if (isManualLocked && !isLockedByCurrentUser) {
      return enqueueSnackbar(t('draftManuals.toc.lockedBy', '', { username: manual?.lock?.username || '' }));
    }
    setIsCreating(true);
    expandNodes(['prefaces']);
    createPreface(
      manual.id,
      { title: 'Preface Title' },
      (prefaceId) => {
        queryClient.invalidateQueries(['draft_manual', manual.id]);
        queryClient.invalidateQueries(['draft_preface', manual.id]);
        setIsCreating(false);
        handleNodeClick('preface', prefaceId);
      },
      (error) => {
        setIsCreating(false);
        errorHelper.enqueueErrors(error);
      }
    );
  };

  const handleCreatePolicy = (chapterId: string) => {
    if (isManualLocked && !isLockedByCurrentUser) {
      return enqueueSnackbar(t('draftManuals.toc.lockedBy', '', { username: manual?.lock?.username || '' }));
    }
    setIsCreating(true);
    expandNodes([chapterId]);
    createPolicy(
      manual.id,
      { chapterId, title: 'Policy Title' },
      (policyId) => {
        queryClient.invalidateQueries(['draft_manual', manual.id]);
        queryClient.invalidateQueries(['draft_policy', manual.id]);
        handleNodeClick('policy', policyId);
        setIsCreating(false);
      },
      (error) => {
        setIsCreating(false);
        errorHelper.enqueueErrors(error);
      }
    );
  };

  const handleManageStructureClick = () => {
    setGettingLock(true);
    if (!isManualLocked) {
      getLock(
        manual.id,
        (lock) => {
          queryClient.invalidateQueries(['draft_manual', manual.id]);
          setGettingLock(false);
          navigate(`/portal/policy/draft-manuals/${manual.id}/manual-structure`);
        },
        (error: ServerErrorResponse) => {
          errorHelper.enqueueErrors(error);
          setGettingLock(false);
        }
      );
    } else if (isLockedByCurrentUser) {
      setGettingLock(false);
      navigate(`/portal/policy/draft-manuals/${manual.id}/manual-structure`);
    }
  };
  const setActionsMenuItems = () => {
    const actions = [];
    if (manualHasPermissions(manual.id, ['updateDraftManual'])) {
      actions.push({
        key: t('common.manageStructure'),
        label: t('common.manageStructure'),
        icon: isManualLocked && !isLockedByCurrentUser && (
          <Tooltip
            title={t('draftManuals.manualStructure.lockedBy') + manual.lock?.username}
            sx={{ marginTop: 'auto', marginBottom: 'auto', height: 20 }}
          >
            <LockIcon color='neutral' />
          </Tooltip>
        ),
        onClick: () => handleManageStructureClick(),
        disabled: (isManualLocked && !isLockedByCurrentUser) || gettingLock,
      });
    }
    return actions;
  };

  return manual ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <Stack>
        <Toolbar className='phx-panel-header'>
          <Stack
            direction='row'
            spacing={4}
            sx={{ flexGrow: 1 }}
          >
            <Button
              className='icon-only'
              variant='outlined'
              color='neutral'
              size='small'
              startIcon={<FilterAltOutlined />}
            ></Button>
          </Stack>
          {gettingLock && <CircularProgress sx={{ mr: 2 }} />}
          <Stack
            direction='row'
            spacing={4}
          >
            <ActionMenu
              id='manual_actions'
              btnText={t('common.actions')}
              items={setActionsMenuItems()}
              btnProps={{
                size: 'small',
                variant: 'outlined',
                color: 'neutral',
              }}
            />
          </Stack>
        </Toolbar>
        <Toolbar className='phx-panel-header hover-btn-container'>
          <Stack
            direction='row'
            spacing={4}
            sx={{ flexGrow: 1 }}
          >
            <Typography variant='subtitle1'>{t('draftManuals.toc.content')}</Typography>
          </Stack>
          <Stack
            direction='row'
            spacing={4}
          >
            {manualHasPermissions(manual.id, ['createDraftManualChapter']) && (
              <Tooltip
                title={t('draftManuals.toc.addChapter')}
                placement='left'
              >
                <Button
                  className='icon-only hover-btn'
                  variant='outlined'
                  color='neutral'
                  size='small'
                  onClick={handleCreateChapter}
                  startIcon={<AddIcon />}
                  disabled={isCreating}
                />
              </Tooltip>
            )}
          </Stack>
        </Toolbar>
        <SimpleTreeView
          selectedItems={selectedItem(node)}
          expandedItems={[...(expandedItems ?? [])]}
          sx={{
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
          onItemExpansionToggle={toggleNode}
          expansionTrigger='iconContainer'
        >
          <TreeItem
            key={'prefaces'}
            itemId={'prefaces'}
            label={
              <Toolbar className='phx-tree-toolbar hover-btn-container'>
                <Stack
                  direction='row'
                  spacing={4}
                  sx={{ flexGrow: 1 }}
                >
                  <Typography>{t('draftManuals.toc.prefaces')}</Typography>
                </Stack>
                <Stack
                  direction='row'
                  spacing={4}
                >
                  {manualHasPermissions(manual.id, ['createDraftPreface']) && (
                    <Tooltip
                      title={t('draftManuals.toc.addPreface')}
                      placement='left'
                    >
                      <Button
                        className='icon-only hover-btn'
                        variant='outlined'
                        color='neutral'
                        size='small'
                        onClick={handleCreatePreface}
                        startIcon={<AddIcon />}
                        disabled={isCreating}
                      ></Button>
                    </Tooltip>
                  )}
                </Stack>
              </Toolbar>
            }
            sx={{
              '& .MuiTreeItem-content': {
                borderBottom: `solid 1px ${colors.grey[300]}`,
                py: 3,
              },
            }}
          >
            {manual.prefaces.map((preface) => (
              <TreeItem
                key={preface.id}
                itemId={preface.id}
                label={
                  <Stack
                    direction='row'
                    spacing={2}
                  >
                    <Typography>{parse(preface.title)}</Typography>
                    {preface.lock && (
                      <Tooltip title={draftManualHelper.formatPrefaceLockInfo(preface)}>
                        <LockIcon
                          color='neutral'
                          fontSize='small'
                        />
                      </Tooltip>
                    )}
                  </Stack>
                }
                onClick={() => handleNodeClick('preface', preface.id)}
                sx={{
                  opacity: preface.state === 'ENABLED' ? 1 : 0.5,
                  '& .MuiTreeItem-content': {
                    borderBottom: `solid 1px ${colors.grey[300]}`,
                    py: 3,
                  },
                }}
              />
            ))}
          </TreeItem>
          {manual.chapters.map((chapter) => (
            <TreeItem
              key={chapter.id}
              itemId={chapter.id}
              label={
                <Toolbar className='phx-tree-toolbar hover-btn-container'>
                  <Stack
                    direction='row'
                    spacing={4}
                    sx={{ flexGrow: 1 }}
                  >
                    <Box onClick={() => handleNodeClick('chapter', chapter.id)}>
                      <Typography>{manualHelper.formatManualNodeTitle(chapter, manual)}</Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction='row'
                    spacing={4}
                  >
                    {manualHasPermissions(manual.id, ['createDraftPolicy']) && (
                      <Tooltip
                        title={t('draftManuals.toc.addPolicy')}
                        placement='left'
                      >
                        <Button
                          className='icon-only hover-btn'
                          variant='outlined'
                          color='neutral'
                          size='small'
                          onClick={() => handleCreatePolicy(chapter.id)}
                          startIcon={<AddIcon />}
                          disabled={isCreating}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </Toolbar>
              }
              sx={{
                opacity: chapter.state === 'ENABLED' ? 1 : 0.5,
                '& .MuiTreeItem-content': {
                  borderBottom: `solid 1px ${colors.grey[300]}`,
                  py: 3,
                },
              }}
            >
              {(chapter.policies ?? []).map((policy) => (
                <TreeItem
                  key={policy.id}
                  itemId={policy.id}
                  label={
                    <Stack
                      direction='row'
                      spacing={2}
                    >
                      <Typography>{manualHelper.formatManualNodeTitle(policy, manual)}</Typography>
                      {policy.lock && (
                        <Stack
                          direction='row'
                          spacing={1}
                        >
                          {draftManualHelper.policyLockAvatar(policy, true) ?? <Box />}
                          <LockIcon
                            color='neutral'
                            fontSize='small'
                          />
                        </Stack>
                      )}
                    </Stack>
                  }
                  onClick={() => handleNodeClick('policy', policy.id)}
                  sx={{
                    opacity: policy.state === 'ENABLED' ? 1 : 0.5,
                    '& .MuiTreeItem-content': {
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      py: 3,
                    },
                  }}
                />
              ))}
            </TreeItem>
          ))}
        </SimpleTreeView>
      </Stack>
    </Box>
  ) : null;
};

export default ToC;
