import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { DraftPreface } from 'types/manual';

export default function useDraftPreface(
  manualId?: string,
  prefaceId?: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexiverse/prefaces/v1/manuals/${manualId}/prefaces/${prefaceId}`;
  return useQuery<DraftPreface, Error>(
    ['draft_preface', manualId, prefaceId],
    async () => {
      const data = await fetcher({
        method: 'get',
        url,
      });
      return data;
    },
    {
      ...options,
      enabled: !!prefaceId,
      keepPreviousData: false,
    }
  );
}
