import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQueryClient } from 'react-query';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Badge,
  Button,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import useRoleUsers from "data_sources/roles/useRoleUsers";
import useManageRoleUser from 'data_sources/roles/useManageRoleUser';
import { User } from 'types/user';
import ListSection from 'components/_layouts/ListSection';
import { ActionMenu } from 'components/_atoms';
import AddUser from './AddUser';

const columnHelper = createColumnHelper<User>();

export default function Users() {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const usersQuery = useRoleUsers(currentOrganizationId || '', id || '');
  const queryClient = useQueryClient();
  const [addingUsers, setAddingUsers] = useState<boolean>(false);

  const {
    removeUser,
  } = useManageRoleUser();

  const columns = [
    columnHelper.accessor('lastName', {
      cell: (info) => (
        <Stack>
          <Link href={`/portal/lexid/users/${info.row.original.id}`} target="_blank" rel="noopener noreferrer">
            <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>{[
              info.row.original.firstName,
              info.row.original.middleName,
              info.row.original.lastName,
            ].join(' ')}</Typography>
          </Link>
          {info.row.original.email && (
            <Link href={`mailto:${info.row.original.email}`}>{info.row.original.email}</Link>
          )}
        </Stack>
      ),
      header: () => t('users.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('active', {
      cell: (info) => (
        <Badge variant='inline' badgeContent={info.getValue() ? 'Active' : 'Inactive'} color={info.getValue() ? 'success' : 'error'} sx={{ textTransform: 'uppercase' }} />
      ),
      header: () => t('users.properties.active'),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <ActionMenu
          id={`action_menu_${info.row.original.id}`}
          items={[
            ...(currentOrganizationId && id ? [{
              key: `remove_${info.row.original.id}`,
              label: t('roles.actions.removeUser'),
              onClick: () => removeUser(currentOrganizationId, info.row.original.id || '', id, () => {
                console.log('clear role users')
                queryClient.invalidateQueries(['role_users', currentOrganizationId, id])
              })
            },
            ] : []),
          ]
          }
        />
      ),
      header: () => '',
      size: 60,
    }),
  ];

  const users = React.useMemo(() => usersQuery.data && usersQuery.data.pages ?
    usersQuery.data.pages.flatMap((x) => x.content) : []
    , [usersQuery.data]);

  return (
    <Stack>
      <Toolbar className='phx-panel-header'>
        <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
          {' '}
        </Stack>
        <Stack direction='row' spacing={4}>
          <Button onClick={() => setAddingUsers((prev) => !prev)}>
            {addingUsers ? t('roles.actions.done') : t('roles.actions.addUser')}
          </Button>
        </Stack>
      </Toolbar>
      {addingUsers ? (
        <AddUser />
      ) : (
        <ListSection
          columns={columns}
          data={users}
          query={usersQuery}
        />
      )}
    </Stack>
  )
}
