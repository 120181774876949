import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppContext } from '../../context';
import Organizations from './Organizations';
import Settings from './Settings';
import EditOrganization from './Settings/Edit';
import EditOrganizationAuthentication from './Settings/Authentication';
import Users from './Users';
import UserImports from './UserImports';
import Roles from './Roles';
import Groups from './Groups';

export default function LexID() {
  const {
    currentOrganizationId,
    enforceAppPermissions,
    enforcePermissions,
    hasPermission,
    hasAppPermission,
    setCurrentModule,
  } = useContext(AppContext);

  const getDefaultRoute = () => {
    if (!hasPermission('getOrg', currentOrganizationId)) {
      // getOrg required to see org settings
      if (hasAppPermission('listOrgs')) {
        return <Navigate to='./organizations' />
      } else if (hasPermission('listUsers', currentOrganizationId)) {
        return <Navigate to='./users' />
      } else if (hasPermission('listRoles', currentOrganizationId)) {
        return <Navigate to='./roles' />
      } else if (hasPermission('listGroups', currentOrganizationId)) {
        return <Navigate to='./groups' />
      } else {
        return <Navigate to='/dashboard' />;
      }
    } else {
      return <Navigate to='./settings' />;
    }
  }

  useEffect(() => {
    setCurrentModule('lexid');
  }, [setCurrentModule]);

  return (
    <Routes>
      <Route path='' element={getDefaultRoute()} />
      <Route path='settings/edit' element={enforcePermissions(['updateOrg'], <EditOrganization />)} />
      <Route path='settings/auth' element={enforcePermissions(['updateOrgSSO'], <EditOrganizationAuthentication />)} />
      <Route path='settings/*' element={enforcePermissions(['getOrg'], <Settings />)} />
      <Route path='organizations/*' element={enforceAppPermissions(['listOrgs'], <Organizations />)} />
      <Route path='groups/*' element={enforcePermissions(['listGroups'], <Groups />)} />
      <Route path='roles/*' element={enforcePermissions(['listRoles'], <Roles />)} />
      <Route path='users/*' element={enforcePermissions(['listUsers'], <Users />)} />
      <Route path='user-imports/*' element={enforcePermissions(['listJobsImportUsers'], <UserImports />)} />
    </Routes>
  );
}
