import React from 'react';
import { Select, MenuItem } from '@mui/material';
import useTranslations from 'hooks/useTranslations';
import { ManualFilterOptions } from 'data_sources/draftManuals/enums';

interface PolicyContentFilterProps {
  filter: ManualFilterOptions;
  setFilter: React.Dispatch<React.SetStateAction<ManualFilterOptions>>;
}

const PolicyContentFilter: React.FC<PolicyContentFilterProps> = React.memo(({ filter, setFilter }) => {
  const t = useTranslations();

  return (
    <Select
      id='filter-select'
      value={filter}
      onChange={(e) => setFilter(e.target.value as ManualFilterOptions)}
      sx={{ height: 30, width: 160, color: '#939496', fontWeight: 600 }}
    >
      <MenuItem
        value={ManualFilterOptions.ALL}
        sx={{ color: '#00000099', fontWeight: 500 }}
      >
        {t('draftManuals.manualStructure.filters.all')}
      </MenuItem>
      <MenuItem
        value={ManualFilterOptions.DISABLED}
        sx={{ color: '#00000099', fontWeight: 500 }}
      >
        {t('draftManuals.manualStructure.filters.disabled')}
      </MenuItem>
      <MenuItem
        value={ManualFilterOptions.ENABLED}
        sx={{ color: '#00000099', fontWeight: 500 }}
      >
        {t('draftManuals.manualStructure.filters.enabled')}
      </MenuItem>
    </Select>
  );
});

export default PolicyContentFilter;
