import { useEffect } from 'react';
import { PhxListItemNode, PhxListNode } from '../../nodes/PhxListNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { useList } from './useList';

export default function ListPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([PhxListNode, PhxListItemNode])) {
      throw new Error(
        'ListPlugin: PhxListNode and/or PhxListItemNode not registered on editor',
      );
    }
  }, [editor]);

  useList(editor);

  return null;
}