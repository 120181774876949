import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useQueryClient } from 'react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Stack, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

import useTranslations from 'hooks/useTranslations';
import useUserPendingAcknowledgements from 'data_sources/users/useUserPendingAcknowledgements';
import useManageUserAcknowledgement from 'data_sources/users/useManageUserAcknowledgement';
import { PendingAcknowledgement } from 'types/acknowledgements';
import ListSection from 'components/_layouts/ListSection';
import datetimeHelper from 'helpers/datetimeHelper';
import dayjs from 'dayjs';
import { ActionMenu } from 'components/_atoms';
import { ActionMenuItemProps } from 'components/_atoms/ActionMenu';

const columnHelper = createColumnHelper<PendingAcknowledgement>();

export default function PendingAcknowledgements() {
  const t = useTranslations();
  const { id } = useParams();
  const acknowledgementsQuery = useUserPendingAcknowledgements(id || '');
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { cancelAcknowledgment } = useManageUserAcknowledgement();

  const statusBadge = (dueAt: string | null) => {
    if (!dueAt) return null;
    const dueDate = dayjs(dueAt);
    const today = dayjs()
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    let badgeContent = 'Overdue';
    let badgeColor: 'error' | 'warning' | 'success' | 'info' = 'error';
    // console.log(dueAt, !!dueDate.isSame(today));
    if (dueDate.isSame(today)) {
      badgeContent = 'Due Today';
      badgeColor = 'warning';
    } else if (dueDate.isAfter(dayjs().add(30, 'day'))) {
      badgeContent = `${Math.ceil(dueDate.diff(today, 'week', true))} weeks left`;
      badgeColor = 'warning';
    } else if (dueDate.isAfter(dayjs())) {
      badgeContent = `${Math.ceil(dueDate.diff(today, 'day', true))} days left`;
      badgeColor = 'warning';
    }

    return (
      <Badge
        variant='inline'
        badgeContent={badgeContent}
        color={badgeColor}
        sx={{ textTransform: 'uppercase' }}
      />
    );
  };

  const handleCancelAcknowledgment = (
    manualId: string,
    acknowledgementId: string
  ) => {
    confirm({
      cancellationButtonProps: {
        variant: 'outlined',
        color: 'neutral',
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      confirmationText: t(
        'users.pendingAcknowledgements.cancelConfirm.confirmationText'
      ),
      description: t('users.pendingAcknowledgements.cancelConfirm.body'),
      title: t('users.pendingAcknowledgements.cancelConfirm.title'),
    })
      .then(() => {
        setIsProcessing(true);
        cancelAcknowledgment(
          id || '',
          manualId,
          acknowledgementId,
          () => {
            setIsProcessing(false);
            queryClient.invalidateQueries([
              'user_pending_acknowledgements',
              id,
            ]);
          },
          () => {
            setIsProcessing(false);
          }
        );
      })
      .catch(() => {
        // don't do it
      });
  };

  const acknowledgementActions = (data: PendingAcknowledgement) => {
    const actions: ActionMenuItemProps[] = [];
    if (
      data.authorizations
        .map((x) => x.action)
        .includes('deleteManualAcknowledgement')
    ) {
      actions.push({
        key: `cancel_${data.id}`,
        label: t('users.pendingAcknowledgements.cancel'),
        onClick: () => handleCancelAcknowledgment(data.manualId, data.id),
      });
    }
    /* actions.push({
      key: `reminder_${data.id}`,
      label: t('users.pendingAcknowledgements.reminder'),
      href: '#',
    }); */
    return actions;
  };

  const columns = [
    columnHelper.accessor('policyNumber', {
      cell: (info) => (
        <Stack>
          <Typography sx={{ fontWeight: 'bold' }}>
            {[
              info.row.original.manualType,
              info.row.original.policyNumber,
            ].join(' ')}
          </Typography>
        </Stack>
      ),
      header: () => '',
      size: 140,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <Stack>
          <Typography
            variant='h6'
            sx={{ fontWeight: 'semibold' }}
          >
            {parse(info.row.original.policyTitle)}
          </Typography>
          {info.row.original.manualTitle && (
            <Typography> {parse(info.row.original.manualTitle)}</Typography>
          )}
        </Stack>
      ),
      header: () => '',
      size: 9999,
    }),
    columnHelper.accessor('assignedAt', {
      cell: (info) => (
        <Typography>
          {[
            t('users.pendingAcknowledgements.assignedAt'),
            datetimeHelper.formatDate(info.row.original.assignedAt, {
              format: 'short',
            }),
          ].join(': ')}
        </Typography>
      ),
      header: () => t('users.pendingAcknowledgments.assignedAt'),
      size: 200,
    }),
    columnHelper.accessor('dueAt', {
      cell: (info) => (
        <Typography>
          {info.row.original.dueAt
            ? [
                t('users.pendingAcknowledgements.dueAt'),
                dayjs(info.row.original.dueAt).format('M/D/YYYY'),
              ].join(': ')
            : ''}
        </Typography>
      ),
      header: () => t('users.pendingAcknowledgments.dueAt'),
      size: 200,
    }),
    columnHelper.display({
      id: 'status',
      cell: (info) => statusBadge(info.row.original.dueAt),
      header: () => t('users.pendingAcknowledgments.dueAt'),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <ActionMenu
          id={`action_menu_${info.row.original.id}`}
          items={acknowledgementActions(info.row.original)}
        />
      ),
      header: () => '',
      size: 60,
    }),
  ];

  const acknowledgements = React.useMemo(
    () =>
      acknowledgementsQuery.data && acknowledgementsQuery.data.pages
        ? acknowledgementsQuery.data.pages.flatMap((x) => x.content)
        : [],
    [acknowledgementsQuery.data]
  );

  return (
    <Stack>
      <ListSection
        columns={columns}
        data={acknowledgements}
        query={acknowledgementsQuery}
        hideHeaders
        isProcessing={isProcessing}
      />
    </Stack>
  );
}
