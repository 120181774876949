import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import parse from 'html-react-parser';
import useTranslations from 'hooks/useTranslations';
import { AppContext } from 'views/App/context';
import { DraftManualDetailContext } from '../context';
import useDraftPolicy from 'data_sources/draftManuals/useDraftPolicy';
import useManagePolicy from 'data_sources/draftManuals/useManagePolicy';
import draftManualHelper from '../helpers/draftManualHelper';
import { DraftPolicy, DraftPolicySection, DraftPolicySubSection, FlattenedItem } from 'types/manual';
import { ManualFilterOptions } from 'data_sources/draftManuals/enums';
import { ServerErrorResponse } from 'data_sources/constants';
import errorHelper from 'helpers/errorHelper';
import { Box } from '@mui/material';

/***********
 * VIEW - Not locked, no edit permission
 * DISABLED - policy is currently disabled
 * EDITABLE - Not locked, has edit permission
 * EDITING - locked by current user
 * LOCKED - locked by another user
 ******/
type PolicyMode = 'VIEW' | 'DISABLED' | 'EDITABLE' | 'EDITING' | 'LOCKED';

interface PolicyEditorContextProps {
  autoSave: () => Promise<void>;
  currentSection: string | null;
  disableSave: (policyData: DraftPolicy | null) => boolean;
  filter: ManualFilterOptions;
  filteredSections: () => DraftPolicySection[] | null | undefined;
  filterSubsections: (subSections: DraftPolicySubSection[]) => DraftPolicySubSection[];
  gettingLock: boolean;
  id: string;
  isOpenModifyPolicy: boolean;
  isSaving: boolean;
  handleAcquireLockClick: () => void;
  handleInsertSectionAfter: (sectionId: string) => void;
  handleInsertSubSectionAfter: (sectionId: string, subsectionId?: string) => void;
  handleModifyClick: () => void;
  handleSave: () => Promise<void>;
  handleSectionChange: (updatedItem: DraftPolicySubSection | DraftPolicySection) => void;
  handleSelectSection: (sectionId: string) => void;
  policyData: DraftPolicy | null;
  policyMode: PolicyMode;
  policyNumber: string;
  policyQuery: any;
  setFilter: React.Dispatch<React.SetStateAction<ManualFilterOptions>>;
  setGettingLock: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenModifyPolicy: React.Dispatch<React.SetStateAction<boolean>>;
  updatePolicyData: (updatedData: any) => void;
}

const PolicyEditorContext = createContext<PolicyEditorContextProps | undefined>(undefined);

export const PolicyEditorProvider: React.FC<{ id: string; children: React.ReactNode }> = ({ id, children }) => {
  const queryClient = useQueryClient();
  const t = useTranslations();
  const confirm = useConfirm();
  const { currentProfile } = useContext(AppContext);
  const { manual, manualHasPermissions } = useContext(DraftManualDetailContext);
  const policyQuery = useDraftPolicy(manual.id, id);
  const [policyData, setPolicyData] = useState<DraftPolicy | null>(null);
  const [currentSection, setCurrentSection] = useState<string | null>(null);
  const [filter, setFilter] = useState(ManualFilterOptions.ENABLED);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [gettingLock, setGettingLock] = useState<boolean>(false);
  const [hasLock, setHasLock] = useState<string | null>(null);
  const [isOpenModifyPolicy, setIsOpenModifyPolicy] = useState<boolean>(false);
  const [policyMode, setPolicyMode] = useState<PolicyMode>('VIEW');
  const { getLock, removeLock, takeLock, update: updatePolicy } = useManagePolicy();
  const policyNumber = draftManualHelper.getNumberManualNode(policyQuery.data, manual);

  const committedPolicyQuery = useDraftPolicy(manual.id, id, false);

  useEffect(() => {
    if (policyQuery.data) {
      if (policyData === null || policyData.id !== id) {
        setPolicyData(policyQuery.data);
      }
    }
  }, [policyQuery.data]);

  useEffect(() => {
    let mode: PolicyMode = 'VIEW';
    if (manualHasPermissions(manual.id, ['updateDraftPolicy']) && policyQuery.data) {
      if (policyQuery.data.lock) {
        if (policyQuery.data.lock.userId === currentProfile?.user?.id) {
          mode = 'EDITING';
          setHasLock(policyQuery.data.id);
        } else {
          mode = 'LOCKED';
          if (hasLock === policyQuery.data.id) {
            enqueueSnackbar(
              <Box>
                {parse(
                  t('draftManuals.policy.lostLock', '', {
                    user: draftManualHelper.formatPolicyLockInfo(policyQuery.data),
                  })
                )}
              </Box>,
              {
                variant: 'error',
                autoHideDuration: 10000,
              }
            );
            setHasLock(null);
          }
        }
      } else {
        mode = 'EDITABLE';
      }
      if (policyQuery.data.state === 'DISABLED') {
        mode = 'DISABLED';
      }
      if (policyMode === 'EDITING' && mode === 'DISABLED') {
        enqueueSnackbar(
          <div
            dangerouslySetInnerHTML={{
              __html: t('draftManuals.policy.savedButDisabled'),
            }}
          />,
          {
            variant: 'error',
            autoHideDuration: 10000,
          }
        );
      }
    }

    setPolicyMode(mode);
  }, [manualHasPermissions, manual.id, policyQuery.data, currentProfile?.user?.id]);

  const updatePolicyData = useCallback((updatedData: any) => {
    setPolicyData((prev) => ({ ...prev, ...updatedData }));
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    const updatedPolicy: DraftPolicy = {
      ...policyData!, // Ensure we have a copy of the policy data
    };
    const sectionIds: string[] = [];
    updatedPolicy.sections.forEach((section) => {
      if (sectionIds.includes(section.id) || section.id.startsWith('new_')) {
        section.id = '';
      }
      sectionIds.push(section.id);
      section.subSections.forEach((subsection) => {
        if (sectionIds.includes(section.id) || subsection.id.startsWith('new_')) {
          subsection.id = '';
        }
        sectionIds.push(subsection.id);
      });
    });

    updatePolicy(
      manual.id,
      id,
      updatedPolicy,
      () => {
        removeLock(
          manual.id,
          id,
          () => {
            setPolicyData(null);
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            queryClient.invalidateQueries(['draft_policy', manual.id, id]);
            setIsSaving(false);
            // clearTimer();
          },
          (error: ServerErrorResponse) => {
            errorHelper.enqueueErrors(error);
            setIsSaving(false);
          }
        );
      },
      (errors: ServerErrorResponse) => {
        // console.log(errors.errorInformationCode, errors);
        if (errors.errorInformationCode === 3134) {
          queryClient.invalidateQueries(['draft_manual', manual.id]);
          queryClient.invalidateQueries(['draft_policy', manual.id, id]);
        } else {
          errorHelper.enqueueErrors(errors);
        }
        setIsSaving(false);
      }
    );
    setFilter(ManualFilterOptions.ENABLED);
  };

  const autoSave = async () => {
    if (policyData) {
      const updatedPolicy: DraftPolicy = {
        ...policyData!, // Ensure we have a copy of the policy data
      };
      updatedPolicy.sections.forEach((section) => {
        if (section.id.startsWith('new_')) {
          section.id = '';
        }
        section.subSections.forEach((subsection) => {
          if (subsection.id.startsWith('new_')) {
            subsection.id = '';
          }
        });
      });
      // console.log('autosave', updatedPolicy);
      updatePolicy(
        manual.id,
        id,
        updatedPolicy,
        (data) => {
          if (data) {
            setPolicyData((prev) => {
              // Ensure we have a copy of the policy data
              if (!prev) return data;
              // console.log('after update', data, prev);
              prev.sections.forEach((section, sectionIndex) => {
                if (section.id.startsWith('new_') || section.id === '') {
                  data.sections.forEach((newSection, nsi) => {
                    if (nsi === sectionIndex) {
                      // console.log('setting section id', section.id, newSection.id);
                      section.id = newSection.id;
                    }
                  });
                }
                section.subSections.forEach((subsection, subSectionIndex) => {
                  if (subsection.id.startsWith('new_') || subsection.id === '') {
                    data.sections.forEach((newSection, nsi) => {
                      if (nsi === sectionIndex) {
                        newSection.subSections.forEach((newSubSection, nssi) => {
                          if (nssi === subSectionIndex) {
                            subsection.id = newSubSection.id;
                          }
                        });
                      }
                    });
                  }
                });
              });

              return prev;
            });
          }
          queryClient.invalidateQueries(['draft_manual', manual.id]);
          queryClient.invalidateQueries(['draft_policy', manual.id, id]);
        },
        (errors: ServerErrorResponse) => {
          // console.log(errors.errorInformationCode, errors);
          if (errors.errorInformationCode === 3134) {
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            queryClient.invalidateQueries(['draft_policy', manual.id, id]);
          } else {
            errorHelper.enqueueErrors(errors);
          }
        }
      );
    }
  };

  const handleSelectSection = useCallback(
    (sectionId: string) => {
      if (currentSection !== sectionId && policyData) {
        setCurrentSection(sectionId);
        if (JSON.stringify(policyData) !== JSON.stringify(policyQuery.data) && !sectionId?.startsWith('new_')) {
          // console.log('policy diff', JSON.stringify(policyData), JSON.stringify(policyQuery.data));
          // save but keep the lock
          autoSave();
        }
      }
    },
    [policyData, policyQuery.data, currentSection]
  );

  const handleModifyClick = () => {
    setGettingLock(true);
    getLock(
      manual.id,
      id,
      (lock) => {
        queryClient.invalidateQueries(['draft_manual', manual.id]);
        queryClient.invalidateQueries(['draft_policy', manual.id, id]);
        setGettingLock(false);
      },
      (error: ServerErrorResponse) => {
        errorHelper.enqueueErrors(error);
        setGettingLock(false);
      }
    );
    setFilter(ManualFilterOptions.ENABLED);
  };

  const handleAcquireLockClick = () => {
    setGettingLock(true);
    confirm({
      cancellationButtonProps: {
        variant: 'outlined',
        color: 'neutral',
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      confirmationText: t('draftManuals.policy.acquireLock.ok'),
      description: t('draftManuals.policy.acquireLock.body'),
      title: t('draftManuals.policy.acquireLock.title'),
    })
      .then(() => {
        takeLock(
          manual.id,
          id,
          (lock) => {
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            queryClient.invalidateQueries(['draft_policy', manual.id, id]);
            setFilter(ManualFilterOptions.ENABLED);
            setGettingLock(false);
          },
          (error: ServerErrorResponse) => {
            errorHelper.enqueueErrors(error);
            setGettingLock(false);
          }
        );
      })
      .catch(() => {
        // don't do it
        setGettingLock(false);
      });
  };

  const handleInsertSectionAfter = useCallback(
    (sectionId: string) => {
      if (policyData) {
        const newId = `new_${Date.now()}`;
        updatePolicyData(draftManualHelper.insertSectionAfter(sectionId, newId, policyData));

        handleSelectSection(newId);
      }
    },
    [policyData]
  );

  const handleInsertSubSectionAfter = useCallback(
    (sectionId: string, subsectionId?: string) => {
      if (policyData) {
        const newId = `new_${Date.now()}`;
        updatePolicyData(draftManualHelper.insertSubSectionAfter(sectionId, newId, policyData, subsectionId));

        handleSelectSection(newId);
      }
    },
    [policyData]
  );

  const filteredSections = () => {
    if (filter === ManualFilterOptions.ALL) return policyData?.sections;

    if (filter === ManualFilterOptions.ENABLED)
      return policyData?.sections.filter((section) => section.state === ManualFilterOptions.ENABLED);
    if (filter === ManualFilterOptions.DISABLED) {
      return policyData?.sections.filter(
        (section) =>
          section.state === ManualFilterOptions.DISABLED ||
          section.subSections.some((sub) => sub.state === ManualFilterOptions.DISABLED)
      );
    }
    return null;
  };

  const filterSubsections = (subSections: DraftPolicySubSection[]) => {
    if (filter === ManualFilterOptions.ALL) return subSections;
    return subSections.filter((sub) => sub.state === filter);
  };

  const disableSave = (policyData: DraftPolicy | null) => {
    if (!policyData) return true; // No policy data, disable save
    let disabled = isSaving;
    if (policyData.title.length === 0) disabled = true; // Title is empty, disable save
    policyData.sections.forEach((section) => {
      if (section.title.length === 0) disabled = true; // Section title is empty, disable save
      section.subSections.forEach((subsection) => {
        if (subsection.title.length === 0) disabled = true; // Subsection title is empty, disable save
      });
    });
    return disabled;
  };

  const handleSectionChange = (updatedItem: DraftPolicySubSection | DraftPolicySection) => {
    setPolicyData((prevData) => {
      if (!prevData) return null;

      const updatedSections = prevData.sections.map((section) => {
        if (section.id === updatedItem.id) {
          const newSubSections =
            updatedItem.state === 'DISABLED'
              ? section.subSections.map((subSection) => ({ ...subSection, state: 'DISABLED' as 'DISABLED' }))
              : section.subSections;

          return { ...section, ...updatedItem, subSections: newSubSections };
        }

        const updatedSubSections = section.subSections.map((subSection) => {
          if (subSection.id === updatedItem.id) {
            return { ...subSection, ...updatedItem };
          }
          return subSection;
        });

        const isAnySubSectionEnabled = updatedSubSections.some((subSection) => subSection.state !== 'DISABLED');
        const shouldEnableSection = isAnySubSectionEnabled && section.state === 'DISABLED';

        return updatedSubSections !== section.subSections
          ? { ...section, subSections: updatedSubSections, state: shouldEnableSection ? 'ENABLED' : section.state }
          : section;
      });

      return { ...prevData, sections: updatedSections };
    });
  };

  const contextValue = {
    autoSave,
    currentSection,
    disableSave,
    filter,
    filteredSections,
    filterSubsections,
    gettingLock,
    id,
    isOpenModifyPolicy,
    isSaving,
    handleAcquireLockClick,
    handleInsertSectionAfter,
    handleInsertSubSectionAfter,
    handleModifyClick,
    handleSave,
    handleSectionChange,
    handleSelectSection,
    policyData,
    policyMode,
    policyNumber,
    policyQuery,
    setFilter,
    setGettingLock,
    setIsOpenModifyPolicy,
    updatePolicyData,
  };

  return <PolicyEditorContext.Provider value={contextValue}>{children}</PolicyEditorContext.Provider>;
};

export const usePolicyEditorContext = () => {
  const context = useContext(PolicyEditorContext);
  if (!context) {
    throw new Error('usePolicyEditorContext must be used within a PolicyEditorProvider');
  }
  return context;
};
