import React, { useCallback, useMemo, useContext } from "react";
import { QueryClient, UseInfiniteQueryResult, useQueryClient } from "react-query";
import * as Yup from 'yup';

import { AppContext } from "views/App/context";
import useUrlParams from "hooks/useUrlParams";
import useTranslations from "hooks/useTranslations";
import useRoles from "data_sources/roles/useRoles";
import useManageRoles from "data_sources/roles/useManageOrganizationRole";
import type { Role } from "types/role";
import { ApiCollectionResponse } from "types/api_response";
import { ServerErrorResponse } from "data_sources/constants";

type RolesProviderProps = {
  children: React.ReactNode;
};

type RolesUrlParams = {
  search?: string | null;
  sort?: string | null;
};

export type RolesProviderResponse = {
  clearParams: () => void;
  createRole: (
    data: Role,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => void;
  params: RolesUrlParams;
  queryClient: QueryClient | null;
  updateParams: (updatedParams: RolesUrlParams) => void;
  updateRole: (
    guid: string,
    data: Role,
    onSuccess?: (data: Role) => void,
    onError?: (errors: ServerErrorResponse) => void,) => void;
  roles: Role[] | null;
  rolesQuery: UseInfiniteQueryResult<ApiCollectionResponse<Role>, Error>;
  roleSchema: any,
}

export const SORT_OPTIONS = [
  {
    key: 'name',
    label: 'Name',
    value: 'name_asc'
  },
  {
    key: 'created_at',
    label: 'Recently Created',
    value: 'createdAt_desc'
  },
  {
    key: 'updated_at',
    label: 'Recently Updated',
    value: 'updatedAt_desc'
  },
]

const defaultParams: RolesUrlParams = {
  search: null,
  sort: null,
};

export const RolesContext = React.createContext<RolesProviderResponse>({} as RolesProviderResponse);

const RolesProvider: ({ children }: RolesProviderProps) => any = ({ children }) => {
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const queryClient = useQueryClient();
  const t = useTranslations();
  const [params, setParams] = useUrlParams(defaultParams);
  const updateParams = useCallback((updatedParams: RolesUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams: any = {
      ...params
    };
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null || newParams[key] === '') {
        delete newParams[key];
      }
    });
    if (newParams.sort) {
      const opt = SORT_OPTIONS.find((x) => x.key === newParams.sort);
      if (opt) newParams.sort = opt.value;
    }

    return newParams;
  }, [params]);

  // const isFiltered = params !== defaultParams;

  const roleData = useRoles(orgId, queryParams);
  const {
    create: createRole,
    update: updateRole,

  } = useManageRoles();

  const roleSchema = Yup.object().shape({
    name: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('roles.properties.name')} is required`),
  });

  const roles = useMemo(() => roleData.data && roleData.data.pages ?
    roleData.data.pages.flatMap((x: any) => x.content) : null
    , [roleData.data]);

  const handleCreateRole = useCallback((
    data: Role,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    createRole(
      orgId,
      data,
      (guid: string) => {
        queryClient.resetQueries(['organization_roles']);
        if (onSuccess) onSuccess(guid);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [createRole, orgId, queryClient]);

  const handleUpdateRole = useCallback((
    id: string,
    data: Role,
    onSuccess?: (updatedData: Role) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    updateRole(
      orgId,
      id,
      data,
      (updatedData: Role) => {
        queryClient.invalidateQueries(['organization_roles']);
        queryClient.invalidateQueries(['organization_role', updatedData.orgId, updatedData.id]);
        if (onSuccess) onSuccess(updatedData);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [orgId, queryClient, updateRole])

  const contextValue: RolesProviderResponse = useMemo(() => ({
    clearParams,
    createRole: handleCreateRole,
    params,
    queryClient,
    updateParams,
    updateRole: handleUpdateRole,
    rolesQuery: roleData,
    roles: roles,
    roleSchema,
  }), [clearParams, handleCreateRole, handleUpdateRole, params, queryClient, updateParams, roleData, roles, roleSchema]);

  return (
    <RolesContext.Provider value={contextValue}>
      {children}
    </RolesContext.Provider>
  )
};

export default RolesProvider;