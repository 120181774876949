import React from 'react';
import jp from 'jsonpath';
import { Badge, Box, colors } from "@mui/material";
import contentHelper from "helpers/contentHelper";
import { ServerError, ServerErrorResponse } from 'data_sources/constants';
import useTranslations from 'hooks/useTranslations';

type CountResults = {
  INSERTED?: number;
  FAIL?: number;
  UPDATED?: number;
  INACTIVE?: number;
}

export default class userImportHelper {
  static calculatedStatus = (data: any) => {
    let status = data.status
    if (status?.match(/succeeded/i)) {
      const counts = jp.query(data.payload, '$..itemCounts')[0];
      if (counts) {
        if (counts['FAIL'] && counts['FAIL'] > 0) {
          status = 'FAILED'
          if ((counts['INSERTED'] || 0) + (counts['UPDATED'] || 0)) {
            status = 'PARTIAL_SUCCESS'
          }
        }
      };
    }
    return status;
  }

  static renderErrors = (errMsg: string) => {
    const t = useTranslations();
    let errors: ServerErrorResponse | null;
    try {
      errors = JSON.parse(errMsg);
    } catch (err) {
      errors = {
        errorInformationCode: 0,
        detail: errMsg,
        errorMessage: errMsg,
        errorDetails: []
      };
      console.log('exception parsing error', errMsg)
    }
    const errorDetails: ServerError[] = [];
    if (errors?.errorDetails && errors?.errorDetails.length > 0) {
      errors.errorDetails.forEach((e) => errorDetails.push(e))
    } else if (errors) {
      const rootError: ServerError = {
        code: errors?.errorInformationCode || 0,
        detail: errors?.detail || '',
        message: errors?.errorMessage || errors?.message || ''
      }
      errorDetails.push(rootError);
    }
    if (errorDetails.length === 0) errorDetails.push({
      code: 3,
      detail: 'Internal error',
      message: 'Internal error'
    })
    return (
      <Box
        sx={{
          'ul.error-list': {
            color: colors.red[600]
          }
        }}
      >
        <ul className="error-list" style={{ margin: '2px 0' }}>
          {errorDetails.map((x, i) => (
            <li key={`server_errors_${i}`} data-errors={JSON.stringify(x)}>{t(`errors.${x.code}`) || x.message}</li>
          ))}
        </ul>
      </Box>
    )
  }

  static userImportCounts: (data: any) => CountResults = (data) => {
    let counts = {}
    if (data.status?.match(/succeeded/i)) {
      counts = jp.query(data.payload, '$..itemCounts')[0];
    }
    return counts;
  }
  static resultBucket: (data: any) => string | null | undefined = (data) => {
    return jp.query(data.payload, '$..output.bucket')[0];
  }

  static resultKey: (data: any) => string | null | undefined = (data) => {
    return jp.query(data.payload, '$..output.key')[0];
  }

  static badgeColor = (status: string | undefined): "primary" | "secondary" | "error" | "info" | "success" | "warning" | "default" | undefined => {
    let colorClass: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "default" | undefined = 'info';
    if (status?.match(/succeeded/i)) colorClass = 'success';
    if (status?.match(/failed|timed_out|aborted/i)) colorClass = 'error';
    if (status?.match(/partial_success/i)) colorClass = 'warning';
    if (status?.match(/pending/i)) colorClass = 'info';
    return colorClass;
  }

  static renderStatusBadge = (data: any) => {
    let status = userImportHelper.calculatedStatus(data);
    return <Badge variant='inline' badgeContent={contentHelper.humanize(status)} color={userImportHelper.badgeColor(status)} sx={{ textTransform: 'uppercase' }} />;
  }
}