import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Link,
  Stack,
  Typography,
} from '@mui/material';

// import useCitations from './useCitations';
import DetailView from 'components/_layouts/DetailView';
import CitationManualItem from './CitationManualItem';
import useCitationManuals from './useCitationManuals';
import Loading from '../../Loading';

export default function CitationDetail() {
  const { id } = useParams();
  const citationsQuery = useCitationManuals({ term: id });

  return (
    <DetailView
      title={id || ''}
      breadcrumbItems={[
        <Link key='b1' href='../citations'>
          {'Citations'}
        </Link>,
        <Typography key='b2'>{id || ''}</Typography>
      ]}
    >
      <Stack spacing={3}>
        <Stack spacing={1}>
          {citationsQuery.data?.manuals && citationsQuery.data?.manuals.length > 0 ? (
            <>
              {citationsQuery.data?.manuals.map((manual, i1) => (
                <CitationManualItem
                  item={manual}
                  key={`${i1}_${manual.manualGuid}`}
                  initiallyCollapsed={citationsQuery.data?.manuals.length > 1}
                />
              ))}
            </>
          ) : (
            <>
              {citationsQuery.isFetching || citationsQuery.isLoading ? (
                <Loading />
              ) : (
                <Alert severity='warning'>
                  {'No results found'}
                </Alert>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </DetailView>
  );
}
