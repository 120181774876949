import { Box, Card, CardMedia } from "@mui/material";
import React from "react";
import contentHelper from "helpers/contentHelper";

type PolicyMediaProps = {
  mediaKey: string;
  alt?: string;
}

const PolicyMedia: React.FC<PolicyMediaProps> = ({ mediaKey, alt }: PolicyMediaProps) => {
  const [content, setContent] = React.useState<string>('');

  React.useEffect(() => {
    contentHelper.handleRetrieveMedia(mediaKey, setContent);
  }, [mediaKey]);

  return content && content.length > 0 ? (
    <Card
      sx={{
        mx: 'auto',
        display: 'inline-flex',
      }}
    >
      <CardMedia
        className='policy-image'
        component='img'
        sx={{
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
          mx: 'auto'
        }}
        src={content}
        title={alt || ''}
      />
    </Card>
  ) : (
    <Box />
  )
}

export default PolicyMedia