import React, { useCallback, useContext, useMemo } from "react";
import { QueryClient, UseInfiniteQueryResult, useQueryClient } from "react-query";
import * as Yup from 'yup';

import { AppContext } from "views/App/context";
import useUrlParams from "hooks/useUrlParams";
import useArchivedManuals from "data_sources/archivedManuals/useArchivedManuals";
import { ManualItem } from 'types/manual';
import { ApiCollectionResponse } from "types/api_response";

type ArchivedManualsProviderProps = {
  children: React.ReactNode;
};

type ArchivedManualsUrlParams = {
  q?: string | null;
};

export type ArchivedManualsProviderResponse = {
  clearParams: () => void;
  params: ArchivedManualsUrlParams;
  queryClient: QueryClient | null;
  updateParams: (updatedParams: ArchivedManualsUrlParams) => void;
  manuals: ManualItem[];
  manualsQuery: UseInfiniteQueryResult<ApiCollectionResponse<ManualItem>, Error>;
  manualSchema: any;
  orgId: string;
}

const defaultParams: ArchivedManualsUrlParams = {
  q: null,
};

export const ArchivedManualsContext = React.createContext<ArchivedManualsProviderResponse>({} as ArchivedManualsProviderResponse);

const ArchivedManualsProvider: ({ children }: ArchivedManualsProviderProps) => any = ({ children }) => {
  const queryClient = useQueryClient();
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const [params, setParams] = useUrlParams(defaultParams);
  const updateParams = useCallback((updatedParams: ArchivedManualsUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams = {
    };

    return newParams;
  }, []);

  const manualData = useArchivedManuals(orgId, queryParams);

  const manualSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required('Name is required'),
  });

  const manuals = useMemo(() => manualData.data && manualData.data.pages ?
    manualData.data.pages.flatMap((x) => x.content) : []
    , [manualData.data]);

  const contextValue: ArchivedManualsProviderResponse = useMemo(() => ({
    clearParams,
    params,
    queryClient,
    updateParams,
    manualsQuery: manualData,
    manuals: manuals,
    manualSchema,
    orgId,
  }), [clearParams, params, queryClient, updateParams, manualData, manuals, manualSchema, orgId]);

  return (
    <ArchivedManualsContext.Provider value={contextValue}>
      {children}
    </ArchivedManualsContext.Provider>
  )
};

export default ArchivedManualsProvider;