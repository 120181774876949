import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../../context';
import {
  Alert,
  Box,
  Typography,
} from '@mui/material';
import useTranslations from 'hooks/useTranslations';

export default function Dashboard() {
  const { currentProfile, hasNoPermission, setCurrentModule } = useContext(AppContext);
  const t = useTranslations();
  useEffect(() => {
    setCurrentModule('dashboard');
  }, [setCurrentModule]);
  return (
    <Box
      className='dashboard'
      sx={{ p: 6 }}
    >
      <Typography variant='h5'>{currentProfile?.organization?.name}</Typography>
      <Typography variant='h6'>{t('dashboard.welcome')} {currentProfile?.user?.firstName}</Typography>
      {hasNoPermission() && (
        <Alert
          severity='warning'
        >
          <Typography variant='body1'>{t('dashboard.noPermissions')}</Typography>
        </Alert>
      )}
      <Outlet />
    </Box>
  );
}
