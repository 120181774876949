import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { JobType } from './enums';
import { UserImportResult } from 'types/user_import';

export default function useOrganizationUserImportResults(
  organizationId: string,
  bucketName: string,
  key: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/phoenix/job-service/v1/org/${organizationId}/jobs/job-type/${JobType.ImportUsers.toString()}/bucket/${encodeURIComponent(bucketName)}/key/${encodeURIComponent(key)}`;
  return useQuery<UserImportResult, Error>(
    ['organization_user_import_results', organizationId, bucketName, key],
    async (): Promise<UserImportResult> => {
      const data: UserImportResult = await fetcher({
        method: 'get',
        url,
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: false,
    }
  );
}
