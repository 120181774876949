import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQueryClient } from 'react-query';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import useUsers from "data_sources/users/useOrganizationUsers";
import useManageRoleUser from 'data_sources/roles/useManageRoleUser';
import { User } from 'types/user';
import ListSection from 'components/_layouts/ListSection';

const columnHelper = createColumnHelper<User>();

export default function AddUser() {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const usersQuery = useUsers(currentOrganizationId || '');
  const [addedUsers, setAddedUsers] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const {
    addUser,
  } = useManageRoleUser();

  const columns = [
    columnHelper.accessor('lastName', {
      cell: (info) => (
        <Stack>
          <Link href={`/portal/lexid/users/${info.row.original.id}`} target="_blank" rel="noopener noreferrer">
            <Typography sx={{ fontWeight: 'bold' }}>{[
              info.row.original.firstName,
              info.row.original.middleName,
              info.row.original.lastName,
            ].join(' ')}</Typography>
          </Link>
          {info.row.original.email && (
            <Link href={`mailto:${info.row.original.email}`}>{info.row.original.email}</Link>
          )}
        </Stack>
      ),
      header: () => t('users.properties.name'),
      size: 9999,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => addedUsers.includes(info.row.original.id || '') ? null : (
        <Button
          size='small'
          variant='contained'
          onClick={() => addUser(currentOrganizationId || '', info.row.original.id || '', id || '', () => {
            setAddedUsers((prev) => ([
              ...prev,
              info.row.original.id || '',
            ]));
            queryClient.invalidateQueries(['role_users', currentOrganizationId, id])
          })}
        >
          {t('roles.actions.addUser')}
        </Button>
      ),
      header: () => '',
      size: 120,
    }),
  ];

  const users = React.useMemo(() => usersQuery.data && usersQuery.data.pages ?
    usersQuery.data.pages.flatMap((x) => x.content) : []
    , [usersQuery.data]);

  return (
    <ListSection
      columns={columns}
      data={users}
      query={usersQuery}
    >
    </ListSection>
  )
}
