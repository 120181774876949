import * as React from 'react';
import {
  Box,
  IconButton,
} from '@mui/material';
import {
  FormatAlignCenterOutlined,
  FormatAlignRightOutlined,
  FormatAlignLeftOutlined,
} from "@mui/icons-material";
import {
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
} from 'lexical';

type ElementFormatProps = {
  value: ElementFormatType;
  editor: LexicalEditor;
  disabled?: boolean;
};

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, 'justify' | 'start' | 'end' | ''>]: {
    icon: any;
    name: string;
  };
} = {
  left: {
    icon: <FormatAlignLeftOutlined />,
    name: 'Left Align',
  },
  center: {
    icon: <FormatAlignCenterOutlined />,
    name: 'Center Align',
  },
  right: {
    icon: <FormatAlignRightOutlined />,
    name: 'Right Align',
  }
};

export default function ElementFormat({
  editor,
  value,
  disabled = false,
}: ElementFormatProps) {

  return (
    <Box>
      {Object.entries(ELEMENT_FORMAT_OPTIONS).map(([k, v]) => (
        <IconButton
          key={k}
          disabled={disabled}
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, k.toString() as ElementFormatType);
          }}
          className='toolbar-item'
          color={value === k.toString() ? 'primary' : 'neutral'}
          title={v.name}
        >
          {v.icon}
        </IconButton>
      ))}
    </Box>
  );
}
