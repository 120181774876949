import React from 'react';
import { Box, CircularProgress } from "@mui/material";

const Loading: React.FC = () => (
  <Box sx={{
    display: 'flex',
    minHeight: '20vh',
    alignItems: 'center',
    justifyContent: 'center',
  }}
    data-testid='loading-indicator'>
    <CircularProgress />
  </Box>
)

export default Loading;