import { useInfiniteQuery } from 'react-query';

import { ApiCollectionResponse } from '../../types/api_response';
import { infiniteEmptyResults } from '../constants';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';

export type ManualGroup = {
  id: string;
  description?: string;
  name?: string;
  orgId?: string;
}

export default function useIssuedManualGroups<T extends ManualGroup>(
  manualId: string,
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexipub/manuals/issued/v1/manuals/${manualId}/permissions/groups`;

  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['issued_manual_groups', manualId, params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      getNextPageParam: (lastPage) => {
        if (lastPage.offset && (lastPage.offset < (lastPage.count || 0)) && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            offset: lastPage.offset,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

          return `${url}?${paramString}`;
        }
      }
    }
  );
}
