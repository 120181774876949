/* eslint-disable class-methods-use-this */
import contentHelper from 'helpers/contentHelper';
import parse from 'html-react-parser';
import {
  Chapter,
  Manual,
  Policy as ManualPolicy,
  Preface,
} from '../types/manual';
import { Policy, Section, SubSection } from '../types/policy';
export default class manualHelper {
  static loadStoredManual = async () => {
    let storedManualJson = localStorage.getItem('authoring-manual');
    if (!storedManualJson) {
      await fetch(
        'https://dj8ibq5dbhvjr.cloudfront.net/manuals/chambersburg.json'
      )
        .then((resp) => resp.json())
        .then((data: any) => {
          manualHelper.saveLocalManual(data);
        });
      storedManualJson = localStorage.getItem('authoring-manual');
    }
    if (storedManualJson) {
      try {
        return JSON.parse(storedManualJson) as Manual;
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  };

  static getManual = () => {
    let storedManualJson = localStorage.getItem('authoring-manual');
    if (storedManualJson) {
      try {
        return JSON.parse(storedManualJson) as Manual;
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  };

  static saveManual = (manual: Manual) => {
    localStorage.setItem('authoring-manual', JSON.stringify(manual));
  };

  static getPreface = (id: string) => {
    const manual = this.getManual();
    let preface: Preface | null = null;
    if (!!manual) {
      manual.prefaces.forEach((p) => {
        if (p.id === id) preface = p;
      });
    }
    return preface;
  };

  static savePreface = (id: string, data: Preface) => {
    const manual = this.getManual();
    if (!!manual) {
      manual.prefaces.forEach((p) => {
        if (p.id === id) {
          p.title = data.title;
          p.content = data.content;
          this.saveManual(manual);
        }
      });
    }
    return manual;
  };

  static getChapter = (id: string) => {
    const manual = this.getManual();
    let chapter: Chapter | null = null;
    if (!!manual) {
      manual.chapters.forEach((c) => {
        if (c.id === id) chapter = c;
      });
    }
    return chapter;
  };

  static saveChapter = (id: string, data: Chapter) => {
    const manual = this.getManual();
    if (!!manual) {
      manual.chapters.forEach((c) => {
        if (c.id === id) {
          c.title = data.title;
          this.saveManual(manual);
        }
      });
    }
    return manual;
  };

  static saveLocalManual = (data: any) => {
    const manual: Manual = {
      id: data.guid,
      title: contentHelper.decodeHtmlEntities(data.name.value) ?? '',
      vertical: data.vertical.key,
      type: data.manualType.value,
      prefaces: data.prefaces.map(
        (preface: any) =>
          ({
            id: preface.guid,
            title: preface.title.value,
            content: preface.content.value,
            excluded: false,
          }) as Preface
      ),
      chapters: data.chapters.map(
        (chapter: any) =>
          ({
            id: chapter.guid,
            title: contentHelper.decodeHtmlEntities(chapter.title.value),
            policies: chapter.policies.map(
              (policy: any) =>
                ({
                  id: policy.guid,
                  title: contentHelper.decodeHtmlEntities(policy.title.value),
                  issuedAt: policy.revisedDate,
                  excluded: false,
                }) as ManualPolicy
            ),
          }) as Chapter
      ),
    };
    localStorage.setItem('authoring-manual', JSON.stringify(manual));
    data.chapters.forEach((chapter: any) => {
      chapter.policies.forEach((policy: any) => {
        this.saveLocalPolicy(policy, chapter.guid, manual.id);
      });
    });
  };

  static getPolicy = (id: string) => {
    let storedPolicyJson = localStorage.getItem(`policy-${id}`);
    if (storedPolicyJson) {
      try {
        return JSON.parse(storedPolicyJson) as Policy;
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  };

  static savePolicy = (policy: Policy) => {
    localStorage.setItem(`policy-${policy.id}`, JSON.stringify(policy));
  };

  static saveLocalPolicy = (data: any, chapterId: string, manualId: string) => {
    const policy: Policy = {
      id: data.guid,
      title: contentHelper.decodeHtmlEntities(data.title.value) ?? '',
      excluded: false,
      issuedAt: '',
      manualId,
      chapterId,
      sections: data.sections.map(
        (section: any) =>
          ({
            id: section.guid,
            title: contentHelper.decodeHtmlEntities(section.title.value),
            content: section.content.value,
            subSections: section.subSections.map(
              (subSection: any) =>
                ({
                  id: subSection.guid,
                  title: contentHelper.decodeHtmlEntities(
                    subSection.title.value
                  ),
                  content: subSection.content.value,
                }) as SubSection
            ),
          }) as Section
      ),
    };
    localStorage.setItem(`policy-${policy.id}`, JSON.stringify(policy));
  };

  static getNumberManualNode = (node: any, manualParam?: Manual) => {
    let result = '';
    let manual = null;
    if (manualParam) manual = manualParam;
    else manual = this.getManual();
    if (manual) {
      manual.chapters.forEach((chapter, chapterIndex) => {
        if (chapter.id === node.id) {
          result = `${chapterIndex + 1}`;
        } else {
          chapter.policies.forEach((policy, policyIndex) => {
            if (policy.id === node.id) {
              result = `${(chapterIndex + 1) * 100 + policyIndex + 1}`;
            }
          });
        }
      });
    }
    return result;
  };

  static formatManualNodeTitle = (node: any, manual?: Manual) => {
    let result = parse(node?.title ?? '');
    const num = this.getNumberManualNode(node, manual);
    if (num?.length > 0) {
      result = `${num}: ${result}`;
    }
    return result;
  };

  static formatManualChapterTitle = (manual: Manual, chapter: any) => {
    let result = parse(chapter?.title ?? '');
    const num = manual.chapters.findIndex((c) => c.id === chapter.id);
    if (num >= 0) {
      result = `${num + 1}: ${result}`;
    }
    return result;
  };
}
