import { Manual } from "types/manual";

/**
 * Formats a Manual object into an HTML string.
 * @param manual - The Manual object to format.
 * @returns The formatted HTML string representing the manual.
 */
const manualFormatter = (manual: Manual): string => {
  const doc = document.createElement('div');

  // Create and append the manual name heading
  const h1 = document.createElement('h1');
  h1.textContent = manual?.name;
  doc.appendChild(h1);

  // Append prefaces if available
  if (manual.prefaces) {
    manual.prefaces.forEach((preface) => {
      const sec = document.createElement('section');
      sec.appendChild(appendContent('h2', formatTitle(preface)));
      sec.appendChild(appendContent('div', preface.content));
      doc.appendChild(sec);
    });
  }

  // Append chapters if available
  if (manual.chapters) {
    manual.chapters.forEach((chapter) => {
      const chap = document.createElement('div');
      chap.appendChild(appendContent('h3', formatTitle(chapter)));

      // Append policies if available
      (chapter.policies || []).forEach((policy) => {
        const pol = document.createElement('article');
        pol.appendChild(appendContent('h4', formatTitle(policy)));

        // Append sections if available
        policy.sections.forEach((section) => {
          const sec = document.createElement('section');
          sec.appendChild(appendContent('h5', formatTitle(section)));
          sec.appendChild(appendContent('div', section.content));

          // Append subSections if available
          section.subSections.forEach((subSection) => {
            sec.appendChild(appendContent('h6', formatTitle(subSection)));
            sec.appendChild(appendContent('div', subSection.content));
          });

          pol.appendChild(sec);
        });

        chap.appendChild(pol);
      });

      doc.appendChild(chap);
    });
  }

  return doc.innerHTML;
}

/**
 * Appends a new element with the specified tag name and content to the parent element.
 * @param tagName - The tag name of the element to append.
 * @param content - The content of the element.
 * @returns The newly created element.
 */
const appendContent = (tagName: string, content: string): HTMLElement => {
  const element = document.createElement(tagName);
  element.innerHTML = content;
  return element;
}

/**
 * Formats the title of a section.
 * @param section - The section object.
 * @returns The formatted title.
 */
const formatTitle = (section: { title: string }): string => {
  return section.title;
}
export default manualFormatter;