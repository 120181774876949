import * as React from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  LexicalEditor,
} from 'lexical';





export type InsertItem = {
  key: string;
  label: string;
  onClick: () => void;
};

type InsertMenuProps = {
  editor: LexicalEditor;
  disabled?: boolean;
  items: InsertItem[];
};


export default function InsertMenu({
  editor,
  disabled = false,
  items = [],
}: InsertMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id="insert_menu_button"
        aria-controls={open ? 'insert_menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        size='small'
        color='neutral'
        disabled={disabled}
      >
        Insert
      </Button>
      <Menu
        id="insert_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

      >
        {items.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
