import React, { useContext, useEffect, useState } from 'react';
import numeral from 'numeral';
import { useConfirm } from 'material-ui-confirm';
import parse from 'html-react-parser';

import { AppContext } from '../../../context';
import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import PropertyList from 'components/_layouts/PropertyList';
import datetimeHelper from 'helpers/datetimeHelper';
import { Button, Link } from '@mui/material';
import { ActionMenu } from 'components/_atoms';
import useOrganization from 'data_sources/organizations/useOrganization';
import useManageOrganization from 'data_sources/organizations/useManageOrganization';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../Loading';

export default function Settings() {
  const { currentOrganizationId, hasPermission, setCurrentModule } = useContext(AppContext);
  const t = useTranslations();
  const confirm = useConfirm();
  const [processingEnforceAuth, setProcessingEnforceAuth] = useState<boolean>(false);
  const { enforceAuth } = useManageOrganization();
  const organizationQuery = useOrganization(currentOrganizationId);

  const handleEnforce = (authenticationType: string) => {
    confirm({
      description: t(`organizations.actions.auth.confirm.${authenticationType}`)
    }).then(() => {
      setProcessingEnforceAuth(true);
      enforceAuth(currentOrganizationId, authenticationType, (jobId) => {
        enqueueSnackbar(parse(t(`organizations.actions.auth.confirmation.${authenticationType}`)),
          {
            variant: 'success',
            autoHideDuration: 6000
          });
        setProcessingEnforceAuth(false);
      })
    }).catch(() => {
      // don't do it
    })
  }

  const actions = [];
  if (currentOrganizationId !== 'LexipolGenesisRoot') {
    if (currentOrganizationId && hasPermission('updateOrg', currentOrganizationId)) {
      actions.push(
        (
          <Button
            key='edit_org'
            variant="contained"
            href='./edit'
          >
            {t('organizations.actions.edit')}
          </Button>
        )
      );
    }
    if (currentOrganizationId && (hasPermission('updateOrgSSO', currentOrganizationId) || hasPermission('updateAllUsersAuthFlow', currentOrganizationId))) {
      actions.push(
        (
          <ActionMenu
            key="auth_actions"
            id="auth_actions"
            btnText={t('organizations.actions.auth.button')}
            items={[
              ...(hasPermission('updateOrgSSO', currentOrganizationId) ? [
                {
                  key: 'update_auth',
                  label: t('organizations.actions.auth.update'),
                  href: './auth'
                },
              ] : []),
              ...(hasPermission('updateAllUsersAuthFlow', currentOrganizationId) ? [
                {
                  key: 'enforce_sso',
                  label: t('organizations.actions.auth.enforceSSO'),
                  onClick: () => handleEnforce('SSO'),
                },
                {
                  key: 'enforce_password',
                  label: t('organizations.actions.auth.enforcePassword'),
                  onClick: () => handleEnforce('PASSWORD'),
                },
              ] : []),
            ]}
          />
        )
      );
    }
  }

  useEffect(() => {
    setCurrentModule('lexid');
  }, [setCurrentModule]);

  return (
    <DetailView
      title={t('organizations.settings.title')}
      toolbarRight={actions}
      query={organizationQuery}
    >
      {processingEnforceAuth && (
        <Loading />
      )}
      <PropertyList
        columns={3}
        items={organizationQuery.data ? [
          {
            label: t('organizations.properties.salesforceId'),
            value: organizationQuery.data?.organization?.salesforceId,
          },
          {
            label: t('organizations.properties.userCount'),
            value: numeral(organizationQuery.data?.organization?.userCount).format('0,0'),
          },
          {
            label: t('organizations.properties.roleCount'),
            value: numeral(organizationQuery.data?.organization?.roleCount).format('0,0'),
          },
          {
            label: t('organizations.properties.groupCount'),
            value: numeral(organizationQuery.data?.organization?.groupCount).format('0,0'),
          },
          {
            label: t('organizations.properties.authenticationType'),
            value: t(`organizations.authenticationTypes.${organizationQuery.data?.cognitoPool?.authenticationType}`),
          },
          {
            label: t('organizations.properties.identifier'),
            value: organizationQuery.data?.cognitoPool?.urn,
            copyable: true,
            copyValue: organizationQuery.data?.cognitoPool?.urn,
          },
          {
            label: t('organizations.properties.replyUrl'),
            value: organizationQuery.data?.cognitoPool?.replyUrl ? (
              <Link
                href={organizationQuery.data?.cognitoPool?.replyUrl}
                target="_blank"
              >
                {organizationQuery.data?.cognitoPool?.replyUrl}
              </Link>
            ) : null,
            copyable: true,
            copyValue: organizationQuery.data?.cognitoPool?.replyUrl,
          },
          {
            label: t('organizations.properties.country'),
            value: organizationQuery.data?.organization?.country,
          },
          {
            label: t('organizations.properties.state'),
            value: organizationQuery.data?.organization?.state,
          },
          {
            label: t('organizations.properties.city'),
            value: organizationQuery.data?.organization?.city,
          },
          {
            label: t('organizations.properties.createdAt'),
            value: datetimeHelper.formatDate(organizationQuery.data?.organization?.createdAt),
          },
          {
            label: t('organizations.properties.lastUpdate'),
            value: datetimeHelper.formatDate(organizationQuery.data?.organization?.lastUpdate),
          },
        ] : []}
      />
    </DetailView>
  );
}
