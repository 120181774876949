import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, Stack, Toolbar } from '@mui/material';
import { Section, SubSection } from '../types/policy';
import ContentEditor from '../ContentEditor';
import { TitleTextField } from '../styles';
import { ActionMenu } from 'components/_atoms';
type SectionEditorProps = {
  section: Section | SubSection;
  sectionNumber: string;
  onChange: (section: Section | SubSection) => void;
  isEditable?: boolean;
  isDragging?: boolean;
  hideContent?: boolean;
};
const SectionEditor: React.FC<SectionEditorProps> = ({
  section,
  sectionNumber,
  onChange,
  isEditable = false,
  isDragging = false,
  hideContent = false,
}: SectionEditorProps) => {
  const [sectionData, setSectionData] = useState<Section | SubSection>(section);
  const updateSectionData = (updatedData: any) => {
    setSectionData((prev) => ({ ...prev, ...updatedData }));
  };
  useEffect(() => {
    if (sectionData) onChange(sectionData);
  }, [onChange, sectionData]);

  return sectionData ? (
    <Box>
      <Box>
        <Toolbar
          className="phx-toolbar"
          sx={{ gap: 4, borderRadius: '4px !important' }}
        >
          <Stack direction="row" spacing={4} sx={{ flexGrow: 1 }}>
            <TitleTextField
              id={`title_${sectionData.id}`}
              key={`title_${sectionData.id}`}
              label={false}
              variant="outlined"
              fullWidth
              value={sectionData?.title}
              onChange={({ target }) =>
                updateSectionData({ title: target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: 20,
                        fontWeight: 600,
                      },
                    }}
                  >
                    {sectionNumber}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                readOnly: !isEditable,
              }}
            />
          </Stack>
          {!hideContent && (
            <Stack direction="row" spacing={4}>
              <ActionMenu id={`action_menu_${section.id}`} items={[]} />
            </Stack>
          )}
        </Toolbar>
      </Box>
      {!hideContent && (
        <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
          <Box sx={{ minHeight: 0 }}>
            <Stack spacing={3}>
              <ContentEditor
                id={`content_${sectionData.id}`}
                key={`content_${sectionData.id}_${isEditable}`}
                contentHTML={sectionData.content}
                onSave={(content) => updateSectionData({ content: content })}
                isEditable={isEditable}
              />
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  ) : null;
};
export default SectionEditor;
