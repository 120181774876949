import { useQuery } from 'react-query';
import {
  baseApiUrl,
  fetcher,
  getToken,
  ServerErrorResponse,
} from './constants';
import { Permission } from 'types/permissions_response';

const url = `${baseApiUrl()}/lexid/admin-user-auth/v1/application`;

export const getApplicationPermissions = async (
  onError?: (errors: ServerErrorResponse) => void
) => {
  const idToken = getToken();
  if (idToken) {
    const respData = await fetcher({
      method: 'get',
      url: `${url}`,
    }).catch((err) => {
      console.log('error retrieving app permssions');
    });
    const perms: Permission[] = respData?.authorizations ?? [];
    return perms;
  } else {
    return [];
  }
};

export default function usePermissions(
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const idToken = getToken();
  return useQuery<Permission[], Error>(
    ['permissions', idToken],
    async () => getApplicationPermissions(),
    {
      ...options,
      keepPreviousData: false,
    }
  );
}
