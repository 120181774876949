import React, { useCallback, useState } from 'react';
// import * as Diff from 'diff';
import HtmlDiff from 'htmldiff-js';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  Stack,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import stagedManualA from './staged_manual.json';
import stagedManualB from './staged_manual2.json';
import manualFormatter from './formatter';


export default function ManualDiffs() {
  const [manualA, setManualA] = useState(JSON.stringify(stagedManualA, null, 2));
  const [manualB, setManualB] = useState(JSON.stringify(stagedManualB, null, 2));
  const [results, setResults] = useState('');

  const handleAnalyze = useCallback(() => {
    const manualAJson = JSON.parse(manualA && manualA.length > 2 ? manualA : '{}');
    const manualBJson = JSON.parse(manualB && manualB.length > 2 ? manualB : '{}');
    // console.log(manualFormatter(manualAJson));
    const docA = manualFormatter(manualAJson);
    const docB = manualFormatter(manualBJson);
    /*const diff = Diff.diffWords(docA, docB);
    console.log('diff', diff)
    let strContent = '';
    for (var i = 0; i < diff.length; i++) {

      if (diff[i].added && diff[i + 1] && diff[i + 1].removed) {
        var swap = diff[i];
        diff[i] = diff[i + 1];
        diff[i + 1] = swap;
      }
      let strNode = '';
      if (diff[i].removed) {
        strNode = `<del>${diff[i].value}</del>`;
      } else if (diff[i].added) {
        strNode = `<ins>${diff[i].value}</ins>`;
      } else {
        strNode = diff[i].value;
      }
      strContent += strNode;
    }
    setResults(strContent); */
    const diffHtml = HtmlDiff.execute(docA, docB);
    setResults(diffHtml);
  }, [manualA, manualB]);

  React.useEffect(() => {
    const style = document.createElement('style');
    style.innerText = `del {
      text-decoration: none;
      color: #b30000;
      background: #fadad7;
    }
    ins {
      background: #eaf2c2;
      color: #406619;
      text-decoration: none;
    }`;
    document.head.appendChild(style);
    handleAnalyze();
  }, [handleAnalyze]);
  // console.log('!!', results)

  return (
    <Box sx={{ p: 5 }}>
      <Card>
        <CardHeader
          title='Manual Diff Display'
          sx={{
            backgroundColor: colors.grey[100],
            borderBottom: `solid 1px ${colors.grey[200]}`,
            p: 6,
          }}
        />
        <CardContent>
          <Grid container spacing={5}>
            <Grid md={6}>
              <Stack spacing={3}>
                <TextField
                  id='manual_a'
                  key='manual_a'
                  label='Manual A'
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={15}
                  maxRows={15}
                  value={manualA}
                  inputProps={{ style: { fontFamily: 'courier', fontWeight: 'bold', fontSize: 14 } }}
                  onChange={({ target }) => setManualA(target.value)}
                />
                <TextField
                  id='manual_b'
                  key='manual_b'
                  label='Manual B'
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={15}
                  maxRows={15}
                  value={manualB}
                  inputProps={{ style: { fontFamily: 'courier', fontWeight: 'bold', fontSize: 14 } }}
                  onChange={({ target }) => setManualB(target.value)}
                />
              </Stack>
            </Grid>
            <Grid md={6}>
              <Stack spacing={1}>
                <Card variant='outlined' sx={{ p: 2, maxHeight: '75vh', overflow: 'auto' }}>
                  <div dangerouslySetInnerHTML={{ __html: results }} />
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            component='button'
            onClick={handleAnalyze}
            variant='contained'
            sx={{
              width: '100%'
            }}
          >
            Analyze
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
