import React from 'react';
import {
  Badge,
  Card,
  CardHeader,
  colors,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import {
  Citation,
} from './citations';

export type CitationItemProps = {
  item: Citation;
}

const CitationItem = React.memo(({
  item,
}: CitationItemProps) => {
  return (
    <Link href={`./${item.key}`}>
      <Card sx={{ borderColor: colors.blue[100] }}>
        <CardHeader
          title={(
            <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='h6'
                sx={{
                  cursor: 'pointer',
                  p: 1,
                  '&:hover': {
                    background: colors.blue[50],
                    borderRadius: 1,
                  }
                }}
              >
                {item.key}
              </Typography>
              <Badge variant='inline' badgeContent={item.citation} color='neutral' />
            </Stack>
          )}
        />
      </Card>
    </Link>
  );
});

export default CitationItem;
