import { useQuery } from 'react-query';
import { ApiCollectionResponse } from 'types/api_response';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';
import { Persona } from 'types/permissions_response';

export default function usePersonas<T extends Persona>(
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-resource-authorization/v1/resource-personas`;
  return useQuery<ApiCollectionResponse<T>, Error>(
    ['personas', params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        url: pageParam || `${url}?${paramString}`,
        options: { signal }
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}
