import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { Job } from 'types/job';
import { JobType } from './enums';

export default function useOrganizationUserImport(
  organizationId: string,
  id: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/phoenix/job-service/v1/organizations/${organizationId}/jobs/job-type/${JobType.ImportUsers.toString()}/jobs/${id}`;
  return useQuery<Job, Error>(
    ['organization_user_import', organizationId, id],
    async () => {
      if (organizationId && id) {
        const data = await fetcher({
          method: 'get',
          url,
        });
        return data;
      }
    },
    {
      ...options,
      keepPreviousData: false,
    }
  );
}
