import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SortableTree from '../../helpers/sortableTree';
import { TreeItems, Section } from '../../types/policy';

type PolicyStructureModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sections: Section[];
  isEditable: boolean;
  policyData: any;
  updatePolicyData: (updatedData: any) => void;
};

const PolicyStructureModal: React.FC<PolicyStructureModalProps> = ({
  isOpen,
  onClose,
  sections,
  isEditable,
  updatePolicyData,
  policyData,
}) => {
  const transformSectionsToTreeItems = (sections: Section[]): TreeItems => {
    return sections.map((section, sectionIndex) => ({
      ...section,
      depth: 0,
      parentId: null,
      isSubSection: false,
      collapsed: false,
      sectionIndex: `${sectionIndex + 1}`,
      children: section.subSections
        ? section.subSections.map((sub, subIndex) => ({
            ...sub,
            depth: 1,
            parentId: section.id,
            isSubSection: true,
            collapsed: false,
            sectionIndex: `${sectionIndex + 1}.${subIndex + 1}`,
            children: [],
          }))
        : [],
    }));
  };

  const [treeItems, setTreeItems] = useState<TreeItems>(
    transformSectionsToTreeItems(sections)
  );

  const transformTreeItemsToSections = (tree: TreeItems): Section[] => {
    return tree.map((node) => ({
      id: node.id,
      title: node.title,
      content: node.content,
      sectionIndex: node.sectionIndex,
      subSections:
        node.children?.map((child) => ({
          id: child.id,
          title: child.title,
          content: child.content,
          sectionIndex: child.sectionIndex,
        })) || [],
    }));
  };

  const handleTreeChange = (updatedTree: TreeItems) => {
    const updatedSections = transformTreeItemsToSections(updatedTree);
    updatePolicyData({ sections: updatedSections });
    setTreeItems(transformSectionsToTreeItems(updatedSections));
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: 4,
          width: '90%',
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 2,
            borderBottom: '1px solid #ddd',
            mb: 4,
          }}
        >
          <Typography variant="h6">Manual Structure</Typography>
          <IconButton onClick={onClose} aria-label="close modal">
            <CloseIcon />
          </IconButton>
        </Box>

        <SortableTree
          items={treeItems}
          onChange={handleTreeChange}
          indentationWidth={30}
          policyData={policyData}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PolicyStructureModal;
