import styled from '@emotion/styled';
import { Box, colors } from '@mui/material';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';

export const MuiContentEditable = styled(ContentEditable)({
  minHeight: 200,
  width: '100%',
  padding: '0 8px',
  borderRadius: 5,
  paddingTop: 2,
  paddingLeft: 10,
  position: 'relative',
  outline: 'none',
  '&[contenteditable=false]': {
    minHeight: 0,
  },
  '&[contenteditable=false] .text-entity': {
    fontWeight: 600,
    '&:before': {
      content: '"["',
    },
    '&:after': {
      content: '"]"',
    },
  },
  '&[contenteditable=true] .text-entity': {
    padding: '2px 4px',
    backgroundColor: colors.blue[50],
    borderRadius: 8,
    display: 'inline-flex',
    '&:before': {
      content: '"["',
    },
    '&:after': {
      content: '"]"',
    },
  },
});

export const EditorPlaceholder = styled(Box)({
  position: 'absolute',
  top: 15,
  left: 10,
  userSelect: 'none',
  display: 'inline-block',
  pointerEvents: 'none',
});
