import React, { useContext } from 'react';
import numeral from 'numeral';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Badge,
  Box,
  Button,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { UserImportsContext } from '../context';
// import { ActionMenu } from 'components/_atoms';
import ListView from 'components/_layouts/ListView';
import datetimeHelper from 'helpers/datetimeHelper';
import { Job } from 'types/job';
import userImportHelper from '../userImportHelper';

const columnHelper = createColumnHelper<Job>();



const UsersList: React.FC = () => {
  const t = useTranslations();
  const { userImports, userImportsQuery } = useContext(UserImportsContext);

  const renderCountBadges = (data: any) => {
    const badges: React.ReactNode[] = [];
    const counts = userImportHelper.userImportCounts(data);
    Object.entries(counts).forEach(([k, v]) => {
      badges.push(<Badge key={`count_badge_${k}`} variant='inline' badgeContent={`${t(`userImports.counts.${k}`)}: ${numeral(v).format('0,0')}`} color={k === 'FAIL' ? 'error' : 'info'} />);
    });
    return badges;
  }

  const columns = [
    columnHelper.accessor('jobDescr', {
      cell: (info) => (
        <Stack>
          <Stack direction='row' spacing={4}>
            <Link href={`./${info.row.original.jobId}`}>
              <Typography variant='h6' sx={{ fontWeight: 'semibold', display: 'inline-flex' }}>{info.row.original.jobDescr}</Typography>
            </Link>
          </Stack>
          <Box sx={{ display: 'inline-flex' }}>
            <Tooltip title={datetimeHelper.formatDateTime(info.row.original.createdAt)}>
              <Typography>{
                datetimeHelper.formatDate(info.row.original.createdAt)
              }</Typography>
            </Tooltip>
          </Box>
        </Stack>
      ),
      header: () => t('users.properties.name'),
      size: 9999,
    }),
    columnHelper.display({
      cell: (info) => (
        <Stack direction='row' spacing={2}>
          {renderCountBadges(info.row.original)}
        </Stack>
      ),
      header: t('userImports.properties.userCount'),
      size: 9999,
    }),
    columnHelper.accessor('status', {
      cell: (info) => (
        userImportHelper.renderStatusBadge(info.row.original)
      ),
      header: () => t('userImports.properties.status'),
      size: 200,
    }),
    /* columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <ActionMenu
          id={`action_menu_${info.row.original.id}`}
          items={[
            {
              key: `edit_${info.row.original.id}`, label: t('common.edit'), href: `./edit/${info.row.original.id}`
            },
          ]
          }
        />
      ),
      header: () => '',
      size: 60,
    }), */
  ];

  const actions = (
    (
      <Stack direction='row' spacing={4}>
        <Button
          variant="contained"
          href='./new'
        >
          {t('userImports.actions.create')}
        </Button>
      </Stack>
    )
  );

  return (
    <ListView
      columns={columns}
      data={userImports}
      query={userImportsQuery}
      title={t('userImports.title')}
      toolbarRight={actions}>
    </ListView>
  );
}

export default UsersList;
