import React, { useCallback, useMemo, useContext } from "react";
import { QueryClient, UseInfiniteQueryResult, useQueryClient } from "react-query";
import * as Yup from 'yup';
import { useConfirm } from 'material-ui-confirm';

import { AppContext } from "views/App/context";
import useUrlParams from "hooks/useUrlParams";
import useTranslations from "hooks/useTranslations";
import useGroups from "data_sources/groups/useGroups";
import useManageGroup from "data_sources/groups/useManageGroup";
import type { Group } from "types/group";
import { ApiCollectionResponse } from "types/api_response";
import { ServerErrorResponse } from "data_sources/constants";

type GroupsProviderProps = {
  children: React.ReactNode;
};

type GroupsUrlParams = {
  search?: string | null;
  sort?: string | null;
};

export type GroupsProviderResponse = {
  clearParams: () => void;
  createGroup: (
    data: Group,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => void;
  deleteGroup: (
    guid: string,
    onSuccess?: () => void,
    onError?: (errors: ServerErrorResponse) => void,) => void;
  params: GroupsUrlParams;
  queryClient: QueryClient | null;
  updateParams: (updatedParams: GroupsUrlParams) => void;
  updateGroup: (
    guid: string,
    data: Group,
    onSuccess?: (data: Group) => void,
    onError?: (errors: ServerErrorResponse) => void,) => void;
  groups: Group[];
  groupsQuery: UseInfiniteQueryResult<ApiCollectionResponse<Group>, Error>;
  groupSchema: any,
}

export const SORT_OPTIONS = [
  {
    key: 'name',
    label: 'Name',
    value: 'name_asc'
  },
  {
    key: 'created_at',
    label: 'Recently Created',
    value: 'createdAt_desc'
  },
  {
    key: 'updated_at',
    label: 'Recently Updated',
    value: 'updatedAt_desc'
  },
]

const defaultParams: GroupsUrlParams = {
  search: null,
  sort: null,
};

export const GroupsContext = React.createContext<GroupsProviderResponse>({} as GroupsProviderResponse);

const GroupsProvider: ({ children }: GroupsProviderProps) => any = ({ children }) => {
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const queryClient = useQueryClient();
  const t = useTranslations();
  const confirm = useConfirm();
  const [params, setParams] = useUrlParams(defaultParams);
  const updateParams = useCallback((updatedParams: GroupsUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams: any = {
      ...params
    };
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null || newParams[key] === '') {
        delete newParams[key];
      }
    });
    if (newParams.sort) {
      const opt = SORT_OPTIONS.find((x) => x.key === newParams.sort);
      if (opt) newParams.sort = opt.value;
    }

    return newParams;
  }, [params]);

  // const isFiltered = params !== defaultParams;

  const groupData = useGroups(orgId, queryParams);
  const {
    create: createGroup,
    update: updateGroup,
    destroy: deleteGroup,

  } = useManageGroup();

  const groupSchema = Yup.object().shape({
    name: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('groups.properties.name')} is required`),
  });

  const groups = useMemo(() => groupData.data && groupData.data.pages ?
    groupData.data.pages.flatMap((x: any) => x.content) : []
    , [groupData.data]);

  const handleCreateGroup = useCallback((
    data: Group,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    createGroup(
      orgId,
      data,
      (guid: string) => {
        queryClient.resetQueries(['groups']);
        if (onSuccess) onSuccess(guid);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [createGroup, orgId, queryClient]);

  const handleUpdateGroup = useCallback((
    id: string,
    data: Group,
    onSuccess?: (updatedData: Group) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    updateGroup(
      orgId,
      id,
      data,
      (updatedData: Group) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group', updatedData.orgId, updatedData.id]);
        if (onSuccess) onSuccess(updatedData);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [orgId, queryClient, updateGroup])

  const handleDeleteGroup = useCallback((
    id: string,
    onSuccess?: () => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    confirm({
      description: t('groups.delete.confirm')
    }).then(() => {
      deleteGroup(currentOrganizationId, id,
        () => {
          queryClient.invalidateQueries(['groups']);
          if (onSuccess) onSuccess();
        },
        (errors: ServerErrorResponse) => {
          if (onError) onError(errors);
        },
      )
    }).catch(() => {
      // don't do it
    })
  }, [confirm, currentOrganizationId, deleteGroup, queryClient, t])

  const contextValue: GroupsProviderResponse = useMemo(() => ({
    clearParams,
    createGroup: handleCreateGroup,
    deleteGroup: handleDeleteGroup,
    params,
    queryClient,
    updateParams,
    updateGroup: handleUpdateGroup,
    groupsQuery: groupData,
    groups: groups,
    groupSchema,
  }), [clearParams, handleCreateGroup, handleDeleteGroup, params, queryClient, updateParams, handleUpdateGroup, groupData, groups, groupSchema]);

  return (
    <GroupsContext.Provider value={contextValue}>
      {children}
    </GroupsContext.Provider>
  )
};

export default GroupsProvider;