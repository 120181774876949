import React, { useContext } from 'react';
import numeral from 'numeral';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Link, Stack, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { GroupsContext } from '../context';
import { AppContext } from 'views/App/context';
import { ActionMenu, QuickSearch } from 'components/_atoms';
import ListView from 'components/_layouts/ListView';
import { Group } from 'types/group';

const columnHelper = createColumnHelper<Group>();

const GroupsList: React.FC = () => {
  const t = useTranslations();
  const { currentOrganizationId, hasPermission } = useContext(AppContext);
  const { groups, groupsQuery, params, updateParams } =
    useContext(GroupsContext);

  const columns = [
    columnHelper.accessor('organizationGroupId', {
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: t('roles.properties.organizationGroupId'),
      size: 2,
    }),
    columnHelper.accessor('name', {
      cell: (info) => (
        <Stack>
          <Link href={`./${info.row.original.id}`}>
            <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>
              {info.row.original.name}
            </Typography>
          </Link>
          {info.row.original.description && (
            <Typography variant='body2'>
              {info.row.original.description}
            </Typography>
          )}
        </Stack>
      ),
      header: () => t('groups.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('totalUserCount', {
      cell: (info) => (
        <Typography>
          {(info.getValue() || 0) >= 0
            ? numeral(info.getValue()).format('0,0')
            : '--'}
        </Typography>
      ),
      header: t('groups.properties.userCount'),
      size: 2,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <ActionMenu
          id={`action_menu_${info.row.original.id}`}
          items={[
            {
              key: `edit_${info.row.original.id}`,
              label: t('common.edit'),
              href: `./edit/${info.row.original.id}`,
            },
          ]}
        />
      ),
      header: () => '',
      size: 60,
    }),
  ];

  const actions = (
    <Stack direction='row' spacing={4}>
      {currentOrganizationId &&
      hasPermission('createGroup', currentOrganizationId) ? (
        <Button variant='contained' href='./new'>
          {t('groups.actions.create')}
        </Button>
      ) : null}
    </Stack>
  );

  return (
    <ListView
      columns={columns}
      data={groups}
      query={groupsQuery}
      title={t('groups.title')}
      toolbarLeft={' '}
      toolbarRight={actions}
    ></ListView>
  );
};

export default GroupsList;
