import { User } from '../../types/user';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageOrganizationUser() {
  const create = async (
    organizationId: string,
    data: User,
    callback?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users`;
    // console.log('manageUser', onError)
    await fetcher({
      method: 'post',
      url,
      body: data,
    }).then((data) => {
      if (callback) callback(data.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const update = async (organizationId: string, id: string, data: User, callback?: (data: User) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users/${id}`;
    await fetcher({
      method: 'put',
      url,
      body: data,
    }).then((data) => {
      if (callback) callback(data);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    create,
    update,
  }
}