import { Card, CardContent, Stack, Typography, colors } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

export type FileInfo = {
  data: string;
  name: string;
  size: number;
  type: string;
}

export type FileUploaderProps = {
  onChange: (file: FileInfo) => void;
  onError?: (errors: string[]) => void;
  uploaderOptions?: DropzoneOptions;
  placeholder?: string;
};

const FileUploader: React.FC<FileUploaderProps> = ({
  onChange,
  onError = () => { },
  uploaderOptions = {},
  placeholder = "Drag 'n' drop some files here, or click to select files"
}) => {
  const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) onError([]);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        if (reader.result) {
          try {
            const parts = reader.result.toString().split(',');
            const typeStr = parts[0].split(';')[0].split(':')[1];
            // console.log('file loaded', file.name, reader.result.toString());
            const info: FileInfo = {
              data: parts[1],
              name: file.name,
              size: parts[1].length,
              type: typeStr,
            }
            setFileInfo(info);
            // console.log('file loaded', file.name, fileBase64);
            onChange(info);
          } catch (err) {
            console.log('file reading has failed')
          }
        }
      }
      reader.readAsDataURL(file);
    });
  }, [onChange, onError]);

  const { getRootProps, getInputProps } = useDropzone({
    ...uploaderOptions,
    onDrop,
    onDropRejected(fileRejections, event) {
      if (fileRejections && fileRejections.length > 0) {
        console.log('fileRejections', fileRejections)
        onError(fileRejections.flatMap((file) => file.errors.map((err) => err.code)));
      }
    },
  });

  return (
    <Stack spacing={2}>
      <Card
        sx={{
          background: colors.blueGrey[50],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 80,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} data-testid="file-input"/>
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>{placeholder}</Typography>
          {fileInfo && (
            <Card>
              <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                <Typography sx={{ fontFamily: 'courier', textAlign: 'center' }}>{fileInfo?.name}</Typography>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Card>
    </Stack>
  )
}

export default FileUploader;
