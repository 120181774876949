import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import numeral from 'numeral';
import {
  Stack,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import PropertyList from 'components/_layouts/PropertyList';
import useGroup from "data_sources/groups/useGroup";
import datetimeHelper from 'helpers/datetimeHelper';

export default function Overview() {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const groupQuery = useGroup(currentOrganizationId || '', id || '');

  return (
    <Stack spacing={12}>
      <PropertyList
        columns={3}
        items={groupQuery.data && groupQuery.data.name ? [
          {
            label: t('groups.properties.name'),
            value: groupQuery.data?.name,
          },
          {
            label: t('groups.properties.description'),
            value: groupQuery.data?.description,
          },
          {
            label: t('groups.properties.userCount'),
            value: numeral(groupQuery?.data.totalUserCount).format('0,0'),
          },
          {
            label: t('groups.properties.roleCount'),
            value: numeral(groupQuery?.data.roleCount).format('0,0'),
          },
          {
            label: t('groups.properties.createdAt'),
            value: datetimeHelper.formatDate(groupQuery.data?.createdAt),
          },
          {
            label: t('groups.properties.updatedAt'),
            value: datetimeHelper.formatDate(groupQuery.data?.updatedAt),
          },
        ] : []}
      />
    </Stack>
  );
}
