import React from 'react';

import DraftManualDetailProvider from './context';
import DraftManualsDetailPage from './Page';

const DraftManualsDetail: React.FC = () => {
  return (
    <DraftManualDetailProvider>
      <DraftManualsDetailPage />
    </DraftManualDetailProvider>
  );
};

export default DraftManualsDetail;
