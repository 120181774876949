import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { CognitoPool, Organization } from 'types/organization';


export const getOrganization = async (
  id: string,
  signal?: AbortSignal | undefined,
  onError?: (errors: ServerErrorResponse) => void,
) => {
  const url = `${baseApiUrl()}/lexid/admin-org/v1/organizations/${id}`;
  // console.log('get org', id)
  if (id === 'LexipolGenesisRoot') {
    const rootOrg: Organization = {
      organization: {
        id: 'LexipolGenesisRoot',
        name: 'Lexipol (Root)',
        groupCount: 1,
        roleCount: 1,
        userCount: 1,
        status: 'active',
      },
      cognitoPool: {} as CognitoPool,
    }
    return rootOrg;
  }
  const data = await fetcher({
    method: 'get',
    url,
    options: { signal },
  });
  return data;
}

export default function useOrganization(
  id: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  return useQuery<Organization, Error>(
    ['organization', id],
    async ({ signal }) => {
      if (id) {
        return getOrganization(id, signal, onError);
      }
    },
    {
      ...options,
      keepPreviousData: false,
      placeholderData: {} as Organization,
    }
  );
}
