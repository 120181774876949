import { useQuery } from 'react-query';

import { CitationManualResponse } from './citations';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from 'data_sources/constants';

export default function useCitationManuals(
  params: any = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexiverse/v1/citations/manuals`;

  return useQuery<CitationManualResponse, Error>(
    ['citation_manuals', params],
    async ({ signal }) => {
      if (!params.term || params.term.toString().length < 3) {
        return {} as CitationManualResponse;
      }
      const paramString = new URLSearchParams(Object.entries({
        pageSize: 25,
        pageNumber: 1,
        key: params.term ?? ''
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: CitationManualResponse = await fetcher({
        method: 'get',
        url: `${url}?${paramString}`,
        options: { signal },
      })
        .catch((err) => {
          handleError(err, { onError });
        });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}