import { useInfiniteQuery } from 'react-query';

import { ArchivedManualItem } from '../../types/manual';
import { ApiCollectionResponse } from '../../types/api_response';
import { infiniteEmptyResults } from '../constants';
import { baseApiUrl, defaultHeaders, ServerErrorResponse } from '../constants';
import contentHelper from 'helpers/contentHelper';

export default function useArchivedManuals<T extends ArchivedManualItem>(
  organizationId: string,
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexipub/manuals/archived/v1/manuals`;

  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['archived_manuals', organizationId, params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

      const data: ApiCollectionResponse<T> = await fetch(pageParam || `${url}?${paramString}`, {
        method: 'get',
        headers: defaultHeaders(),
        signal,
      }).then((r) => r.json());

      return convertResults(data) as ApiCollectionResponse<T>;
    },
    {
      ...options,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      getNextPageParam: (lastPage) => {
        if (lastPage.offset && (lastPage.offset < (lastPage.count || 0)) && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            offset: lastPage.offset,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

          return `${url}?${paramString}`;
        }
      }
    }
  );
}
const convertResults = (data: any) => ({
  count: data.totalElements,
  offset: data.totalElements,
  content: data.content.map((x: any) => ({
    createdAt: x.createdAt,
    id: x.id,
    name: contentHelper.decodeHtmlEntities(x.name || ''),
    organizationId: x.organizationId,
    key: x.key,
    version: x.version,
  })),
});