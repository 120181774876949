import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  AppBar,
  Toolbar,
  Stack,
} from '@mui/material';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import {
  DraftChapter,
  DraftManual,
  DraftPolicy,
  DraftPolicyItem,
  DraftPolicySection,
  DraftPolicySubSection,
  DraftPreface,
  DraftPrefaceItem,
} from 'types/manual';
import SortableItem from 'helpers/sortableItems';
import { useManualStructureContext } from './context';
import useTranslations from 'hooks/useTranslations';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ManualFilterOptions } from 'data_sources/draftManuals/enums';
import DraggableItem from './_components/DraggableItem';
import Loading from 'views/App/Portal/Loading';
import InactivityDialog from './_components/InactivityDialog';
import draftManualHelper from '../helpers/draftManualHelper';
import ContentEditor from '../ContentEditor';
import useDraftPreface from 'data_sources/draftManuals/useDraftPreface';
import useDraftPolicy from 'data_sources/draftManuals/useDraftPolicy';
import RightSidebar from 'components/_layouts/RightSidebar';

const ManualStructure: React.FC = () => {
  const {
    reorderedManual,
    filter,
    selectedChapter,
    isSaving,
    handleFilterChange,
    handleChapterClick,
    handleDragEndPrefaces,
    handleDragEndChapters,
    handleDragEndPolicies,
    handleSave,
    handleToggleState,
    getFilteredItems,
    showDialog,
    handleDialogConfirm,
    sidebarItem,
    setSidebarItem,
  } = useManualStructureContext();
  const t = useTranslations();
  const navigate = useNavigate();

  const prefaceQuery = useDraftPreface(
    reorderedManual?.id,
    sidebarItem?.type === 'preface' ? sidebarItem?.item.id : undefined
  );
  const policyQuery = useDraftPolicy(
    reorderedManual?.id,
    sidebarItem?.type === 'policy' ? sidebarItem?.item.id : undefined
  );

  const [contentData, setContentData] = useState<DraftPolicy | DraftPreface | null>(null);
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);
  const [sidebarItemType, setSidebarItemType] = useState<string | null>(null);

  useEffect(() => {
    if (sidebarItem?.type === 'preface' && prefaceQuery.data) {
      setContentData(prefaceQuery?.data as DraftPreface);
      const date = prefaceQuery.data.updatedAt || prefaceQuery.data.createdAt;
      setUpdatedAt(date ? new Date(date) : null);
    } else if (sidebarItem?.type === 'policy' && policyQuery.data) {
      setContentData(policyQuery?.data as DraftPolicy);
      const date = policyQuery.data.updatedAt || policyQuery.data.createdAt;
      setUpdatedAt(date ? new Date(date) : null);
    } else {
      setContentData(null);
      setUpdatedAt(null);
    }
  }, [sidebarItem, prefaceQuery.data, policyQuery.data]);

  useEffect(() => {
    if (sidebarItem?.type) {
      setSidebarItemType(sidebarItem.type);
    }
  }, [sidebarItem]);

  const getSectionIndex = (section: DraftPolicySection) => {
    if (policyQuery?.data?.state !== 'DISABLED') {
      return `${draftManualHelper.getNumberManualNode(policyQuery?.data, reorderedManual as DraftManual)}.${draftManualHelper.getEnabledSectionIndex(section?.id, policyQuery?.data as DraftPolicy)}`;
    }
    return '';
  };

  const getSubSectionIndex = (section: DraftPolicySection, subSection: DraftPolicySubSection) => {
    if (policyQuery?.data?.state !== 'DISABLED')
      return `${draftManualHelper.getNumberManualNode(policyQuery?.data, reorderedManual as DraftManual)}.${draftManualHelper.getEnabledSectionIndex(section.id, policyQuery?.data as DraftPolicy)}.${draftManualHelper.getEnabledSubSectionIndex(section.id, subSection.id, policyQuery?.data as DraftPolicy)}`;
    return '';
  };

  const modalActions = (
    <>
      <Button
        onClick={handleDialogConfirm}
        color='primary'
        autoFocus
      >
        {t('draftManuals.manualStructure.timerModal.ok')}
      </Button>
    </>
  );

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <InactivityDialog
        open={showDialog}
        onCancel={handleDialogConfirm}
        actions={modalActions}
      />
      <AppBar
        color='primary'
        position='static'
        className='takeover-nav'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <Toolbar
          sx={{
            flexGrow: 1,
            px: 4,
            alignItems: 'center',
          }}
        >
          <Stack
            direction='row'
            spacing={2}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            <Typography variant='h3'> {t('draftManuals.manualStructure.title')}</Typography>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: sidebarItem ? '70%' : '100%', transition: 'width 0.4s ease' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingInline: '16px',
              marginTop: 32,
              marginBottom: 32,
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant='h4'>{reorderedManual?.name}</Typography>
              <Typography
                sx={{ mt: 4 }}
                variant='subtitle1'
              >
                {t('draftManuals.manualStructure.subTitle')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {isSaving && <CircularProgress />}
              <Button
                disabled={isSaving}
                variant='contained'
                color='primary'
                onClick={handleSave}
              >
                {t('common.save')}
              </Button>
            </Box>
          </div>
          <Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: '#f2f6fa',
                  mb: 6,
                  mx: 4,
                  p: 4,
                  borderRadius: 4,
                }}
              >
                <FormControl
                  sx={{
                    borderRadius: 2,
                    width: '300px',
                    backgroundColor: '#f2f6fa',
                  }}
                >
                  <InputLabel id='filter-label'>{t('draftManuals.manualStructure.filters.label')}</InputLabel>
                  <Select
                    labelId='filter-label'
                    id='filter-select'
                    value={filter}
                    label='Filter'
                    onChange={handleFilterChange}
                  >
                    <MenuItem value={ManualFilterOptions.ALL}>{t('draftManuals.manualStructure.filters.all')}</MenuItem>
                    <MenuItem value={ManualFilterOptions.DISABLED}>
                      {t('draftManuals.manualStructure.filters.disabled')}
                    </MenuItem>
                    <MenuItem value={ManualFilterOptions.ENABLED}>
                      {t('draftManuals.manualStructure.filters.enabled')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {reorderedManual ? (
                <>
                  <Grid
                    sx={{ px: 4 }}
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                    >
                      <Paper
                        sx={{
                          p: 0.5,
                          borderRadius: '12px',
                          border: '1px solid #e4e8e8',
                          backgroundColor: '#f2f6fa',
                          maxHeight: '64vh',
                        }}
                      >
                        <Paper
                          sx={{
                            p: 4,
                            borderRadius: '12px',
                            border: '1px solid #e4e8e8',
                            maxHeight: '62vh',
                            overflowY: 'auto',
                          }}
                        >
                          <Typography
                            variant='h6'
                            sx={{ mb: 2, display: 'flex', gap: 2 }}
                          >
                            {t('draftManuals.manualStructure.columns.prefaces')}
                            <Typography
                              sx={{
                                my: 'auto',
                                backgroundColor: '#d7e6ea',
                                width: 24,
                                borderRadius: 100,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {getFilteredItems('prefaces')?.length}
                            </Typography>
                          </Typography>
                          <Box sx={{}}>
                            <DndContext
                              collisionDetection={closestCenter}
                              onDragEnd={handleDragEndPrefaces}
                            >
                              <SortableContext
                                items={getFilteredItems('prefaces').map((p: DraftPrefaceItem) => p.id)}
                                strategy={verticalListSortingStrategy}
                              >
                                {(getFilteredItems('prefaces') as DraftPrefaceItem[]).map((preface) => (
                                  <SortableItem
                                    key={preface.id}
                                    id={preface.id}
                                  >
                                    <DraggableItem
                                      item={preface}
                                      title={preface.title}
                                      itemType='preface'
                                      handleToggleState={handleToggleState}
                                      isSelected={sidebarItem?.item.id === preface.id}
                                      sidebarItem={sidebarItem}
                                      setSidebarItem={setSidebarItem}
                                    />
                                  </SortableItem>
                                ))}
                              </SortableContext>
                            </DndContext>
                          </Box>
                        </Paper>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                    >
                      <Paper
                        sx={{
                          p: 0.5,
                          borderRadius: '12px',
                          border: '1px solid #e4e8e8',
                          backgroundColor: '#f2f6fa',
                          maxHeight: '64vh',
                        }}
                      >
                        <Paper
                          sx={{
                            p: 4,
                            borderRadius: '12px',
                            border: '1px solid #e4e8e8',
                            maxHeight: '62vh',
                            overflowY: 'auto',
                          }}
                        >
                          <Typography
                            variant='h6'
                            sx={{ mb: 2, display: 'flex', gap: 2 }}
                          >
                            {t('draftManuals.manualStructure.columns.chapters')}
                            <Typography
                              sx={{
                                my: 'auto',
                                backgroundColor: '#d7e6ea',
                                width: 24,
                                borderRadius: 100,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {getFilteredItems('chapters')?.length}
                            </Typography>
                          </Typography>
                          <Box sx={{}}>
                            <DndContext
                              collisionDetection={closestCenter}
                              onDragEnd={handleDragEndChapters}
                            >
                              <SortableContext
                                items={getFilteredItems('chapters').map((c: DraftChapter) => c.id)}
                                strategy={verticalListSortingStrategy}
                              >
                                {(getFilteredItems('chapters') as DraftChapter[]).map((chapter) => (
                                  <SortableItem
                                    key={chapter.id}
                                    id={chapter.id}
                                  >
                                    <DraggableItem
                                      item={chapter}
                                      title={
                                        draftManualHelper.formatManualNodeTitle(chapter, {
                                          ...reorderedManual,
                                          chapters: reorderedManual.chapters.filter(
                                            (chapter) => chapter.state !== 'DISABLED'
                                          ),
                                        }) as string
                                      }
                                      itemType='chapter'
                                      isSelected={selectedChapter === chapter.id}
                                      handleToggleState={handleToggleState}
                                      handleChapterClick={handleChapterClick}
                                    />
                                  </SortableItem>
                                ))}
                              </SortableContext>
                            </DndContext>
                          </Box>
                        </Paper>
                      </Paper>
                    </Grid>
                    {selectedChapter ? (
                      <Grid
                        item
                        xs={12}
                        md={4}
                      >
                        <Paper
                          sx={{
                            p: 0.5,
                            borderRadius: '12px',
                            border: '1px solid #e4e8e8',
                            backgroundColor: '#f2f6fa',
                            maxHeight: '64vh',
                          }}
                        >
                          <Paper
                            sx={{
                              p: 4,
                              borderRadius: '12px',
                              border: '1px solid #e4e8e8',
                              maxHeight: '62vh',
                              overflowY: 'auto',
                            }}
                          >
                            <Typography
                              variant='h6'
                              sx={{ mb: 2, display: 'flex', gap: 2 }}
                            >
                              {t('draftManuals.manualStructure.columns.policies')}
                              <Typography
                                sx={{
                                  my: 'auto',
                                  backgroundColor: '#d7e6ea',
                                  width: 24,
                                  borderRadius: 100,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                {getFilteredItems('policies')?.length}
                              </Typography>
                            </Typography>
                            <Box sx={{}}>
                              <DndContext
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEndPolicies}
                              >
                                <SortableContext
                                  items={getFilteredItems('policies').map((p: DraftPolicyItem) => p.id)}
                                  strategy={verticalListSortingStrategy}
                                >
                                  {(getFilteredItems('policies') as DraftPolicyItem[]).map((policy) => (
                                    <SortableItem
                                      key={policy.id}
                                      id={policy.id}
                                    >
                                      <DraggableItem
                                        item={policy}
                                        title={
                                          draftManualHelper.formatManualNodeTitle(policy, reorderedManual) as string
                                        }
                                        itemType='policy'
                                        handleToggleState={handleToggleState}
                                        isSelected={sidebarItem?.item.id === policy.id}
                                        sidebarItem={sidebarItem}
                                        setSidebarItem={setSidebarItem}
                                      />
                                    </SortableItem>
                                  ))}
                                </SortableContext>
                              </DndContext>
                            </Box>
                          </Paper>
                        </Paper>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        md={4}
                      >
                        <Paper
                          sx={{
                            p: 0.5,
                            borderRadius: '12px',
                            border: '1px solid #e4e8e8',
                            backgroundColor: '#f2f6fa',
                            maxHeight: '64vh',
                          }}
                        >
                          <Paper
                            sx={{
                              p: 4,
                              borderRadius: '12px',
                              border: '1px solid #e4e8e8',
                              backgroundColor: '#f2f6fa',
                              maxHeight: '62vh',
                              overflowY: 'auto',
                            }}
                          >
                            <Typography
                              variant='h6'
                              sx={{ mb: 2, display: 'flex', gap: 2 }}
                            >
                              {t('draftManuals.manualStructure.columns.policies')}
                            </Typography>
                            <Box>
                              <Typography>{t('draftManuals.manualStructure.columns.selectChapter')}</Typography>
                            </Box>
                          </Paper>
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                </>
              ) : (
                <Loading />
              )}
            </Box>
          </Box>
        </Box>
        <RightSidebar
          open={!!sidebarItem}
          header={
            <Typography
              sx={{
                width: 'max-content',
                px: 2,
                py: 1,
                fontSize: 12,
                backgroundColor: '#c8e2f7',
                borderRadius: 6,
                color: '#004B87',
                mt: 'auto',
              }}
            >
              {sidebarItemType === 'policy'
                ? 'Policy ' + draftManualHelper.getNumberManualNode(policyQuery?.data, reorderedManual as DraftManual)
                : 'Preface'}
            </Typography>
          }
          onClose={() => setSidebarItem(null)}
          isLoading={prefaceQuery?.isLoading || policyQuery?.isLoading}
          hasError={prefaceQuery?.isError || policyQuery?.isError}
          error='Failed to load content. Please try again.'
        >
          <Box>
            <Box>
              <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{contentData?.title}</Typography>
              {updatedAt && (
                <Typography sx={{ color: '#697586', fontWeight: 600, fontSize: 14 }}>
                  {updatedAt.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </Typography>
              )}
            </Box>
            {sidebarItem?.type === 'preface' && (
              <Box sx={{ color: '#697586', mt: 3 }}>
                <ContentEditor
                  id={`preface_content_${prefaceQuery?.data?.id}`}
                  key={`preface_content_${prefaceQuery?.data?.id}`}
                  contentHTML={prefaceQuery?.data?.content || ' '}
                  isEditable={false}
                />
              </Box>
            )}
            {sidebarItem?.type === 'policy' &&
              policyQuery?.data?.sections
                ?.filter((section) => section.state !== 'DISABLED')
                .map((section) => (
                  <Box
                    key={section.id}
                    sx={{ mt: 3 }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 16 }}
                    >{`${getSectionIndex(section)} ${section.title}`}</Typography>
                    <ContentEditor
                      id={`section_${section.id}`}
                      key={`section_${section.id}`}
                      contentHTML={section.content || ' '}
                      isEditable={false}
                    />
                    {section.subSections
                      ?.filter((subSection) => subSection.state !== 'DISABLED')
                      .map((subSection) => (
                        <Box
                          key={subSection.id}
                          sx={{ ml: 3, mt: 2 }}
                        >
                          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                            {`${getSubSectionIndex(section, subSection)} ${subSection.title}`}
                          </Typography>
                          <ContentEditor
                            id={`subsection_${subSection.id}`}
                            key={`subsection_${subSection.id}`}
                            contentHTML={subSection.content || ' '}
                            isEditable={false}
                          />
                        </Box>
                      ))}
                  </Box>
                ))}
          </Box>
        </RightSidebar>
      </Box>
    </Box>
  );
};

export default ManualStructure;
