import React, { useContext } from 'react';
import { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  MenuList,
  MenuItem,
  Paper,
  Tooltip,
  Toolbar,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import useTranslations from '../../../../hooks/useTranslations';
import { AppContext } from '../../context';
import { Treeview } from '../../../../components/_atoms';
import { TreeItem } from '../../../../components/_atoms/Treeview';
import { NavItemDef, NAV_ITEMS } from '../navItems';

const drawerWidth: number = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 72,
    }),
  },
}));

export type MainNavProps = {
  signOut?: WithAuthenticatorProps['signOut'];
};

const MainNav: React.FC<MainNavProps> = ({ signOut }) => {
  const t = useTranslations();
  const currLoc = useLocation();
  const {
    currentModule,
    currentOrganizationId,
    hasPermission,
    hasAppPermission,
    isMobile,
    leftNavCollapsed,
    setCurrentModule,
    setMainNavCollapsed,
    showNav,
    urlSegments,
    userOrganizationId,
  } = useContext(AppContext);
  const urlParts = urlSegments();

  const toggleDrawer = () => {
    setMainNavCollapsed(!leftNavCollapsed);
  };

  const buildHREF = (path: string, prefix: string = '') => {
    return [prefix, path].filter((x) => x !== null).join('/');
  };

  const moduleAllowed = (item: NavItemDef) => {
    let allowed = false;
    if (!item.children || item.children.length === 0) allowed = true;
    item.children?.forEach((child) => {
      if (navItemAllowed(child)) allowed = true;
      // console.log('moduleNav:child', child.key, navItemAllowed(child), allowed)
    });
    // console.log('moduleNav', item.key, allowed)
    return allowed;
  };

  const navItemAllowed = (item: NavItemDef) => {
    if (item.permissions) {
      const matchingPerms = item.permissions.filter((x) =>
        hasPermission(x, currentOrganizationId)
      );
      // console.log('navItem:perms', item.key, item.permissions, matchingPerms);
      if (matchingPerms.length === 0) return false;
    }
    if (item.appPermissions) {
      const matchingPerms = item.appPermissions.filter((x) =>
        hasAppPermission(x)
      );
      // console.log('navItem:appPerms', item.key, item.appPermissions, matchingPerms)
      if (matchingPerms.length === 0) return false;
    }
    if (item.sameOrg) {
      if ((userOrganizationId ?? '') !== (currentOrganizationId ?? '')) {
        // console.log('sameOrg', (userOrganizationId ?? '') !== (currentOrganizationId ?? ''), userOrganizationId, currentOrganizationId)
        return false;
      }
    }
    if (item.orgContext) {
      if ((userOrganizationId ?? '') === (currentOrganizationId ?? '')) {
        return false;
      }
    }
    if (item.rootOnly) {
      if ((userOrganizationId ?? '') !== 'LexipolGenesisRoot') {
        return false;
      }
    }
    if (item.nonRoot) {
      if ((currentOrganizationId ?? '') === 'LexipolGenesisRoot') {
        return false;
      }
    }
    return true;
  };

  const treeItem = (
    data: NavItemDef,
    level: number = 0,
    prefix?: string
  ): TreeItem => {
    const itemSelected = data.path === urlParts[level + 1];
    // console.log('item', itemSelected, level, data.path, urlParts[level + 1], urlParts);
    return {
      id: data.path,
      icon: data.icon,
      label: data.label ? t(data.label) : '',
      ...(data.path ? { href: buildHREF(data.path, prefix) } : {}),
      children: data.children
        ? data.children
            .filter((x) => navItemAllowed(x))
            .map((x) => treeItem(x, level, buildHREF(data.path, prefix)))
        : [],
      selected: itemSelected,
    };
  };

  const treeData = () => {
    const currentModuleNav =
      NAV_ITEMS.find((x) => x.path.split('/')[0] === currentModule)?.children ||
      [];
    // console.log('curr mod', currentModuleNav, currentModule)
    return currentModuleNav
      .filter((x) => navItemAllowed(x))
      .map((v) => treeItem(v, 1, `/portal/${currentModule}`));
  };

  const NavTooltip = ({
    title,
    children,
    ...props
  }: {
    title: string;
    children: React.ReactElement;
  }) => (
    <Tooltip
      {...props}
      title={title}
      placement='right'
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'rgba(0,0,0,0.9)',
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );

  return isMobile ? (
    <MuiDrawer
      className='main-nav-container'
      container={window.document.body}
      open={showNav}
      anchor='left'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '72px 1fr',
          height: '100%',
          width: '90vh',
        }}
      >
        <Paper sx={{ backgroundColor: 'primary.dark', p: 3, borderRadius: 0 }}>
          {
            <MenuList
              sx={{
                p: 0,
                pt: 12,
                '&& .Mui-selected .MuiIconButton-root': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              {NAV_ITEMS.map((x) =>
                moduleAllowed(x) ? (
                  <MenuItem
                    key={x.key}
                    disableGutters
                    selected={!!(urlSegments()[1] === x.key)}
                    sx={{ color: '#FFF' }}
                  >
                    <NavTooltip title={t(x.label || '')}>
                      <IconButton
                        id={`module_nav_${x.key}`}
                        data-navitem={x.key}
                        size='large'
                        color='inherit'
                        href={`/portal/${x.path}`}
                        onClick={() => {
                          console.log('curr module', x.key);
                          setCurrentModule(x.key);
                          setMainNavCollapsed(
                            !(x.children && x.children?.length > 0)
                          );
                        }}
                      >
                        {x.icon}
                      </IconButton>
                    </NavTooltip>
                  </MenuItem>
                ) : null
              )}
            </MenuList>
          }
        </Paper>
        <Paper
          sx={{
            backgroundColor: 'primary.dark',
            p: 5,
            borderLeft: 'solid 1px #FFF',
          }}
        >
          <Toolbar />
          <Treeview
            data={treeData()}
            darkMode={isMobile}
            selectedPath={currLoc.pathname}
          />
        </Paper>
      </Box>
    </MuiDrawer>
  ) : (
    <Drawer
      className='left-nav-container'
      variant='permanent'
      open={!leftNavCollapsed}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '72px 1fr',
          height: '100%',
        }}
      >
        <Paper sx={{ backgroundColor: 'primary.dark', p: 3, borderRadius: 0 }}>
          {
            <MenuList
              sx={{
                p: 0,
                '&& .Mui-selected .MuiIconButton-root': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              {NAV_ITEMS.map((x) =>
                moduleAllowed(x) ? (
                  <MenuItem
                    key={x.key}
                    disableGutters
                    selected={!!(urlSegments()[1] === x.key)}
                    sx={{ color: '#FFF' }}
                  >
                    <NavTooltip title={t(x.label || '')}>
                      <IconButton
                        size='large'
                        color='inherit'
                        href={`/portal/${x.path}`}
                        id={`module_nav_${x.key}`}
                        data-navitem={x.key}
                        onClick={() => {
                          setCurrentModule(x.key);
                          setMainNavCollapsed(
                            !(x.children && x.children?.length > 0)
                          );
                        }}
                      >
                        {x.icon}
                      </IconButton>
                    </NavTooltip>
                  </MenuItem>
                ) : null
              )}
              <Divider />
              <MenuItem
                disableGutters
                sx={{ color: '#FFF' }}
              >
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={toggleDrawer}
                >
                  {leftNavCollapsed ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </MenuItem>
            </MenuList>
          }
        </Paper>
        <Paper sx={{ p: 5 }}>
          <Treeview
            data={treeData()}
            selectedPath={currLoc.pathname}
          />
        </Paper>
      </Box>
    </Drawer>
  );
};

export default MainNav;
