import { NewUserImportRequest } from '../../types/user_import';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageOrganizationUserImport() {
  const create = async (organizationId: string, data: NewUserImportRequest, callback?: (jobId: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users/import-user-file`;
    const filenameParts = data.fileContents?.name.split('.');
    const fileType = filenameParts ? filenameParts[filenameParts.length - 1] : '';
    const newUserImport = {
      ...data,
      fileContents: data.fileContents?.data,
      fileType,
    }
    await fetcher({
      method: 'post',
      url,
      body: newUserImport,
    }).then((data) => {
      if (callback) callback(data.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    create,
  }
}