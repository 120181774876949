import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import manualHelper from '../helpers/manualHelper';
import { Manual } from '../types/manual';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from '../helpers/sortableItems';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { enqueueSnackbar } from 'notistack';

const ManualStructure: React.FC = () => {
  const [manual, setManual] = useState<Manual | null>(null);
  const [isPrefaceDisabled, setIsPrefaceDisabled] = useState<string[]>([]);
  const [isChapterDisabled, setIsChapterDisabled] = useState<string[]>([]);
  const [isPolicyDisabled, setIsPolicyDisabled] = useState<string[]>([]);
  const [reorderedManual, setReorderedManual] = useState<Manual | null>(null);
  const [filter, setFilter] = useState('All');
  const [selectedChapter, setSelectedChapter] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    manualHelper.loadStoredManual().then((manualData) => {
      if (!!manualData) {
        setManual(manualData as Manual);
        setReorderedManual(manualData as Manual);
      }
    });
  }, []);

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value);
  };

  const handleChapterClick = (chapterId: string) => {
    if (selectedChapter !== chapterId) {
      setSelectedChapter(chapterId);
    } else {
      setSelectedChapter(null);
    }
  };

  const handleDragEndPrefaces = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setReorderedManual((prevManual) => {
        if (!prevManual) return prevManual;
        const oldIndex = prevManual.prefaces.findIndex(
          (p) => p.id === active.id
        );
        const newIndex = prevManual.prefaces.findIndex((p) => p.id === over.id);
        const newPrefaces = arrayMove(prevManual.prefaces, oldIndex, newIndex);
        return { ...prevManual, prefaces: newPrefaces };
      });
    }
  };

  const handleDragEndChapters = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setReorderedManual((prevManual) => {
        if (!prevManual) return prevManual;
        const oldIndex = prevManual.chapters.findIndex(
          (c) => c.id === active.id
        );
        const newIndex = prevManual.chapters.findIndex((c) => c.id === over.id);
        const newChapters = arrayMove(prevManual.chapters, oldIndex, newIndex);
        return { ...prevManual, chapters: newChapters };
      });
    }
  };

  const handleDragEndPolicies = (event: any) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    setReorderedManual((prevManual) => {
      if (!prevManual) return prevManual;

      const chapterContainingPolicy = prevManual.chapters.find((chapter) =>
        chapter.policies.some((policy) => policy.id === active.id)
      );

      if (!chapterContainingPolicy) return prevManual;

      const oldIndex = chapterContainingPolicy.policies.findIndex(
        (policy) => policy.id === active.id
      );
      const newIndex = chapterContainingPolicy.policies.findIndex(
        (policy) => policy.id === over.id
      );

      const updatedManual = JSON.parse(JSON.stringify(prevManual));
      const updatedChapter = updatedManual.chapters.find(
        (chapter: any) => chapter.id === chapterContainingPolicy.id
      );

      if (updatedChapter) {
        const reorderedPolicies = arrayMove(
          updatedChapter.policies,
          oldIndex,
          newIndex
        );
        updatedChapter.policies = reorderedPolicies;
      }

      return updatedManual;
    });
  };

  const handleSave = () => {
    if (reorderedManual) {
      setManual(reorderedManual);
      manualHelper.saveManual(reorderedManual);
      enqueueSnackbar('Structure saved succesfully', {
        variant: 'success',
        autoHideDuration: 4000,
      });
    }
  };

  const handleDisable = (
    type: 'preface' | 'chapter' | 'policy',
    id: string,
    event: any
  ) => {
    event.stopPropagation();
    switch (type) {
      case 'preface':
        return setIsPrefaceDisabled((prev) => [...prev, id]);
      case 'chapter':
        return setIsChapterDisabled((prev) => [...prev, id]);
      case 'policy':
        return setIsPolicyDisabled((prev) => [...prev, id]);
    }
  };

  const handleEnable = (
    type: 'preface' | 'chapter' | 'policy',
    id: string,
    event: any
  ) => {
    event.stopPropagation();
    switch (type) {
      case 'preface':
        return setIsPrefaceDisabled((prev) =>
          prev.filter((prefaceId: string) => prefaceId !== id)
        );
      case 'chapter':
        return setIsChapterDisabled((prev) =>
          prev.filter((chapterId: string) => chapterId !== id)
        );
      case 'policy':
        return setIsPolicyDisabled((prev) =>
          prev.filter((policyId: string) => policyId !== id)
        );
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            mb: 4,
            p: 2,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#004B87',
            color: '#fff',
          }}
        >
          <IconButton
            onClick={() => navigate('/prototypes/authoring')}
            sx={{ mr: 2 }}
          >
            <ArrowBack sx={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h4">Manage Structure</Typography>
        </Box>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          <Typography sx={{ p: 4, mb: 4 }} variant="subtitle1">
            Drag and drop to reorder and customize the structure of the manual
            content.
          </Typography>
          <Button onClick={handleSave}>Save</Button>
        </div>

        <Box
          sx={{
            width: '100%',
            backgroundColor: '#f5f5f5',
            mb: 4,
            ml: 2,
            p: 4,
            borderRadius: 4,
          }}
        >
          <FormControl
            sx={{
              borderRadius: 2,
              width: '300px',
              backgroundColor: '#f5f5f5',
            }}
          >
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={filter}
              label="Filter"
              onChange={handleFilterChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Prefaces">Prefaces</MenuItem>
              <MenuItem value="Chapters">Chapters</MenuItem>
              <MenuItem value="Policies">Policies</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Grid sx={{ p: 4 }} container spacing={4}>
          {(filter === 'All' || filter === 'Prefaces') && (
            <Grid item xs={12} md={4}>
              <Paper
                sx={{ p: 4, borderRadius: '12px', border: '1px solid #ccc' }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Prefaces
                </Typography>
                <Box sx={{ minHeight: '300px' }}>
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEndPrefaces}
                  >
                    <SortableContext
                      items={reorderedManual?.prefaces.map((p) => p.id) || []}
                      strategy={verticalListSortingStrategy}
                    >
                      {reorderedManual?.prefaces.map((preface, index) => (
                        <SortableItem key={preface.id} id={preface.id}>
                          <Paper
                            sx={{
                              p: 2,
                              mb: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              borderRadius: '8px',
                              border: '1px solid #ddd',
                              color: isPrefaceDisabled.find(
                                (prefaceId) => prefaceId === preface.id
                              )
                                ? '#c5c5c5'
                                : '#000',
                            }}
                          >
                            <Typography>
                              {manualHelper.formatManualChapterTitle(
                                reorderedManual,
                                preface
                              )}
                            </Typography>
                            <IconButton>
                              {!isPrefaceDisabled.find(
                                (prefaceId) => prefaceId === preface.id
                              ) ? (
                                <VisibilityIcon
                                  onClick={(e) =>
                                    handleDisable('preface', preface.id, e)
                                  }
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={(e) =>
                                    handleEnable('preface', preface.id, e)
                                  }
                                />
                              )}
                            </IconButton>
                          </Paper>
                        </SortableItem>
                      ))}
                    </SortableContext>
                  </DndContext>
                </Box>
              </Paper>
            </Grid>
          )}
          {(filter === 'All' || filter === 'Chapters') && (
            <Grid item xs={12} md={4}>
              <Paper
                sx={{ p: 4, borderRadius: '12px', border: '1px solid #ccc' }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Chapters
                </Typography>
                <Box sx={{ minHeight: '300px' }}>
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEndChapters}
                  >
                    <SortableContext
                      items={reorderedManual?.chapters.map((c) => c.id) || []}
                      strategy={verticalListSortingStrategy}
                    >
                      {reorderedManual?.chapters.map((chapter) => (
                        <SortableItem key={chapter.id} id={chapter.id}>
                          <Paper
                            sx={{
                              p: 2,
                              mb: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              borderRadius: '8px',
                              border:
                                selectedChapter === chapter.id
                                  ? '2px solid #1976d2'
                                  : '1px solid #ddd',
                              backgroundColor:
                                selectedChapter === chapter.id
                                  ? '#bbdefb'
                                  : 'transparent',
                              cursor: 'pointer',
                              color: isChapterDisabled.find(
                                (chapterId) => chapterId === chapter.id
                              )
                                ? '#c5c5c5'
                                : '#000',
                            }}
                            onClick={() => handleChapterClick(chapter.id)}
                          >
                            <Typography>
                              {manualHelper.formatManualChapterTitle(
                                reorderedManual,
                                chapter
                              )}
                            </Typography>
                            <IconButton>
                              {!isChapterDisabled.find(
                                (chapterId) => chapterId === chapter.id
                              ) ? (
                                <VisibilityIcon
                                  onClick={(e) =>
                                    handleDisable('chapter', chapter.id, e)
                                  }
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={(e) =>
                                    handleEnable('chapter', chapter.id, e)
                                  }
                                />
                              )}
                            </IconButton>
                          </Paper>
                        </SortableItem>
                      ))}
                    </SortableContext>
                  </DndContext>
                </Box>
              </Paper>
            </Grid>
          )}

          {selectedChapter && (filter === 'All' || filter === 'Policies') && (
            <Grid item xs={12} md={4}>
              <Paper
                sx={{ p: 4, borderRadius: '12px', border: '1px solid #ccc' }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Policies
                </Typography>
                <Box sx={{ minHeight: '300px' }}>
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEndPolicies}
                  >
                    <SortableContext
                      items={
                        reorderedManual?.chapters
                          .find((chapter) => chapter.id === selectedChapter)
                          ?.policies.map((policy) => policy.id) || []
                      }
                      strategy={verticalListSortingStrategy}
                    >
                      {reorderedManual?.chapters
                        .find((chapter) => chapter.id === selectedChapter)
                        ?.policies.map((policy) => (
                          <SortableItem key={policy.id} id={policy.id}>
                            <Paper
                              sx={{
                                p: 2,
                                mb: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                color: isPolicyDisabled.find(
                                  (policyId) => policyId === policy.id
                                )
                                  ? '#c5c5c5'
                                  : '#000',
                              }}
                            >
                              <Typography>
                                {manualHelper.formatManualNodeTitle(
                                  policy,
                                  reorderedManual
                                )}
                              </Typography>
                              <IconButton>
                                {!isPolicyDisabled.find(
                                  (policyId) => policyId === policy.id
                                ) ? (
                                  <VisibilityIcon
                                    onClick={(e) =>
                                      handleDisable('policy', policy.id, e)
                                    }
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    onClick={(e) =>
                                      handleEnable('policy', policy.id, e)
                                    }
                                  />
                                )}
                              </IconButton>
                            </Paper>
                          </SortableItem>
                        ))}
                    </SortableContext>
                  </DndContext>
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ManualStructure;
