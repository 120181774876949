import React from 'react';
import { Box, InputAdornment, Stack } from '@mui/material';
import { FlattenedItem } from '../types/policy';
import { TitleTextField } from '../styles';
type TreeSectionEditorProps = {
  section: FlattenedItem;
  sectionNumber: string;
  onChange: (section: FlattenedItem) => void;
};
const TreeSectionEditor: React.FC<TreeSectionEditorProps> = ({
  section,
  sectionNumber,
  onChange,
}: TreeSectionEditorProps) => {
  return section ? (
    <Box sx={{ flexGrow: 1 }}>
      <Stack direction="row" spacing={4}>
        <TitleTextField
          id={`title_${section.id}`}
          key={`title_${section.id}`}
          label={false}
          variant="outlined"
          fullWidth
          value={section.title}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 20,
                    fontWeight: 600,
                  },
                }}
              >
                {sectionNumber}
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Box>
  ) : null;
};
export default TreeSectionEditor;
