/* eslint-disable class-methods-use-this */
import React, { Fragment } from 'react';
import { domToReact, DOMNode } from 'html-react-parser';
import { Box, Link, Stack } from '@mui/material';
import {
  AttachFile,
} from '@mui/icons-material';

export interface PolicyAttachment {
  sectionKey: string;
  section: string;
  attachmentKey: string;
  attachmentName: string;
}

export default class contentHelper {
  static decodeHtmlEntities = (content: string) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    return doc.documentElement.textContent;
  };

  static handleContent = (node: DOMNode, ind: number) => {
    if (node.type === 'tag' && ['primary', 'indexterm', 'lexindex'].includes(node.name)) {
      // console.log(node.name, node);
      return <Fragment />;
    }
    if (node.type === 'tag') {
      if (node.name === 'img') {
        if (node.attribs.src && node.attribs.src.startsWith('s3://')) {
          //console.log('img', node.attribs, ind);
          // const key = node.attribs.src.split('://')[1];
          return (
            <Box component='span' sx={{
              display: 'inline-flex',
              width: 150,
              height: 150,
              alignItems: 'center',
              justifyContent: 'center',
              border: 'solid 1px #DDD'
            }}>
              <Fragment>
                Media:({node.attribs.title || node.attribs.alt})
              </Fragment>
            </Box>
          )
        }
      }
      if (node.name === 'a') {
        if (node.attribs.href && node.attribs.href.startsWith('s3://')) {
          // console.log('attachment found', node.attribs.title, node.attribs.href)
          return (
            <Link>
              <Stack
                component='span'
                direction='row'
                spacing={2}
                sx={{ display: 'inline-flex' }}
              >
                <AttachFile />
                {domToReact(node.children as DOMNode[])}
              </Stack>
            </Link>
          )
        } else if (node.attribs.href && node.attribs.href.startsWith('xref://')) {
          // console.log('crossref found', node.attribs.title, node.attribs.href);
          const key = node.attribs.href.split('://')[1]

          return (
            <Link title={key}>
              {domToReact(node.children as DOMNode[])}
            </Link>
          )
        } else {
          node.attribs.target = '_blank';
          node.attribs.rel = 'noopener noreferrer';
        }
      }

    }
    return node;
  };

  static formatLabel = (node: any) => {
    const parts = [];
    if (node?.heading) parts.push(node.heading);
    parts.push(node?.title?.value ?? '');
    return parts.join(': ');
  }

  static extractAttachments = (content: string) => {
    const contentNode = document.createElement('div');
    contentNode.innerHTML = content;
    const links: { key: string; name: string; }[] = [];
    const linkMatches = contentNode.querySelectorAll('a');
    linkMatches.forEach((l) => {
      if (l.href.startsWith('s3://')) {
        links.push({
          key: l.href.split('://')[1],
          name: l.title || ''
        })
      }
    });
    return links;
  };

  static humanize = (text: string) => {
    if (/id/i.test(text)) return text.toUpperCase();

    return text.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }
}
