import React, { useCallback, useContext, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Link,
  Stack,
  Typography,
  Select,
  MenuItem,
  Badge,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import useUrlParams from 'hooks/useUrlParams';
import useDraftManuals from 'data_sources/draftManuals/useDraftManuals';
import useVerticals from 'data_sources/useVerticals';
import { DraftManualsContext } from '../context';
import { ActionMenu, QuickSearch } from 'components/_atoms';
import datetimeHelper from 'helpers/datetimeHelper';
import ListView from 'components/_layouts/ListView';
import { DraftManualItem, ManualPermissions } from 'types/manual';
import { ActionMenuItemProps } from 'components/_atoms/ActionMenu';

type DraftManualsUrlParams = {
  search?: string | null;
  verticalId?: string | null;
};

const defaultParams: DraftManualsUrlParams = {
  search: null,
  verticalId: null,
};

const columnHelper = createColumnHelper<DraftManualItem>();

const DraftManualsList: React.FC = () => {
  const t = useTranslations();
  const { setManualPermissions } = useContext(DraftManualsContext);
  const [params, setParams] = useUrlParams(defaultParams);

  const updateParams = useCallback(
    (updatedParams: DraftManualsUrlParams) => {
      setParams((prev: any) => ({
        ...prev,
        ...updatedParams,
      }));
      document.body.dispatchEvent(new Event('params-changed'));
    },
    [setParams]
  );

  const queryParams = useMemo(() => {
    const newParams: any = {
      ...params,
    };
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null || newParams[key] === '') {
        delete newParams[key];
      }
    });

    return newParams;
  }, [params]);

  const manualsQuery = useDraftManuals(queryParams, {
    onSuccess: (data: any) => {
      const mPerms: ManualPermissions = {};
      (data.pages
        ? data.pages.flatMap((x: any) => x.content)
        : ([] as DraftManualItem[])
      ).forEach((m: DraftManualItem) => {
        mPerms[m.id] = (m.authorizations ?? []).map((x) => x.action).sort();
      });
      setManualPermissions(mPerms);
    },
  });

  const manuals = useMemo(
    () =>
      manualsQuery.data && manualsQuery.data.pages
        ? manualsQuery.data.pages.flatMap((x) => x.content)
        : [],
    [manualsQuery.data]
  );

  const verticalsQuery = useVerticals();

  const manualActions: (manual: DraftManualItem) => ActionMenuItemProps[] = (
    manual
  ) => {
    const actions: ActionMenuItemProps[] = [];

    return actions;
  };

  const getVerticalName = useCallback(
    (verticalId: string) => {
      if (verticalsQuery.data) {
        return verticalsQuery.data.find((v) => v.id === verticalId)?.name;
      }
    },
    [verticalsQuery.data]
  );

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Link href={`./${info.row.original.id}`}>
          <Typography
            variant='h6'
            sx={{ fontWeight: 'semibold' }}
          >
            {info.getValue()}
          </Typography>
        </Link>
      ),
      header: () => t('policy.manuals.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('verticalId', {
      cell: (info) => (
        <Typography>{getVerticalName(info.getValue())}</Typography>
      ),
      header: () => t('policy.manuals.properties.vertical'),
      size: 200,
    }),
    columnHelper.accessor('manualType', {
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => t('policy.manuals.properties.manualType'),
      size: 200,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => (
        <Typography>{datetimeHelper.formatDate(info.getValue())}</Typography>
      ),
      header: () => t('policy.manuals.properties.createdAt'),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <ActionMenu
          id={`action_menu_${info.row.original.id}`}
          items={manualActions(info.row.original)}
        />
      ),
      header: () => '',
      size: 60,
    }),
  ];

  return (
    <ListView
      columns={columns}
      data={manuals}
      query={manualsQuery}
      title={
        <Stack
          direction='row'
          spacing={2}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography variant='h5'>{t('policy.draftManuals.title')}</Typography>
          {(manualsQuery.data?.pages[0]?.count ?? 0) > 0 && (
            <Badge
              variant='inline'
              badgeContent={manualsQuery.data?.pages[0]?.count}
              color='neutral'
              sx={{ textTransform: 'uppercase' }}
            />
          )}
        </Stack>
      }
      subtitle={t('policy.draftManuals.subtitle')}
      toolbarLeft={
        <Stack
          direction='row'
          spacing={3}
        >
          <QuickSearch
            id='keyword_search'
            initialValue={params.q || ''}
            onChange={(val) => updateParams({ search: val })}
            placeholder={t('policy.draftManuals.search.placeholder')}
          />
          <Select
            id='vertical'
            size='small'
            label={false}
            value={params.verticalId || ''}
            onChange={({ target }) =>
              updateParams({ verticalId: target.value })
            }
            displayEmpty
            sx={{
              minWidth: 200,
              '& fieldset': {
                top: 0,
              },
              '& legend': {
                display: 'none',
              },
            }}
          >
            <MenuItem
              key={`vertical_all`}
              value=''
            >
              {t('policy.draftManuals.search.verticalPlaceholder')}
            </MenuItem>
            {verticalsQuery.data &&
              verticalsQuery.data.map((k) => (
                <MenuItem
                  key={`vertical_${k.id}`}
                  value={k.id}
                >
                  {k.name}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      }
    />
  );
};

export default DraftManualsList;
