import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AuthUtil, { AmplifyConfig } from 'utils/AuthUtil';
import useTranslations from 'hooks/useTranslations';
import Footer from '../Portal/Footer';

export default function Login() {
  const t = useTranslations();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const handleContinue = () => {
    setIsProcessing(true);
    localStorage.setItem('phx-username', username);
    AuthUtil.userLookup(username, async (poolFound) => {
      if (poolFound) {
        const amplifyConfig = localStorage.getItem('phx-userpoolConfig')?.toString();
        const config = JSON.parse(amplifyConfig || '{}') as AmplifyConfig;
        Amplify.configure(config);
        if (config?.Auth?.oauth && config.Auth.provider) {
          console.log('start fed', config.Auth.provider);
          await Auth.federatedSignIn({ customProvider: config.Auth.provider });
          // console.log('sso user', user);
        } else {
          console.log('navigate to dashboard (poolFound)');
          navigate('/portal/dashboard');
        }
      } else {
        console.log('error finding user');
        setIsProcessing(false);
        enqueueSnackbar(
          <div
            dangerouslySetInnerHTML={{
              __html: t('login.step_1.errorMessage'),
            }}
          />,
          {
            variant: 'error',
            autoHideDuration: 10000,
          }
        );
      }
    });
  };

  useEffect(() => {
    AuthUtil.clearStorage(); // Clear storage on page load
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr 50px',
        height: '100vh',
      }}
    >
      <Box
        className='login'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card sx={{ maxWidth: 'sm', p: 6 }}>
          <CardContent>
            <Stack spacing={5}>
              <Stack
                spacing={2}
                sx={{ alignItems: 'center' }}
              >
                <img
                  src='/lexipol_logo.svg'
                  alt='Lexipol'
                  style={{ display: 'inline-block', width: 48 }}
                />
                <Typography variant='h4'>{t('login.step_1.title')}</Typography>
                <Typography variant='subtitle1'>{t('login.step_1.subtitle')}</Typography>
              </Stack>
              <TextField
                id='name'
                key='name'
                label={t('login.step_1.fields.username.label')}
                variant='outlined'
                required
                autoFocus
                fullWidth
                value={username}
                onChange={({ target }) => setUsername(target.value)}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter') {
                    handleContinue();
                    evt.preventDefault();
                  }
                }}
              />
            </Stack>
          </CardContent>
          <CardActions>
            {isProcessing ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                component='button'
                onClick={handleContinue}
                variant='contained'
                sx={{
                  width: '100%',
                }}
              >
                Continue
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
      <Footer />
    </Box>
  );
}
