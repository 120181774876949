import React, { useState } from "react";

import {
  Collapse,
  List,
} from '@mui/material';

import { TreeItem } from "..";
import TreeNode from "../Node";

export type TreeBranchProps = {
  item: TreeItem;
  level: number;
}

const TreeBranch: React.FC<TreeBranchProps> = ({
  item,
  level,
}: TreeBranchProps) => {
  const [selected, setSelected] = useState(item.selected);
  const hasChildren = item.children && item.children.filter((x) => x.label).length !== 0;
  const newLevel = level + 1;

  const renderBranches = () => {
    if (hasChildren) {
      return (
        <Collapse in={selected} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children.map((child) => (
              <TreeNode key={child.id} item={child} level={newLevel} />
            ))}
          </List>
        </Collapse>
      )
    }
    return null;
  }

  const toggleSelected = () => {
    setSelected((prev) => !prev);
  }

  return (
    <>
      <TreeNode
        hasChildren={hasChildren}
        item={item}
        level={level}
        onToggle={toggleSelected}
        selected={selected}
      />
      {selected === true && renderBranches()}
    </>
  );
}


export default TreeBranch;