import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import RolesProvider from "./context";
import { AppContext } from '../../../context';
import RolesList from './List';
import NewRole from './New';
import EditRole from './Edit';
import RoleDetails from './Details';

const Roles: React.FC = () => {
  const { enforcePermissions } = React.useContext(AppContext);

  return (
    <RolesProvider>
      <Routes>
        <Route path='' element={enforcePermissions(['listRoles'], <RolesList />)} />
        <Route path='new' element={enforcePermissions(['createRole'], <NewRole />)} />
        <Route path='edit/:id' element={enforcePermissions(['updateRole'], <EditRole />)} />
        <Route path=':id' element={enforcePermissions(['getRole'], <RoleDetails />)} />
      </Routes>
    </RolesProvider>
  );
}


export default Roles;
