import React from 'react';

import {
  Box,
  Breadcrumbs,
  colors,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import errorHelper from 'helpers/errorHelper';
import Loading from 'views/App/Portal/Loading';


export type DetailViewProps = {
  actions?: React.ReactNode;
  breadcrumbItems?: React.ReactNode[];
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
  query?: any;
  subtitle?: string | null;
  title: React.ReactNode;
  toolbarLeft?: React.ReactNode;
  toolbarRight?: React.ReactNode;
}

const DetailView: React.FC<DetailViewProps> = ({
  actions = null,
  breadcrumbItems = [],
  children = [],
  headerContent = [],
  query,
  subtitle = null,
  title,
  toolbarLeft = null,
  toolbarRight = null,
}: DetailViewProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%'
      }}
    >
      <Box sx={{ px: 5, py: 4, borderBottom: 1, borderColor: colors.grey[100], backgroundColor: colors.grey[50] }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: 4,
          alignItems: 'center',
        }}>
          <Stack spacing={1}>
            {breadcrumbItems && breadcrumbItems.length > 0 && (
              <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbItems}
              </Breadcrumbs>
            )}
            <Typography variant='h1'>{title}</Typography>
            {subtitle && (
              <Typography variant='body1'>{parse(subtitle)}</Typography>
            )}
            {headerContent}
          </Stack>
          {actions}
        </Box>
      </Box>
      <Box sx={{ p: 4 }}>
        {(toolbarLeft || toolbarRight) && !query?.isError && (
          <Toolbar className='phx-toolbar'>
            <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
              {toolbarLeft}
            </Stack>
            <Stack direction='row' spacing={4}>
              {toolbarRight}
            </Stack>
          </Toolbar>
        )}
      </Box>
      <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
        <Box sx={{ minHeight: 0 }}>
          {query?.isError ? errorHelper.renderErrors(query) : children}
          {query && (query.isLoading || query.isFetching) && (
            <Loading />
          )}
        </Box>
      </Box>
    </Box>
  );
}


export default DetailView;