import React from "react";
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Form } from "components/_forms";

type ModalProps = {
  actions?: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  open?: boolean;
  size?: false | Breakpoint | undefined;
  title?: string | null;
  onSubmit?: () => void;
};

function Modal({
  actions = null,
  children = [],
  onClose = () => null,
  open = false,
  size = "sm",
  title = null,
  onSubmit,
}: ModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={size}
      scroll="paper"
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", pr: 1 }}>
        {title}
        <IconButton aria-label="close" onClick={onClose} sx={{ ml: "auto" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {onSubmit ? (
        <Form onSubmit={onSubmit}>
          <DialogContent>
            <Stack spacing={4} sx={{ py: 3 }}>
              {children}
            </Stack>
          </DialogContent>
          {actions && (
            <DialogActions>
              <Stack direction="row" spacing={3}>
                {actions}
              </Stack>
            </DialogActions>
          )}
        </Form>
      ) : (
        <>
          <DialogContent>
            <Stack spacing={4} sx={{ py: 3 }}>
              {children}
            </Stack>
          </DialogContent>
          {actions && (
            <DialogActions>
              <Stack direction="row" spacing={3}>
                {actions}
              </Stack>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
}

export default Modal;
