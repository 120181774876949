import React, { useContext } from 'react';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { AppContext } from '../context';
import { getOrganization } from 'data_sources/organizations/useOrganization';
import { Box, CircularProgress } from '@mui/material';
import { Organization } from 'types/organization';

export default function SwitchOrganization() {
  const { currentProfile, updatePermissions } = useContext(AppContext);
  const { id } = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let newId: string = id || '';
  if (newId.length === 0 && currentProfile?.user?.orgId) {
    newId = currentProfile.user?.orgId;
  }
  if (!id || id === '') {
    localStorage.setItem('phx-currentOrganizationId', newId?.toString());
    localStorage.setItem('phx-currentOrganizationName', '');
    return (
      <Navigate
        to={searchParams.get('redir') || '/portal/lexid/organizations'}
      />
    );
  } else {
    getOrganization(newId).then((org: Organization) => {
      if (org) {
        localStorage.setItem('phx-currentOrganizationId', newId?.toString());
        localStorage.setItem(
          'phx-currentOrganizationName',
          org?.organization?.name || ''
        );
        updatePermissions(
          org.organization?.id,
          (org?.organization?.authorizations || []).map((x) => x.action)
        );
        // console.log('switch-org', newId, searchParams.get('redir'), JSON.parse(localStorage.getItem('phx-orgPermissions') ?? '{}')[newId])
        navigate(searchParams.get('redir') || '/portal/dashboard');
      } else {
        navigate('/portal/dashboard');
      }
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '40vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
