import {
  baseApiUrl,
  fetcher,
  handleError,
  ServerErrorResponse,
} from '../constants';

type NewChapterRequest = {
  title: string;
};

export default function useManageChapter() {
  const create = async (
    manualId: string,
    data: NewChapterRequest,
    callback?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexiverse-manual-api/v1/manuals/${manualId}/chapters`;
    await fetcher({
      url,
      method: 'post',
      body: data,
    })
      .then((data) => {
        if (callback) callback(data.id);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  return {
    create,
  };
}
