import { useSortable } from '@dnd-kit/sortable';
import React, { ReactElement } from 'react';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
type SortableSectionProps = {
  id: string;
  children: ReactElement;
};
const SortableItem: React.FC<SortableSectionProps> = ({
  id,
  children,
}: SortableSectionProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    gap: 4,
    width: '100%',
  };
  const childStyle = {
    width: '100%',
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <DragIndicatorIcon {...listeners} sx={{ mt: 4, cursor: 'grab' }} />
      <div style={childStyle}>{children}</div>
    </div>
  );
};
export default SortableItem;
