import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';

export type UserUserGroupsResponseItem = {
  groupId: string;
  groupName: string;
}

export type UserUserGroupsResponse = {
  groups: UserUserGroupsResponseItem[];
}

export default function useUserUserGroups(
  organizationId: string,
  userId: string,
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users/${userId}/groups`;
  return useQuery<UserUserGroupsResponse, Error>(
    ['user_user_groups', organizationId, userId],
    async ({ pageParam, signal }): Promise<UserUserGroupsResponse> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

      const data: UserUserGroupsResponse = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}
