import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  SerializedElementNode,
  isHTMLElement,
} from 'lexical';
import {
  QuoteNode,
  SerializedQuoteNode,
} from '@lexical/rich-text';

export class PhxQuoteNode extends QuoteNode {

  static getType(): string {
    return 'phx-quote';
  }

  static clone(node: PhxQuoteNode): PhxQuoteNode {
    return new PhxQuoteNode(node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      blockquote: (node: Node) => ({
        conversion: $convertBlockquoteElement,
        priority: 0,
      }),
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    if (element && isHTMLElement(element)) {
      element.removeAttribute('dir');
      element.removeAttribute('class');
    }

    return {
      element,
    };
  }

  static importJSON(serializedNode: SerializedQuoteNode): PhxQuoteNode {
    const node = $createPhxQuoteNode();
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON(): SerializedElementNode {
    return {
      ...super.exportJSON(),
      type: 'phx-quote',
    };
  }
}

function $convertBlockquoteElement(element: HTMLElement): DOMConversionOutput {
  const node = $createPhxQuoteNode();
  if (element.style !== null) {
    node.setFormat(element.style.textAlign as ElementFormatType);
  }
  return { node };
}

export function $createPhxQuoteNode(): QuoteNode {
  return $applyNodeReplacement(new QuoteNode());
}

export function $isQuoteNode(
  node: LexicalNode | null | undefined,
): node is QuoteNode {
  return node instanceof QuoteNode;
}