import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';

export default function useStickyState<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
  const location = useLocation();
  const [value, setValue] = useState<T>(() => {
    const stickyValue = localStorage.getItem(key) as string;
    let result = defaultValue;
    try {
      result = JSON.parse(stickyValue) as T;
    } catch (err) {
      localStorage.removeItem(key);
    }

    return result;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  useEffect(() => {
    const stickyValue = localStorage.getItem(key) as string;
    if (stickyValue) {
      try {
        let storedValue = JSON.parse(stickyValue) as T;
        if (JSON.stringify(storedValue) !== JSON.stringify(value)) {
          console.log('stickyState updatevalue from storage', storedValue);
          setValue(storedValue);
        }
      } catch (err) {
      }
    }
  }, [key, location, value]);

  return [value, setValue];
}
