import {
  DOMExportOutput,
  LexicalEditor,
  SerializedTextNode,
  TextNode,
  isHTMLElement,
} from 'lexical';

export class PhxTextNode extends TextNode {
  static getType(): string {
    return 'phx-text';
  }

  static clone(node: PhxTextNode): PhxTextNode {
    return new PhxTextNode(node.__text, node.__key);
  }

  isSimpleText(): boolean {
    return this.__type === 'phx-text' && this.__mode === 0;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);
    if (element) {
      if (element && isHTMLElement(element) && element.getAttribute('style') === 'white-space: pre-wrap;') element.removeAttribute('style');
    }

    return {
      element,
    };
  }

  static importJSON(serializedNode: SerializedTextNode): PhxTextNode {
    const node = super.importJSON(serializedNode);
    return node;
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: 'phx-text',
      version: 1,
    };
  }
}
