import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import IssuedManualProvider from './context';
import IssuedManualsList from './List';
import IssuedManualsToC from './ToC';
import IssuedManualsChapter from './Chapter';
import IssuedManualsPreface from './Preface';
import IssuedManualsPolicy from './Policy';
import NewAdhocAcknowledgement from './Policy/NewAdhocAcknowledgement';
import IssuedManualsAcknowledgementSchedules from './AcknowledgementSchedules';
import NewAcknowledgementSchedule from './AcknowledgementSchedules/New';
import EditAcknowledgementSchedule from './AcknowledgementSchedules/Edit';

const IssuedManuals: React.FC = () => {
  return (
    <IssuedManualProvider>
      <Routes>
        <Route
          path=''
          element={<IssuedManualsList />}
        />
        <Route
          path=':id'
          element={<IssuedManualsToC />}
        />
        <Route
          path=':id/chapter/:chapterId'
          element={<IssuedManualsChapter />}
        />
        <Route
          path=':id/preface/:prefaceId'
          element={<IssuedManualsPreface />}
        />
        <Route
          path=':id/policy/:policyId/new-acknowledgement'
          element={<NewAdhocAcknowledgement />}
        />
        <Route
          path=':id/policy/:policyId'
          element={<IssuedManualsPolicy />}
        />
        <Route
          path=':id/new-acknowledgement-schedule'
          element={<NewAcknowledgementSchedule />}
        />
        <Route
          path=':id/acknowledgement-schedules/:scheduleId/edit'
          element={<EditAcknowledgementSchedule />}
        />
        <Route
          path=':id/acknowledgement-schedules'
          element={<IssuedManualsAcknowledgementSchedules />}
        />
      </Routes>
    </IssuedManualProvider>
  );
};

export default IssuedManuals;
