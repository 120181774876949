import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import Modal from 'components/_layouts/Modal';
import useTranslations from 'hooks/useTranslations';
import React from 'react';

interface InactivityDialogProps {
  open: boolean;
  onCancel: () => void;
  actions: React.ReactNode;
}

const InactivityDialog: React.FC<InactivityDialogProps> = ({ open, onCancel, actions }) => {
  const t = useTranslations();

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={t('draftManuals.manualStructure.timerModal.title')}
      actions={actions}
      size='sm'
    >
      <Typography>{t('draftManuals.manualStructure.timerModal.content')}</Typography>
    </Modal>
  );
};

export default InactivityDialog;
