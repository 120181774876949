import React, { useEffect, useState } from 'react';
import parse, { DOMNode } from 'html-react-parser';
import { Box, Button, Stack, Toolbar, Typography } from '@mui/material';

import { Preface } from '../types/manual';
import manualHelper from '../helpers/manualHelper';
import ContentEditor from '../ContentEditor';
import { TitleTextField } from '../styles';
import { ActionMenu } from 'components/_atoms';
import contentHelper from 'helpers/contentHelper';

type PrefaceEditorProps = {
  id: string;
  onChange: (preface: Preface) => void;
  onSave: (preface: Preface) => void;
};

const PrefaceEditor: React.FC<PrefaceEditorProps> = ({
  id,
  onChange,
  onSave,
}: PrefaceEditorProps) => {
  const [prefaceData, setPrefaceData] = useState<Preface | null>(null);
  const [isPrefaceEditable, setIsPrefaceEditable] = useState<boolean>(false);

  useEffect(() => {
    const preface = manualHelper.getPreface(id);
    setPrefaceData(preface);
  }, [id]);

  const updatePrefaceData = (updatedData: any) => {
    setPrefaceData((prev) => {
      const data = { ...prev, ...updatedData };
      if (JSON.stringify(prev) !== JSON.stringify(data)) {
        onChange(data);
      }
      return data;
    });
  };

  return prefaceData ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle1">{`Edit preface: ${parse(prefaceData.title)}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {isPrefaceEditable ? (
              <Button
                onClick={() => {
                  onSave(prefaceData);
                  setIsPrefaceEditable(false);
                }}
                variant="contained"
              >
                Save
              </Button>
            ) : (
              <Button
                onClick={() => setIsPrefaceEditable(true)}
                variant="contained"
              >
                Modify
              </Button>
            )}
          </Stack>
        </Box>
      </Box>

      {isPrefaceEditable ? (
        <>
          <Box sx={{ p: 3 }}>
            <Toolbar
              className="phx-toolbar"
              sx={{ gap: 4, borderRadius: '4px !important' }}
            >
              <Stack direction="row" spacing={4} sx={{ flexGrow: 1 }}>
                <TitleTextField
                  id={`title_${prefaceData.id}`}
                  key={`title_${prefaceData.id}`}
                  label={false}
                  variant="outlined"
                  fullWidth
                  value={prefaceData?.title}
                  onChange={({ target }) =>
                    updatePrefaceData({ title: target.value })
                  }
                />
              </Stack>
              <Stack direction="row" spacing={4}>
                <ActionMenu id={`action_menu_${id}`} items={[]} />
              </Stack>
            </Toolbar>
          </Box>
          <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
            <Box sx={{ minHeight: 0 }}>
              <Stack spacing={3}>
                <ContentEditor
                  id={`content_${prefaceData.id}`}
                  key={`content_${prefaceData.id}`}
                  contentHTML={prefaceData.content}
                  onSave={(content) => updatePrefaceData({ content: content })}
                  isEditable
                />
              </Stack>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
          <Typography component="div" variant="h5">
            {parse(contentHelper.formatLabel(prefaceData))}
          </Typography>
          {parse(prefaceData?.content ?? '', {
            replace(node: DOMNode, ind: number) {
              return contentHelper.handleContent(node, ind);
            },
          })}
        </Box>
      )}
    </Box>
  ) : null;
};

export default PrefaceEditor;
