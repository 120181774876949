import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Link, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { UsersContext } from '../context';
import { AppContext } from 'views/App/context';
import useTranslations from 'hooks/useTranslations';
import useOrganization from 'data_sources/organizations/useOrganization';
import { Form } from 'components/_forms';
import { User } from 'types/user';
import { type ServerErrorResponse } from 'data_sources/constants';
import FormView from 'components/_layouts/FormView';

const NewUser: React.FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { currentOrganizationId } = useContext(AppContext);
  const { createUser, userSchema } = useContext(UsersContext);
  const [serverErrors, setServerErrors] = useState<ServerErrorResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const [ssoOrg, setSsoOrg] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<User>({
    values: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      alias: '',
      username: '',
      jobTitle: '',
      ssoLinkValue: '',
      organizationUserId: '',
      active: true,
    },
    resolver: yupResolver(userSchema),
  });
  const orgQuery = useOrganization(currentOrganizationId || '');

  React.useEffect(() => {
    if (orgQuery.data && orgQuery.data?.cognitoPool?.authenticationType === 'SSO') {
      setSsoOrg(true);
    } else {
      setSsoOrg(false);
    }
  }, [orgQuery.data]);

  const handleSave: SubmitHandler<User> = (values: User) => {
    setIsSaving(true);
    createUser(
      values,
      (guid: string) => navigate('..'),
      (errors: ServerErrorResponse) => {
        setIsSaving(false);
        setServerErrors(errors);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FormView
        title={t('users.new.title')}
        breadcrumbItems={[
          <Link
            key='b1'
            href='..'
          >
            {t('common.users')}
          </Link>,
          <Typography key='b2'>{t('users.new.title')}</Typography>,
        ]}
        actions={
          <>
            <Button
              color='neutral'
              href='..'
            >
              {t('common.cancel')}
            </Button>
            <Button
              onClick={() => {
                trigger();
              }}
              disabled={isSaving}
              component='button'
              type='submit'
              variant='contained'
            >
              {t('users.new.save')}
            </Button>
          </>
        }
        errors={serverErrors}
      >
        <Grid
          container
          columnSpacing={4}
          rowGap={6}
        >
          <Grid
            xs={12}
            sm={5}
          >
            <TextField
              id='firstName'
              key='firstName'
              label={t('users.properties.firstName')}
              variant='outlined'
              required
              autoFocus
              fullWidth
              {...register('firstName')}
              error={!!errors.firstName}
              helperText={errors.firstName ? errors.firstName.message : undefined}
            />
          </Grid>
          <Grid
            xs={12}
            sm={2}
          >
            <TextField
              id='middleName'
              key='middleName'
              label={t('users.properties.middleName')}
              variant='outlined'
              fullWidth
              {...register('middleName')}
              error={!!errors.middleName}
              helperText={errors.middleName ? errors.middleName.message : undefined}
            />
          </Grid>
          <Grid
            xs={12}
            sm={5}
          >
            <TextField
              id='lastName'
              key='lastName'
              label={t('users.properties.lastName')}
              variant='outlined'
              required
              fullWidth
              {...register('lastName')}
              error={!!errors.lastName}
              helperText={errors.lastName ? errors.lastName.message : undefined}
            />
          </Grid>
          <Grid
            xs={12}
            sm={ssoOrg ? 6 : 12}
          >
            <TextField
              id='email'
              key='email'
              label={t('users.properties.email')}
              variant='outlined'
              fullWidth
              required
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : undefined}
            />
          </Grid>
          {ssoOrg && (
            <Grid
              xs={12}
              sm={6}
            >
              <TextField
                id='ssoLinkValue'
                key='ssoLinkValue'
                label={t('users.properties.ssoLinkValue')}
                variant='outlined'
                fullWidth
                {...register('ssoLinkValue')}
                error={!!errors.ssoLinkValue}
                helperText={errors.ssoLinkValue ? errors.ssoLinkValue.message : undefined}
              />
            </Grid>
          )}
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              id='username'
              key='username'
              label={t('users.properties.username')}
              variant='outlined'
              fullWidth
              {...register('username')}
              error={!!errors.username}
              helperText={errors.username ? errors.username.message : t('users.properties.usernameHint')}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              id='organizationUserId'
              key='organizationUserId'
              label={t('users.properties.organizationUserId')}
              variant='outlined'
              fullWidth
              {...register('organizationUserId')}
              error={!!errors.organizationUserId}
              helperText={
                errors.organizationUserId
                  ? errors.organizationUserId.message
                  : t('users.properties.organizationUserIdHint')
              }
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              id='jobTitle'
              key='jobTitle'
              label={t('users.properties.jobTitle')}
              variant='outlined'
              fullWidth
              {...register('jobTitle')}
              error={!!errors.jobTitle}
              helperText={errors.jobTitle ? errors.jobTitle.message : undefined}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              id='alias'
              key='alias'
              label={t('users.properties.alias')}
              variant='outlined'
              fullWidth
              {...register('alias')}
              error={!!errors.alias}
              helperText={errors.alias ? errors.alias.message : undefined}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              id='phone'
              key='phone'
              label={t('users.properties.phone')}
              variant='outlined'
              fullWidth
              {...register('phone')}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone.message : undefined}
            />
          </Grid>
        </Grid>
      </FormView>
    </Form>
  );
};

export default NewUser;
