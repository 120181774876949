import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  ParagraphNode,
  RangeSelection,
  SerializedParagraphNode,
  isHTMLElement,
} from 'lexical';

export class PhxParagraphNode extends ParagraphNode {
  static getType(): string {
    return 'phx-paragraph';
  }

  static clone(node: PhxParagraphNode): PhxParagraphNode {
    return new PhxParagraphNode(node.__key);
  }

  // View

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('p');
    element.classList.add(`PhxEditorTheme__paragraph`);
    return element;
  }
  updateDOM(
    prevNode: PhxParagraphNode,
    dom: HTMLElement,
    config: EditorConfig
  ): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      p: (node: Node) => ({
        conversion: $convertParagraphElement,
        priority: 0,
      }),
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);
    if (element && isHTMLElement(element)) element.removeAttribute('dir');

    return {
      element,
    };
  }

  static importJSON(serializedNode: SerializedParagraphNode): PhxParagraphNode {
    const node = $createPhxParagraphNode();
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    node.setTextFormat(serializedNode.textFormat);
    return node;
  }

  exportJSON(): SerializedParagraphNode {
    return {
      ...super.exportJSON(),
      textFormat: this.getTextFormat(),
      type: 'phx-paragraph',
      version: 1,
    };
  }

  // Mutation

  insertNewAfter(
    rangeSelection: RangeSelection,
    restoreSelection: boolean
  ): PhxParagraphNode {
    const newElement = $createPhxParagraphNode();
    newElement.setTextFormat(rangeSelection.format);
    const direction = this.getDirection();
    newElement.setDirection(direction);
    newElement.setFormat(this.getFormatType());
    this.insertAfter(newElement, restoreSelection);
    return newElement;
  }
}

function $convertParagraphElement(element: HTMLElement): DOMConversionOutput {
  const node = $createPhxParagraphNode();
  if (element.style) {
    node.setFormat(element.style.textAlign as ElementFormatType);
  }
  return { node };
}

export function $createPhxParagraphNode(): PhxParagraphNode {
  return $applyNodeReplacement(new PhxParagraphNode());
}

export function $isPhxParagraphNode(
  node: LexicalNode | null | undefined
): node is PhxParagraphNode {
  return node instanceof PhxParagraphNode;
}
