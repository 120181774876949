import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  colors,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ContentCopy,
} from '@mui/icons-material';

export type PropertyListItem = {
  key?: string;
  label: React.ReactNode;
  value?: React.ReactNode;
  copyable?: boolean;
  copyValue?: string;
}

export type PropertyListProps = {
  items: PropertyListItem[];
  columns?: number;
  highlightNulls?: boolean;
}

function PropertyList({
  items,
  columns = 1,
  highlightNulls = false,
}: PropertyListProps) {
  let columnSize = 12;
  if (columns) columnSize /= columns;
  return (
    <Grid container spacing={4}>
      {items.map((item, ind) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={`item_${ind}`} className={`item-${item.key ?? ind.toString()}`} xs={12} sm={columnSize}>
          <Stack spacing={1}>
            <Typography component='div' sx={{
              fontSize: '0.65rem',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: (highlightNulls && !item.value) ? colors.red[600] : colors.grey[600]
            }}>{item.label}</Typography>
            <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
              <Typography
                component='div'
                sx={{
                  fontWeight: 'bold',
                  maxWidth: '80%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '& small': {
                    fontWeight: 'normal',
                    color: colors.grey[800],
                    ml: 2,
                  }
                }}
              >
                {item.value ?? '--'}
              </Typography>
              {item.copyable && item.copyValue && (
                <CopyToClipboard text={item.copyValue}>
                  <IconButton>
                    <ContentCopy />
                  </IconButton>
                </CopyToClipboard>
              )}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}

export default PropertyList;
