import { useInfiniteQuery } from 'react-query';

import { Group } from '../../types/group';
import { ApiCollectionResponse } from '../../types/api_response';
import { baseApiUrl, fetcher, infiniteEmptyResults } from '../constants';
import { ServerErrorResponse } from '../constants';

export default function useGroups<T extends Group>(
  organizationId?: string,
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/user-group/v1/orgs/${organizationId}/groups`;

  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['groups', organizationId, params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        url: pageParam || `${url}?${paramString}`,
        options: { signal }
      });

      return data;
    },
    {
      ...options,
      enabled: !!organizationId,
      keepPreviousData: true,
      placeholderData: infiniteEmptyResults,
      getNextPageParam: (lastPage) => {
        if (lastPage.offset && (lastPage.offset < (lastPage.count || 0)) && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            offset: lastPage.offset,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

          return `${url}?${paramString}`;
        }
      }
    }
  );
}