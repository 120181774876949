import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Link,
  Typography,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import ListView from 'components/_layouts/ListView';
import useArchivedManualVersions from 'data_sources/archivedManuals/useArchivedManualVersions';
import { ManualVersion } from 'types/manual';
import { ArchivedManualsContext } from '../context';
import datetimeHelper from 'helpers/datetimeHelper';

const columnHelper = createColumnHelper<ManualVersion>();

const ArchivedManualsToC: React.FC = () => {
  const t = useTranslations();
  const { id } = useParams();
  const { orgId } = useContext(ArchivedManualsContext);
  const manualQuery = useArchivedManualVersions(orgId, id || '');

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>
          {info.getValue()}
        </Typography>
      ),
      header: () => t('policy.manuals.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('version', {
      cell: (info) => (
        <Typography>{info.getValue()}</Typography>
      ),
      header: () => t('policy.manuals.properties.version'),
      size: 200,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => (
        <Typography>{datetimeHelper.formatDate(info.getValue())}</Typography>
      ),
      header: () => t('policy.manuals.properties.archivedAt'),
      size: 200,
    }),
  ];

  const versions = useMemo(() => manualQuery.data && manualQuery.data.pages ?
    manualQuery.data.pages.flatMap((x) => x.content) : []
    , [manualQuery.data]);

  return (
    <ListView
      columns={columns}
      data={versions}
      query={manualQuery}
      title={versions[0]?.name || 'Manual Versions'}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('policy.archivedManuals.title')}
        </Link>,
        <Typography key='b2'>{versions[0]?.name || 'Manual Versions'}</Typography>
      ]}
    />
  );
}

export default ArchivedManualsToC;
