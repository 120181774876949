export enum JobStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  ABORTED = 'ABORTED',
}

export enum UserImportCounts {
  INSERTED = 'INSERTED',
  FAIL = 'FAIL',
  UPDATED = 'UPDATED',
  INACTIVE = 'INACTIVE',
}

export enum JobType {
  ImportUsers = 'ImportUsers',
}