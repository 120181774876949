import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Button,
  Link,
  Typography,
  colors,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import parse from 'html-react-parser';
import { useQueryClient } from 'react-query';

import useTranslations from 'hooks/useTranslations';
import ListView from 'components/_layouts/ListView';
import useLegacyArchivedManualVersions from 'data_sources/legacyArchivedManuals/useLegacyArchivedManualVersions';
import { ManualVersion } from 'types/manual';
import useLegacyArchivedManualVersion from 'data_sources/legacyArchivedManuals/useLegacyArchivedManualVersion';
import { LegacyArchivedManualsContext } from '../context';
import datetimeHelper from 'helpers/datetimeHelper';

const columnHelper = createColumnHelper<ManualVersion>();

const ArchivedManualsToC: React.FC = () => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { orgId } = useContext(LegacyArchivedManualsContext);
  const manualQuery = useLegacyArchivedManualVersions(orgId, id || '');
  const [retrievingIds, setRetrievingIds] = React.useState<string[]>([]);
  const { retrieve: retrieveManual } = useLegacyArchivedManualVersion();

  const handleRetrieveClick = useCallback((manual: ManualVersion) => {
    setRetrievingIds([...retrievingIds, manual.version.toString()]);
    retrieveManual(manual.id, manual.version.toString(), () => {
      enqueueSnackbar(parse(t(`policy.legacyArchivedManuals.retrievalConfirmation`, '', {
        title: manual.name,
        versionDate: datetimeHelper.formatDate(manual.createdAt)
      })),
        {
          variant: 'success',
          autoHideDuration: 6000
        });
      window.setTimeout(() => {
        queryClient.invalidateQueries('notifications_unread_count');
      }, 15000);
    });
  }, [queryClient, retrieveManual, retrievingIds, t]);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Typography variant='h6' sx={{
          fontWeight: 'semibold',
          '& i': {
            color: colors.grey[500]
          }
        }}>
          {(info.getValue() ?? '').length > 0 ? info.getValue() : <i>{t('policy.legacyArchivedManuals.missingTitle')}</i>}
        </Typography>
      ),
      header: () => t('policy.manuals.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('version', {
      cell: (info) => (
        <Typography>{info.getValue()}</Typography>
      ),
      header: () => t('policy.manuals.properties.version'),
      size: 200,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => (
        <Typography>{datetimeHelper.formatDate(info.getValue())}</Typography>
      ),
      header: () => t('policy.manuals.properties.archivedAt'),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <Button
          size='small'
          variant='outlined'
          disabled={retrievingIds.includes(info.row.original.version.toString())}
          onClick={() => handleRetrieveClick(info.row.original)}
        >
          {retrievingIds.includes(info.row.original.version.toString()) ? t('policy.legacyArchivedManuals.retrievingArchive') : t('policy.legacyArchivedManuals.retrieveArchive')}
        </Button>
      ),
      header: () => '',
      size: 180,
    }),
  ];

  const versions = useMemo(() => manualQuery.data && manualQuery.data.pages ?
    manualQuery.data.pages.flatMap((x) => x.content) : []
    , [manualQuery.data]);

  return (
    <ListView
      columns={columns}
      data={versions}
      query={manualQuery}
      title={versions[0]?.name || 'Manual Versions'}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('policy.legacyArchivedManuals.title')}
        </Link>,
        <Typography key='b2'>{versions[0]?.name || 'Manual Versions'}</Typography>
      ]}
    />
  );
}

export default ArchivedManualsToC;
