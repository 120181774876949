import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { Badge, Box, Button, CircularProgress, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
// import { DraftChapter } from 'types/manual';
import manualHelper from '../helpers/draftManualHelper';
import { DraftManualDetailContext } from '../context';
import useManageManual from 'data_sources/draftManuals/useManageManual';
import { ContentPage, ContentPageContainer, TitleTextField } from '../styles';
import { AppContext } from 'views/App/context';
import { ServerErrorResponse } from 'data_sources/constants';
import errorHelper from 'helpers/errorHelper';
import { DraftManual } from 'types/manual';

type ChapterEditorProps = {
  id: string;
};

/***********
 * VIEW - Not locked, no edit permission
 * DISABLED - chapter is currently disabled
 * EDITABLE - Not locked, has edit permission
 * EDITING - locked by current user
 * LOCKED - locked by another user
 ******/
type ManualMode = 'VIEW' | 'DISABLED' | 'EDITABLE' | 'EDITING' | 'LOCKED';

const ChapterEditor: React.FC<ChapterEditorProps> = ({ id }: ChapterEditorProps) => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const { currentProfile } = useContext(AppContext);
  const { manual, manualHasPermissions } = useContext(DraftManualDetailContext);
  const chapter = manual.chapters.find((c) => c.id === id);
  const { getLock, removeLock, update: updateManual } = useManageManual();
  const [chapterTitle, setChapterTitle] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [gettingLock, setGettingLock] = useState<boolean>(false);
  const [manualMode, setManualMode] = useState<ManualMode>('VIEW');

  useEffect(() => {
    let mode: ManualMode = 'VIEW';
    if (manualHasPermissions(manual.id, ['updateDraftManual'])) {
      if (manual.lock) {
        if (manual.lock.userId === currentProfile?.user?.id) {
          mode = 'EDITING';
        } else {
          mode = 'LOCKED';
        }
      } else {
        mode = 'EDITABLE';
      }
      if (chapter?.state === 'DISABLED') {
        mode = 'DISABLED';
      }
      if (manualMode === 'EDITING' && mode === 'DISABLED') {
        enqueueSnackbar(
          <div
            dangerouslySetInnerHTML={{
              __html: t('draftManuals.chapter.savedButDisabled'),
            }}
          />,
          {
            variant: 'error',
            autoHideDuration: 10000,
          }
        );
      }
    }

    setManualMode(mode);
  }, [manualHasPermissions, manual.id, manual.lock, currentProfile?.user?.id]);

  const handleModifyClick = () => {
    setGettingLock(true);
    getLock(
      manual.id,
      (lock) => {
        queryClient.invalidateQueries(['draft_manual', manual.id]);
        queryClient.invalidateQueries(['draft_preface', manual.id]);
        setGettingLock(false);
      },
      (error: ServerErrorResponse) => {
        errorHelper.enqueueErrors(error);
        setGettingLock(false);
      }
    );
  };

  useEffect(() => {
    if (chapter) {
      setChapterTitle(chapter.title || '');
    }
  }, [chapter]);

  const handleSave = async () => {
    setIsSaving(true);
    const updatedManual: DraftManual = JSON.parse(JSON.stringify(manual));
    updatedManual.chapters.forEach((ch) => {
      if (ch.id === id) {
        ch.title = chapterTitle;
      }
    });
    updateManual(
      manual.id,
      updatedManual,
      () => {
        removeLock(
          manual.id,
          () => {
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            setIsSaving(false);
          },
          (error: ServerErrorResponse) => {
            errorHelper.enqueueErrors(error);
            setIsSaving(false);
          }
        );
      },
      (errors) => {
        console.log(errors);
        errorHelper.enqueueErrors(errors);
        setIsSaving(false);
      }
    );
  };

  const handleTitleChange = (title: string) => {
    setChapterTitle(title);
  };

  const renderChapterActions = useCallback(() => {
    let actions: React.ReactNode = null;

    if (manual && manualMode === 'EDITABLE') {
      actions = (
        <Button
          disabled={gettingLock}
          onClick={handleModifyClick}
        >
          {t('common.modify')}
        </Button>
      );
    }
    if (manual && manualMode === 'EDITING') {
      actions = (
        <Button
          onClick={handleSave}
          disabled={isSaving}
        >
          {t('common.save')}
        </Button>
      );
    }
    if (manual && manualMode === 'LOCKED') {
      actions = (
        <Stack
          direction='row'
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {manual.lock && (
            <Tooltip title={manual.lock?.username}>
              <LockIcon color='neutral' />
            </Tooltip>
          )}
          <Button
            onClick={() => {}}
            disabled
          >
            {t('common.modify')}
          </Button>
        </Stack>
      );
    }
    if (manualMode === 'DISABLED') {
      actions = (
        <Badge
          variant='inline'
          badgeContent={t('common.disabled')}
          color='warning'
          sx={{ textTransform: 'uppercase' }}
        />
      );
    }

    return actions;
  }, [id, manual, manualMode, chapterTitle]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <Stack>
        <Toolbar className='phx-panel-header'>
          <Stack
            direction='row'
            spacing={4}
            sx={{ flexGrow: 1 }}
          >
            {' '}
          </Stack>
          <Stack
            direction='row'
            spacing={4}
          >
            {(gettingLock || isSaving) && <CircularProgress />}
            <Stack
              direction='row'
              spacing={4}
              sx={{ ml: 2 }}
            >
              {renderChapterActions()}
            </Stack>
          </Stack>
        </Toolbar>
        <ContentPageContainer>
          <ContentPage>
            <Stack spacing={4}>
              <>
                {manualMode === 'EDITING' ? (
                  <Box>
                    <Stack spacing={3}>
                      <TitleTextField
                        id={`title_${id}`}
                        key={`title_${id}`}
                        label={false}
                        variant='outlined'
                        fullWidth
                        value={chapterTitle}
                        onChange={(e) => handleTitleChange(e.target.value)}
                      />
                    </Stack>
                  </Box>
                ) : (
                  <Box>
                    <Stack spacing={1}>
                      <Typography
                        component='div'
                        variant='h5'
                      >
                        {manualHelper.formatManualNodeTitle(chapter, manual)}
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </>
            </Stack>
          </ContentPage>
        </ContentPageContainer>
      </Stack>
    </Box>
  );
};

export default ChapterEditor;
