import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import { GroupsContext } from "../context";
import useTranslations from "hooks/useTranslations";
import { Form } from "components/_forms";
import { Group } from "types/group";
import { type ServerErrorResponse } from "data_sources/constants";
import FormView from "components/_layouts/FormView";

const NewGroup: React.FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { createGroup, groupSchema } = useContext(GroupsContext);
  const [serverErrors, setServerErrors] = useState<ServerErrorResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<Group>({
    values: {
      name: '',
      description: '',
    },
    resolver: yupResolver(groupSchema),
  });

  const handleSave: SubmitHandler<Group> = (values: Group) => {
    setIsSaving(true);
    createGroup(
      values,
      (guid: string) => navigate('..'),
      (errors: ServerErrorResponse) => {
        setIsSaving(false);
        setServerErrors(errors);
      }
    );
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FormView
        title={t('groups.new.title')}
        breadcrumbItems={[
          <Link key='b1' href='..'>
            {t('groups.title')}
          </Link>,
          <Typography key='b2'>
            {t('groups.new.title')}
          </Typography>
        ]}
        actions={(
          <>
            <Button color='neutral' href='..'>{t('common.cancel')}</Button>
            <Button
              onClick={() => {
                trigger();
              }}
              disabled={isSaving}
              component='button'
              type="submit"
              variant="contained"
            >
              {t('groups.new.save')}
            </Button>
          </>
        )}
        errors={serverErrors}
      >
        <Grid container columnSpacing={4} rowGap={6}>
          <Grid xs={12} sm={12}>
            <TextField
              id='name'
              key='name'
              label={t('groups.properties.name')}
              variant="outlined"
              required autoFocus
              fullWidth
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : undefined}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <TextField
              id='description'
              key='description'
              label={t('groups.properties.description')}
              variant="outlined"
              fullWidth
              multiline
              {...register('description')}
              error={!!errors.description}
              helperText={errors.description ? errors.description.message : undefined}
            />
          </Grid>
        </Grid>
      </FormView>
    </Form>
  );
}


export default NewGroup;