import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import useStickyState from '../../hooks/useStickyState';
import { useCookies } from 'react-cookie';
// import useTranslations from "hooks/useTranslations";
import { getApplicationPermissions } from 'data_sources/usePermissions';
import { getProfile } from 'data_sources/useProfile';
import { ProfileResponse } from 'types/profile_response';
import AuthUtil from 'utils/AuthUtil';

type AppProviderProps = {
  userOrgId: string | undefined;
  children: React.ReactNode;
};

export type OrganizationPermissions = { [key: string]: string[] };

export type AppProviderResponse = {
  currentModule?: string;
  currentProfile: ProfileResponse | null;
  currentOrganizationId: string;
  leftNavCollapsed: boolean;
  isTakeover: boolean;
  appPermissions: string[] | null;
  orgPermissions: OrganizationPermissions | null;
  hasAppPermission: (key: string) => boolean;
  hasNoPermission: () => boolean;
  hasPermission: (key: string, orgId: string) => boolean;
  enforcePermissions: (keys: string[], viewComponent: any) => any;
  enforceAppPermissions: (keys: string[], viewComponent: any) => any;
  isMobile: boolean;
  isRoot: boolean;
  showNav: boolean;
  setCurrentModule: (newState: string) => void;
  setMainNavCollapsed: (newState: boolean) => void;
  setShowNav: (newState: boolean) => void;
  setIsTakeover: (newState: boolean) => void;
  updatePermissions: (orgId: string, orgPermissions: string[]) => void;
  urlSegments: () => string[];
  setUserOrganizationId: (newState: string) => void;
  userOrganizationId: string | undefined;
};

export const AppContext = React.createContext<AppProviderResponse>(
  {} as AppProviderResponse
);

const AppProvider: ({ userOrgId, children }: AppProviderProps) => any = ({
  userOrgId,
  children,
}) => {
  const currLoc = useLocation();
  const idToken = localStorage.getItem('phx-idToken')?.toString();
  const urlSegments = useCallback(() => {
    return currLoc.pathname.slice(1).split('/');
  }, [currLoc.pathname]);
  const navigate = useNavigate();
  // const t = useTranslations();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [currentModule, setCurrentModule] = useState<string>();
  const [showNav, setShowNav] = useState<boolean>(false);
  const [isTakeover, setIsTakeover] = useState<boolean>(false);
  const [leftNavCollapsed, setMainNavCollapsed] = useStickyState<boolean>(
    'left-nav-collapsed',
    false
  );
  const [userOrganizationId, setUserOrganizationId] = useStickyState<
    string | undefined
  >('phx-userOrgId', userOrgId);
  const [isRoot, setIsRoot] = useStickyState<boolean>('phx-isRoot', false);

  const [cookies, setCookie] = useCookies([
    `app_permissions_${idToken}`,
    `profile_permissions_${idToken}`,
  ]);
  const [currentProfile, setCurrentProfile] =
    useStickyState<ProfileResponse | null>('phx-currentProfile', null);
  const [orgPermissions, setOrgPermissions] =
    useStickyState<OrganizationPermissions | null>('phx-orgPermissions', null);
  const [appPermissions, setAppPermissions] = useStickyState<string[] | null>(
    'phx-appPermissions',
    null
  );

  const updatePermissions = useCallback(
    (orgId: string, orgPermissions: string[]) => {
      setOrgPermissions((prev: OrganizationPermissions | null) => ({
        ...prev,
        [orgId]: orgPermissions.sort(),
      }));
    },
    [setOrgPermissions]
  );

  useEffect(() => {
    if (
      currLoc &&
      currLoc.pathname !== '/login' &&
      currLoc.pathname !== '/portal/dashboard'
    ) {
      localStorage.setItem('phx-lastUrl', JSON.stringify(currLoc));
    }
  }, [currLoc]);

  useEffect(() => {
    const updateProfileInfo = () => {
      if (idToken) {
        if (AuthUtil.isRoot(idToken)) {
          setIsRoot(true);
        }
        if (
          cookies[`app_permissions_${idToken}`] &&
          // eslint-disable-next-line no-self-compare
          (appPermissions?.length ?? 0) > 0
        ) {
          // app permissions already set
        } else {
          getApplicationPermissions()
            .then((permissionsResult) => {
              // console.log('appPermissions', permissionsResult.map((x) => x.action))
              setCookie(
                `app_permissions_${idToken}`,
                JSON.stringify(permissionsResult.map((x) => x.action)),
                { expires: new Date(new Date().getTime() + 30 * 60 * 1000) }
              );
              setAppPermissions(permissionsResult.map((x) => x.action).sort());
            })
            .catch((err) => {
              // something went wrong
              console.log('err', err);
            });
        }
        if (isRoot) {
          const rootProfile: ProfileResponse = {
            user: {
              username: 'root',
              firstName: 'Root',
              lastName: 'User',
              email: '',
              orgId: 'LexipolGenesisRoot',
            },
            organization: {
              id: 'LexipolGenesisRoot',
              name: 'Lexipol',
              city: '',
              state: '',
              country: '',
            },
          };
          localStorage.setItem(
            'phx-currentProfile',
            JSON.stringify(rootProfile)
          );
          setCurrentProfile(rootProfile);
        } else if (cookies[`profile_permissions_${idToken}`]) {
          // profile permissions already set
        } else {
          getProfile().then((profile: ProfileResponse) => {
            if (profile) {
              setCurrentProfile(profile);
              if (profile?.authorizations && profile?.organization) {
                setCookie(
                  `profile_permissions_${idToken}`,
                  JSON.stringify(new Date().toISOString()),
                  { expires: new Date(new Date().getTime() + 30 * 60 * 1000) }
                );
                updatePermissions(
                  profile.organization.id,
                  (profile.authorizations || []).map((x) => x.action)
                );
              }
            }
          });
        }
      }
    };
    window.addEventListener('id-token-changed', updateProfileInfo);
    updateProfileInfo();
    return () => {
      window.removeEventListener('id-token-changed', updateProfileInfo);
    };
  }, [
    appPermissions?.length,
    cookies,
    idToken,
    isRoot,
    navigate,
    setAppPermissions,
    setCookie,
    setCurrentProfile,
    setIsRoot,
    updatePermissions,
    userOrganizationId,
  ]);

  const hasPermission: (key: string, orgId: string) => boolean = useCallback(
    (key, orgId) => {
      if (orgPermissions && orgPermissions[orgId]) {
        return orgPermissions[orgId].includes(key);
      } else {
        const orgPerms: OrganizationPermissions = JSON.parse(
          localStorage.getItem('phx-orgPermissions') || '{}'
        );
        if (orgPerms && orgPerms[orgId]) {
          return orgPerms[orgId].includes(key);
        }
      }
      return false;
    },
    [orgPermissions]
  );

  const hasAppPermission: (key: string) => boolean = useCallback(
    (key) => {
      // console.log('userPermissions', userPermissions)
      if (appPermissions) {
        return appPermissions.includes(key);
      }
      return false;
    },
    [appPermissions]
  );

  const hasNoPermission: () => boolean = useCallback(() => {
    let result = false;
    const orgPermissionsCount =
      orgPermissions && userOrgId && orgPermissions[userOrgId]
        ? orgPermissions[userOrgId].length
        : 0;
    const appPermissionsCount = appPermissions ? appPermissions.length : 0;
    if (currentProfile && orgPermissionsCount + appPermissionsCount === 0) {
      result = true;
    }
    return result;
  }, [appPermissions, currentProfile, orgPermissions, userOrgId]);

  useEffect(() => {
    if (
      localStorage.getItem('phx-currentOrganizationId') === null ||
      localStorage.getItem('phx-currentOrganizationId') === ''
    ) {
      localStorage.setItem(
        'phx-currentOrganizationId',
        userOrganizationId || ''
      );
    }
  }, [userOrganizationId]);

  const currentOrganizationId =
    localStorage.getItem('phx-currentOrganizationId')?.toString() ||
    userOrganizationId;

  const enforcePermissions: (keys: string[], viewComponent: any) => any =
    useCallback(
      (keys, viewComponent) => {
        if (keys.every((k) => hasPermission(k, currentOrganizationId || ''))) {
          return viewComponent;
        }
        // console.log('enforcePermissions', keys, currentOrganizationId, JSON.stringify((orgPermissions ?? {})[currentOrganizationId ?? '']));
        return <Navigate to='/portal/dashboard' />;
      },
      [currentOrganizationId, hasPermission]
    );

  const enforceAppPermissions: (keys: string[], viewComponent: any) => any =
    useCallback(
      (keys, viewComponent) => {
        if (keys.every((k) => hasAppPermission(k))) {
          return viewComponent;
        }
        return <Navigate to='/portal/dashboard' />;
      },
      [hasAppPermission]
    );

  const contextValue = useMemo(() => {
    return {
      appPermissions,
      orgPermissions,
      currentModule,
      currentOrganizationId: currentOrganizationId || '',
      currentProfile,
      isMobile,
      isRoot,
      isTakeover,
      urlSegments,
      leftNavCollapsed,
      enforcePermissions,
      enforceAppPermissions,
      hasAppPermission,
      hasNoPermission,
      hasPermission,
      setCurrentModule,
      showNav,
      setIsTakeover,
      setShowNav,
      setMainNavCollapsed,
      setUserOrganizationId,
      updatePermissions,
      userOrganizationId,
    };
  }, [
    appPermissions,
    orgPermissions,
    currentModule,
    currentOrganizationId,
    currentProfile,
    isMobile,
    isRoot,
    isTakeover,
    urlSegments,
    leftNavCollapsed,
    enforcePermissions,
    enforceAppPermissions,
    hasAppPermission,
    hasNoPermission,
    hasPermission,
    showNav,
    setMainNavCollapsed,
    setUserOrganizationId,
    updatePermissions,
    userOrganizationId,
  ]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
