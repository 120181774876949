import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';

import {
  DraftPolicySection,
  TreeItems,
  DraftPolicy,
  FlattenedItem,
  DraftManual,
  DraftPolicySubSection,
} from 'types/manual';

import { SortableTree } from './sortableTree';

interface ModifyPolicyStructureProps {
  onClose: () => void;
  sections: any; // Updated type
  policyData: DraftPolicy;
  manual: DraftManual;
  updatePolicyData: (updatedData: Partial<DraftPolicy>) => void;
  onChange: (updatedSection: DraftPolicySection | DraftPolicySubSection) => void;
}

const ModifyPolicyStructure: React.FC<ModifyPolicyStructureProps> = ({
  onClose,
  sections,
  updatePolicyData,
  policyData,
  manual,
  onChange,
}) => {
  const transformSectionsToTreeItems = (sections: DraftPolicySection[]): TreeItems => {
    return sections.map((section) => ({
      id: section.id,
      title: section.title,
      content: section.content,
      state: section.state,
      agencyModified: section.agencyModified,
      editLevelTag: section.editLevelTag,
      parentId: section.parentId ?? null,
      depth: 0,
      sectionNumber: '',
      isSubSection: false,
      collapsed: false,
      subSections: [],
      children: (section.subSections ?? []).map((sub) => ({
        id: sub.id,
        title: sub.title,
        content: sub.content,
        state: sub.state,
        agencyModified: sub.agencyModified,
        editLevelTag: sub.editLevelTag,
        parentId: section.id,
        depth: 1,
        sectionNumber: '',
        isSubSection: true,
        subSections: [],
        collapsed: false,
        children: [],
      })),
    }));
  };

  const transformTreeItemsToSections = (tree: TreeItems): DraftPolicySection[] => {
    return tree.map((node) => ({
      id: node.id,
      title: node.title,
      content: node.content,
      state: node.state,
      agencyModified: node.agencyModified,
      editLevelTag: node.editLevelTag,
      parentId: node.parentId ?? '',
      depth: node.depth,
      sectionNumber: node.sectionNumber,
      isSubSection: node.isSubSection,
      collapsed: node.collapsed,
      subSections: node.children.map((child) => ({
        id: child.id,
        title: child.title,
        content: child.content,
        state: child.state,
        agencyModified: child.agencyModified,
        editLevelTag: child.editLevelTag,
        parentId: child.parentId ?? '',
        depth: child.depth,
        sectionNumber: child.sectionNumber,
        isSubSection: child.isSubSection,
        collapsed: child.collapsed,
        subSections: [],
      })),
    }));
  };

  const [treeItems, setTreeItems] = useState<TreeItems>(transformSectionsToTreeItems(sections));

  useEffect(() => {
    setTreeItems(transformSectionsToTreeItems(sections));
  }, [sections]);

  const handleTreeChange = (updatedTree: TreeItems) => {
    const updatedSections = transformTreeItemsToSections(updatedTree);
    updatePolicyData({ sections: updatedSections });
    setTreeItems(transformSectionsToTreeItems(updatedSections));
  };

  const handleSectionChange = useCallback(
    (updatedSection: FlattenedItem) => {
      const section: DraftPolicySection | DraftPolicySubSection = updatedSection.isSubSection
        ? ({
            ...updatedSection,
          } as DraftPolicySubSection)
        : ({
            ...updatedSection,
          } as DraftPolicySection);

      onChange(section);
    },
    [onChange]
  );

  return (
    <Box>
      <Box>
        <SortableTree
          collapsible
          indicator
          removable
          items={treeItems}
          onChange={handleTreeChange}
          onSectionChange={handleSectionChange}
          manual={manual}
          policyData={policyData}
        />
      </Box>
    </Box>
  );
};

export default ModifyPolicyStructure;
