import React, { useEffect } from 'react';
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  MailOutlined as UnreadIcon,
  DraftsOutlined as ReadIcon,
} from '@mui/icons-material';
import { useQueryClient } from 'react-query';

import { Notification } from 'types/notifications';
import notificationsHelper from '../notificationsHelper';
import useCookieState from 'hooks/useCookieState';
import useManageNotification from 'data_sources/notifications/useManageNotification';


export type NotificationItemProps = {
  notification: Notification;
  toggleRead: (notification: Notification) => void;
};

export default function NotificationDetail({
  notification,
  toggleRead,
}: NotificationItemProps) {
  const { updateState } = useManageNotification();
  const queryClient = useQueryClient();
  const [readIds, setReadIds] = useCookieState<string[]>('readNotifications', []);

  useEffect(() => {
    const markRead = (notification: Notification) => {
      updateState(
        notification.id,
        'read',
        () => {
          queryClient.invalidateQueries('notifications_unread_count');
          window.setTimeout(() => {
            queryClient.invalidateQueries('notifications');
          }, 5000);
        }
      )
    };
    // console.log('mark read', readIds.includes(notification.id), readIds, notification.id)
    if (notification.state === 'unread' && !readIds.includes(notification.id)) {
      setReadIds((prev) => [...prev, notification.id]);
      // console.log('marking read', notification.id)
      markRead(notification)
    }
  }, [notification, queryClient, readIds, setReadIds, updateState]);

  return (
    <Box
      sx={{
        px: 5,
        py: 4,
        backgroundColor: "#FFF",
      }}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start'
          }}
        >
          <Typography
            variant='h5'
            sx={{
              minHeight: 30,
            }}
          >
            {notificationsHelper.getSubject(notification)}
          </Typography>
          <IconButton size='small' onClick={(e) => {
            e.stopPropagation();
            toggleRead(notification);
          }}>
            {notification.state === 'unread' ? <UnreadIcon fontSize='small' /> : <ReadIcon fontSize='small' />}
          </IconButton>
        </Box>
        {notificationsHelper.getMessageType(notification) && <Typography variant='overline' sx={{ lineHeight: '1em' }}>{notificationsHelper.getMessageType(notification)}</Typography>}
        <Typography variant='body1'>{notificationsHelper.getBody(notification)}</Typography>
        {notificationsHelper.getTimeStamp(notification) && <Typography variant='caption'>{notificationsHelper.getTimeStamp(notification)}</Typography>}
      </Stack>

    </Box>
  );
};
