import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageUserRole() {
  const addRole = async (organizationId: string, userId: string, roleId: string, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-user/v1/org/${organizationId}/user/${userId}/roles/${roleId}`;
    await fetcher({
      method: 'post',
      url,
      body: {},
    }).then((data) => {
      if (callback) callback(data.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const removeRole = async (organizationId: string, userId: string, roleId: string, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${organizationId}/users/${userId}/roles/${roleId}`;
    await fetcher({
      method: 'delete',
      url,
      body: {},
    }).then(() => {
      if (callback) callback(roleId);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    addRole,
    removeRole,
  }
}