import type { Spread } from 'lexical';

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical';

export type SerializedTextEntityNode = Spread<
  {
    textEntityName: string;
    type: 'textEntity';
    data: Record<string, any>;
    version: 1;
  },
  SerializedTextNode
>;

function convertTextEntityElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createTextEntityNode(textContent);
    if (domNode.hasAttribute('data-textEntity-data')) {
      try {
        node.__data = JSON.parse(domNode.getAttribute('data-textEntity-data') ?? '{}');
      } catch { }
    }
    return {
      node,
    };
  }

  return null;
}

export class TextEntityNode extends TextNode {
  __textEntity: string;
  __data?: Record<string, any>;

  static getType(): string {
    return 'textEntity';
  }

  static clone(node: TextEntityNode): TextEntityNode {
    return new TextEntityNode(node.__textEntity, node.__text, node.__key, node.__data);
  }
  static importJSON(serializedNode: SerializedTextEntityNode): TextEntityNode {
    const node = $createTextEntityNode(serializedNode.textEntityName);
    node.setTextContent(serializedNode.text);
    node.__data = serializedNode.data;
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(textEntityName: string, text?: string, key?: NodeKey, data?: Record<string, any>) {
    super(text ?? textEntityName, key);
    this.__textEntity = textEntityName;
    this.__data = data;
  }

  exportJSON(): SerializedTextEntityNode {
    return {
      ...super.exportJSON(),
      textEntityName: this.__textEntity,
      type: 'textEntity',
      data: this.__data ?? {},
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = 'text-entity';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('class', 'phx-text-entity');
    element.setAttribute('data-textEntity-data', JSON.stringify(this.__data));
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('phx-text-entity')) {
          return null;
        }
        return {
          conversion: convertTextEntityElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }
}

export function $createTextEntityNode(textEntityName: string, data?: Record<string, any>): TextEntityNode {
  const textEntityNode = new TextEntityNode(textEntityName);
  textEntityNode.setMode('segmented').toggleDirectionless();
  textEntityNode.__data = data;
  return textEntityNode;
}

export function $isTextEntityNode(
  node: LexicalNode | null | undefined,
): node is TextEntityNode {
  return node instanceof TextEntityNode;
}