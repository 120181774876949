import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  INDENT_CONTENT_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';
import {
  FormatAlignCenterOutlined,
  FormatAlignRightOutlined,
  FormatAlignJustifyOutlined,
  FormatAlignLeftOutlined,
} from '@mui/icons-material';

type ElementFormatMenuProps = {
  value: ElementFormatType;
  editor: LexicalEditor;
  disabled?: boolean;
};

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, ''>]: {
    icon: any;
    name: string;
  };
} = {
  left: {
    icon: <FormatAlignLeftOutlined />,
    name: 'Left Align',
  },
  center: {
    icon: <FormatAlignCenterOutlined />,
    name: 'Center Align',
  },
  right: {
    icon: <FormatAlignRightOutlined />,
    name: 'Right Align',
  },
  justify: {
    icon: <FormatAlignJustifyOutlined />,
    name: 'Justify Align',
  },
  start: {
    icon: <FormatAlignLeftOutlined />,
    name: 'Start Align',
  },
  end: {
    icon: <FormatAlignRightOutlined />,
    name: 'End Align',
  },
};

export default function ElementFormatMenu({
  editor,
  value,
  disabled = false,
}: ElementFormatMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatOption = ELEMENT_FORMAT_OPTIONS[value || 'left'];

  return (
    <Box>
      <Button
        id="element_format_menu_button"
        aria-controls={open ? 'element_format_menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        size='small'
        color='neutral'
        disabled={disabled}
      >
        {formatOption.name}
      </Button>
      <Menu
        id="element_format_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

      >
        {Object.entries(ELEMENT_FORMAT_OPTIONS).map(([k, v]) => (
          <MenuItem
            key={k}
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, k.toString() as ElementFormatType);
            }}
          >
            <Typography>{v.name}</Typography>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          key='outdent'
          onClick={() => {
            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
          }}
        >
          <Typography>Outdent</Typography>
        </MenuItem>
        <MenuItem
          key='indent'
          onClick={() => {
            editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
          }}
        >
          <Typography>Indent</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
