import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
  colors,
} from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
// import useAuthoringManual from './data_sources/useAuthoringManual';
// import useAuthoringManualPolicy from './data_sources/useAuthoringManualPolicy';
// import { ManualLevels } from './data_sources/enum';
import { Chapter, Manual, Preface } from './types/manual';
import manualHelper from './helpers/manualHelper';

import PrefaceEditor from './PrefaceEditor';
import ChapterEditor from './ChapterEditor';
import PolicyEditor from './PolicyEditor';
import Loading from 'views/App/Portal/Loading';
import { Policy } from './types/policy';
import { set } from 'lodash';

export default function Authoring() {
  const navigate = useNavigate();
  const [manual, setManual] = useState<Manual | null>(null);
  const [action, setAction] = useState<string>('Actions');
  const { node } = useParams();
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set<string>());

  const expandNodes = (ids: string[]) => {
    if (!ids.every((x) => expandedItems.has(x))) {
      setExpandedItems((prev) => {
        ids.forEach((x) => prev.add(x));
        return prev;
      });
    }
  };
  const collapseNodes = (ids: string[]) => {
    if (ids.some((x) => expandedItems.has(x))) {
      setExpandedItems((prev) => {
        ids.forEach((x) => prev.delete(x));
        return prev;
      });
    }
  };

  const toggleNode = (id: string) => {
    if (expandedItems.has(id)) {
      collapseNodes([id]);
    } else {
      expandNodes([id]);
    }
  };

  useEffect(() => {
    manualHelper.loadStoredManual().then((manualData) => {
      if (!!manualData) setManual(manualData as Manual);
    });
  }, []);

  useEffect(() => {
    if (action === 'Manage Structure') {
      navigate('/prototypes/authoring/manual-structure');
      setAction('Actions');
    }
  }, [action]);

  useEffect(() => {
    if (manual) manualHelper.saveManual(manual);
  }, [manual]);

  const selectedItem = (node?: string) => {
    if (!node) return null;

    const [type, id] = node.split(':');
    return id;
  };

  const handlePrefaceChanged = useCallback(
    (updatedPreface: Preface) => {
      if (manual) {
        setManual((prev) => {
          const updatedManual: Manual = JSON.parse(JSON.stringify(prev));
          updatedManual.prefaces.forEach((p) => {
            if (p.id === updatedPreface.id) {
              p.title = updatedPreface.title;
              p.content = updatedPreface.content;
            }
          });
          return updatedManual;
        });
      }
    },
    [manual]
  );

  const handleChapterOnChange = useCallback((id: string, updatedChapter: Chapter) => {
    if (manual) {
      setManual((prev) => {
        const updatedManual: Manual = JSON.parse(JSON.stringify(prev));
        updatedManual.chapters.forEach((c) => {
          if (c.id === id) {
            c.title = updatedChapter.title;
          }
        });
        return updatedManual;
      });
    }
  }, []);

  const handleSaveChapter = useCallback((id: string, updatedChapter: Chapter) => {
    manualHelper.saveChapter(id, updatedChapter);
  }, []);

  const handlePolicyOnChange = useCallback((id: string, updatedPolicy: Policy) => {
    if (manual) {
      setManual((prev) => {
        const updatedManual: Manual = JSON.parse(JSON.stringify(prev));
        updatedManual.chapters.forEach((c) => {
          c.policies.forEach((p) => {
            if (p.id === id) {
              p.title = updatedPolicy.title;
            }
          });
        });
        return updatedManual;
      });
    }
  }, []);

  const handleSavePolicy = useCallback((updatedPolicy: Policy) => {
    manualHelper.savePolicy(updatedPolicy);
  }, []);

  const renderNode = (node?: string) => {
    if (!node) return null;

    const [type, id] = node.split(':');
    switch (type) {
      case 'preface':
        return (
          <PrefaceEditor
            id={id}
            onChange={handlePrefaceChanged}
            onSave={(updatedPreface) => {
              manualHelper.savePreface(id, updatedPreface);
            }}
          />
        );
      case 'chapter':
        expandNodes([id]);
        return (
          <ChapterEditor
            id={id}
            onChange={handleChapterOnChange}
            onSave={handleSaveChapter}
          />
        );
      case 'policy':
        if (manual) {
          manual.chapters.forEach((ch) => {
            ch.policies.forEach((p) => {
              if (p.id === id) {
                expandNodes([ch.id, p.id]);
              }
            });
          });
        }
        return (
          <PolicyEditor
            id={id}
            onChange={handlePolicyOnChange}
            onSave={handleSavePolicy}
          />
        );
    }
  };

  const handleNodeClick = (type: string, nodeId: string) => {
    const u = `${type}:${nodeId}`;
    if (expandedItems?.has(nodeId)) {
      collapseNodes([nodeId]);
    } else {
      expandNodes([nodeId]);
    }
    navigate(u);
  };

  console.log('authoring render');

  const handleActionsChange = (event: any) => {
    setAction(event.target.value);
  };

  return manual ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
      }}
    >
      <Box
        sx={{
          px: 5,
          py: 4,
          borderBottom: 1,
          borderColor: colors.grey[100],
          backgroundColor: colors.grey[50],
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Stack spacing={1}>
            <Breadcrumbs aria-label='breadcrumb'>
              {[
                <Link
                  key='b1'
                  href='#'
                >
                  Manuals
                </Link>,
                <Typography key='b4'>{parse(manual.title)}</Typography>,
              ]}
            </Breadcrumbs>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ p: 4, pt: 0, minHeight: 0, overflow: 'auto' }}>
        <Box sx={{ minHeight: 0 }}>
          {
            <Box
              sx={{
                height: '80vh',
                display: 'grid',
                gridTemplateColumns: '1fr 3fr',
              }}
            >
              <SimpleTreeView
                selectedItems={selectedItem(node)}
                expandedItems={[...expandedItems]}
                sx={{
                  height: '85vh',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    margin: 4,
                    gap: 2,
                    pb: 4,
                    borderBottom: '1px solid grey',
                  }}
                >
                  {/* <Button
                    onClick={() =>
                      navigate('/prototypes/authoring/manual-structure')
                    }
                  >
                    Manual structure
                  </Button> */}
                  <FormControl
                    variant='filled'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel
                      id='actions-menu-label'
                      style={{ fontSize: 14 }}
                    >
                      Actions
                    </InputLabel>
                    <Select
                      labelId='actions-menu-label'
                      id='actions-menu'
                      value={action}
                      onChange={handleActionsChange}
                      label='Actions'
                      placeholder='Actions'
                      style={{ height: 30 }}
                    >
                      <MenuItem
                        disabled
                        value={'Actions'}
                        style={{ fontWeight: 500 }}
                      >
                        Actions
                      </MenuItem>
                      <MenuItem
                        value={'Manage Structure'}
                        style={{ fontWeight: 500 }}
                      >
                        Manage Structure
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {manual.prefaces.map((preface) => (
                  <TreeItem
                    key={preface.id}
                    itemId={preface.id}
                    label={parse(preface.title)}
                    onClick={() => handleNodeClick('preface', preface.id)}
                    sx={{
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      '& .MuiTreeItem-content': {
                        py: 2,
                      },
                    }}
                  />
                ))}
                {manual.chapters.map((chapter) => (
                  <TreeItem
                    key={chapter.id}
                    itemId={chapter.id}
                    label={
                      <Box onClick={() => handleNodeClick('chapter', chapter.id)}>
                        <Typography>{manualHelper.formatManualNodeTitle(chapter)}</Typography>
                      </Box>
                    }
                    onClick={() => toggleNode(chapter.id)}
                    sx={{
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      '& .MuiTreeItem-content': {
                        py: 2,
                      },
                    }}
                  >
                    {(chapter.policies ?? []).map((policy) => (
                      <TreeItem
                        key={policy.id}
                        itemId={policy.id}
                        label={manualHelper.formatManualNodeTitle(policy)}
                        onClick={() => handleNodeClick('policy', policy.id)}
                        sx={{
                          borderBottom: `solid 1px ${colors.grey[300]}`,
                          '& .MuiTreeItem-content': {
                            py: 2,
                          },
                        }}
                      />
                    ))}
                  </TreeItem>
                ))}
              </SimpleTreeView>
              <Box
                sx={{
                  p: 0,
                  borderLeft: `solid 1px ${colors.grey[300]}`,
                  height: '85vh',
                  overflowY: 'auto',
                }}
              >
                {renderNode(node)}
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  ) : (
    <Loading />
  );
}
