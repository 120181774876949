import React from 'react';
import { Box } from '@mui/material';

import DetailView from 'components/_layouts/DetailView';
import permissions from 'translations/en-us/permissions.json';


export default function Translations() {
  const actions = permissions['en-us'].permissions.actions;
  return (
    <DetailView
      title={'Permission Translations'}
    >
      <Box
        sx={{
          'table': {
            border: 'solid 1px #CCC',
            borderCollapse: 'collapse',
            width: '100%',
          },
          'th, td': {
            border: 'solid 1px #CCC',
            padding: 1,
            fontFamily: 'Arial'
          },
          'th': {
            backgroundColor: '#EEE',
            fontWeight: 'bold',
          },
          'pre': {
            backgroundColor: '#F5F5F5',
            border: 'solid 1px #CCC',
            p: 1,
            borderRadius: 1,
            m: 0,
            display: 'inline-flex',
          }
        }}
      >
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(actions).map(([key, value]) => (
              <tr key={key}>
                <td><pre>{key}</pre></td>
                <td>{value.name}</td>
                <td>{value.description}</td>
              </tr>)
            )}
          </tbody>
        </table>
      </Box>
    </DetailView >
  );
}