import React from 'react';
import {
  Alert,
  Button,
  Stack,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { QuickSearch } from 'components/_atoms';
import useHashState from 'hooks/useHashState';
import DetailView from 'components/_layouts/DetailView';
import CitationItem from './Item';
import useCitations from './useCitations';
import Loading from '../../Loading';

export default function Citations() {
  const [term, setTerm] = useHashState<string>('term', '');
  const citationsQuery = useCitations({ term, pageNumber: 1, pageSize: 25 });
  const { ref, inView } = useInView();
  console.log('citations')

  const citations = React.useMemo(() => citationsQuery.data && citationsQuery.data.pages ?
    citationsQuery.data.pages.flatMap((x) => x.citations).filter((x) => !!x) : []
    , [citationsQuery.data]);

  const infiniteTrigger = citationsQuery.hasNextPage ? (
    <Stack ref={ref} direction='row' spacing={4}>
      <Button
        onClick={() => citationsQuery.fetchNextPage()}
        disabled={!citationsQuery.hasNextPage || citationsQuery.isFetchingNextPage}
      >
        {citationsQuery.hasNextPage ? 'Load more' : ''}
      </Button>
    </Stack>
  ) : null;

  React.useEffect(() => {
    if (inView) {
      citationsQuery.fetchNextPage();
    }
  }, [inView, citationsQuery]);

  return (
    <DetailView
      title={'Citations'}
    >
      <Stack spacing={3}>
        <QuickSearch
          id='citation_search'
          initialValue={term || ''}
          onChange={(val) => setTerm(val)}
          placeholder='Search citations'
        />
        <Stack spacing={1}>
          {citations.length > 0 ? (
            <>
              {citations.map((citation, i1) => (
                <CitationItem
                  item={citation}
                  key={`${i1}_${citation.key}`}
                />
              ))}
              {infiniteTrigger}
            </>
          ) : (
            <>
              {citationsQuery.isFetching || citationsQuery.isLoading ? (
                <Loading />
              ) : (
                <Alert severity={term.length > 0 ? 'warning' : 'info'}>
                  {term.length > 0 ? (term.length < 3 ? 'Search term must be at least 3 characters' : 'No results found') : 'Enter a keyword to search for a citation'}
                </Alert>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </DetailView>
  );
}
