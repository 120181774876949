import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQueryClient } from 'react-query';
import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { AppContext } from '../../../../context';
// import useTranslations from 'hooks/useTranslations';
import useOrganizationRoles from 'data_sources/roles/useRoles';
import useUserRoles from 'data_sources/users/useUserRoles';
import useManageUserRole from 'data_sources/users/useManageUserRole';
import { Role } from 'types/role';
import Loading from 'views/App/Portal/Loading';
import errorHelper from 'helpers/errorHelper';

export default function Roles() {
  const { currentOrganizationId } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();
  // const t = useTranslations();
  const { id } = useParams();
  const rolesQuery = useOrganizationRoles(currentOrganizationId || '');
  const userRolesQuery = useUserRoles(currentOrganizationId || '', id || '');
  const { addRole, removeRole } = useManageUserRole();

  const roles = React.useMemo(() => rolesQuery.data && rolesQuery.data.pages ?
    rolesQuery.data.pages.flatMap((x: any) => x.content) : []
    , [rolesQuery.data]);

  const userRoles = React.useMemo(() => userRolesQuery.data && userRolesQuery.data.roles ?
    userRolesQuery.data.roles : []
    , [userRolesQuery.data]);

  const handleRoleChange = (role: Role, isEnabled: boolean) => {
    console.log('role change', role.name, isEnabled, role.id);
    setIsSaving(true);
    if (isEnabled) {
      addRole(currentOrganizationId || '', id || '', role.id || '', () => {
        setIsSaving(false);
        queryClient.invalidateQueries(['user_roles', currentOrganizationId, id])
      });
    } else {
      removeRole(currentOrganizationId || '', id || '', role.id || '', () => {
        setIsSaving(false);
        queryClient.invalidateQueries(['user_roles', currentOrganizationId, id])
      });
    }
  }
  if (rolesQuery.isError) {
    return errorHelper.renderErrors(rolesQuery);
  }

  if (userRolesQuery.isError) {
    return errorHelper.renderErrors(userRolesQuery);
  }

  return rolesQuery.isLoading || rolesQuery.isFetching ||
    userRolesQuery.isLoading || userRolesQuery.isFetching ? <Loading /> : (
    <Stack spacing={2}>
      {roles.map((role) => (
        <FormControlLabel key={role.id}
          sx={{
            opacity: isSaving ? 0.5 : 1,
            pointerEvents: isSaving ? 'none' : 'auto',
          }}
          control={(
            <Switch
              id={role.id}
              checked={userRoles.map((x) => x?.roleId).includes(role?.id)}
              onChange={({ target }) => handleRoleChange(role, target.checked)}
            />
          )}
          label={(
            <Stack>
              <Typography sx={{ fontWeight: 600 }}>{role.name}</Typography>
              {role.description?.length > 0 && <Typography variant='caption'>{role.description}</Typography>}
            </Stack>
          )}
        />
      ))}
    </Stack>
  );
}
