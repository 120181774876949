import React, { useContext } from 'react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Link, Stack, Typography,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { LegacyArchivedManualsContext } from '../context';
import { QuickSearch } from 'components/_atoms';
import ListView from 'components/_layouts/ListView';
import { ArchivedManualItem } from 'types/manual';

const columnHelper = createColumnHelper<ArchivedManualItem>();

const LegacyArchivedManualsList: React.FC = () => {
  const t = useTranslations();
  const { manuals, manualsQuery, params, updateParams } = useContext(LegacyArchivedManualsContext);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Stack>
          <Link href={`./${info.row.original.id}`}>
            <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>
              {info.getValue()}
            </Typography>
          </Link>
        </Stack>
      ),
      header: () => t('policy.manuals.properties.name'),
      size: 9999,
    }),
  ];

  return (
    <ListView
      columns={columns}
      data={manuals}
      query={manualsQuery}
      title={t('policy.legacyArchivedManuals.title')}
      subtitle={t('policy.legacyArchivedManuals.subtitle')}
      toolbarLeft={<QuickSearch
        id='legacy_archived_manuals_keyword_search'
        initialValue={params.q || ''}
        onChange={(val) => updateParams({ q: val })}
        placeholder={t('policy.legacyArchivedManuals.search.placeholder')}
      />}
    />
  );
}

export default LegacyArchivedManualsList;
