import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';
import { RolePermission } from 'types/permissions_response';

export default function useRolePermissions(
  organizationId: string,
  roleId: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-role/v1/organizations/${organizationId}/roles/${roleId}/permissions`;
  return useQuery<RolePermission[], Error>(
    ['role_permissions', organizationId, roleId],
    async () => {
      const respData = await fetcher({
        method: 'get',
        url: `${url}`,
      });
      const perms: RolePermission[] = respData?.content ?? [];

      return perms;
    },
    {
      ...options,
      keepPreviousData: false,
      placeholderData: [],
    }
  );
}
