import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, getToken, ServerErrorResponse } from '../constants';
import { NotificationsUnreadCount } from 'types/notifications';

const url = `${baseApiUrl()}/notifications/v1/admin/unread-count`;

export const getNotificationsUnreadCount = async (onError?: (errors: ServerErrorResponse) => void) => {
  const idToken = getToken();
  if (idToken) {
    const data = await fetcher({
      method: 'get',
      url: `${url}`,
    });
    return data ?? {};
  } else {
    return {};
  }
}

export default function useNotificationsUnreadCount(
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const idToken = getToken();
  const isRoot = localStorage.getItem('phx-isRoot');
  return useQuery<NotificationsUnreadCount, Error>(
    ['notifications_unread_count', idToken],
    async () => getNotificationsUnreadCount(onError),
    {
      ...options,
      keepPreviousData: false,
      enabled: isRoot === 'false',
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
      placeholderData: { count: 0 } as NotificationsUnreadCount,
    }
  );
}
