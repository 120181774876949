import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  colors,
} from '@mui/material';
import {
  ArrowDropUp,
  ArrowDropDown,
  ContentCopy,
} from '@mui/icons-material';

import {
  CitationResult,
} from './citations'

export type CitationItemProps = {
  item: CitationResult;
  isCollapsed?: boolean;
  onCitationClick: () => void;
}



export default function CitationItem({
  item,
  isCollapsed = false,
  onCitationClick,
}: CitationItemProps) {
  const [collapsed, setCollapsed] = React.useState<boolean>(isCollapsed || false);

  return (
    <Card>
      <CardHeader
        title={(
          <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant='h6'
              onClick={onCitationClick}
              sx={{
                cursor: 'pointer',
                p: 1,
                '&:hover': {
                  background: colors.blue[50],
                  borderRadius: 1,
                }
              }}
            >
              {item.key}
            </Typography>
            <Badge variant='inline' badgeContent={item.type} color='neutral' />
            <Badge variant='inline' badgeContent={item.manuals.length} color='info' />
          </Stack>
        )}
        action={(
          <IconButton onClick={() => setCollapsed((prev) => !prev)}>
            {collapsed ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        )}
        sx={{ borderBottom: 'solid 1px #DDD' }}
      />
      {!collapsed && (
        <CardContent>
          <Stack spacing={2}>
            {item.manuals.map((manual, i2) => (
              <Stack
                key={`${i2}_${item.key}_${manual.name}`}
                direction='row'
                spacing={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#EEE',
                  borderRadius: '5px',
                  padding: '10px',
                }}
              >
                <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontWeight: 'bold' }}>{manual.name}</Typography>
                  <Typography>{`${manual.sections.join(', ')}`}</Typography>
                </Stack>
                <Stack direction='row' spacing={4} sx={{ justifySelf: 'end' }}>
                  <CopyToClipboard text={`${manual.name} - ${manual.sections.join(', ')}`}>
                    <IconButton>
                      <ContentCopy />
                    </IconButton>
                  </CopyToClipboard>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </CardContent>
      )}
    </Card>
  );
}
