import { useInfiniteQuery } from 'react-query';

import { ArchivedManualItem } from '../../types/manual';
import { ApiCollectionResponse } from '../../types/api_response';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import contentHelper from 'helpers/contentHelper';

export default function useLegacyArchivedManuals<T extends ArchivedManualItem>(
  organizationId: string,
  params = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexipub/manuals/archived/v1/legacy-manuals`;

  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['legacy_archived_manuals', organizationId, params],
    async ({ pageParam, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal }
      });

      return convertResults(data) as ApiCollectionResponse<T>;
    },
    {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        if (lastPage.offset && (lastPage.offset < (lastPage.count || 0)) && lastPage.content.length > 0) {
          const paramString = new URLSearchParams(Object.entries({
            ...params,
            offset: lastPage.offset,
          })
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v != null)
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();

          return `${url}?${paramString}`;
        }
      }
    }
  );
}
const convertResults = (data: any) => ({
  count: data.totalElements,
  offset: data.totalElements,
  content: data.content.map((x: any) => ({
    id: x.id,
    manualType: {
      key: x.manualType?.key,
      name: x.manualType?.value,
    },
    name: contentHelper.decodeHtmlEntities(x.name || ''),
    organizationId: x.organizationId,
    fromDate: x.fromDate,
    toDate: x.toDate,
    vertical: {
      key: x.vertical?.key,
      name: x.vertical?.value,
    },
  })),
});