import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';

export type RoleGroupResponseItem = {
  groupId: string;
  groupName: string;
}

export type RoleGroupsResponse = {
  groups: RoleGroupResponseItem[];
}

export default function useRoleGroups(
  organizationId: string,
  roleId: string,
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-role/v1/organizations/${organizationId}/roles/${roleId}/groups`;
  return useQuery<RoleGroupsResponse, Error>(
    ['role_groups', organizationId, roleId],
    async ({ pageParam, signal }): Promise<RoleGroupsResponse> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: RoleGroupsResponse = await fetcher({
        method: 'get',
        url: pageParam || `${url}?${paramString}`,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}
