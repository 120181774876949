import React, { useState } from 'react';
import parse, { DOMNode } from 'html-react-parser';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  colors,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
// import RockyRiverv2 from '../manuals/rocky_river_v2.json';
// import RockyRiverv4 from '../manuals/rocky_river_v4.json';
// import chamberburg from '../manuals/chambersburg.json';
// import moonTownship from '../manuals/moon_township.json';
import { Manual } from './manual';
import manualComparer from './comparer';
import { ManualChapterComparison, ManualComparison, ManualPrefaceComparison, PolicyComparison, SectionComparison, SubSectionComparison } from './manual_comparison';
import contentHelper from './contentHelper';

type ManualDef = {
  label: string;
  url: string;
}
type ManualDefs = { [key: string]: ManualDef }

const manuals: ManualDefs = {
  'rocky-river-v2': {
    label: 'Rocky River Fire Department Policy Manual (v2)',
    url: 'https://dj8ibq5dbhvjr.cloudfront.net/manuals/rocky_river_v2.json',
  },
  'rocky-river-v4': {
    label: 'Rocky River Fire Department Policy Manual (v4)',
    url: 'https://dj8ibq5dbhvjr.cloudfront.net/manuals/rocky_river_v4.json',
  },
  'chambersburg': {
    label: 'Chambersburg Police Department Policy Manual',
    url: 'https://dj8ibq5dbhvjr.cloudfront.net/manuals/chambersburg.json',
  },
  'moon-township': {
    label: 'Moon Township Police Department Policy Manual',
    url: 'https://dj8ibq5dbhvjr.cloudfront.net/manuals/moon_township.json',
  },
}

export default function ManualCompare() {
  const [results, setResults] = useState<ManualComparison | null>(null);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [sourceManual, setSourceManual] = useState<string>('chambersburg');
  const [targetManual, setTargetManual] = useState<string>('moon-township');

  React.useEffect(() => {
    const sUrl = manuals[sourceManual]?.url;
    const tURl = manuals[targetManual]?.url;

    fetch(sUrl).then((resp) => resp.json()).then((s) => {
      fetch(tURl).then((resp) => resp.json()).then((t) => {
        if (s && t) {
          const r = manualComparer.compare(s as Manual, t as Manual);
          setResults(r);
        }
      });
    });
  }, [sourceManual, targetManual]);

  const handleTreeSelection = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    setSelectedNode(itemId)
  }

  const getNodeColor = (comparison: string) => {
    let variant: string = colors.blue[50];
    if (comparison === 'diff') variant = colors.amber[100];
    if (comparison === 'removed') variant = colors.red[100];
    if (comparison === 'added') variant = colors.green[100];

    return variant;
  }

  const renderManual = (manual: ManualComparison) => {
    const chapters = manual.chapters
    return (
      <Stack spacing={5}>
        {manual.prefaces.map((x) => renderPreface(x))}
        {chapters.map((x) => renderChapter(x))}
      </Stack>
    )
  }

  const renderPreface = (preface: ManualPrefaceComparison) => {
    return (
      <Box key={preface.guid}>
        <Typography component='div' variant='h5'>{parse(preface.title)}</Typography>
        {parse(preface.content ?? '', {
          replace(node: DOMNode, ind: number) {
            return contentHelper.handleContent(node, ind);
          }
        })}
      </Box>
    )
  }
  const renderChapter = (chapter: ManualChapterComparison) => {
    return (
      <Box key={chapter.guid}>
        <Typography component='div' variant='h3'>{parse(chapter.title)}</Typography>
        {chapter.policies && chapter.policies?.length > 0 && (
          <Stack spacing={5}>
            {chapter.policies.map((p) => renderPolicy(p))}
          </Stack>
        )}
      </Box>
    )
  }

  const renderPolicy = (policy: PolicyComparison) => {
    return (
      <Box key={policy.guid}>
        <Typography component='div' variant='h4'>{parse(policy.title)}</Typography>
        {policy.sections?.length > 0 && (
          <Stack spacing={5}>
            {policy.sections.map((s) => renderSection(s))}
          </Stack>
        )}
      </Box>
    )
  }

  const renderSection = (section: SectionComparison) => {
    return (
      <Box key={section.guid}>
        <Typography component='div' variant='h5'>{parse(section.title)}</Typography>
        {parse(section.content ?? '', {
          replace(node: DOMNode, ind: number) {
            return contentHelper.handleContent(node, ind);
          }
        })}
        {section.subSections?.length > 0 && (
          <Stack spacing={5}>
            {section.subSections.map((ss) => renderSubSection(ss))}
          </Stack>
        )}
      </Box>
    )
  }

  const renderSubSection = (section: SubSectionComparison) => {
    return (
      <Box key={section.guid}>
        <Typography component='div' variant='h6'>{parse(section.title)}</Typography>
        {parse(section.content ?? '', {
          replace(node: DOMNode, ind: number) {
            return contentHelper.handleContent(node, ind);
          }
        })}
      </Box>
    )
  }

  const renderSelectedNode = (manual: ManualComparison, guid: string | null) => {
    if (!manual) return null;
    let content;
    if (guid) {
      manual.prefaces.forEach((pr) => {
        if (pr.guid === guid) content = renderPreface(pr)
      })
      manual.chapters.forEach((ch) => {
        if (ch.guid === guid) content = renderChapter(ch);
        ch.policies.forEach((pol) => {
          if (pol.guid === guid) content = renderPolicy(pol)
          pol.sections.forEach((sec) => {
            if (sec.guid === guid) content = renderSection(sec)
            sec.subSections.forEach((ss) => {
              if (ss.guid === guid) content = renderSubSection(ss)
            })
          })
        })
      })
    }
    return content ?? renderManual(manual);
  }

  return (
    <Box sx={{
      p: 2,
      '& del': {
        textDecoration: 'none',
        color: '#b30000',
        background: colors.red[100],
      },
      '& ins': {
        textDecoration: 'none',
        color: '#406619',
        background: colors.green[100],
      },
    }}>
      <Card>
        <CardHeader
          title='Manual Comparison'
          subheader={(
            <Stack direction='row' spacing={2}>
              <Select
                id='source_manual'
                size='small'
                value={sourceManual}
                onChange={({ target }) => setSourceManual(target.value)}
              >
                {Object.entries(manuals).map(([k, v]) => (
                  <MenuItem key={`source_manual${k}`} value={k}>{v.label}</MenuItem>
                ))}
              </Select>
              <Typography>vs</Typography>
              <Select
                id='target_manual'
                size='small'
                value={targetManual}
                onChange={({ target }) => setTargetManual(target.value)}
              >
                {Object.entries(manuals).map(([k, v]) => (
                  <MenuItem key={`target_manual${k}`} value={k}>{v.label}</MenuItem>
                ))}
              </Select>
            </Stack>
          )}
          sx={{
            backgroundColor: colors.grey[100],
            borderBottom: `solid 1px ${colors.grey[200]}`,
            p: 6,
          }}
          action={(
            <Stack direction='row' spacing={2}>
              <Box sx={{
                backgroundColor: colors.blue[50],
                colors: colors.grey[900],
                p: 2,
                border: `solid 1px ${colors.grey[500]}`
              }}>SAME</Box>
              <Box sx={{
                backgroundColor: colors.amber[100],
                colors: colors.grey[900],
                p: 2,
                border: `solid 1px ${colors.grey[500]}`
              }}>DIFF</Box>
              <Box sx={{
                backgroundColor: colors.green[100],
                colors: colors.grey[900],
                p: 2,
                border: `solid 1px ${colors.grey[500]}`
              }}>ADD</Box>
              <Box sx={{
                backgroundColor: colors.red[100],
                colors: colors.grey[900],
                p: 2,
                border: `solid 1px ${colors.grey[500]}`
              }}>REM</Box>
            </Stack>
          )}
        />
        <CardContent sx={{ p: 0 }}>
          {results && (
            <Box
              sx={{
                height: '85vh',
                display: 'grid',
                gridTemplateColumns: '1fr 3fr',
              }}
            >
              <SimpleTreeView
                onItemSelectionToggle={handleTreeSelection}
                sx={{
                  height: '85vh',
                  overflowY: 'auto',
                }}
              >
                {results.prefaces.map((pr) => (
                  <TreeItem
                    key={pr.guid}
                    itemId={pr.guid}
                    label={parse(pr.title)}
                    sx={{
                      backgroundColor: getNodeColor(pr.comparison),
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      '& .MuiTreeItem-content': {
                        py: 2
                      }
                    }}
                  />
                ))}
                {results.chapters.map((ch) => (
                  <TreeItem
                    key={ch.guid}
                    itemId={ch.guid}
                    label={parse(ch.title)}
                    sx={{
                      backgroundColor: getNodeColor(ch.comparison),
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      '& .MuiTreeItem-content': {
                        py: 2
                      }
                    }}
                  >
                    {(ch.policies ?? []).map((pol) => (
                      <TreeItem
                        key={pol.guid}
                        itemId={pol.guid}
                        label={parse(pol.title)}
                        sx={{
                          backgroundColor: getNodeColor(pol.comparison),
                          borderBottom: `solid 1px ${colors.grey[300]}`,
                          '& .MuiTreeItem-content': {
                            py: 2
                          }
                        }}
                      >
                        {(pol.sections ?? []).map((sec) => (
                          <TreeItem
                            key={sec.guid}
                            itemId={sec.guid}
                            label={parse(sec.title)}
                            sx={{
                              backgroundColor: getNodeColor(sec.comparison),
                              borderBottom: `solid 1px ${colors.grey[300]}`,
                              '& .MuiTreeItem-content': {
                                py: 2
                              }
                            }}
                          >
                            {(sec.subSections ?? []).map((sec) => (
                              <TreeItem
                                key={sec.guid}
                                itemId={sec.guid}
                                label={parse(sec.title)}
                                sx={{
                                  backgroundColor: getNodeColor(sec.comparison),
                                  borderBottom: `solid 1px ${colors.grey[300]}`,
                                  '& .MuiTreeItem-content': {
                                    py: 2
                                  }
                                }}
                              />
                            ))}
                          </TreeItem>
                        ))}
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </SimpleTreeView>
              <Box sx={{
                p: 5,
                borderLeft: `solid 1px ${colors.grey[300]}`,
                height: '85vh',
                overflowY: 'auto'
              }}>
                {renderSelectedNode(results, selectedNode)}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
