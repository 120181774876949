import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';
import { Role } from 'types/role';

export type GroupRolesResponse = {
  roles: Role[];
}

export default function useGroupRoles(
  organizationId: string,
  groupId: string,
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/user-group/v1/organizations/${organizationId}/groups/${groupId}/roles`;
  return useQuery<GroupRolesResponse, Error>(
    ['group_roles', organizationId, groupId, params],
    async ({ pageParam, signal }): Promise<GroupRolesResponse> => {
      const paramString = new URLSearchParams(Object.entries({
        ...params,
      })
        // eslint-disable-next-line no-unused-vars
        .filter(([_k, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})).toString();
      const data: GroupRolesResponse = await fetcher({
        url: pageParam || `${url}?${paramString}`,
        options: { signal }
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}
