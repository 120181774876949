import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, LexicalEditor } from 'lexical';
import { $patchStyleText } from '@lexical/selection';
import {
  CHANGE_FONT_COLOR_COMMAND,
  CHANGE_BACKGROUND_COLOR_COMMAND,
} from '../../nodes/PhxTextNode';

export function applyStyleToSelection(
  editor: LexicalEditor,
  styles: Record<string, string>
) {
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      if (!selection.isCollapsed()) {
        $patchStyleText(selection, styles);
      } else {
        const styleString = Object.entries(styles)
          .map(([key, value]) => `${key}: ${value}`)
          .join('; ');
        selection.setStyle(styleString);
      }
    }
  });
}

export function getStyleObjectFromCSS(
  styleString: string
): Record<string, string> {
  return styleString
    .split(';')
    .reduce((styleObject: Record<string, string>, stylePropertyValue) => {
      const [property, value] = stylePropertyValue
        .split(':')
        .map((item) => item.trim());
      if (property && value) {
        styleObject[property] = value;
      }
      return styleObject;
    }, {});
}

export default function ColorPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const unregisterFontColor = editor.registerCommand(
      CHANGE_FONT_COLOR_COMMAND,
      (color: string) => {
        applyStyleToSelection(editor, { color });
        return true;
      },
      1
    );

    const unregisterBackgroundColor = editor.registerCommand(
      CHANGE_BACKGROUND_COLOR_COMMAND,
      (color: string) => {
        applyStyleToSelection(editor, { 'background-color': color });
        return true;
      },
      1
    );

    return () => {
      unregisterFontColor();
      unregisterBackgroundColor();
    };
  }, [editor]);

  return null;
}
