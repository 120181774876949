import React from 'react';
import {
  Alert,
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Stack,
  colors,
} from "@mui/material";
import {
  Warning,
} from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import { ServerError, type ServerErrorResponse } from "../../../data_sources/constants";
import errorHelper from 'helpers/errorHelper';
import Loading from 'views/App/Portal/Loading';

export type FormViewProps = {
  actions?: React.ReactNode;
  breadcrumbItems?: React.ReactNode[];
  children: React.ReactNode;
  errors?: ServerErrorResponse;
  fields?: React.ReactNode;
  query?: any;
  title: string;
}

const FormView: React.FC<FormViewProps> = ({
  actions = [],
  breadcrumbItems = [],
  children = [],
  errors,
  query,
  title,
}: FormViewProps) => {
  const t = useTranslations();
  const errorDetails: ServerError[] = [];
  if (errors?.errorDetails && errors?.errorDetails.length > 0) {
    errors.errorDetails.forEach((e) => errorDetails.push(e))
  } else if (errors) {
    const rootError: ServerError = {
      code: errors?.errorInformationCode || 0,
      detail: errors?.detail || '',
      message: errors?.errorMessage || errors?.message || ''
    }
    errorDetails.push(rootError);
  }
  return (
    <Container maxWidth='md' sx={{ p: 6 }}>
      <Stack spacing={6}>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbItems}
        </Breadcrumbs>
        <Card
          variant='outlined'
          sx={{
            borderRadius: 2,
          }}
        >
          <CardHeader
            title={title}
            sx={{
              backgroundColor: colors.grey[100],
              borderBottom: `solid 1px ${colors.grey[200]}`,
              p: 6,
            }}
          />
          <CardContent
            sx={{
              p: 6
            }}
          >
            {
              errorDetails && errorDetails.length > 0 && (
                <Alert icon={<Warning />} severity='error' sx={{ alignItems: 'center', mb: 5 }}>
                  <ul className="error-list" style={{ margin: '2px 0' }}>
                    {errorDetails.map((x, i) => (
                      <li key={`server_errors_${i}`} data-errors={JSON.stringify(x)}>{t(`errors.${x.code}`) || x.message}</li>
                    ))}
                  </ul>
                </Alert>
              )
            }
            <Stack spacing={6}>
              {query?.isError ? errorHelper.renderErrors(query) : (query && (query.isLoading || query.isFetching) ? <Loading /> : children)}
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              borderTop: `solid 1px ${colors.grey[200]}`,
              justifyContent: 'end',
              p: 6,
            }}
          >
            <Stack direction='row' spacing={2}>
              {(!query || query.data) ? actions : null}
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </Container>
  );
}


export default FormView;