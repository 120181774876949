import React from 'react';
import { Action, ActionProps } from './ActionComponent';
import { IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export function Handle(props: ActionProps) {
  return (
    <Action
      cursor='grab'
      data-cypress='draggable-handle'
      {...props}
    >
      <IconButton
        size='small'
        sx={{
          cursor: 'grab',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DragIndicatorIcon />
      </IconButton>
    </Action>
  );
}
