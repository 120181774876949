import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Link, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import ListView from 'components/_layouts/ListView';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import { ManualToCItem } from 'types/manual';
import Loading from 'views/App/Portal/Loading';

const columnHelper = createColumnHelper<ManualToCItem>();

const IssuedManualsToC: React.FC = () => {
  const t = useTranslations();
  const { id } = useParams();
  const manualQuery = useIssuedManual(id);

  const columns = [
    columnHelper.accessor('title', {
      cell: (info) => (
        <Link
          href={`./${info.row.original.contentType}/${info.row.original.id}`}
        >
          {info.getValue()}
        </Link>
      ),
      size: 9999,
    }),
  ];

  const tocItems = useMemo(() => {
    let toc: ManualToCItem[] = [];
    if (manualQuery.data && manualQuery.data.prefaces) {
      toc = [
        ...toc,
        ...manualQuery.data.prefaces.map(
          (x) =>
            ({
              id: x.id,
              title: ['Preface', x.title].join(': '),
              contentType: 'preface',
            }) as ManualToCItem
        ),
      ];
    }
    if (manualQuery.data && manualQuery.data.chapters) {
      toc = [
        ...toc,
        ...manualQuery.data.chapters.map(
          (x) =>
            ({
              id: x.id,
              title: [x.key, x.title].join(': '),
              contentType: 'chapter',
            }) as ManualToCItem
        ),
      ];
    }
    return toc;
  }, [manualQuery.data]);

  React.useEffect(() => {
    if (manualQuery.data && manualQuery.data.chapters) {
      manualQuery.data.chapters.forEach((chapter) => {
        if (chapter.policies) {
          chapter.policies.forEach((p) => {
            let hasXref = false;
            if (p.sections) {
              p.sections.forEach((s) => {
                if (/xref/.test(s.content)) {
                  hasXref = true;
                }
                if (s.subSections) {
                  s.subSections.forEach((ss) => {
                    if (/xref/.test(ss.content)) {
                      hasXref = true;
                    }
                  });
                }
              });
            }
            if (hasXref) console.log('has crossref', p.key, p.title);
          });
        }
      });
    }
  }, [manualQuery.data]);

  return manualQuery.isFetching || manualQuery.isLoading ? (
    <Loading />
  ) : (
    <ListView
      columns={columns}
      data={tocItems}
      hideHeaders
      query={manualQuery}
      title={manualQuery.data?.name || ''}
      breadcrumbItems={[
        <Link
          key='b1'
          href='..'
        >
          {t('policy.issuedManuals.title')}
        </Link>,
        <Typography key='b2'>{manualQuery.data?.name || ''}</Typography>,
      ]}
    />
  );
};

export default IssuedManualsToC;
