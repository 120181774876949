import React, { useCallback, useMemo, useContext } from "react";
import { QueryClient, UseInfiniteQueryResult, useQueryClient } from "react-query";
import * as Yup from 'yup';

import { AppContext } from "views/App/context";
import useUrlParams from "hooks/useUrlParams";
import useTranslations from "hooks/useTranslations";
import useUsers from "data_sources/users/useOrganizationUsers";
import useManageUsers from "data_sources/users/useManageOrganizationUser";
import type { User } from "types/user";
import { ApiCollectionResponse } from "types/api_response";
import { ServerErrorResponse } from "data_sources/constants";

type UsersProviderProps = {
  children: React.ReactNode;
};

type UsersUrlParams = {
  search?: string | null;
  sort?: string | null;
};

export type UsersProviderResponse = {
  clearParams: () => void;
  createUser: (
    data: User,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => void;
  params: UsersUrlParams;
  queryClient: QueryClient | null;
  updateParams: (updatedParams: UsersUrlParams) => void;
  updateUser: (
    guid: string,
    data: User,
    onSuccess?: (data: User) => void,
    onError?: (errors: ServerErrorResponse) => void,) => void;
  users: User[];
  usersQuery: UseInfiniteQueryResult<ApiCollectionResponse<User>, Error>;
  userSchema: any,
}

export const SORT_OPTIONS = [
  {
    key: 'name',
    label: 'Last Name',
    value: 'lastName_asc'
  },
  {
    key: 'created_at',
    label: 'Recently Created',
    value: 'createdAt_desc'
  },
  {
    key: 'updated_at',
    label: 'Recently Updated',
    value: 'updatedAt_desc'
  },
]

const defaultParams: UsersUrlParams = {
  search: null,
  sort: 'name',
};

export const UsersContext = React.createContext<UsersProviderResponse>({} as UsersProviderResponse);

const UsersProvider: ({ children }: UsersProviderProps) => any = ({ children }) => {
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const queryClient = useQueryClient();
  const t = useTranslations();
  const [params, setParams] = useUrlParams(defaultParams);
  const updateParams = useCallback((updatedParams: UsersUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams: any = {
      ...params
    };
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null || newParams[key] === '') {
        delete newParams[key];
      }
    });
    if (newParams.sort) {
      const opt = SORT_OPTIONS.find((x) => x.key === newParams.sort);
      if (opt) newParams.sort = opt.value;
    }

    return newParams;
  }, [params]);

  // const isFiltered = params !== defaultParams;

  const userData = useUsers(orgId, queryParams);
  const {
    create: createUser,
    update: updateUser,

  } = useManageUsers();

  const userSchema = Yup.object().shape({
    email: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(t('errors.101')),
    firstName: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(t('errors.106')),
    lastName: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(t('errors.107')),
    phone: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      }),
  });

  const users = useMemo(() => userData.data && userData.data.pages ?
    userData.data.pages.flatMap((x) => x.content) : []
    , [userData.data]);

  const handleCreateUser = useCallback((
    data: User,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    createUser(
      orgId,
      data,
      (guid: string) => {
        queryClient.resetQueries(['organization_users']);
        if (onSuccess) onSuccess(guid);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [createUser, orgId, queryClient]);

  const handleUpdateUser = useCallback((
    id: string,
    data: User,
    onSuccess?: (updatedData: User) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    updateUser(
      orgId,
      id,
      data,
      (updatedData: User) => {
        queryClient.invalidateQueries(['organization_users']);
        queryClient.invalidateQueries(['organization_user', updatedData.orgId, updatedData.id]);
        if (onSuccess) onSuccess(updatedData);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [orgId, queryClient, updateUser])

  const contextValue: UsersProviderResponse = useMemo(() => ({
    clearParams,
    createUser: handleCreateUser,
    params,
    queryClient,
    updateParams,
    updateUser: handleUpdateUser,
    usersQuery: userData,
    users: users,
    userSchema,
  }), [clearParams, handleCreateUser, handleUpdateUser, params, queryClient, updateParams, userData, users, userSchema]);

  return (
    <UsersContext.Provider value={contextValue}>
      {children}
    </UsersContext.Provider>
  )
};

export default UsersProvider;