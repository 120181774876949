/* eslint-disable class-methods-use-this */
import React from 'react';
import { domToReact, DOMNode } from 'html-react-parser';
import { baseApiUrl, blobFetcher, handleError } from 'data_sources/constants';
import { Link, Stack } from '@mui/material';
import PolicyMedia from 'views/App/Portal/Policy/shared/PolicyMedia';
import {
  AttachFile,
} from '@mui/icons-material';
import PolicyCrossref from 'views/App/Portal/Policy/shared/PolicyCrossref';
import { Manual } from 'types/manual';
import manualHelper from './manualHelper';
import ExcalidrawImage from 'views/App/Portal/Policy/shared/Excalidraw';

export interface PolicyAttachment {
  sectionKey: string;
  section: string;
  attachmentKey: string;
  attachmentName: string;
}

export default class contentHelper {
  static decodeHtmlEntities = (content: string) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    return doc.documentElement.textContent;
  };

  static handleRetrieveMedia = (key: string, onLoad: (content: string) => void) => {
    const idToken = localStorage.getItem('phx-idToken')?.toString();
    let scope = 'issued';
    // let contentType = '';
    if (/staged/.test(key)) scope = 'staged';
    const url = `${baseApiUrl()}/lexipub/manuals/${scope}/v1/media?key=${encodeURIComponent(key)}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Api-Key': process.env.REACT_APP_API_GATEWAY_KEY || '',
        'Authorization': idToken || '',
      },
    })
      .then(resp => {
        console.log('attachment hdrs', resp.headers);
        // contentType = resp.headers.get('Content-Type')?.toString() || '';
        return resp.blob();
      })
      .then((blob) => {
        const rdr = new FileReader();
        rdr.onload = (e) => onLoad(e.target?.result as string);
        rdr.readAsDataURL(blob);
      });
  }

  static handleRetrieveCrossref = (key: string, onLoad: (manual: Manual) => void) => {
    const parts = key.split('/');
    const orgId = parts[1];
    const scope = parts[3];
    const manualId = parts[4];
    const contentId = parts[6];
    console.log('xref', orgId, scope, manualId, contentId);
    const url = `${baseApiUrl()}/lexipub/manuals/${scope}/v1/manuals/${manualId}`
    blobFetcher({
      method: 'get',
      url,
    }).catch((err) => {
      handleError(err, {
        onError: () => {
          console.log(err)
        }
      });
    }).then(async (data) => {
      const isJsonBlob = (data: any) => data instanceof Blob && data.type === "application/json";
      const responseData = isJsonBlob(data) ? await (data)?.text() : data || {};
      const responseJson = (typeof responseData === "string") ? JSON.parse(decodeURIComponent(escape(atob(responseData)))) : responseData;
      onLoad(manualHelper.convertManual(responseJson) as Manual);
    });
  }

  static handleDownloadAttachment = (key: string, filename: string) => {
    const idToken = localStorage.getItem('phx-idToken')?.toString();
    let scope = 'issued';
    if (/staged/.test(key)) scope = 'staged';
    const url = `${baseApiUrl()}/lexipub/manuals/${scope}/v1/media?key=${encodeURIComponent(key)}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Api-Key': process.env.REACT_APP_API_GATEWAY_KEY || '',
        'Authorization': idToken || '',
      },
    })
      .then(resp => {
        console.log('attachment hdrs', resp.headers);
        return resp.blob();
      })
      .then((blob) => {
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  static handleContent = (node: DOMNode, ind: number) => {
    if (node.type === 'tag') {
      if (node.name === 'img') {
        if (node.attribs.src && node.attribs.src.startsWith('s3://')) {
          console.log('img', node.attribs, ind);
          const key = node.attribs.src.split('://')[1];
          return (
            <PolicyMedia
              mediaKey={key}
              alt={node.attribs.title || node.attribs.alt}
            />
          )
        }
      }
      if (node.name === 'a') {
        if (node.attribs.href && node.attribs.href.startsWith('s3://')) {
          console.log('attachment found', node.attribs.title, node.attribs.href)
          return (
            <Link
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                contentHelper.handleDownloadAttachment(node.attribs.href.split('://')[1], node.attribs.title || 'file');
              }}
            >
              <Stack
                component='span'
                direction='row'
                spacing={2}
                sx={{ display: 'inline-flex' }}
              >
                <AttachFile />
                {domToReact(node.children as DOMNode[])}
              </Stack>
            </Link>
          )
        } else if (node.attribs.href && node.attribs.href.startsWith('xref://')) {
          console.log('crossref found', node.attribs.title, node.attribs.href);
          const key = node.attribs.href.split('://')[1]

          return (
            <PolicyCrossref
              xrefKey={key}
            >
              {domToReact(node.children as DOMNode[])}
            </PolicyCrossref>
          )
        } else {
          node.attribs.target = '_blank';
          node.attribs.rel = 'noopener noreferrer';
        }
      }
      if (node.attribs['data-lexical-excalidraw-json']) {
        const excalidrawJson = node.attribs['data-lexical-excalidraw-json'];

        return (
          <ExcalidrawImage excalidrawJson={excalidrawJson} />
        )
      }
      if (['indexterm', 'primary', 'lexindex'].includes(node.name)) {
        console.log('content tag', node.name, node);
        return (<></>);
      }
    }
    return node;
  };

  static extractAttachments = (content: string) => {
    const contentNode = document.createElement('div');
    contentNode.innerHTML = content;
    const links: { key: string; name: string; }[] = [];
    const linkMatches = contentNode.querySelectorAll('a');
    linkMatches.forEach((l) => {
      if (l.href.startsWith('s3://')) {
        links.push({
          key: l.href.split('://')[1],
          name: l.title || ''
        })
      }
    });
    return links;
  };

  static formatLabel = (node: any) => {
    const parts = [];
    if (node?.heading) parts.push(node.heading);
    parts.push(node?.title?.value ?? '');
    return parts.join(': ');
  }

  static humanize = (text: string) => {
    return text.split('_').map((word) => (/\bid\b/i.test(word)) ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  static parameterize = (text: string) => {
    return text.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
  }
}
