import { $getSelection, LexicalEditor } from 'lexical';
import { Button, DialogActions, ListItemButton } from '@mui/material';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { $createTextEntityNode } from '../../nodes/PhxTextEntityNode';

const dummyEntities = [
  {
    key: 'ORGANIZATION_NAME',
    data: {
      id: 1,
      label: 'Organization Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam libero erat, ac viverra neque feu',
    },
  },
  {
    key: 'VERTICAL',
    data: {
      id: 2,
      label: 'Vertical Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam libero erat, ac viverra',
    },
  },
  {
    key: 'JURISDICTION',
    data: {
      id: 3,
      label: 'Jurisdiction Name',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  },
  {
    key: 'JURISDICTION 2',
    data: {
      id: 4,
      label: 'Jurisdiction 2 Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam libero erat, ac',
    },
  },
  {
    key: 'RIGHT',
    data: {
      id: 5,
      label: 'Right Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam libero erat, ac viverra neque feugiat eu. In consequat facilisis',
    },
  },
];

interface EntityData {
  id: number;
  label: string;
  description: string;
}

interface TextEntity {
  key: string;
  data: EntityData;
}

export function InsertTextEntityDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const onSubmit = (entity: TextEntity) => {
    activeEditor.update(() => {
      const selection = $getSelection();
      const textEntityNode = $createTextEntityNode(
        entity?.key ?? '',
        entity?.data
      );

      if (selection) {
        selection.insertNodes([textEntityNode]);
      }
    });

    onClose();
  };

  const [input, setInput] = useState('');
  const [filteredEntities, setFilteredEntities] = useState(dummyEntities);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setInput(value);

    const filtered = dummyEntities.filter((entity) =>
      entity.data.label.toLowerCase().includes(value)
    );

    setFilteredEntities(filtered);
  };

  return (
    <Box sx={{ maxWidth: 752 }}>
      <Box
        className="App"
        sx={{
          width: 400,
          height: 620,
          marginInline: '100px auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <form style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="search-bar"
            className="text"
            onInput={handleInput}
            value={input}
            label="Search title"
            variant="outlined"
            placeholder="Search..."
            size="small"
            sx={{
              width: 350,
              margin: '10px auto',
            }}
          />
          <IconButton
            style={{ marginTop: 14, cursor: 'default' }}
            aria-label="search"
          >
            <SearchIcon style={{ fill: 'blue' }} />
          </IconButton>
        </form>
        <List style={{ maxHeight: '540px', overflowY: 'scroll' }}>
          {filteredEntities.map((entity) => (
            <ListItem key={entity.data.id}>
              <ListItemButton
                onClick={() => onSubmit(entity)}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  primary={entity.data.label}
                  secondary={entity.data.description}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
