import { Auth } from "aws-amplify";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { baseApiUrl, fetcher } from 'data_sources/constants';

declare module 'jwt-decode' {
  export interface JwtPayload {
    'custom:orgId': string;
    'custom:root'?: string;
  }
}

export type AmplifyConfig = {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    mandatorySignIn: boolean;
    oauth?: {
      domain: string;
      scope: string[];
      redirectSignIn: string;
      redirectSignOut: string;
      responseType: string;
    }
    provider?: string;
  }
}

export default class AuthUtil {
  static async userLookup(username: string, callback: (userpoolFound: boolean) => void) {
    // console.log('!!', baseApiUrl, process.env.REACT_APP_API_BASE_PROD, window.location.host)
    fetcher({
      method: 'get',
      url: `${baseApiUrl()}/lexid/lookup/v1/lookup/${username}`,
      anonymousAllowed: true,
    }).then((data) => {
      const configData = data;
      if (configData.Auth.oauth) {
        const signinRedir: string = `${window.location.origin}/dashboard`;
        const signoutRedir: string = `${window.location.origin}/login`;
        configData.Auth.oauth.redirectSignIn = signinRedir;
        configData.Auth.oauth.redirectSignOut = signoutRedir;
      }
      const pool = configData;
      if (pool) {
        localStorage.setItem('phx-userpoolConfig', JSON.stringify(pool));
        callback(true);
      } else {
        localStorage.removeItem('phx-userpoolConfig');
        callback(false);
      }

    }).catch((err) => {
      console.log("Error looking up user: ", err);
      callback(false);
    });
  }

  static getUserPoolConfig() {
    return localStorage.getItem('phx-userpoolConfig')
  }

  static clearStorage() {
    localStorage.removeItem('phx-currentUserName');
    localStorage.removeItem('phx-currentOrganizationName');
    localStorage.removeItem('phx-currentOrganizationId');
    localStorage.removeItem('phx-userOrgId');
    localStorage.removeItem('phx-userpoolConfig');
    localStorage.removeItem('phx-idToken');
    localStorage.removeItem('phx-username');
    localStorage.removeItem('phx-currentProfile');
    localStorage.removeItem('phx-orgPermissions');
    localStorage.removeItem('phx-userPermissions');
    localStorage.removeItem('phx-isRoot');
  }

  static idTokenExpired() {
    const token = localStorage.getItem('phx-idToken');
    if (token) {
      const tokenPayload: JwtPayload = jwtDecode(token.toString());
      var current_time = new Date().getTime() / 1000;
      // console.log('isTokenExpired', !(tokenPayload.exp && current_time <= tokenPayload.exp), tokenPayload.exp, current_time)
      if (tokenPayload.exp && current_time <= tokenPayload.exp) {
        return false;
      }
      return true;
    }
    return true;
  }

  static isRoot(idToken: string) {
    const tokenPayload: JwtPayload = jwtDecode(idToken);
    return tokenPayload['custom:root'] !== undefined;
  }

  static async updateSessionInfo(callback: (id: string | null) => void) {
    if (this.getUserPoolConfig()) {
      await Auth.currentSession().then((session) => {
        const idToken = session.getIdToken();
        console.log('updateSessionInfo:idToken', idToken)
        // const existingToken = localStorage.getItem('phx-idToken');
        if (!!idToken) {
          localStorage.setItem('phx-idToken', idToken.getJwtToken());
          const tokenPayload: JwtPayload = jwtDecode(idToken.getJwtToken());
          console.log('updateSessionInfo:jwt', tokenPayload['custom:orgId'], tokenPayload)
          const orgId = tokenPayload['custom:orgId']
          if (tokenPayload && orgId) {
            localStorage.setItem('phx-userOrgId', orgId);
          }
          const isRoot = tokenPayload['custom:root']
          // console.log('update session isRoot', !!isRoot, idToken.getJwtToken());
          localStorage.setItem('phx-isRoot', JSON.stringify(!!isRoot ? true : false));
          console.log('id-token-changed', JSON.stringify(idToken), orgId)
          callback(orgId);
          document.body.dispatchEvent(new Event('id-token-changed'));
        } else {
          console.log('error getting idtoken from session')
          localStorage.removeItem('phx-idToken');
        }
      }).catch((err) => {
        console.log('error getting session', err);
      });
    }
  }
}