import React from "react";
import {
  Box,
  colors,
  Link,
} from "@mui/material";

import useTranslations from '../../../hooks/useTranslations';

const Footer: React.FC = () => {
  const t = useTranslations();

  return (
    <Box sx={{
      p: 3,
      zIndex: (theme) => theme.zIndex.drawer + 2,
      background: '#FFF',
      borderTop: `solid 1px ${colors.grey[200]}`,
      display: 'flex',
      justifyContent: 'center',
      gap: 4,
    }}>
      <Link href="https://www.lexipol.com/privacy-policy/" target="_blank">{t('common.footer.privacy')}</Link>
      <Link href="https://www.lexipol.com/cookie-notice/" target="_blank">{t('common.footer.cookie')}</Link>
      <Link href="https://www.lexipol.com/privacy-policy/#donotsell" target="_blank">{t('common.footer.donotsell')}</Link>
    </Box>
  );
}

export default Footer;