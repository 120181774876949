import { useCallback, useEffect, useRef, useState } from 'react';

const getUrlParams = () => {
  const params = new URLSearchParams(window.location.hash.substr(1));
  return params;
};

const mergeUrlParams: (urlParams: any, data: any) => void = (urlParams, data) => {
  Object.entries(data).forEach(([key, val]) => {
    if (val === null || val === undefined) {
      urlParams.delete(key);
    } else {
      let v = val;
      if (typeof val === 'object' && val !== null) {
        v = JSON.stringify(val);
      }
      urlParams.set(key, v);
    }
  });
};

const mergeParamData: (urlParams: any, data: any) => void = (urlParams, data) => {
  const x = { ...data };
  Object.keys(x).forEach((key) => {
    if (urlParams.get(key) !== null && urlParams.get(key) !== undefined) {
      let v = urlParams.get(key);
      if (v && (v[0] === '[' || v[0] === '{')) {
        v = JSON.parse(v);
      }
      if (
        (['page', 'per_page'].includes(key) || key.endsWith('_id')) &&
        !Number.isNaN(Number(v))
      ) {
        v = Number(v);
      }
      x[key] = v;
    }
  });
  return x;
};

export default function useUrlParams(defaultParams: any) {
  const initialParams = mergeParamData(getUrlParams(), defaultParams);
  const [value, setValue] = useState<any>(initialParams);
  const unmounted = useRef<boolean>();
  const isPopstate = useRef<boolean>();
  unmounted.current = false;
  const prevValue = useRef();
  useEffect(() => {
    if (
      (!prevValue.current || prevValue.current !== value) &&
      !isPopstate.current
    ) {
      const params = getUrlParams();
      mergeUrlParams(params, value || defaultParams);
      window.history.replaceState(
        { ...params },
        '',
        `#${params.toString()}`
      );
    }
    prevValue.current = value;
    isPopstate.current = false;
  }, [defaultParams, value]);

  useEffect(() => {
    const updateValue = () => {
      if (!unmounted.current) {
        const data = mergeParamData(getUrlParams(), value);

        isPopstate.current = true;
        console.log('updateValue', data)
        setValue(data);
      }
    };
    window.addEventListener('popstate', updateValue);
    return () => {
      unmounted.current = true;
      window.removeEventListener('popstate', updateValue);
    };
  }, [value]);

  useEffect(() => {
    const updateValue = () => {
      const data = mergeParamData(getUrlParams(), value);
      console.log('updateValue:hashchange', data)
      setValue(data);
    };
    window.addEventListener('hashchange', () => { updateValue(); });
    return () => {
      window.removeEventListener('hashchange', updateValue);
    };
  }, [value]);

  const updateParams = useCallback((updatedParams: any) => {
    setValue((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setValue]);

  return [value, setValue, updateParams];
}
