import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  colors,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  MailOutlined as UnreadIcon,
  DraftsOutlined as ReadIcon,
} from '@mui/icons-material';

import { Notification } from 'types/notifications';
import notificationsHelper from '../notificationsHelper';

export type NotificationPopoverItemProps = {
  item: Notification;
  onClick?: () => void;
};

const NotificationPopoverItem = React.memo(({
  item,
  onClick,
}: NotificationPopoverItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/portal/notifications/${item.id}`)
    if (onClick) onClick();
  }

  return (
    <Box
      sx={{
        px: 5,
        py: 4,
        backgroundColor: "#FFF",
        borderBottom: `solid 1px ${colors.grey[100]}`,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: colors.grey[50]
        }
      }}
      onClick={handleClick}
    >
      <Stack spacing={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 4
          }}
        >
          <Typography
            variant='subtitle2'
            sx={{
              fontWeight: item.state === 'unread' ? 600 : 'inherit',
            }}
          >
            {notificationsHelper.getSubject(item)}
          </Typography>
          <IconButton size='small' onClick={(e) => { }}>
            {item.state === 'unread' ? <UnreadIcon fontSize='small' /> : <ReadIcon fontSize='small' />}
          </IconButton>
        </Box>
        {notificationsHelper.getMessageType(item) && <Typography variant='overline' sx={{ lineHeight: '1em' }}>{notificationsHelper.getMessageType(item)}</Typography>}
        <Typography
          variant='body1'
          component='div'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {notificationsHelper.getBodyText(item)}
        </Typography>
        {notificationsHelper.getTimeStamp(item) && <Typography variant='caption'>{notificationsHelper.getTimeStamp(item)}</Typography>}
      </Stack>
    </Box>
  );
});

export default NotificationPopoverItem;
