import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Download as DownloadIcon,
  Info
} from '@mui/icons-material';

import { AppContext } from "views/App/context";
import { UserImportsContext } from "../context";
import useTranslations from "hooks/useTranslations";
import FormView from "components/_layouts/FormView";
import { Form } from "components/_forms";
import FileUploader from "components/_atoms/FileUploader";
import { NewUserImportRequest } from "types/user_import";
import { ServerErrorResponse, baseApiUrl } from "data_sources/constants";
import datetimeHelper from "helpers/datetimeHelper";

const NewUserImport: React.FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { currentOrganizationId } = useContext(AppContext);
  const { createUserImport, newUserImportSchema } = useContext(UserImportsContext);
  const [serverErrors, setServerErrors] = useState<ServerErrorResponse>();
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    trigger,
  } = useForm<NewUserImportRequest>({
    values: {
      jobDescription: '',
      fileType: '',
    },
    resolver: yupResolver(newUserImportSchema),
  });

  const handleSave: SubmitHandler<NewUserImportRequest> = (values: NewUserImportRequest) => {
    setIsSaving(true);
    const requestData: NewUserImportRequest = { ...values };
    if (requestData.jobDescription.length === 0) {
      requestData.jobDescription = `User Import ${datetimeHelper.timestamp()}`
    }
    createUserImport(
      requestData,
      () => navigate('..'),
      (errors: ServerErrorResponse) => {
        setIsSaving(false);
        setServerErrors(errors);
      }
    );
  }

  const handleDownloadTemplate = () => {
    const idToken = localStorage.getItem('phx-idToken')?.toString();
    const url = `${baseApiUrl()}/lexid/admin-user/v1/organizations/${currentOrganizationId}/import-user-xlsx-template`;
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Api-Key': process.env.REACT_APP_API_GATEWAY_KEY || '',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': idToken || '',
      },
    })
      .then(resp => resp.blob())
      .then((blob) => {
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'user-import-template.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FormView
        title={t('userImports.new.title')}
        breadcrumbItems={[
          <Link key='b1' href='..'>
            {t('userImports.title')}
          </Link>,
          <Typography key='b2'>
            {t('userImports.new.title')}
          </Typography>
        ]}
        actions={(
          <>
            <Button color='neutral' href='..'>{t('common.cancel')}</Button>
            <Button
              onClick={() => {
                trigger();
              }}
              disabled={isSaving}
              component='button'
              type="submit"
              variant="contained"
            >
              {t('userImports.new.save')}
            </Button>
          </>
        )}
        errors={serverErrors}
      >
        <Stack spacing={6}>
          <Card>
            <CardContent>
              <Stack direction='row' spacing={5} sx={{ display: 'grid', gap: 4, gridTemplateColumns: '2fr 1fr' }}>
                <Typography variant='body1'>{t('userImports.new.downloadTemplate.instructions')}</Typography>
                <Stack spacing={2}>
                  <Button
                    variant="contained"
                    color="neutral"
                    onClick={handleDownloadTemplate}
                    startIcon={<DownloadIcon />}
                  >
                    {t('userImports.new.downloadTemplate.ctaText')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="info"
                    href={`/${encodeURIComponent('LexID User Import Guidance.pdf')}`}
                    target='_blank'
                    startIcon={<Info />}
                  >
                    {t('userImports.new.downloadTemplate.instructionsText')}
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <TextField
            id='name'
            key='name'
            label={t('userImports.properties.jobDescription')}
            variant="outlined"
            autoFocus
            fullWidth
            {...register('jobDescription')}
            error={!!errors.jobDescription}
            helperText={errors.jobDescription ? errors.jobDescription.message : undefined}
          />
          <Card>
            <CardHeader title={t('userImports.properties.importFile')} />
            <CardContent>
              <Stack spacing={6}>
                <Controller
                  name='fileContents'
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormControl key='fileContents'>
                      <FileUploader
                        onChange={onChange}
                        onError={(errors) => {
                          console.log('onError', errors)
                          if (errors && errors.length > 0) {
                            setError('fileContents', {
                              type: 'file-type-valid',
                              message: errors.map((x) => t(`userImports.errorMessages.${x}`)).join('; '),
                            })
                          } else {

                            clearErrors('fileContents');
                          }
                        }}
                        placeholder="Drag 'n' drop or click to select an XLS or XLSX import file"
                        uploaderOptions={{
                          accept: {
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls']
                          },
                          maxSize: 26214400,
                          maxFiles: 1,
                        }}
                      />
                      {errors.fileContents && (
                        <FormHelperText error>{errors.fileContents.message}</FormHelperText>
                      )}
                    </FormControl>
                  )} />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </FormView>
    </Form >
  );
}


export default NewUserImport;