import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Button, Link, Stack, Typography } from '@mui/material';

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useOrganizationUser from 'data_sources/users/useOrganizationUser';
import useUserRoles from 'data_sources/users/useUserRoles';
import useUserUserGroups from 'data_sources/users/useUserUserGroups';
import useUrlParams from 'hooks/useUrlParams';
import Overview from './Overview';
import Roles from './Roles';
import Groups from './Groups';
import Permissions from './Permissions';
import ManualPermissions from './ManualPermissions';
import PendingAcknowledgements from './PendingAcknowledgements';

type Tab = {
  label: React.ReactNode;
  key: string;
  component: React.ReactNode;
};

type UserUrlParams = {
  tab: string;
};

const defaultParams: UserUrlParams = {
  tab: 'overview',
};

export default function Details() {
  const { hasPermission, hasAppPermission, currentOrganizationId } =
    useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const userQuery = useOrganizationUser(currentOrganizationId || '', id || '');
  const [params, setParams] = useUrlParams(defaultParams);
  const userRolesQuery = useUserRoles(
    currentOrganizationId || '',
    id || '',
    {},
    { enabled: !!hasPermission('listUserRoles', currentOrganizationId) }
  );
  const userGroupsQuery = useUserUserGroups(
    currentOrganizationId || '',
    id || '',
    {},
    { enabled: !!hasPermission('listUserGroups', currentOrganizationId) }
  );

  const tabs: { [key: string]: Tab } = {
    overview: {
      label: t('users.details.tabs.details'),
      key: 'details',
      component: <Overview />,
    },
  };
  if (
    currentOrganizationId &&
    (hasPermission('addUserToRole', currentOrganizationId) ||
      hasPermission('deleteUserFromRole', currentOrganizationId)) &&
    hasPermission('listUserRoles', currentOrganizationId) &&
    hasPermission('listRoles', currentOrganizationId)
  ) {
    tabs.roles = {
      label: (
        <Stack
          direction='row'
          spacing={2}
        >
          <Typography sx={{ fontWeight: 600 }}>
            {t('users.details.tabs.roles')}
          </Typography>
          {userRolesQuery.data?.roles && (
            <Badge
              variant='inline'
              badgeContent={userRolesQuery.data.roles.length.toString()}
            />
          )}
        </Stack>
      ),
      key: 'roles',
      component: <Roles />,
    };
  }

  if (
    currentOrganizationId &&
    (hasPermission('addUserToGroup', currentOrganizationId) ||
      hasPermission('deleteUserFromGroup', currentOrganizationId)) &&
    hasPermission('listUserGroups', currentOrganizationId) &&
    hasPermission('listGroups', currentOrganizationId)
  ) {
    tabs.groups = {
      label: (
        <Stack
          direction='row'
          spacing={2}
        >
          <Typography sx={{ fontWeight: 600 }}>
            {t('users.details.tabs.groups')}
          </Typography>
          {userGroupsQuery.data?.groups && (
            <Badge
              variant='inline'
              badgeContent={userGroupsQuery.data?.groups.length.toString()}
            />
          )}
        </Stack>
      ),
      key: 'groups',
      component: <Groups />,
    };
  }
  if (
    currentOrganizationId &&
    hasPermission('listUserPermissions', currentOrganizationId)
  ) {
    tabs.permissions = {
      label: (
        <Stack
          direction='row'
          spacing={2}
        >
          <Typography sx={{ fontWeight: 600 }}>
            {t('users.details.tabs.permissions')}
          </Typography>
        </Stack>
      ),
      key: 'permissions',
      component: <Permissions />,
    };
  }
  if (
    currentOrganizationId &&
    hasPermission('getUserManualPermissions', currentOrganizationId)
  ) {
    tabs.manuals = {
      label: (
        <Stack
          direction='row'
          spacing={2}
        >
          <Typography sx={{ fontWeight: 600 }}>
            {t('users.details.tabs.manual_permissions')}
          </Typography>
        </Stack>
      ),
      key: 'manual_permissions',
      component: <ManualPermissions />,
    };
  }

  if (hasAppPermission('listManualAcknowledgements')) {
    tabs.assignments = {
      label: (
        <Stack
          direction='row'
          spacing={2}
        >
          <Typography sx={{ fontWeight: 600 }}>
            {t('users.details.tabs.pending_acknowledgements')}
          </Typography>
        </Stack>
      ),
      key: 'pending_acknowledgements',
      component: <PendingAcknowledgements />,
    };
  }

  const updateParams = React.useCallback(
    (updatedParams: UserUrlParams) => {
      setParams((prev: any) => ({
        ...prev,
        ...updatedParams,
      }));
      document.body.dispatchEvent(new Event('params-changed'));
    },
    [setParams]
  );

  return (
    <DetailView
      title={
        <Stack
          direction='row'
          spacing={2}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography variant='h5'>
            {[
              userQuery.data?.firstName,
              userQuery.data?.middleName,
              userQuery.data?.lastName,
            ].join(' ')}
          </Typography>
          {userQuery.data?.active !== undefined && (
            <Badge
              variant='inline'
              badgeContent={userQuery.data?.active ? 'Active' : 'Inactive'}
              color={userQuery.data?.active ? 'success' : 'error'}
              sx={{ textTransform: 'uppercase' }}
            />
          )}
        </Stack>
      }
      breadcrumbItems={[
        <Link
          key='b1'
          href='..'
        >
          {t('users.title')}
        </Link>,
        <Typography key='b4'>
          {[
            userQuery.data?.firstName,
            userQuery.data?.middleName,
            userQuery.data?.lastName,
          ].join(' ')}
        </Typography>,
      ]}
      toolbarLeft={
        <Stack
          direction='row'
          spacing={3}
        >
          {Object.entries(tabs).map(([key, tab]) => (
            <Button
              key={key}
              size='small'
              onClick={() => updateParams({ tab: key })}
              variant={key === params.tab ? 'contained' : 'text'}
            >
              {tab.label}
            </Button>
          ))}
        </Stack>
      }
      toolbarRight={
        <Button
          variant='contained'
          href={`../edit/${userQuery.data?.id}`}
        >
          {t('users.actions.edit')}
        </Button>
      }
      query={userQuery}
    >
      {tabs[params.tab]?.component}
    </DetailView>
  );
}
