import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import ArchivedManualProvider from "./context";
import ArchivedManualsList from './List';
import ArchivedManualsVersions from './Versions';

const ArchivedManuals: React.FC = () => {

  return (
    <ArchivedManualProvider>
      <Routes>
        <Route path='' element={<ArchivedManualsList />} />
        <Route path=':id' element={<ArchivedManualsVersions />} />
      </Routes>
    </ArchivedManualProvider>
  );
}


export default ArchivedManuals;
