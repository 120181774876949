import React from 'react';
import { Box, Button, colors, Stack, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import useUrlParams from 'hooks/useUrlParams';
import useNotifications from 'data_sources/notifications/useNotifications';
import errorHelper from 'helpers/errorHelper';
import NotificationPopoverItem from './popover_item';
import { isString } from 'lodash';
import Loading from '../Loading';
import notificationsHelper from './notificationsHelper';

type NotificationsParams = {
  id?: string | null;
};

const defaultParams: NotificationsParams = {
  id: null,
};

const parseData = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return { data: jsonString };
  }
};

type NotificationsPopoverProps = {
  onClose: () => void;
};

export default function NotificationsPopover({
  onClose,
}: NotificationsPopoverProps) {
  const t = useTranslations();
  const notificationsQuery = useNotifications({
    unreadOnly: true,
    limit: 6,
  });
  const [params, setParams, updateParams] = useUrlParams(defaultParams);

  const notifications = React.useMemo(
    () =>
      notificationsQuery.data && notificationsQuery.data.pages
        ? notificationsQuery.data.pages.flatMap((x) =>
            x.content.map((x) => ({
              ...x,
              notificationData: isString(x.notificationData)
                ? parseData(x.notificationData)
                : x.notificationData,
            }))
          )
        : [],
    [notificationsQuery.data]
  );

  const notificationGroups =
    notificationsHelper.groupNotifications(notifications);

  return (
    <Box
      className='notifications_popover'
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        backgroundColor: '#FFF',
        width: '350px',
        minHeight: '400px',
        maxHeight: '80vh',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <Box
          sx={{
            px: 4,
            py: 4,
            borderBottom: `solid 1px ${colors.grey[100]}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='subtitle1'>
            {t('notifications.title')}
          </Typography>
          <Button
            variant='outlined'
            size='small'
            href='/portal/notifications'
            onClick={onClose}
          >
            {t('notifications.viewAll')}
          </Button>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
          }}
        >
          {notificationsQuery.isError ? (
            errorHelper.renderErrors(notificationsQuery)
          ) : (
            <>
              {Object.entries(notificationGroups).map(([group, items]) =>
                items.length > 0 ? (
                  <Stack key={group}>
                    <Typography
                      component='div'
                      variant='overline'
                      sx={{
                        px: 4,
                        py: 1,
                        borderBottom: `solid 1px ${colors.grey[100]}`,
                      }}
                    >
                      {t(`notifications.groups.${group}`)}
                    </Typography>
                    {items.map((notification) => (
                      <NotificationPopoverItem
                        key={notification.id}
                        item={notification}
                        onClick={() => onClose()}
                      />
                    ))}
                  </Stack>
                ) : null
              )}
              {notificationsQuery.isLoading && <Loading />}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
