import React, { useMemo, useContext, useState, useEffect, useCallback, useRef } from 'react';
import parse, { DOMNode } from 'html-react-parser';
import { useQueryClient } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import { Badge, Box, Button, CircularProgress, Skeleton, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import { useTimeout } from 'hooks/useTimeout';
import { AppContext } from 'views/App/context';
import manualHelper from '../helpers/draftManualHelper';
import contentHelper from 'helpers/contentHelper';
import useDraftPreface from 'data_sources/draftManuals/useDraftPreface';
import useManagePreface from 'data_sources/draftManuals/useManagePreface';
import { DraftManualDetailContext } from '../context';
import { ContentPage, ContentPageContainer, TitleTextField } from '../styles';
import errorHelper from 'helpers/errorHelper';
import { DraftPreface } from 'types/manual';

import ContentEditor from '../ContentEditor';
import { ServerErrorResponse } from 'data_sources/constants';
import draftManualHelper from '../helpers/draftManualHelper';

type PrefaceEditorProps = {
  id: string;
};

/***********
 * VIEW - Not locked, no edit permission
 * DISABLED - preface is currently disabled
 * EDITABLE - Not locked, has edit permission
 * EDITING - locked by current user
 * LOCKED - locked by another user
 ******/
type PrefaceMode = 'VIEW' | 'DISABLED' | 'EDITABLE' | 'EDITING' | 'LOCKED';

const AUTOSAVE_INTERVAL = 60000;

const PrefaceEditor: React.FC<PrefaceEditorProps> = ({ id }: PrefaceEditorProps) => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { currentProfile } = useContext(AppContext);
  const { manual, manualHasPermissions } = useContext(DraftManualDetailContext);
  const prefaceQuery = useDraftPreface(manual.id, id);
  const { getLock, removeLock, takeLock, update: updatePreface } = useManagePreface();
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Initialize timerRef
  const [prefaceContent, setPrefaceContent] = useState<string>('');
  const [prefaceTitle, setPrefaceTitle] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [gettingLock, setGettingLock] = useState<boolean>(false);
  const [hasLock, setHasLock] = useState<string | null>(null);
  const [prefaceMode, setPrefaceMode] = useState<PrefaceMode>('VIEW');

  useEffect(() => {
    let mode: PrefaceMode = 'VIEW';
    if (manualHasPermissions(manual.id, ['updateDraftPreface']) && prefaceQuery.data) {
      if (prefaceQuery.data.lock) {
        if (prefaceQuery.data.lock.userId === currentProfile?.user?.id) {
          mode = 'EDITING';
          setHasLock(prefaceQuery.data.id);
        } else {
          mode = 'LOCKED';
          if (hasLock === prefaceQuery.data.id) {
            enqueueSnackbar(
              <div
                dangerouslySetInnerHTML={{
                  __html: t('draftManuals.preface.lostLock', '', {
                    user: draftManualHelper.formatPrefaceLockInfo(prefaceQuery.data),
                  }),
                }}
              />,
              {
                variant: 'error',
                autoHideDuration: 10000,
              }
            );
            setHasLock(null);
          }
        }
      } else {
        mode = 'EDITABLE';
      }
    }
    if (prefaceQuery?.data?.state === 'DISABLED') {
      mode = 'DISABLED';
    }
    if (prefaceMode === 'EDITING' && mode === 'DISABLED') {
      enqueueSnackbar(
        <div
          dangerouslySetInnerHTML={{
            __html: t('draftManuals.preface.savedButDisabled'),
          }}
        />,
        {
          variant: 'error',
          autoHideDuration: 10000,
        }
      );
    }

    if (mode !== prefaceMode) setPrefaceMode(mode);
  }, [manualHasPermissions, manual.id, prefaceQuery.data, currentProfile?.user?.id]);

  const handleModifyClick = () => {
    setGettingLock(true);
    getLock(
      manual.id,
      id,
      (lock) => {
        queryClient.invalidateQueries(['draft_manual', manual.id]);
        queryClient.invalidateQueries(['draft_preface', manual.id]);
        setGettingLock(false);
      },
      (error: ServerErrorResponse) => {
        errorHelper.enqueueErrors(error);
        setGettingLock(false);
      }
    );
  };

  const handleAcquireLockClick = () => {
    setGettingLock(true);
    confirm({
      cancellationButtonProps: {
        variant: 'outlined',
        color: 'neutral',
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      confirmationText: t('draftManuals.preface.acquireLock.ok'),
      description: t('draftManuals.preface.acquireLock.body'),
      title: t('draftManuals.preface.acquireLock.title'),
    })
      .then(() => {
        takeLock(
          manual.id,
          id,
          (lock) => {
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            queryClient.invalidateQueries(['draft_preface', manual.id, id]);
            setGettingLock(false);
          },
          (error: ServerErrorResponse) => {
            errorHelper.enqueueErrors(error);
            setGettingLock(false);
          }
        );
      })
      .catch(() => {
        // don't do it
        setGettingLock(false);
      });
  };

  useEffect(() => {
    if (prefaceQuery.data) {
      setPrefaceContent(prefaceQuery.data.content || '');
      setPrefaceTitle(prefaceQuery.data.title || '');
    }
  }, [prefaceQuery.data]);

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const updatedPreface: DraftPreface = {
      ...prefaceQuery.data!,
      title: prefaceTitle,
      content: prefaceContent,
    };
    updatePreface(
      manual.id,
      id,
      updatedPreface,
      () => {
        removeLock(
          manual.id,
          id,
          () => {
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            queryClient.invalidateQueries(['draft_preface', manual.id]);
            setIsSaving(false);
            setHasLock(null);
          },
          (error: ServerErrorResponse) => {
            errorHelper.enqueueErrors(error);
            setIsSaving(false);
          }
        );
      },
      (errors) => {
        if (errors.errorInformationCode === 3134) {
          queryClient.invalidateQueries(['draft_manual', manual.id]);
          queryClient.invalidateQueries(['draft_preface', manual.id, id]);
        } else {
          errorHelper.enqueueErrors(errors);
        }
        setIsSaving(false);
      }
    );
  }, [prefaceQuery.data, prefaceTitle, prefaceContent]);

  const autoSave = () => {
    if (prefaceMode === 'EDITING') {
      const updatedPreface: DraftPreface = {
        ...prefaceQuery.data!,
        title: prefaceTitle,
        content: prefaceContent,
      };
      updatePreface(
        manual.id,
        id,
        updatedPreface,
        () => {
          queryClient.invalidateQueries(['draft_manual', manual.id]);
          queryClient.invalidateQueries(['draft_preface', manual.id]);
        },
        (errors) => {
          if (errors.errorInformationCode === 3134) {
            queryClient.invalidateQueries(['draft_manual', manual.id]);
            queryClient.invalidateQueries(['draft_preface', manual.id, id]);
          } else {
            errorHelper.enqueueErrors(errors);
          }
          setIsSaving(false);
        }
      );
    }
  };

  useTimeout(() => {
    autoSave();
  }, AUTOSAVE_INTERVAL);

  const renderPrefaceActions = useCallback(() => {
    let actions: React.ReactNode = null;

    if (prefaceQuery.data && prefaceMode === 'EDITABLE') {
      actions = (
        <Button
          disabled={gettingLock}
          onClick={handleModifyClick}
        >
          {t('common.modify')}
        </Button>
      );
    }
    if (prefaceQuery.data && prefaceMode === 'EDITING') {
      actions = (
        <>
          <Button
            onClick={handleSave}
            disabled={isSaving}
          >
            {t('common.save')}
          </Button>
        </>
      );
    }
    if (prefaceQuery.data && prefaceMode === 'LOCKED') {
      actions = (
        <Stack
          direction='row'
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {prefaceQuery.data.lock && (
            <Tooltip title={draftManualHelper.formatPrefaceLockInfo(prefaceQuery.data)}>
              <LockIcon color='neutral' />
            </Tooltip>
          )}
          <Button
            onClick={handleAcquireLockClick}
            disabled={gettingLock}
          >
            {t('common.modify')}
          </Button>
        </Stack>
      );
    }
    if (prefaceMode === 'DISABLED') {
      actions = (
        <Badge
          variant='inline'
          badgeContent={t('common.disabled')}
          color='warning'
          sx={{ textTransform: 'uppercase' }}
        />
      );
    }

    return actions;
  }, [prefaceQuery.data, prefaceMode, prefaceContent, prefaceTitle]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <Stack>
        <Toolbar className='phx-panel-header'>
          <Stack
            direction='row'
            spacing={4}
            sx={{ flexGrow: 1 }}
          >
            {' '}
          </Stack>
          {(gettingLock || isSaving) && <CircularProgress />}
          <Stack
            direction='row'
            spacing={4}
            sx={{ ml: 2 }}
          >
            {renderPrefaceActions()}
          </Stack>
        </Toolbar>

        <ContentPageContainer>
          <ContentPage>
            <Stack spacing={4}>
              {prefaceQuery.data ? (
                <>
                  {prefaceMode === 'EDITING' ? (
                    <Box>
                      <Stack spacing={3}>
                        <TitleTextField
                          id={`title_${prefaceQuery.data.id}`}
                          key={`title_${prefaceQuery.data.id}`}
                          label={false}
                          variant='outlined'
                          fullWidth
                          value={prefaceTitle}
                          onChange={(e) => setPrefaceTitle(e.target.value)}
                        />
                        <ContentEditor
                          id={`content_${prefaceQuery.data.id}`}
                          key={`content_${prefaceQuery.data.id}`}
                          contentHTML={prefaceContent}
                          onSave={(content) => {
                            setPrefaceContent(content);
                          }}
                          isEditable
                        />
                      </Stack>
                    </Box>
                  ) : (
                    <Box>
                      <Stack spacing={1}>
                        <Typography
                          component='div'
                          variant='h5'
                        >
                          {manualHelper.formatManualNodeTitle(prefaceQuery.data, manual)}
                        </Typography>
                        <Box sx={{ minHeight: 0, overflow: 'auto' }}>
                          {parse(prefaceQuery.data?.content ?? '', {
                            replace(node: DOMNode, ind: number) {
                              return contentHelper.handleContent(node, ind);
                            },
                          })}
                        </Box>
                      </Stack>
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ minHeight: 0 }}>
                  {prefaceQuery?.isError && errorHelper.renderErrors(prefaceQuery)}
                  {prefaceQuery && (prefaceQuery.isLoading || prefaceQuery.isFetching) && (
                    <Stack spacing={2}>
                      <Skeleton
                        variant='rectangular'
                        height={28}
                        width='50%'
                      />
                      <Stack>
                        <Skeleton variant='text' />
                        <Skeleton variant='text' />
                        <Skeleton variant='text' />
                      </Stack>
                    </Stack>
                  )}
                </Box>
              )}
            </Stack>
          </ContentPage>
        </ContentPageContainer>
      </Stack>
    </Box>
  );
};

export default PrefaceEditor;
