import lodash from 'lodash';

import changePassword from './changePassword.json';
import common from './common.json';
import contentEditor from './contentEditor.json';
import dashboard from './dashboard.json';
import draftManuals from './draftManuals.json';
import errors from './errors.json';
import groups from './groups.json';
import login from './login.json';
import notifications from './notifications.json';
import organizations from './organizations.json';
import permissions from './permissions.json';
import policy from './policy.json';
import roles from './roles.json';
import users from './users.json';
import userImports from './userImports.json';

const translations = lodash.merge(
  changePassword,
  common,
  contentEditor,
  dashboard,
  draftManuals,
  errors,
  groups,
  login,
  notifications,
  organizations,
  permissions,
  policy,
  roles,
  users,
  userImports
);

export default translations;
