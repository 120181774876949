import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  ToggleButton,
  Toolbar,
  Typography,
} from '@mui/material';

import { Policy } from '../types/policy';
import manualHelper from '../helpers/manualHelper';
import { TitleTextField } from '../styles';
import { ActionMenu } from 'components/_atoms';
import SectionEditor from '../SectionEditor';
import PolicyStructureModal from './Modal/policyStructureModal';

type PolicyEditorProps = {
  id: string;
  onChange: (id: string, policy: Policy) => void;
  onSave: (policy: Policy) => void;
};

const PolicyEditor: React.FC<PolicyEditorProps> = ({
  id,
  onChange,
  onSave,
}: PolicyEditorProps) => {
  const [policyData, setPolicyData] = useState<Policy | null>(null);
  const [currentSection, setCurrentSection] = useState<string | null>(null);
  const [isPolicyEditable, setIsPolicyEditable] = useState<boolean>(false);
  const [hideContent, setHideContent] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const policy = manualHelper.getPolicy(id);
    setPolicyData(policy);
  }, [id]);

  const updatePolicyData = (updatedData: any) => {
    setPolicyData((prev) => ({ ...prev, ...updatedData }));
  };

  useEffect(() => {
    if (policyData) onChange(id, policyData);
  }, [id, onChange, policyData]);

  return policyData ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle1">{`Edit policy: ${manualHelper.formatManualNodeTitle(policyData)}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {isPolicyEditable ? (
              <>
                <ToggleButton
                  value="hideContent"
                  selected={hideContent}
                  onChange={() => {
                    handleOpenModal();
                  }}
                >
                  <span>Re Order Sections</span>
                </ToggleButton>
                <Button
                  onClick={() => {
                    onSave(policyData);
                    setIsPolicyEditable(false);
                    setHideContent(false);
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setIsPolicyEditable(true)}
                variant="contained"
              >
                Modify
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
      {isPolicyEditable && (
        <Box sx={{ p: 3 }}>
          <Toolbar
            className="phx-toolbar"
            sx={{ gap: 4, borderRadius: '4px !important' }}
          >
            <Stack direction="row" spacing={4} sx={{ flexGrow: 1 }}>
              <TitleTextField
                id={`title_${policyData.id}`}
                key={`title_${policyData.id}`}
                label={false}
                variant="outlined"
                fullWidth
                value={policyData?.title}
                onChange={({ target }) =>
                  updatePolicyData({ title: target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: 20,
                          fontWeight: 600,
                        },
                      }}
                    >
                      {manualHelper.getNumberManualNode(policyData)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack direction="row" spacing={4}>
              <ActionMenu id={`action_menu_${id}`} items={[]} />
            </Stack>
          </Toolbar>
        </Box>
      )}
      <Box sx={{ p: 3, pt: 0, minHeight: 0, overflow: 'auto' }}>
        <Box sx={{ minHeight: 0 }}>
          <Stack spacing={3}>
            {policyData.sections.map((section, sectionIndex) => (
              <Fragment key={section.id}>
                <Box onMouseDown={() => setCurrentSection(section.id)}>
                  <SectionEditor
                    key={section.id}
                    section={section}
                    sectionNumber={`${manualHelper.getNumberManualNode(policyData)}.${sectionIndex + 1}`}
                    onChange={(updatedSection) =>
                      updatePolicyData({
                        sections: [
                          ...policyData.sections.slice(0, sectionIndex),
                          updatedSection,
                          ...policyData.sections.slice(sectionIndex + 1),
                        ],
                      })
                    }
                    isEditable={
                      !!(currentSection === section.id) && isPolicyEditable
                    }
                  />
                </Box>
                {section.subSections && section.subSections.length > 0 && (
                  <Box className="policy-subsections" sx={{ px: 6 }}>
                    <Stack spacing={3}>
                      {section.subSections.map(
                        (subSection, subSectionIndex) => (
                          <Box
                            key={subSection.id}
                            onMouseDown={() => setCurrentSection(subSection.id)}
                          >
                            <SectionEditor
                              key={subSection.id}
                              section={subSection}
                              sectionNumber={`${manualHelper.getNumberManualNode(policyData)}.${sectionIndex + 1}.${subSectionIndex + 1}`}
                              onChange={(updatedSection) =>
                                updatePolicyData({
                                  sections: [
                                    ...policyData.sections.slice(
                                      0,
                                      sectionIndex
                                    ),
                                    {
                                      ...policyData.sections[sectionIndex],
                                      subSections: [
                                        ...policyData.sections[
                                          sectionIndex
                                        ].subSections.slice(0, subSectionIndex),
                                        updatedSection,
                                        ...policyData.sections[
                                          sectionIndex
                                        ].subSections.slice(
                                          subSectionIndex + 1
                                        ),
                                      ],
                                    },
                                    ...policyData.sections.slice(
                                      sectionIndex + 1
                                    ),
                                  ],
                                })
                              }
                              isEditable={
                                !!(currentSection === subSection.id) &&
                                isPolicyEditable
                              }
                            />
                          </Box>
                        )
                      )}
                    </Stack>
                  </Box>
                )}
              </Fragment>
            ))}
          </Stack>
        </Box>
      </Box>
      {isModalOpen && policyData && (
        <PolicyStructureModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          sections={policyData.sections}
          isEditable={isPolicyEditable}
          policyData={policyData}
          updatePolicyData={updatePolicyData}
        />
      )}
    </Box>
  ) : null;
};

export default PolicyEditor;
