import React from 'react';
import {
  Alert,
  Typography,
} from '@mui/material';

export default function Error() {
  return (
    <Alert severity='error'>
      <Typography variant='h5'>Something Went Wrong</Typography>
      <Typography variant='h6'>Please contact customer service for assistance.</Typography>
    </Alert>
  );
}
