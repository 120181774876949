import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import GroupsProvider from "./context";
import { AppContext } from '../../../context';
import GroupsList from './List';
import NewGroup from './New';
import EditGroup from './Edit';
import GroupDetails from './Details';

const Groups: React.FC = () => {
  const { enforcePermissions } = React.useContext(AppContext);

  return (
    <GroupsProvider>
      <Routes>
        <Route path='' element={enforcePermissions(['listGroups'], <GroupsList />)} />
        <Route path='new' element={enforcePermissions(['createGroup'], <NewGroup />)} />
        <Route path='edit/:id' element={enforcePermissions(['updateGroup'], <EditGroup />)} />
        <Route path=':id' element={enforcePermissions(['getGroup'], <GroupDetails />)} />
      </Routes>
    </GroupsProvider>
  );
}


export default Groups;
