import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import contentHelper from 'helpers/contentHelper';

interface AttributeMapFieldProps {
  id: string;
  initialValues: Record<string, string>;
  onChange: (updatedValues: Record<string, string>) => void;
}

const AttributeMapField: React.FC<AttributeMapFieldProps> = ({
  id,
  initialValues,
  onChange,
}) => {
  const t = useTranslations();
  const [values, setValues] = useState<Record<string, string>>(initialValues ?? {});

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof typeof initialValues,
  ) => {
    setValues((prev) => ({
      ...prev,
      [name]: event.target.value,
    }));
    onChange({ ...values, [name]: event.target.value });
  };

  return (
    <Stack spacing={3}>
      {Object.entries(values).map(([key, _]) => (
        <TextField
          key={key}
          label={t(`organizations.attributeMap.${key}`, contentHelper.humanize(key))}
          value={values[key]}
          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, key as keyof typeof initialValues)}
          margin="normal"
          fullWidth
        />
      ))}
    </Stack>
  );
};

export default AttributeMapField;