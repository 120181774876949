import { TreeItems, FlattenedItem } from '../types/policy';

export const moveItem = (
  items: FlattenedItem[],
  activeId: string,
  overId: string | null,
  newParentId: string | null,
  newDepth: number,
  position: 'before' | 'after' | 'inside' = 'after'
): FlattenedItem[] => {
  if (newDepth > 1) {
    console.warn(
      `Cannot move item ${activeId} to depth ${newDepth}. Maximum allowed is 1.`
    );
    alert(
      'Maximum depth of 1 exceeded. Subsections cannot have their own subsections.'
    );
    return items;
  }

  const descendants = getDescendants(items, activeId);

  const itemsToMove = items.filter(
    (item) => item.id === activeId || descendants.includes(item.id)
  );
  let remainingItems = items.filter(
    (item) => !itemsToMove.some((moveItem) => moveItem.id === item.id)
  );

  const activeItem = itemsToMove.find((item) => item.id === activeId);
  if (!activeItem) {
    console.warn(`Active item with id ${activeId} not found.`);
    return items;
  }

  const updatedActiveItem: FlattenedItem = {
    ...activeItem,
    parentId: newParentId,
    depth: newDepth,
  };

  const updatedItemsToMove = itemsToMove.map((item) => {
    if (item.id === activeId) {
      return updatedActiveItem;
    }
    return {
      ...item,
      parentId: updatedActiveItem.id,
      depth: newDepth + (item.depth - activeItem.depth),
    };
  });

  let insertionIndex = remainingItems.length;
  if (overId) {
    const overIndex = remainingItems.findIndex((item) => item.id === overId);
    if (overIndex !== -1) {
      if (position === 'before') {
        insertionIndex = overIndex;
      } else if (position === 'after') {
        insertionIndex = overIndex + 1;
      } else if (position === 'inside') {
        insertionIndex = overIndex + 1;
      }
    }
  }

  const newItems = [
    ...remainingItems.slice(0, insertionIndex),
    ...updatedItemsToMove,
    ...remainingItems.slice(insertionIndex),
  ];

  return newItems;
};

const getDescendants = (items: FlattenedItem[], parentId: string): string[] => {
  const descendants: string[] = [];
  const stack: string[] = [parentId];

  while (stack.length > 0) {
    const currentId = stack.pop()!;
    const children = items.filter((item) => item.parentId === currentId);
    children.forEach((child) => {
      descendants.push(child.id);
      stack.push(child.id);
    });
  }

  return descendants;
};

export const buildTree = (flatList: FlattenedItem[]): TreeItems => {
  const idToNodeMap: { [key: string]: FlattenedItem } = {};
  const tree: TreeItems = [];

  flatList.forEach((item) => {
    idToNodeMap[item.id] = { ...item, children: [] };
  });

  flatList.forEach((item) => {
    if (item.parentId) {
      const parent = idToNodeMap[item.parentId];
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(idToNodeMap[item.id]);
      }
    } else {
      tree.push(idToNodeMap[item.id]);
    }
  });

  return tree;
};

export const flattenTree = (
  tree: TreeItems,
  depth = 0,
  parentId: string | null = null
): FlattenedItem[] => {
  let flattened: FlattenedItem[] = [];
  tree.forEach((item: any) => {
    flattened.push({
      ...item,
      depth,
      parentId,
      sectionNumber: '',
    });
    if (item.children && item.children.length > 0) {
      flattened = flattened.concat(
        flattenTree(item.children, depth + 1, item.id)
      );
    }
  });
  return flattened;
};

export const isDescendant = (
  activeId: string,
  potentialParentId: string | null,
  tree: TreeItems
): boolean => {
  if (!potentialParentId || activeId === potentialParentId) return false;

  const activeItem = findItem(tree, activeId);
  if (!activeItem) {
    console.warn(`Active item with id ${activeId} not found.`);
    return false;
  }

  return searchInSubtree(activeItem.children || [], potentialParentId);
};

const searchInSubtree = (items: TreeItems, targetId: string): boolean => {
  for (const item of items) {
    if (item.id === targetId) {
      return true;
    }
    if (item.children && searchInSubtree(item.children, targetId)) {
      return true;
    }
  }
  return false;
};

const findItem = (items: TreeItems, id: string): FlattenedItem | null => {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      const found = findItem(item.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
