import { useInfiniteQuery } from 'react-query';
import { Organization } from '../../types/organization';
import { ApiCollectionResponse } from '../../types/api_response';
import { baseApiUrl, fetcher } from '../constants';
import { ServerErrorResponse } from '../constants';
export default function useOrganizations<T extends Organization>(
  params: Record<string, any> = {},
  options: object = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/admin-org/v1/organizations`;
  return useInfiniteQuery<ApiCollectionResponse<T>, Error>(
    ['organizations', params],
    async ({ pageParam = 0, signal }): Promise<ApiCollectionResponse<T>> => {
      const paramString = new URLSearchParams(
        Object.entries({
          ...params,
          offset: pageParam,
        })
          .filter(([_k, v]) => v != null)
          .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
      ).toString();
      const data: ApiCollectionResponse<T> = await fetcher({
        method: 'get',
        url: `${url}?${paramString}`,
        options: { signal },
      });
      return data;
    },
    {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        // console.log('Next page data:', lastPage);
        if (
          lastPage?.offset != null &&
          lastPage?.count != null &&
          lastPage.offset < lastPage.count &&
          Array.isArray(lastPage.content) &&
          lastPage.content.length > 0
        ) {
          return lastPage.offset + lastPage.content.length;
        }
        return undefined;
      },
    }
  );
}
