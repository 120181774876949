import { CreatAdHocAcknowledgementRequest } from '../../types/acknowledgements';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageAcknowledgements() {
  const create = async (manualId: string, data: CreatAdHocAcknowledgementRequest, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexipub/acknowledgements/v1/admin/manuals/${manualId}/adhoc`;
    await fetcher({
      url,
      method: 'post',
      body: data,
    }).then((data) => {
      if (callback) callback(data.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  /* const destroy = async (organizationId: string, id: string, callback?: (data: Group) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/user-group/v1/organizations/${organizationId}/groups/${id}`;
    await fetcher({
      url,
      method: 'delete',
    }).then((data) => {
      if (callback) callback(data);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  } */

  return {
    create,
    // destroy,
  }
}