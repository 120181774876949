import {
  baseApiUrl,
  fetcher,
  handleError,
  ServerErrorResponse,
} from '../constants';

export default function useManageUserAcknowledgement() {
  const cancelAcknowledgment = async (
    userId: string,
    manualId: string,
    acknowledgementId: string,
    callback?: () => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexipub/acknowledgements/v1/admin/users/${userId}/manuals/${manualId}/acknowledgements/${acknowledgementId}`;
    await fetcher({
      method: 'delete',
      url,
      body: {},
    })
      .then(() => {
        console.log('cancel acknowledgment success', callback);
        if (callback) callback();
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  return {
    cancelAcknowledgment,
  };
}
