/* eslint-disable class-methods-use-this */
import React from 'react';
import { Alert } from '@mui/material';
import useTranslations from 'hooks/useTranslations';
import { ServerError, ServerErrorResponse } from 'data_sources/constants';
import { Warning } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
export default class errorHelper {
  static renderErrors = (query: any) => {
    const t = useTranslations();
    if (!query.error) return null;
    // console.log('error', typeof query.error.message);
    let message = t('errors.3');
    let code: number | null = null;
    if (query.error?.response?.status === 403) {
      message = t('errors.forbidden');
    } else if (query.error?.response?.message?.length > 0) {
      message = query.error?.response?.message;
    } else if (query.error?.message?.length > 0) {
      const err = JSON.parse(query.error.message);
      if (err) {
        message = err.detail;
        code = err.errorInformationCode;
      }
    }

    return <Alert severity="error">{t(`errors.${code}`) || message}</Alert>;
  };
  static renderAllErrors = (
    serverErrors?: ServerErrorResponse,
    schemaErrors?: object
  ) => {
    const t = useTranslations();
    const errorDetails: ServerError[] = [];
    if (serverErrors?.errorDetails && serverErrors?.errorDetails.length > 0) {
      serverErrors.errorDetails.forEach((e) => errorDetails.push(e));
    } else if (serverErrors) {
      const rootError: ServerError = {
        code: serverErrors?.errorInformationCode || 0,
        detail: serverErrors?.detail || '',
        message: serverErrors?.errorMessage || serverErrors?.message || '',
      };
      errorDetails.push(rootError);
    }
    if (schemaErrors && Object.keys(schemaErrors).length > 0) {
      Object.entries(schemaErrors).forEach(([k, v]) => {
        errorDetails.push({
          code: 0,
          detail: v.message,
          message: v.message,
        });
      });
    }

    return errorDetails && errorDetails.length > 0 ? (
      <Alert
        icon={<Warning />}
        severity="error"
        sx={{ alignItems: 'center', mb: 5 }}
      >
        <ul className="error-list" style={{ margin: '2px 0' }}>
          {errorDetails.map((x, i) => (
            <li
              key={`server_errors_${i}`}
              data-errors={JSON.stringify(x?.detail)}
            >
              {t(`errors.${x.code}`) || x.message}
            </li>
          ))}
        </ul>
      </Alert>
    ) : null;
  };

  static enqueueErrors = (errors: ServerErrorResponse) => {
    const t = useTranslations();
    let message = errors?.message || 'An unexpected error occurred.';
    let code: number | null = errors.errorInformationCode || null;
    console.log('error', errors);
    if (code === 403) {
      message = t('errors.forbidden');
    } else if (errors?.message?.length && errors?.message?.length > 0) {
      message = errors.message;
    } else if (errors?.detail?.length && errors?.detail?.length > 0) {
      message = errors.detail;
    }

    // if I have more than one Error
    if (errors?.errorDetails && errors.errorDetails.length > 0) {
      const detailedMessages = errors.errorDetails.map(
        (e) => `${t(`errors.${e.code}`)}: ${e.detail}`
      );
      message = detailedMessages.join(' ');
    }

    enqueueSnackbar(t(`errors.${code}`) || message, { variant: 'error' });
  };
}
