import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppContext } from '../../context';
import ArchivedManuals from './ArchivedManuals';
import LegacyArchivedManuals from './LegacyArchivedManuals';
import DraftManuals from './DraftManuals';
import IssuedManuals from './IssuedManuals';
import Citations from './Citations';
import CitationDetail from './Citations/CitationDetail';

export default function Policy() {
  const { hasAppPermission, enforceAppPermissions, setCurrentModule } =
    useContext(AppContext);

  const getDefaultRoute = () => {
    if (hasAppPermission('listDraftManuals')) {
      return <Navigate to='./draft-manuals' />;
    } else if (hasAppPermission('listIssuedManuals')) {
      return <Navigate to='./issued-manuals' />;
    } else if (hasAppPermission('listArchivedManuals')) {
      return <Navigate to='./archived-manuals' />;
    } else {
      return <Navigate to='/dashboard' />;
    }
  };

  useEffect(() => {
    setCurrentModule('policy');
  }, [setCurrentModule]);
  return (
    <Routes>
      <Route
        path=''
        element={getDefaultRoute()}
      />
      <Route
        path='archived-manuals/*'
        element={enforceAppPermissions(
          ['listArchivedManuals'],
          <ArchivedManuals />
        )}
      />
      <Route
        path='draft-manuals/*'
        element={enforceAppPermissions(['listDraftManuals'], <DraftManuals />)}
      />
      <Route
        path='issued-manuals/*'
        element={enforceAppPermissions(
          ['listIssuedManuals'],
          <IssuedManuals />
        )}
      />
      <Route
        path='legacy-archived-manuals/*'
        element={enforceAppPermissions(
          ['listArchivedManuals'],
          <LegacyArchivedManuals />
        )}
      />
      <Route
        path='citations'
        element={<Citations />}
      />
      <Route
        path='citations/:id'
        element={<CitationDetail />}
      />
    </Routes>
  );
}
