import * as React from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';

import { $setBlocksType } from '@lexical/selection';

import {
  $createHeadingNode,
  $createQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text';

import { $createCodeNode } from '@lexical/code';

import { blockTypeToBlockName } from './index';
import { translate as t } from 'hooks/useTranslations';

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

type BlockFormatMenuProps = {
  blockType: keyof typeof blockTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
};

export default function BlockFormatMenu({
  editor,
  blockType,
  rootType,
  disabled = false,
}: BlockFormatMenuProps) {
  // console.log('blockType', blockType)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
      handleClose();
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
    handleClose();
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
    handleClose();
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    }
    handleClose();
  };

  return (
    <Box>
      <Button
        id='block_format_menu_button'
        aria-controls={open ? 'block_format_menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        size='small'
        color='neutral'
        disabled={disabled}
      >
        {blockTypeToBlockName[blockType] ??
          t('contentEditor.toolbar.formatPlaceholder')}
      </Button>
      <Menu
        id='block_format_menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          selected={blockType === 'paragraph'}
          onClick={formatParagraph}
        >
          <Typography>{t('contentEditor.toolbar.normal')}</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'h1'}
          onClick={() => formatHeading('h1')}
        >
          <Typography>{t('contentEditor.toolbar.h1')}</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'h2'}
          onClick={() => formatHeading('h2')}
        >
          <Typography>{t('contentEditor.toolbar.h2')}</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'h3'}
          onClick={() => formatHeading('h3')}
        >
          <Typography>{t('contentEditor.toolbar.h3')}</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'quote'}
          onClick={formatQuote}
        >
          <Typography>{t('contentEditor.toolbar.quote')}</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'code'}
          onClick={formatCode}
        >
          <Typography>{t('contentEditor.toolbar.code')}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
