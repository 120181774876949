import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserImportsProvider from "./context";
import { AppContext } from '../../../context';
import UserImportsList from './List';
import NewUserImport from './New';
import UserImportDetails from './Details';

const UserImports: React.FC = () => {
  const { enforcePermissions } = React.useContext(AppContext);

  return (
    <UserImportsProvider>
      <Routes>
        <Route path='' element={enforcePermissions(['listJobsImportUsers'], <UserImportsList />)} />
        <Route path='new' element={enforcePermissions(['importUserFile'], <NewUserImport />)} />
        <Route path=':id' element={enforcePermissions(['viewJobImportUsers'], <UserImportDetails />)} />
      </Routes>
    </UserImportsProvider>
  );
}


export default UserImports;
