import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  AccountCircle as ProfileIcon,
  ArrowBack,
  Menu as MenuIcon,
} from '@mui/icons-material';

import { AppContext } from "views/App/context";
import MainNav from "./MainNav";
import useOrganization from "data_sources/organizations/useOrganization";
import AuthUtil from "utils/AuthUtil";
import NotificationsNavIcon from "./Notifications/NotificationsNavIcon";

export type TopNavProps = {
  signOut?: WithAuthenticatorProps['signOut'];
};

const TopNav: React.FC<TopNavProps> = ({ signOut }) => {
  const navigate = useNavigate();
  const { currentProfile, isRoot, showNav, setShowNav, currentOrganizationId, isMobile, hasPermission } = useContext(AppContext);
  let defaultOrgName = localStorage.getItem('phx-currentOrganizationName')?.toString();
  if (defaultOrgName?.length === 0) defaultOrgName = currentProfile?.organization?.name || ''
  const [inOrgContext, setInOrgContext] = useState<boolean>(false);
  const organizationQuery = useOrganization(currentOrganizationId, {
    enabled: !!currentOrganizationId && hasPermission('getOrg', currentOrganizationId)
  });

  React.useEffect(() => {
    const inOrg = currentProfile?.user?.orgId && currentOrganizationId !== currentProfile?.user?.orgId;
    setInOrgContext(inOrg || false);
  }, [currentOrganizationId, currentProfile?.user?.orgId]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    AuthUtil.clearStorage();
    if (signOut) signOut();
    localStorage.clear();
    setAnchorEl(null);
    navigate('/login')
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowNav(!showNav);
  };

  return (
    <AppBar color="primary" position="static" sx={{
      zIndex: (theme) => theme.zIndex.drawer + 2,
    }}>
      <Toolbar sx={{
        flexGrow: 1,
        px: isMobile ? 2 : 12,
      }}>
        <Stack direction='row' spacing={3} sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          {isMobile && (
            <>
              <IconButton
                onClick={handleMenuClick}
                aria-controls={showNav ? undefined : 'basic-menu'}
                aria-haspopup='true'
                aria-expanded={showNav ? 'false' : 'true'}
              >
                <MenuIcon sx={{ color: '#FFF' }} />
              </IconButton>
              <MainNav signOut={signOut} />
            </>
          )}
          {inOrgContext ? (
            <>
              {
                organizationQuery.data && organizationQuery.data.organization && (
                  <Typography variant='h5'>{organizationQuery.data.organization.name}</Typography>
                )
              }

              <Button
                variant='text'
                href="/portal/switch-org"
                sx={{ color: '#FFF' }}
                startIcon={<ArrowBack />}
              >
                Return to Lexipol
              </Button>
            </>
          ) : (
            <>
              {currentProfile && currentProfile.organization &&
                currentProfile.organization?.name?.toLowerCase() !== 'lexipol' ? (
                <Stack spacing={3} direction='row' sx={{ alignItems: 'center' }}>
                  <img
                    src='/lexipol_icon_logo_white.png'
                    style={{ height: 24 }}
                    alt='Lexipol'
                  />
                  <Typography variant='h5'>{currentProfile?.organization?.name}</Typography>
                </Stack>
              ) : (
                <img
                  src='/lexipol_logo_white.png'
                  style={{ height: 24 }}
                  alt='Lexipol'
                />
              )}
            </>
          )}

        </Stack>
        <Stack direction='row'>
          {!isRoot && (
            <NotificationsNavIcon />
          )}
          {signOut && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <ProfileIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSignout}>Log out</MenuItem>
                {currentProfile?.user?.authenticationType === 'PASSWORD' && (
                  <MenuItem onClick={() => {
                    navigate('./change-password');
                    handleClose()
                  }}>Change Password</MenuItem>
                )}
              </Menu>
            </div>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default TopNav;