import React, { useState } from 'react';
import { UniqueIdentifier, useDndMonitor, useDroppable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Box, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FlattenedItem } from '../types/policy';
import TreeSectionEditor from '../SectionEditor/TreeSectionEditor';
import manualHelper from '../helpers/manualHelper';

interface SortableTreeItemProps {
  id: string;
  section: FlattenedItem;
  sectionNumber: string;
  depth: number;
  indentationWidth: number;
  hasChildren: boolean;
  onSectionChange: (section: FlattenedItem) => void;
  policyData: any;
}

const SortableTreeItem: React.FC<SortableTreeItemProps> = ({
  id,
  section,
  sectionNumber,
  depth,
  indentationWidth,
  onSectionChange,
  policyData,
}) => {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({ id });

  const [dragPosition, setDragPosition] = useState<'top' | 'bottom' | null>(
    null
  );
  const [onDragOverId, setOnDragOverId] = useState<UniqueIdentifier | null>(
    null
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const { isOver: isOverTop, setNodeRef: setTopRef } = useDroppable({
    id: `${id}-top`,
  });

  const { isOver: isOverBottom, setNodeRef: setBottomRef } = useDroppable({
    id: `${id}-bottom`,
  });

  const style = {
    opacity: isDragging ? 0.5 : 1,
    cursor: isDragging ? 'grabbing' : 'grab',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: isDragging ? '#e0e0e0' : '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginBottom: '8px',
    padding: '8px',
  };

  useDndMonitor({
    onDragMove(event) {
      const { delta, over, active } = event;
      if (over) {
        setOnDragOverId(over.id);
        setActiveId(active.id);
        let overId = over.id.toString();
        if (overId.endsWith('-bottom') || overId.endsWith('-top')) {
          overId = overId.split('-').slice(0, -1).join('-');
        }
        const activeId = event.active.id.toString();
        if (overId === activeId) {
          return;
        }
        if (delta.y < 0) {
          setDragPosition('top');
        } else {
          setDragPosition('bottom');
        }
      }
    },
    onDragEnd(event) {
      setDragPosition(null);
      setOnDragOverId(null);
      setActiveId(null);
    },
    onDragCancel() {
      setDragPosition(null);
      setOnDragOverId(null);
      setActiveId(null);
    },
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        ref={setTopRef}
        sx={{
          marginLeft: depth > 0 ? '25px' : '0px',
          height: dragPosition === 'top' ? '25px' : '0px',
          backgroundColor: isOverTop ? '#e3f2fd' : 'transparent',
          border: isOverTop ? '2px dashed #90caf9' : '2px dashed transparent',
          borderRadius: 2,
          transition: 'background-color 0.2s, border-color 0.2s',
        }}
      />

      <Box
        ref={setNodeRef}
        style={{
          ...style,
          marginLeft: depth * indentationWidth,
        }}
      >
        <IconButton
          size="small"
          {...attributes}
          {...listeners}
          sx={{
            marginRight: '8px',
            cursor: 'grab',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {onDragOverId === section.id ? (
            <ChevronRightIcon />
          ) : (
            <DragIndicatorIcon />
          )}
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <TreeSectionEditor
            section={section}
            sectionNumber={`${manualHelper.getNumberManualNode(policyData)}.${sectionNumber}`}
            onChange={(updatedSection: FlattenedItem) =>
              onSectionChange(updatedSection)
            }
          />
        </Box>
      </Box>
      {!dragPosition && (
        <Box
          sx={{
            height: '25px',
          }}
        />
      )}
      <Box
        sx={{
          marginLeft: '25px',
          height:
            onDragOverId === section.id && activeId !== section.id
              ? '25px'
              : '0px',
          backgroundColor:
            onDragOverId === section.id && activeId !== section.id
              ? '#c8e6c9'
              : 'transparent',
          borderRadius: 2,
          border:
            onDragOverId === section.id && activeId !== section.id
              ? '2px dashed #66bb6a'
              : '0px dashed transparent',
          transition: 'background-color 0.2s, border-color 0.2s',
        }}
      />

      <Box
        ref={setBottomRef}
        sx={{
          marginLeft: depth > 0 ? '25px' : '0px',
          height: dragPosition === 'bottom' ? '25px' : '0px',
          backgroundColor: isOverBottom ? '#e3f2fd' : 'transparent',
          borderRadius: 2,
          border: isOverBottom
            ? '2px dashed #90caf9'
            : '2px dashed transparent',
          transition: 'background-color 0.2s, border-color 0.2s',
        }}
      />
    </Box>
  );
};

export default SortableTreeItem;
