import React, { useCallback, useContext, useMemo } from 'react';
import {
  QueryClient,
  UseInfiniteQueryResult,
  useQueryClient,
} from 'react-query';
import * as Yup from 'yup';

import { AppContext } from 'views/App/context';
import useUrlParams from 'hooks/useUrlParams';
import useIssuedManuals from 'data_sources/issuedManuals/useIssuedManuals';
import { ManualItem, ManualPermissions } from 'types/manual';
import { ApiCollectionResponse } from 'types/api_response';
import useStickyState from 'hooks/useStickyState';

type IssuedManualsProviderProps = {
  children: React.ReactNode;
};

type IssuedManualsUrlParams = {
  q?: string | null;
};

export type IssuedManualsProviderResponse = {
  clearParams: () => void;
  params: IssuedManualsUrlParams;
  queryClient: QueryClient | null;
  updateParams: (updatedParams: IssuedManualsUrlParams) => void;
  manuals: ManualItem[];
  manualsQuery: UseInfiniteQueryResult<
    ApiCollectionResponse<ManualItem>,
    Error
  >;
  manualSchema: any;
  manualPermissions: ManualPermissions | null;
  orgId: string;
  manualHasPermissions: (manualId: string, keys: string[]) => boolean;
};

const defaultParams: IssuedManualsUrlParams = {
  q: null,
};

export const IssuedManualsContext =
  React.createContext<IssuedManualsProviderResponse>(
    {} as IssuedManualsProviderResponse
  );

const IssuedManualsProvider: ({
  children,
}: IssuedManualsProviderProps) => any = ({ children }) => {
  const queryClient = useQueryClient();
  const { currentOrganizationId } = useContext(AppContext);
  const orgId = currentOrganizationId;
  const [params, setParams] = useUrlParams(defaultParams);
  const [manualPermissions, setManualPermissions] =
    useStickyState<ManualPermissions | null>('phx-manualPermissions', null);
  const updateParams = useCallback(
    (updatedParams: IssuedManualsUrlParams) => {
      setParams((prev: any) => ({
        ...prev,
        ...updatedParams,
      }));
      document.body.dispatchEvent(new Event('params-changed'));
    },
    [setParams]
  );

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams = {};

    return newParams;
  }, []);

  const manualData = useIssuedManuals(orgId, queryParams, {
    onSuccess: (data: any) => {
      const mPerms: ManualPermissions = {};
      (data.pages
        ? data.pages.flatMap((x: any) => x.content)
        : ([] as ManualItem[])
      ).forEach((m: ManualItem) => {
        mPerms[m.id] = (m.authorizations ?? []).map((x) => x.action).sort();
      });
      setManualPermissions(mPerms);
    },
  });

  const manualHasPermissions = useCallback(
    (manualId: string, keys: string[]) => {
      let permissionKeys: string[] = [];
      if (manualPermissions && manualPermissions[manualId]) {
        permissionKeys = manualPermissions[manualId];
      }
      return keys.every((x) =>
        permissionKeys.map((x) => x.toLowerCase()).includes(x.toLowerCase())
      );
    },
    [manualPermissions]
  );

  const manualSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required('Name is required'),
  });

  const manuals = useMemo(
    () =>
      manualData.data && manualData.data.pages
        ? manualData.data.pages.flatMap((x) => x.content)
        : [],
    [manualData.data]
  );

  const contextValue: IssuedManualsProviderResponse = useMemo(
    () => ({
      clearParams,
      params,
      queryClient,
      updateParams,
      manualsQuery: manualData,
      manuals: manuals,
      manualPermissions,
      manualHasPermissions,
      manualSchema,
      orgId,
    }),
    [
      clearParams,
      params,
      queryClient,
      updateParams,
      manualData,
      manuals,
      manualPermissions,
      manualHasPermissions,
      manualSchema,
      orgId,
    ]
  );

  return (
    <IssuedManualsContext.Provider value={contextValue}>
      {children}
    </IssuedManualsContext.Provider>
  );
};

export default IssuedManualsProvider;
