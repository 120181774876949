import { baseApiUrl, defaultHeaders, handleError, ServerErrorResponse } from '../constants';

export default function useManageGroupManual() {
  const addManual = async (organizationId: string, roleId: string, manualId: string, personaId: string, callback?: () => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-manual-authorization/v1/organizations/${organizationId}/roles/${roleId}/manuals/${manualId}/personas/${personaId}`;
    await fetch(
      url,
      {
        method: 'post',
        body: '{}',
        headers: defaultHeaders(),
      }
    ).then(() => {
      if (callback) callback();
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const removeManual = async (organizationId: string, roleId: string, manualId: string, personaId: string, callback?: () => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/admin-manual-authorization/v1/organizations/${organizationId}/roles/${roleId}/manuals/${manualId}/personas/${personaId}`;
    await fetch(
      url,
      {
        method: 'delete',
        body: '{}',
        headers: defaultHeaders(),
      }
    ).then(() => {
      if (callback) callback();
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    addManual,
    removeManual,
  }
}