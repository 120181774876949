import React, { useState } from 'react';
import parse, { DOMNode } from 'html-react-parser';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  colors,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { Manual, ManualChapter, ManualPreface, Policy, Section, SubSection } from './manual';
import contentHelper from './contentHelper';


export default function ManualViewer() {
  const [manual, setManual] = useState<Manual | null>(null);
  const [manuals, setManuals] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [selectedManualId, setSelectedManualId] = useState<string | null>(null);

  React.useEffect(() => {
    if (selectedManualId) {
      const sUrl = `https://dj8ibq5dbhvjr.cloudfront.net/${selectedManualId}`;

      fetch(sUrl).then((resp) => resp.json()).then((s) => {
        if (s) setManual(s)
      });
    }
  }, [selectedManualId]);

  React.useEffect(() => {
    fetch('https://dj8ibq5dbhvjr.cloudfront.net/?delimiter=/&prefix=manuals/')
      .then((resp) => resp.text())
      .then((xml: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xml, 'text/xml');
        const results: string[] = [];
        doc.childNodes[0].childNodes.forEach((node) => {
          if (node.nodeName === 'Contents') {
            let key = '';
            node.childNodes.forEach((child) => {
              if (child.nodeName === 'Key') key = child.textContent || '';
            });
            if (key.startsWith('manuals/') && key.endsWith('.json')) {
              results.push(key);
            }
          }
        });
        // console.log('results', results)
        setManuals(results);
        setSelectedManualId(results[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleTreeSelection = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    setSelectedNode(itemId)
  }

  const renderManual = (manual: Manual) => {
    const chapters = manual.chapters
    return (
      <Stack spacing={5}>
        {manual.prefaces.map((x) => renderPreface(x))}
        {chapters.map((x) => renderChapter(x))}
      </Stack>
    )
  }

  const renderPreface = (preface: ManualPreface) => {
    return (
      <Box key={preface.guid}>
        <Typography component='div' variant='h5'>{parse(contentHelper.formatLabel(preface))}</Typography>
        {parse(preface?.content?.value ?? '', {
          replace(node: DOMNode, ind: number) {
            return contentHelper.handleContent(node, ind);
          }
        })}
      </Box>
    )
  }
  const renderChapter = (chapter: ManualChapter) => {
    return (
      <Box key={chapter.guid}>
        <Typography component='div' variant='h3'>{parse(contentHelper.formatLabel(chapter))}</Typography>
        {chapter.policies && chapter.policies?.length > 0 && (
          <Stack spacing={5}>
            {chapter.policies.map((p) => renderPolicy(p))}
          </Stack>
        )}
      </Box>
    )
  }

  const renderPolicy = (policy: Policy) => {
    return (
      <Box key={policy.guid}>
        <Typography component='div' variant='h4'>{parse(contentHelper.formatLabel(policy))}</Typography>
        {policy.sections?.length > 0 && (
          <Stack spacing={5}>
            {policy.sections.map((s) => renderSection(s))}
          </Stack>
        )}
      </Box>
    )
  }

  const renderSection = (section: Section) => {
    return (
      <Box key={section.guid}>
        <Typography component='div' variant='h5'>{parse(contentHelper.formatLabel(section))}</Typography>
        {parse(section?.content?.value ?? '', {
          replace(node: DOMNode, ind: number) {
            return contentHelper.handleContent(node, ind);
          }
        })}
        {section.subSections?.length > 0 && (
          <Stack spacing={5}>
            {section.subSections.map((ss) => renderSubSection(ss))}
          </Stack>
        )}
      </Box>
    )
  }

  const renderSubSection = (section: SubSection) => {
    return (
      <Box key={section.guid}>
        <Typography component='div' variant='h6'>{parse(contentHelper.formatLabel(section))}</Typography>
        {parse(section?.content?.value ?? '', {
          replace(node: DOMNode, ind: number) {
            return contentHelper.handleContent(node, ind);
          }
        })}
      </Box>
    )
  }

  const renderSelectedNode = (manual: Manual, guid: string | null) => {
    if (!manual) return null;
    let content;
    if (guid) {
      manual.prefaces.forEach((pr) => {
        if (pr.guid === guid) content = renderPreface(pr)
      })
      manual.chapters.forEach((ch) => {
        if (ch.guid === guid) content = renderChapter(ch);
        (ch.policies ?? []).forEach((pol) => {
          if (pol.guid === guid) content = renderPolicy(pol)
          pol.sections.forEach((sec) => {
            if (sec.guid === guid) content = renderSection(sec)
            sec.subSections.forEach((ss) => {
              if (ss.guid === guid) content = renderSubSection(ss)
            })
          })
        })
      })
    }
    return content ?? renderManual(manual);
  }

  return (
    <Box sx={{
      p: 2,
      '& del': {
        textDecoration: 'none',
        color: '#b30000',
        background: colors.red[100],
      },
      '& ins': {
        textDecoration: 'none',
        color: '#406619',
        background: colors.green[100],
      },
    }}>
      <Card>
        <CardHeader
          title='Manual Comparison'
          subheader={(
            <Stack direction='row' spacing={2}>
              <Select
                id='source_manual'
                size='small'
                value={selectedManualId !== null ? selectedManualId : ''}
                onChange={({ target }) => setSelectedManualId(target.value)}
              >
                <MenuItem key={`source_manual_default`} value=''>Select a manual</MenuItem>
                {manuals.map((k) => (
                  <MenuItem key={`source_manual${k}`} value={k}>{k.replace('manuals/', '')}</MenuItem>
                ))}
              </Select>
            </Stack>
          )}
          sx={{
            backgroundColor: colors.grey[100],
            borderBottom: `solid 1px ${colors.grey[200]}`,
            p: 6,
          }}
          action={(
            <Stack direction='row' spacing={2}>
            </Stack>
          )}
        />
        <CardContent sx={{ p: 0 }}>
          {manual && (
            <Box
              sx={{
                height: '80vh',
                display: 'grid',
                gridTemplateColumns: '1fr 3fr',
              }}
            >
              <SimpleTreeView
                onItemSelectionToggle={handleTreeSelection}
                sx={{
                  height: '85vh',
                  overflowY: 'auto',
                }}
              >
                {manual.prefaces.map((pr) => (
                  <TreeItem
                    key={pr.guid}
                    itemId={pr.guid}
                    label={parse(contentHelper.formatLabel(pr))}
                    sx={{
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      '& .MuiTreeItem-content': {
                        py: 2
                      }
                    }}
                  />
                ))}
                {manual.chapters.map((ch) => (
                  <TreeItem
                    key={ch.guid}
                    itemId={ch.guid}
                    label={parse(contentHelper.formatLabel(ch))}
                    sx={{
                      borderBottom: `solid 1px ${colors.grey[300]}`,
                      '& .MuiTreeItem-content': {
                        py: 2
                      }
                    }}
                  >
                    {(ch.policies ?? []).map((pol) => (
                      <TreeItem
                        key={pol.guid}
                        itemId={pol.guid}
                        label={parse(contentHelper.formatLabel(pol))}
                        sx={{
                          borderBottom: `solid 1px ${colors.grey[300]}`,
                          '& .MuiTreeItem-content': {
                            py: 2
                          }
                        }}
                      >
                        {(pol.sections ?? []).map((sec) => (
                          <TreeItem
                            key={sec.guid}
                            itemId={sec.guid}
                            label={parse(contentHelper.formatLabel(sec))}
                            sx={{
                              borderBottom: `solid 1px ${colors.grey[300]}`,
                              '& .MuiTreeItem-content': {
                                py: 2
                              }
                            }}
                          >
                            {(sec.subSections ?? []).map((sec) => (
                              <TreeItem
                                key={sec.guid}
                                itemId={sec.guid}
                                label={parse(contentHelper.formatLabel(sec))}
                                sx={{
                                  borderBottom: `solid 1px ${colors.grey[300]}`,
                                  '& .MuiTreeItem-content': {
                                    py: 2
                                  }
                                }}
                              />
                            ))}
                          </TreeItem>
                        ))}
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </SimpleTreeView>
              <Box sx={{
                p: 5,
                borderLeft: `solid 1px ${colors.grey[300]}`,
                height: '85vh',
                overflowY: 'auto'
              }}>
                {renderSelectedNode(manual, selectedNode)}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

