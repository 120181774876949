import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';
import { NotificationUpdateState } from 'types/notifications';

declare global {
  var processingNotificationIds: string[];
}

export default function useManageNotification() {
  const updateState = async (notificationId: string, state: string, callback?: () => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/notifications/v1/admin`;
    window.processingNotificationIds = window.processingNotificationIds ?? [];
    if (!window.processingNotificationIds.includes(notificationId)) {
      window.processingNotificationIds.push(notificationId);
      const data: NotificationUpdateState = {
        content: [
          {
            id: notificationId,
            state,
          }
        ]
      }
      await fetcher({
        method: 'patch',
        url,
        body: data,
      }).then((resp) => {
        window.processingNotificationIds = window.processingNotificationIds.filter((x) => x !== notificationId);
        if (callback) callback();
      })
        .catch((err) => {
          if (onError) handleError(err, { onError })
        });
    }
  }

  return {
    updateState,
  }
}