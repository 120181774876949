import './ColorPicker.css';
import { calculateZoomLevel } from '@lexical/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';

let skipAddingToHistoryStack = false;

interface ColorPickerProps {
  color: string;
  onChange?: (value: string, skipHistoryStack: boolean) => void;
}

const basicColors = [
  '#ffffff',
  '#000000',
  '#d0021b',
  '#f5a623',
  '#f8e71c',
  '#8b572a',
  '#7ed321',
  '#417505',
  '#bd10e0',
  '#9013fe',
  '#4a90e2',
  '#50e3c2',
  '#b8e986',
  '#4a4a4a',
  '#9b9b9b',
];

const DEFAULT_COLOR = '';

export default function ColorPicker({
  color,
  onChange,
}: Readonly<ColorPickerProps>): JSX.Element {
  const [selfColor, setSelfColor] = useState(transformColor('hex', color));
  const [inputColor, setInputColor] = useState(color);

  const saturationRef = useRef<HTMLDivElement>(null);
  const hueRef = useRef<HTMLDivElement>(null);

  const [saturationDimensions, setSaturationDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [hueWidth, setHueWidth] = useState(0);

  useEffect(() => {
    // Update dimensions when the component mounts or when refs change
    function updateDimensions() {
      if (saturationRef.current) {
        const { width, height } = saturationRef.current.getBoundingClientRect();
        setSaturationDimensions({ width, height });
      }
      if (hueRef.current) {
        const { width } = hueRef.current.getBoundingClientRect();
        setHueWidth(width);
      }
    }

    updateDimensions();

    // Also update dimensions when the window resizes
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const saturationPosition = useMemo(
    () => ({
      x: (selfColor.hsv.s / 100) * saturationDimensions.width,
      y: ((100 - selfColor.hsv.v) / 100) * saturationDimensions.height,
    }),
    [selfColor.hsv.s, selfColor.hsv.v, saturationDimensions]
  );

  const huePosition = useMemo(
    () => ({
      x: (selfColor.hsv.h / 360) * hueWidth,
    }),
    [selfColor.hsv.h, hueWidth]
  );

  const onSetHex = (hex: string) => {
    setInputColor(hex);
    if (/^#[0-9A-Fa-f]{6}$/i.test(hex)) {
      const newColor = transformColor('hex', hex);
      setSelfColor(newColor);
      if (onChange) {
        onChange(newColor.hex, false);
      }
    }
  };

  const onMoveSaturation = ({ x, y }: Position) => {
    const { width, height } = saturationDimensions;
    const newHsv = {
      ...selfColor.hsv,
      s: (x / width) * 100,
      v: 100 - (y / height) * 100,
    };
    const newColor = transformColor('hsv', newHsv);
    setSelfColor(newColor);
    setInputColor(newColor.hex);
    if (onChange) {
      onChange(newColor.hex, false);
    }
  };

  const onMoveHue = ({ x }: Position) => {
    const newHsv = { ...selfColor.hsv, h: (x / hueWidth) * 360 };
    const newColor = transformColor('hsv', newHsv);
    setSelfColor(newColor);
    setInputColor(newColor.hex);
    if (onChange) {
      onChange(newColor.hex, false);
    }
  };

  useEffect(() => {
    if (color === undefined) {
      return;
    }
    const newColor = transformColor('hex', color);
    setSelfColor(newColor);
    setInputColor(newColor.hex);
  }, [color]);

  return (
    <div className="color-picker-wrapper">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <TextField
          label="Hex"
          variant="outlined"
          size="small"
          value={inputColor}
          onChange={(e) => onSetHex(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          onClick={() => {
            setInputColor('');
            setSelfColor(transformColor('hex', '#000000'));
            if (onChange) {
              onChange(DEFAULT_COLOR, false);
            }
          }}
          title="Reset to Default Color"
          sx={{ ml: 1, mt: 4 }}
        >
          <EditOffIcon />
        </IconButton>
      </Box>

      <div className="color-picker-basic-color">
        {basicColors.map((basicColor) => (
          <button
            className={basicColor === selfColor.hex ? ' active' : ''}
            key={basicColor}
            style={{ backgroundColor: basicColor }}
            onClick={() => {
              setInputColor(basicColor);
              setSelfColor(transformColor('hex', basicColor));
              if (onChange) {
                onChange(basicColor, false);
              }
            }}
          />
        ))}
      </div>
      <MoveWrapper
        ref={saturationRef}
        className="color-picker-saturation"
        style={{ backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)` }}
        onChange={onMoveSaturation}
      >
        <div
          className="color-picker-saturation_cursor"
          style={{
            backgroundColor: selfColor.hex,
            left: `${saturationPosition.x}px`,
            top: `${saturationPosition.y}px`,
          }}
        />
      </MoveWrapper>
      <MoveWrapper
        ref={hueRef}
        className="color-picker-hue"
        onChange={onMoveHue}
      >
        <div
          className="color-picker-hue_cursor"
          style={{
            backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)`,
            left: `${huePosition.x}px`,
          }}
        />
      </MoveWrapper>
      <div
        className="color-picker-color"
        style={{ backgroundColor: selfColor.hex }}
      />
    </div>
  );
}

export interface Position {
  x: number;
  y: number;
}

interface MoveWrapperProps {
  className?: string;
  style?: React.CSSProperties;
  onChange: (position: Position) => void;
  children: JSX.Element;
}

// Update MoveWrapper to forward the ref
const MoveWrapper = React.forwardRef<HTMLDivElement, MoveWrapperProps>(
  function MoveWrapper({ className, style, onChange, children }, ref) {
    const draggedRef = useRef(false);

    const move = (e: React.MouseEvent | MouseEvent): void => {
      if (ref && typeof ref !== 'function' && ref.current) {
        const div = ref.current;
        const { width, height, left, top } = div.getBoundingClientRect();
        const zoom = calculateZoomLevel(div);
        const x = clamp(e.clientX / zoom - left, width, 0);
        const y = clamp(e.clientY / zoom - top, height, 0);

        onChange({ x, y });
      }
    };

    const onMouseDown = (e: React.MouseEvent): void => {
      if (e.button !== 0) {
        return;
      }

      move(e);

      const onMouseMove = (_e: MouseEvent): void => {
        draggedRef.current = true;
        skipAddingToHistoryStack = true;
        move(_e);
      };

      const onMouseUp = (_e: MouseEvent): void => {
        if (draggedRef.current) {
          skipAddingToHistoryStack = false;
        }

        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseup', onMouseUp, false);

        move(_e);
        draggedRef.current = false;
      };

      document.addEventListener('mousemove', onMouseMove, false);
      document.addEventListener('mouseup', onMouseUp, false);
    };

    return (
      <div
        ref={ref}
        className={className}
        style={style}
        onMouseDown={onMouseDown}
      >
        {children}
      </div>
    );
  }
);

function clamp(value: number, max: number, min: number) {
  return value > max ? max : value < min ? min : value;
}

interface RGB {
  r: number;
  g: number;
  b: number;
}
interface HSV {
  h: number;
  s: number;
  v: number;
}
interface Color {
  hex: string;
  hsv: HSV;
  rgb: RGB;
}

export function toHex(value: string): string {
  if (!value.startsWith('#')) {
    const ctx = document.createElement('canvas').getContext('2d');

    if (!ctx) {
      throw new Error('2d context not supported or canvas already initialized');
    }

    ctx.fillStyle = value;

    return ctx.fillStyle;
  } else if (value.length === 4 || value.length === 5) {
    value = value
      .split('')
      .map((v, i) => (i ? v + v : '#'))
      .join('');

    return value;
  } else if (value.length === 7 || value.length === 9) {
    return value;
  }

  return '#000000';
}

function hex2rgb(hex: string): RGB {
  const rgbArr = (
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g) || []
  ).map((x) => parseInt(x, 16));

  return {
    r: rgbArr[0],
    g: rgbArr[1],
    b: rgbArr[2],
  };
}

function rgb2hsv({ r, g, b }: RGB): HSV {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const d = max - min;

  let h = 0;
  if (d === 0) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / d) % 6;
  } else if (max === g) {
    h = (b - r) / d + 2;
  } else if (max === b) {
    h = (r - g) / d + 4;
  }
  h *= 60;
  if (h < 0) h += 360;

  const s = max === 0 ? 0 : (d / max) * 100;
  const v = max * 100;

  return { h, s, v };
}

function hsv2rgb({ h, s, v }: HSV): RGB {
  s /= 100;
  v /= 100;

  const i = Math.floor(h / 60) % 6;
  const f = h / 60 - Math.floor(h / 60);
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  let r = 0,
    g = 0,
    b = 0;

  switch (i) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
      break;
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

function rgb2hex({ r, g, b }: RGB): string {
  return '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('');
}

function transformColor<M extends keyof Color, C extends Color[M]>(
  format: M,
  color: C
): Color {
  let hex: Color['hex'] = '#000000';
  let rgb: Color['rgb'] = { r: 0, g: 0, b: 0 };
  let hsv: Color['hsv'] = { h: 0, s: 0, v: 0 };

  if (format === 'hex') {
    const value = color as Color['hex'];

    hex = toHex(value);
    rgb = hex2rgb(hex);
    hsv = rgb2hsv(rgb);
  } else if (format === 'rgb') {
    const value = color as Color['rgb'];

    rgb = value;
    hex = rgb2hex(rgb);
    hsv = rgb2hsv(rgb);
  } else if (format === 'hsv') {
    const value = color as Color['hsv'];

    hsv = value;
    rgb = hsv2rgb(hsv);
    hex = rgb2hex(rgb);
  }

  return { hex, hsv, rgb };
}
