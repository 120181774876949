import React from 'react';
import jp from 'jsonpath';
import numeral from 'numeral';
import parse from 'html-react-parser';
import {
  Box,
  Breadcrumbs,
  colors,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
  GridCellParams,
  useGridApiRef,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license';

import useTranslations from '../../../hooks/useTranslations';
import contentHelper from 'helpers/contentHelper';
import errorHelper from 'helpers/errorHelper';
import Loading from 'views/App/Portal/Loading';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY ?? '');

export interface ColumnDef {
  [key: string]: unknown;
}

export type FieldActions = {
  [key: string]: (params: GridCellParams) => void;
};

export type GridViewProps = {
  breadcrumbItems?: React.ReactNode[];
  children?: React.ReactNode;
  columns?: ColumnDef;
  data: any[];
  query: any;
  rowAction?: (data: any) => void;
  subtitle?: string | null;
  title: string;
  toolbarLeft?: React.ReactNode;
  toolbarRight?: React.ReactNode;
};

const GridView: React.FC<GridViewProps> = ({
  breadcrumbItems = [],
  children = [],
  columns = {},
  data = [],
  query,
  rowAction = () => {},
  subtitle = null,
  title,
  toolbarLeft = null,
  toolbarRight = null,
}: GridViewProps) => {
  const t = useTranslations();
  const gridApiRef = useGridApiRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fieldActions: FieldActions = {};
  // console.log('gridView render');

  React.useEffect(() => {
    if (gridApiRef.current?.setRowCount && query.data) {
      gridApiRef.current.setRowCount(query.data.pages[0]?.count);
      // console.log('Row count set to:', query.data.pages[0]?.count);
    }
  }, [query.data, gridApiRef]);

  const CustomGridToolbar = () => (
    <GridToolbarContainer
      sx={{
        p: 2,
        borderBottom: `solid 1px ${colors.grey[200]}`,
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
        csvOptions={{
          fileName: title,
        }}
        slotProps={{
          button: {
            variant: 'outlined',
          },
        }}
      />
    </GridToolbarContainer>
  );

  const getValueByPath = (rowData: any, path: string) => {
    let val;
    if (rowData) {
      const cellVal = jp.value(rowData, path);
      if (cellVal) val = cellVal;
    }
    return val;
  };

  const getFormatter = (options: any) => {
    if (options.formatter) return options.formatter;
    if (options.type === 'number') return (value: any) => value ?? 0;
    return null;
  };

  const getRenderer = (options: any) => {
    if (options.renderer) return options.renderer;
    if (options.type === 'number')
      return (params: GridCellParams) =>
        Number(params.value) >= 0 ? numeral(params.value).format('0,0') : '--';
    return null;
  };

  const columnDefs = Object.entries(columns).map(([field, options]) => {
    const columnOptions = options as any;
    if (columnOptions.onClick) fieldActions[field] = columnOptions.onClick;
    return {
      field,
      name: field,
      headerName: columnOptions.header ?? contentHelper.humanize(field),
      valueGetter: columnOptions.valueGetter
        ? (value: any, row: any) => columnOptions.valueGetter(value, row)
        : (value: any, row: any) => getValueByPath(row, field),
      type: columnOptions.type ?? 'string',
      valueOptions: columnOptions.valueOptions,
      cellClassName:
        columnOptions.cellClassName ??
        (columnOptions.onClick ? 'phx-link-cell' : ''),
      align: columnOptions.align ?? 'left',
      headerAlign: columnOptions.align ?? 'left',
      flex: columnOptions.flex ?? 1,

      ...(getFormatter(options)
        ? {
            valueFormatter: (value: any) => {
              const func = getFormatter(options);
              return func(value);
            },
          }
        : {}),
      ...(getRenderer(options)
        ? {
            renderCell: (params: any) => {
              const func = getRenderer(options);
              return func(params);
            },
          }
        : {}),
      ...(columnOptions.columnProps || {}),
    };
  });

  const handleCellClick = (params: GridCellParams) => {
    if (fieldActions[params.field]) {
      fieldActions[params.field](params);
    }
  };

  function CustomFooter(
    props: NonNullable<GridSlotsComponentsProps['footer']>
  ) {
    const currentCount = data.length || 0;
    const totalCount = query.data?.pages?.[0]?.count || 0;

    return (
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'end',
          gap: 3,
          height: 36,
          alignItems: 'center',
        }}
      >
        {query.isFetchingNextPage && <CircularProgress size={36} />}
        {numeral(currentCount).format('0,0')} of{' '}
        {numeral(totalCount).format('0,0')}
      </Box>
    );
  }

  const handleRowsScrollEnd = () => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage().catch((error: any) => {
        console.error('Error fetching next page:', error);
      });
    } else {
      // console.log('No more pages to fetch or already fetching.');
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
      }}
    >
      <Box
        className='page-header'
        sx={{
          px: 5,
          py: 4,
          borderBottom: 1,
          borderColor: colors.grey[100],
          backgroundColor: '#FFF',
          zIndex: 1000,
        }}
      >
        <Stack spacing={1}>
          {breadcrumbItems && breadcrumbItems.length > 0 && (
            <Breadcrumbs aria-label='breadcrumb'>{breadcrumbItems}</Breadcrumbs>
          )}
          <Typography variant='subtitle1'>{parse(title)}</Typography>
          {subtitle && (
            <Typography variant='body1'>{parse(subtitle)}</Typography>
          )}
        </Stack>
      </Box>
      <Box sx={{ p: 4 }}>
        {(toolbarLeft || toolbarRight) && (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={3}
            className={`phx-toolbar${isMobile ? '--mobile' : ''}`}
          >
            <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
              {toolbarLeft}
            </Stack>
            {toolbarRight && (
              <Stack direction='row' spacing={4} sx={{ justifySelf: 'end' }}>
                {toolbarRight}
              </Stack>
            )}
          </Stack>
        )}
      </Box>
      <Box
        sx={{
          p: 4,
          pt: 0,
          minHeight: 0,
          overflow: 'auto',
          [`.${gridClasses.cell}.phx-link-cell`]: {
            color: '#004B87',
            fontWeight: 600,
            cursor: 'pointer',
          },
        }}
      >
        {query.isError ? (
          errorHelper.renderErrors(query)
        ) : (
          <>
            {query.isLoading && !query.isFetchingNextPage ? (
              <Loading />
            ) : (
              <DataGridPro
                apiRef={gridApiRef}
                rows={data}
                columns={columnDefs}
                onCellClick={handleCellClick}
                rowSelectionModel={[]}
                sx={{
                  borderRadius: 0,
                }}
                slots={{
                  toolbar: CustomGridToolbar,
                  footer: CustomFooter,
                }}
                disableColumnMenu
                disableColumnSorting
                hideFooterPagination
                initialState={{
                  density: 'standard',
                }}
                onRowsScrollEnd={handleRowsScrollEnd}
              />
            )}
          </>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default GridView;
