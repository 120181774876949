import lodash from 'lodash';

import enUsTranslations from '../translations/en-us';

const LOCALES: { [key: string]: any } = {
  'en-us': enUsTranslations,
};

const currentLocale: string = window.localStorage.getItem('locale') || 'en-us';
const translations = LOCALES[currentLocale];
// console.log('!!', currentLocale, translations);

export const translate = (
  key: string,
  defaultResult: string = '',
  interpolations: { [key: string]: string } = {}
): string => {
  let result = lodash.get(translations, `${currentLocale}.${key}`);
  if (result && Object.keys(interpolations).length > 0) {
    // console.log('translate', result, interpolations);
    Object.entries(interpolations).forEach(([k, v]) => {
      result = result.replace(`%{${k}}`, v);
    });
  }

  return result ?? defaultResult;
};

export default function useTranslations() {
  if (typeof window !== 'undefined') {
    return translate;
  }
  return (
    key: string,
    _defaultResult: string = '',
    interpolations: { [key: string]: string } = {}
  ): string => {
    return key;
  };
}
