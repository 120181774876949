import dayjs from "dayjs";

export default class datetimeHelper {
  static formats = {
    default: { weekday: 'short', year: "numeric", month: "short", day: "numeric" },
    short: { year: "numeric", month: "numeric", day: "numeric" },
    long: { weekday: 'long', year: "numeric", month: "long", day: "numeric" },
  };

  static shortDateFormat = 'MM/DD/YYYY';

  static datetimeNoDayFormat = 'MMM D, YYYY LT';

  static defaultDateTimeFormat = 'llll';

  static getFormat = (format: string): any => {
    let result: any = this.formats.default;
    Object.entries(this.formats).forEach(([k, v]) => {
      if (k === format) result = v;
    });
    return result;
  }

  static formatDate = (datetimeStr: string | undefined | null, options?: { format?: string, missingDisplay?: string }) => {
    const defaultOptions = {
      format: 'default',
      missingDisplay: '',
    };
    const { format, missingDisplay } = {
      ...defaultOptions,
      ...options,
    };
    if (!datetimeStr) return missingDisplay;

    if (isNaN(Date.parse(datetimeStr))) return missingDisplay;

    return dayjs(datetimeStr).format(this.shortDateFormat)
  };

  static formatDateTime = (datetimeStr: string | undefined, options?: { format?: string, missingDisplay?: string }) => {
    const defaultOptions = {
      format: 'default',
      missingDisplay: '',
    };
    const { format, missingDisplay } = {
      ...defaultOptions,
      ...options,
    };
    if (!datetimeStr) return missingDisplay;

    if (isNaN(Date.parse(datetimeStr))) return missingDisplay;

    const dt = new Date(datetimeStr);

    return `${dt.toLocaleDateString('en-us')} at ${dt.toLocaleTimeString('en-us', { hour: 'numeric', minute: '2-digit' })}`
  };

  static timestamp = () => {
    const ts = new Date();
    return `${ts.toLocaleDateString()} - ${ts.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
  }
}
