import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useHashState<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get the current value from the URL hash
  const urlValue = searchParams.get(key);
  let currentValue = urlValue ? urlValue as T : defaultValue;

  // Function to update the value in the URL hash
  const setHashState = (newValue: any) => {
    setSearchParams({ [key]: newValue });
  };

  return [currentValue, setHashState];
}