import React, { useEffect, useState } from 'react';
import { Box, Button, colors, InputAdornment, Stack, Typography } from '@mui/material';
import { Add as AddIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import ContentEditor from '../ContentEditor';
import { TitleTextField } from '../styles';
import { ActionMenu } from 'components/_atoms';
import { DraftPolicySection, DraftPolicySubSection } from 'types/manual';
type SectionEditorProps = {
  section: DraftPolicySection | DraftPolicySubSection;
  sectionNumber: string;
  onChange: (section: DraftPolicySection | DraftPolicySubSection) => void;
  isEditable?: boolean;
  isDisabled?: boolean;
  isSubSection?: boolean;
  isLastItem?: boolean;
  insertSection: () => void;
  insertSubSection: () => void;
  setCurrentSection: (sectionId: string) => void;
  viewOnly?: boolean;
};
const SectionEditor: React.FC<SectionEditorProps> = ({
  section,
  sectionNumber,
  onChange,
  isEditable = false,
  isDisabled = false,
  isSubSection = false,
  isLastItem = false,
  insertSection,
  insertSubSection,
  setCurrentSection,
  viewOnly = true,
}: SectionEditorProps) => {
  const [sectionData, setSectionData] = useState<DraftPolicySection | DraftPolicySubSection>(section);
  const [showAddingSection, setShowAddingSection] = useState<boolean>(false);
  const [showAddingSubsection, setShowAddingSubsection] = useState<boolean>(false);
  const updateSectionData = (updatedData: any) => {
    setSectionData((prev) => ({ ...prev, ...updatedData }));
  };
  useEffect(() => {
    if (sectionData) onChange(sectionData);
  }, [sectionData]);

  return sectionData ? (
    <Box
      id={`section_${sectionData.id}`}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ml: isSubSection ? 5 : 0,
        '& .section-footer': {
          opacity: 0,
        },
        '&:hover .section-footer': {
          opacity: 1,
        },
      }}
    >
      <Box>
        <Box
          className={isEditable ? 'phx-toolbar-edit' : 'phx-toolbar-view'}
          sx={{ gap: 4, borderRadius: '4px !important', display: 'flex' }}
        >
          <>
            {isEditable ? (
              <>
                <Stack
                  direction='row'
                  spacing={4}
                  sx={{ flexGrow: 1 }}
                >
                  <TitleTextField
                    id={`title_${sectionData.id}`}
                    key={`title_${sectionData.id}`}
                    label={false}
                    variant='outlined'
                    fullWidth
                    value={sectionData?.title}
                    onChange={({ target }) => updateSectionData({ title: target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position='start'
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: 20,
                              fontWeight: 600,
                            },
                          }}
                        >
                          {sectionNumber}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      readOnly: !isEditable,
                    }}
                  />
                </Stack>
                <Stack
                  direction='row'
                  spacing={4}
                >
                  <ActionMenu
                    id={`action_menu_${section.id}`}
                    items={[]}
                  />
                </Stack>
              </>
            ) : (
              <Stack>
                <Typography
                  className={`title_${sectionData.id}`}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    opacity: !isDisabled ? 1 : 0.5,
                  }}
                  onMouseDown={() => {
                    if (!isDisabled) setCurrentSection(sectionData.id);
                  }}
                >
                  {!isDisabled ? sectionNumber + ': ' + sectionData?.title : sectionData?.title}
                </Typography>
              </Stack>
            )}
          </>
        </Box>
      </Box>

      <Box
        sx={{
          p: 0,
          minHeight: 0,
          overflow: 'auto',
          opacity: !isDisabled ? 1 : 0.5,
        }}
        onMouseDown={() => {
          if (!isDisabled) setCurrentSection(sectionData.id);
        }}
      >
        <ContentEditor
          id={`content_${sectionData.id}`}
          key={`content_${sectionData.id}_${isEditable}`}
          contentHTML={sectionData.content}
          onSave={(content) => updateSectionData({ content: content })}
          isEditable={isEditable && !viewOnly}
        />
      </Box>
      <Stack spacing={2}>
        <Box
          className='section-footer'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {viewOnly ? (
            <Box></Box>
          ) : (
            <>
              <Box
                sx={{
                  border: `solid 1px ${colors.grey[200]}`,
                  borderRadius: 1,
                  px: 3,
                  py: 0,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, auto)',
                  alignItems: 'center',
                }}
              >
                {!isDisabled && (
                  <>
                    {' '}
                    {(!isSubSection || (isSubSection && isLastItem)) && (
                      <>
                        <Button
                          variant='text'
                          size='small'
                          startIcon={<AddIcon />}
                          color='neutral'
                          sx={{ whiteSpace: 'nowrap' }}
                          onMouseOver={() => setShowAddingSection(true)}
                          onMouseOut={() => setShowAddingSection(false)}
                          onClick={(event) => {
                            insertSection();
                          }}
                        >
                          Section
                        </Button>
                        <Box
                          sx={{
                            background: colors.grey[300],
                            width: 2,
                            height: 16,
                          }}
                        ></Box>
                      </>
                    )}
                    <Button
                      variant='text'
                      size='small'
                      startIcon={<AddIcon />}
                      color='neutral'
                      sx={{ whiteSpace: 'nowrap' }}
                      onMouseOver={() => setShowAddingSubsection(true)}
                      onMouseOut={() => setShowAddingSubsection(false)}
                      onClick={(event) => {
                        insertSubSection();
                      }}
                    >
                      Sub-section
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
          <Box
            sx={{
              border: `solid 1px ${colors.grey[200]}`,
              borderRadius: 1,
              px: 0,
              py: 0,
              display: 'grid',
              gridTemplateColumns: 'repeat(1, auto)',
              alignItems: 'center',
            }}
          >
            <Button
              variant='text'
              size='small'
              color='neutral'
              sx={{ whiteSpace: 'nowrap', minWidth: 0 }}
              onClick={() => {
                updateSectionData({
                  state: section.state === 'ENABLED' ? 'DISABLED' : 'ENABLED',
                });
              }}
            >
              {section.state === 'ENABLED' ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Button>
          </Box>
        </Box>
        {showAddingSection && (
          <Box
            sx={{
              height: 16,
              marginLeft: isSubSection ? '-20px !important' : '',
              width: '100%',
              background: colors.blueGrey[50],
              border: `dotted 2px ${colors.blueGrey[200]}`,
            }}
          ></Box>
        )}
        {showAddingSubsection && (
          <Box
            sx={{
              height: 16,
              marginLeft: isSubSection ? '' : '20px !important',
              width: 'calc(100% - 20px)',
              background: colors.blueGrey[50],
              border: `dotted 2px ${colors.blueGrey[200]}`,
            }}
          ></Box>
        )}
      </Stack>
    </Box>
  ) : null;
};
export default SectionEditor;
