import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import parse, { DOMNode } from 'html-react-parser';
import {
  Container,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  colors,
} from '@mui/material';
import { TextSnippet } from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import { Policy } from 'types/policy';
import { ManualChapter } from 'types/manual';
import PolicyHeaders from '../../shared/PolicyHeaders';
import PolicyAttachments from '../../shared/PolicyAttachments';
import { IssuedManualsContext } from '../context';
import contentHelper from 'helpers/contentHelper';

const IssuedManualsPolicy: React.FC = () => {
  const t = useTranslations();
  const { id, policyId } = useParams();
  const [policy, setPolicy] = useState<Policy>();
  const [chapter, setChapter] = useState<ManualChapter>();
  const { manualHasPermissions } = useContext(IssuedManualsContext);
  const manualQuery = useIssuedManual(id || '');
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (manualQuery.data && manualQuery.data.chapters) {
      manualQuery.data.chapters.forEach((chapter) => {
        if (chapter.policies) {
          chapter.policies.forEach((p) => {
            if (p.id === policyId) {
              setPolicy(p);
              setChapter(chapter);
            }
          });
        }
      });
    }
  }, [manualQuery.data, policyId]);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      const sec = params.get('s');
      if (sec) {
        const el = document.getElementById(`polsec_${sec}`);
        if (el) {
          el.scrollIntoView(true);
        }
      }
    }, 2000);
    return () => {
      window.clearTimeout(timer);
    };
  }, [params]);

  return (
    <DetailView
      title={policy ? [policy.key, policy.title].join(': ') : ''}
      breadcrumbItems={[
        <Link key='b1' href='..'>
          {t('policy.issuedManuals.title')}
        </Link>,
        <Link key='b2' href={`../${id}`}>
          {manualQuery.data?.name}
        </Link>,
        ...(chapter
          ? [
              <Link key='b3' href={`../${id}/chapter/${chapter.id}`}>
                {[chapter.key, chapter.title].join(': ')}
              </Link>,
            ]
          : []),
        <Typography key='b4'>
          {policy ? [policy.key, policy.title].join(': ') : ''}
        </Typography>,
      ]}
      headerContent={
        policy ? (
          <Stack spacing={2}>
            <PolicyHeaders policy={policy} />
            <Toolbar className='phx-toolbar'>
              <Stack direction='row' spacing={4} sx={{ flexGrow: 1 }}>
                <Typography variant='h6'>{''}</Typography>
              </Stack>
              <Stack direction='row' spacing={4}>
                {manualHasPermissions(id ?? '', [
                  'createManualAcknowledgement',
                ]) && (
                  <Tooltip
                    title={t(
                      'policy.issuedManuals.actions.newAdhocAcknowledgement'
                    )}
                  >
                    <IconButton
                      href={`../${id}/policy/${policyId}/new-acknowledgement`}
                    >
                      <TextSnippet />
                    </IconButton>
                  </Tooltip>
                )}
                <PolicyAttachments policy={policy} />
              </Stack>
            </Toolbar>
          </Stack>
        ) : null
      }
      query={manualQuery}
    >
      {policy && policy.key && (
        <Stack>
          <Container maxWidth='lg'>
            <Stack spacing={10}>
              {policy.sections.map((section) => (
                <Fragment key={`section_${section.id}`}>
                  <Stack
                    id={`polsec_${section.id}`}
                    sx={{
                      backgroundColor:
                        section.id.toString() === params.get('s')
                          ? colors.amber[50]
                          : 'transparent',
                    }}
                  >
                    <Typography variant='h5'>
                      {[section?.key, section?.title].join(': ')}
                    </Typography>
                    {parse(section.content || '', {
                      replace(node: DOMNode, ind: number) {
                        return contentHelper.handleContent(node, ind);
                      },
                    })}
                  </Stack>
                  {section.subSections.map((subSection) => (
                    <Stack
                      id={`polsec_${subSection.id}`}
                      key={`subsection_${section.id}_${subSection.id}`}
                      sx={{
                        backgroundColor:
                          subSection.id.toString() === params.get('s')
                            ? colors.amber[50]
                            : 'transparent',
                      }}
                    >
                      <Typography variant='h6'>
                        {[subSection.key, subSection.title].join(': ')}
                      </Typography>
                      {parse(subSection.content || '', {
                        replace(node: DOMNode, ind: number) {
                          return contentHelper.handleContent(node, ind);
                        },
                      })}
                    </Stack>
                  ))}
                </Fragment>
              ))}
            </Stack>
          </Container>
        </Stack>
      )}
    </DetailView>
  );
};

export default IssuedManualsPolicy;
