import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from 'data_sources/constants';
import { ManualPermission } from 'types/permissions_response';
import { Group } from 'types/group';

export type GroupManualsResponse = {
  manuals: ManualPermission[];
}

export default function useGroupManuals(
  organizationId: string,
  groupId: string,
  params = {},
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  return useQuery<GroupManualsResponse, Error>(
    ['group_manual_permissions', organizationId, groupId, params],
    async ({ signal }): Promise<GroupManualsResponse> => {
      const group: Group = await fetcher({ url: `${baseApiUrl()}/lexid/user-group/v1/orgs/${organizationId}/groups/${groupId}` });

      const url = `${baseApiUrl()}/lexid/admin-manual-authorization/v1/organizations/${organizationId}/roles/${group.childRoleId}/manual-permissions`;
      const data: GroupManualsResponse = await fetcher({
        method: 'get',
        url,
        options: { signal },
      });

      return data;
    },
    {
      ...options,
      keepPreviousData: true,
    }
  );
}
