import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Box,
  Card,
  CardProps,
  colors,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useOrganizationUserImport from 'data_sources/userImports/useOrganizationUserImport';
import { JobStatus, UserImportCounts } from 'data_sources/userImports/enums';
import numeral from 'numeral';
import userImportHelper from '../userImportHelper';
import DetailsFail from './DetailsFail';
import DetailsInserted from './DetailsInserted';
import DetailsUpdated from './DetailsUpdated';

export default function Details() {
  const { currentOrganizationId } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const tabs = [
    UserImportCounts.INSERTED,
    UserImportCounts.FAIL,
    UserImportCounts.UPDATED,
  ];
  const userImportQuery = useOrganizationUserImport(
    currentOrganizationId || '',
    id || ''
  );
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const ResultTab = styled(Card)<CardProps>(({ theme }) => ({
    padding: 20,
    border: `solid 1px ${colors.grey[100]}`,
    textAlign: 'center',
    '&.selected': {
      border: `solid 1px ${colors.blue[200]}`,
      backgroundColor: colors.blue[50],
    },
  }));

  const renderTab = (tab: string, data: any) => {
    const bucketName = userImportHelper.resultBucket(data);
    const resultKey = userImportHelper.resultKey(data);
    if (!bucketName || !resultKey) return null;
    if (tab === UserImportCounts.INSERTED) {
      return (
        <DetailsInserted
          key="inserted"
          bucketName={bucketName}
          resultKey={resultKey}
        />
      );
    }
    if (tab === UserImportCounts.FAIL) {
      return (
        <DetailsFail key="fail" bucketName={bucketName} resultKey={resultKey} />
      );
    }
    if (tab === UserImportCounts.UPDATED) {
      return (
        <DetailsUpdated
          key="updated"
          bucketName={bucketName}
          resultKey={resultKey}
        />
      );
    }
    // TODO: Inactive tab functionality is temporarily removed.
    // As isn't working as expected, we are going to remove it temporarily and check if is still needed.

    // if (tab === UserImportCounts.INACTIVE) {
    //   return (
    //     <DetailsInactive
    //       key="inactive"
    //       bucketName={bucketName}
    //       resultKey={resultKey}
    //     />
    //   );
    // }
  };

  return (
    <DetailView
      title={
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography variant="h5">{userImportQuery.data?.jobDescr}</Typography>
          {userImportQuery.data?.status !== undefined && (
            <Badge
              variant="inline"
              badgeContent={userImportHelper.calculatedStatus(
                userImportQuery.data
              )}
              color={userImportHelper.badgeColor(
                userImportHelper.calculatedStatus(userImportQuery.data)
              )}
              sx={{ textTransform: 'uppercase' }}
            />
          )}
        </Stack>
      }
      breadcrumbItems={[
        <Link key="b1" href="..">
          {t('userImports.title')}
        </Link>,
        <Typography key="b4">{userImportQuery.data?.jobDescr}</Typography>,
      ]}
      headerContent={
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 3,
          }}
        >
          {userImportQuery.data?.status !== undefined &&
            userImportQuery.data?.status !== JobStatus.FAILED &&
            tabs.map((x) => (
              <Link key={`tab_${x}`} onClick={() => setCurrentTab(x)}>
                <ResultTab className={currentTab === x ? 'selected' : ''}>
                  <Typography variant="h5">
                    {userImportHelper.userImportCounts(userImportQuery.data)[x]
                      ? numeral(
                          userImportHelper.userImportCounts(
                            userImportQuery.data
                          )[x]
                        ).format('0,0')
                      : '--'}
                  </Typography>
                  <Typography variant="caption">
                    {t(`userImports.counts.${x}`)}
                  </Typography>
                </ResultTab>
              </Link>
            ))}
        </Box>
      }
      query={userImportQuery}
    >
      {userImportQuery.data && (
        <>
          {userImportQuery.data?.status === JobStatus.SUCCEEDED ? (
            renderTab(currentTab, userImportQuery.data)
          ) : userImportQuery.data?.status === JobStatus.FAILED ? (
            <Stack sx={{ p: 5 }}>
              <Typography variant="h5">
                {t('userImports.details.failed')}
              </Typography>
              {userImportHelper.renderErrors(
                userImportQuery.data?.stepFunctionErrorCause ?? ''
              )}
            </Stack>
          ) : null}
        </>
      )}
    </DetailView>
  );
}
