import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Link, Typography } from '@mui/material';
import { TextSnippet } from '@mui/icons-material';

import useTranslations from 'hooks/useTranslations';
import ListView from 'components/_layouts/ListView';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import { Policy } from 'types/policy';
import { IssuedManualsContext } from '../context';
import { ActionMenu } from 'components/_atoms';

const columnHelper = createColumnHelper<Policy>();

const IssuedManualsChapter: React.FC = () => {
  const t = useTranslations();
  const { id, chapterId } = useParams();
  const { manualHasPermissions } = useContext(IssuedManualsContext);
  const manualQuery = useIssuedManual(id || '');

  const chapter = useMemo(() => {
    if (manualQuery.data && manualQuery.data.chapters) {
      return manualQuery.data.chapters.find((x) => x.id === chapterId);
    }
  }, [chapterId, manualQuery.data]);

  const renderActions = (item: any) => {
    return [
      ...(manualHasPermissions(id ?? '', ['createManualAcknowledgement'])
        ? [
            {
              key: `new_${item.id}`,
              icon: <TextSnippet />,
              label: t('policy.issuedManuals.actions.newAdhocAcknowledgement'),
              href: `../${id}/policy/${item.id}/new-acknowledgement`,
            },
          ]
        : []),
    ];
  };

  const columns = [
    columnHelper.accessor('title', {
      cell: (info) => (
        <Link href={`../${id}/policy/${info.row.original.id}`}>
          {[info.row.original.key, info.row.original.title].join(': ')}
        </Link>
      ),
      size: 9999,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) =>
        renderActions(info.row.original).length > 0 ? (
          <ActionMenu
            id={`action_menu_${info.row.original.id}`}
            items={renderActions(info.row.original)}
          />
        ) : null,
      header: () => '',
      size: 60,
    }),
  ];

  return (
    <ListView
      columns={columns}
      data={chapter?.policies || []}
      hideHeaders
      query={manualQuery}
      title={chapter ? [chapter.key, chapter.title].join(': ') : ''}
      breadcrumbItems={[
        <Link
          key='b1'
          href='..'
        >
          {t('policy.issuedManuals.title')}
        </Link>,
        <Link
          key='b2'
          href={`../${id}`}
        >
          {manualQuery.data?.name}
        </Link>,
        <Typography key='b2'>
          {chapter ? [chapter.key, chapter.title].join(': ') : ''}
        </Typography>,
      ]}
    />
  );
};

export default IssuedManualsChapter;
