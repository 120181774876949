import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import { createColumnHelper } from '@tanstack/react-table';
import { FormControl, MenuItem, Select, Stack } from '@mui/material';

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import useGroupManuals from 'data_sources/groups/useGroupManuals';
import useManageGroupManual from 'data_sources/groups/useManageGroupManual';
import usePersonas from 'data_sources/usePersonas';
import { ManualPermission, Persona } from 'types/permissions_response';
import Loading from 'views/App/Portal/Loading';
import errorHelper from 'helpers/errorHelper';
import ListSection from 'components/_layouts/ListSection';

const columnHelper = createColumnHelper<ManualPermission>();

export default function Manuals({ roleId }: { roleId: string }) {
  const { currentOrganizationId } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const t = useTranslations();
  const confirm = useConfirm();
  const { id } = useParams();
  const manualsQuery = useGroupManuals(currentOrganizationId || '', id || '');
  const personasQuery = usePersonas({ resourceType: 'Manual' });
  const { addManual, removeManual } = useManageGroupManual();

  const handlePersonaChange = async (
    manualId: string,
    personaId: string,
    priorPersonaId: string
  ) => {
    console.log('persona change', roleId, manualId, personaId, priorPersonaId);
    if (personaId !== priorPersonaId) {
      setIsSaving(true);
      if (personaId?.length > 0) {
        await addManual(
          currentOrganizationId || '',
          roleId,
          manualId,
          personaId,
          () => {
            setIsSaving(false);
            if (!priorPersonaId) {
              queryClient.invalidateQueries('group_manual_permissions');
            }
          }
        );
        if (priorPersonaId) {
          await removeManual(
            currentOrganizationId || '',
            roleId,
            manualId,
            priorPersonaId
          );
          queryClient.invalidateQueries('group_manual_permissions');
        }
      } else {
        await confirm({
          description: t('groups.manual_permissions.cancelConfirm'),
        })
          .then(() => {
            if (priorPersonaId) {
              console.log('remove persona');
              removeManual(
                currentOrganizationId || '',
                roleId,
                manualId,
                priorPersonaId,
                () => {
                  setIsSaving(false);
                  queryClient.invalidateQueries('group_manual_permissions');
                }
              );
            }
          })
          .catch(() => {
            // don't do it
            setIsSaving(false);
          });
      }
    }
  };

  const columns = [
    columnHelper.accessor('name', {
      header: () => t('groups.manual_permissions.name'),
      size: 9999,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <FormControl key='persona_picker' fullWidth>
          <Select
            id='persona_select'
            label={t('groups.manual_permissions.persona')}
            value={info.row.original.persona?.id ?? ''}
            size='small'
            disabled={isSaving}
            onChange={({ target }) => {
              handlePersonaChange(
                info.row.original.id,
                target.value,
                info.row.original.persona?.id
              );
            }}
          >
            <MenuItem key={`persona_empty`} value=''>
              --
            </MenuItem>
            {(personasQuery.data?.content ?? []).map((persona: Persona) => (
              <MenuItem key={`persona_${persona.id}`} value={persona.id}>
                {persona.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      header: () => t('groups.manual_permissions.persona'),
      size: 200,
    }),
  ];

  const groupManuals = React.useMemo(
    () =>
      manualsQuery.data && manualsQuery.data.manuals
        ? manualsQuery.data.manuals
        : [],
    [manualsQuery.data]
  );

  if (manualsQuery.isError) {
    return errorHelper.renderErrors(manualsQuery);
  }

  return manualsQuery.isLoading || manualsQuery.isFetching ? (
    <Loading />
  ) : (
    <Stack spacing={3}>
      <ListSection columns={columns} data={groupManuals} query={manualsQuery} />
    </Stack>
  );
}
