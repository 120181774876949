import { Collision, CollisionDetection, rectIntersection } from '@dnd-kit/core';

export const pointerCollisionDetection: CollisionDetection = ({ pointerCoordinates, droppableContainers }) => {
  const collisions: Collision[] = [];

  droppableContainers.forEach((droppable) => {
    const rect = droppable.rect.current;
    if (rect) {
      const { top, right, bottom, left } = rect;
      const { x, y } = pointerCoordinates as any;
      if (x >= left && x <= right && y >= top && y <= bottom) {
        collisions.push({
          id: droppable.id,
          data: {
            droppable: droppable.id,
          },
        });
      }
    }
  });

  return collisions;
};
