import React, { Fragment, useContext } from 'react';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import {
  Stack,
} from "@mui/material";

import { AppContext } from '../../../../context';
import useTranslations from 'hooks/useTranslations';
import PropertyList from 'components/_layouts/PropertyList';
import useUserPermissions from 'data_sources/users/useUserPermissions';
import errorHelper from 'helpers/errorHelper';
import Loading from 'views/App/Portal/Loading';
import { UserPermission } from 'types/permissions_response';

export default function Permissions() {
  const { currentOrganizationId, hasPermission } = useContext(AppContext);
  const t = useTranslations();
  const { id } = useParams();
  const userPermissionsQuery = useUserPermissions(currentOrganizationId || '', id || '', {}, { enabled: hasPermission('listUserPermissions', currentOrganizationId) });

  const formatValue = (permission: UserPermission) => {
    const desc = t(`permissions.actions.${permission.action}.description`);
    if (permission.groups.length > 0 || permission.roles.length > 0) {
      return `${desc} <small>(${t('permissions.grantedBy')} ${([...permission.groups.map((g) => `${g} group`), ...permission.roles.map((g) => `${g} role`)]).join(', ')})</small>`;
    } else {
      return `${desc} (${t('permissions.grantedByDirect')})`;
    }
  }

  return (
    <Stack spacing={2}>
      {userPermissionsQuery.isError && errorHelper.renderErrors(userPermissionsQuery)}
      {!userPermissionsQuery.isLoading && !userPermissionsQuery.isFetching ? (
        <PropertyList
          columns={1}
          items={(userPermissionsQuery.data?.pages || []).flatMap((x) => x.content).map((permission) => ({
            key: permission.action,
            label: t(`permissions.actions.${permission.action}.name`, permission.action),
            value: (<Fragment>{parse(formatValue(permission))}</Fragment>),
          }))}
        />
      ) : (
        <Loading />
      )}
    </Stack>
  );
}
