import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import parse, { DOMNode } from 'html-react-parser';
import { Container, Link, Typography } from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import DetailView from 'components/_layouts/DetailView';
import useIssuedManual from 'data_sources/issuedManuals/useIssuedManual';
import contentHelper from 'helpers/contentHelper';

const IssuedManualsPreface: React.FC = () => {
  const t = useTranslations();
  const { id, prefaceId } = useParams();
  const manualQuery = useIssuedManual(id);

  const preface = useMemo(() => {
    if (manualQuery.data && manualQuery.data.prefaces) {
      return manualQuery.data.prefaces.find((x) => x.id === prefaceId);
    }
  }, [prefaceId, manualQuery.data]);

  return (
    <DetailView
      title={[t('policy.issuedManuals.preface'), preface?.title].join(': ')}
      breadcrumbItems={[
        <Link
          key='b1'
          href='..'
        >
          {t('policy.issuedManuals.title')}
        </Link>,
        <Link
          key='b2'
          href={`../${id}`}
        >
          {manualQuery.data?.name}
        </Link>,
        <Typography key='b3'>
          {[t('policy.issuedManuals.preface'), preface?.title].join(': ')}
        </Typography>,
      ]}
      query={manualQuery}
    >
      {preface && preface.content && (
        <Container maxWidth='lg'>
          {parse(preface.content, {
            replace(node: DOMNode, ind: number) {
              return contentHelper.handleContent(node, ind);
            },
          })}
        </Container>
      )}
    </DetailView>
  );
};

export default IssuedManualsPreface;
