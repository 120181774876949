import { useQuery } from 'react-query';
import { baseApiUrl, blobFetcher, ServerErrorResponse } from '../constants';
import { Manual } from 'types/manual';
import manualHelper from 'helpers/manualHelper';

export default function useIssuedManual(
  id?: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexipub/manuals/issued/v1/manuals/${id}`;
  return useQuery<Manual, Error>(
    ['issued_manual', id],
    async (): Promise<Manual> => {
      const res = await blobFetcher({
        method: 'get',
        url,
      }).then(async (data) => {
        const isJsonBlob = (data: any) =>
          data instanceof Blob && data.type === 'application/json';
        const responseData = isJsonBlob(data) ? await data?.text() : data || {};
        const responseJson =
          typeof responseData === 'string'
            ? JSON.parse(decodeURIComponent(escape(atob(responseData))))
            : responseData;

        console.log('resp json', responseJson);
        return responseJson;
      });
      return res ? (manualHelper.convertManual(res) as Manual) : ({} as Manual);
    },
    {
      ...options,
      enabled: !!id,
      keepPreviousData: false,
      placeholderData: {} as Manual,
    }
  );
}
