
import PhoenixNodes from "./nodes/PhoenixNodes";
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
  console.error(error);
}

export const lexicalEditorConfig = {
  namespace: "LexicalPOC",
  theme: PlaygroundEditorTheme,
  onError,

  nodes: PhoenixNodes,
};

export const mentionItems: Record<string, string[]> = {
  "$": ["ORGANIZATION_NAME", "VERTICAL", "JURISDICTION"]
}