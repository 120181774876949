import React, { useCallback, useMemo } from "react";
import { QueryClient, UseInfiniteQueryResult, useQueryClient } from "react-query";
import * as Yup from 'yup';

import useUrlParams from "hooks/useUrlParams";
import useTranslations from "hooks/useTranslations";
import useOrganizations from "data_sources/organizations/useOrganizations";
import useManageOrganizations from "data_sources/organizations/useManageOrganization";
import { Organization, NewOrganizationRequest, UpdateOrganizationRequest } from "types/organization";
import { ApiCollectionResponse } from "types/api_response";
import { ServerErrorResponse } from "data_sources/constants";
import countries from 'data_sources/countries.json';

const validCountryCodes = ['USA', 'CAN'];
export const validCountries = countries.filter((x) => validCountryCodes.includes(x.code3))


export const AUTH_TYPES = ['PASSWORD', 'SSO'];

type OrganizationsProviderProps = {
  children: React.ReactNode;
};

type OrganizationsUrlParams = {
  search?: string | null;
};

export type OrganizationsProviderResponse = {
  clearParams: () => void;
  createOrganization: (
    data: NewOrganizationRequest,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => void;
  params: OrganizationsUrlParams;
  queryClient: QueryClient | null;
  selectedOrganization: Organization | null;
  updateParams: (updatedParams: OrganizationsUrlParams) => void;
  updateOrganization: (
    guid: string,
    data: UpdateOrganizationRequest,
    onSuccess?: (data: Organization) => void,
    onError?: (errors: ServerErrorResponse) => void,) => void;
  organizations: Organization[];
  organizationsQuery: UseInfiniteQueryResult<ApiCollectionResponse<Organization>, Error>;
  organizationSchema: any;
  updateOrganizationSchema: any;
  validCountries: any[];
}

const defaultParams: OrganizationsUrlParams = {
  search: null,
};

export const OrganizationsContext = React.createContext<OrganizationsProviderResponse>({} as OrganizationsProviderResponse);

const OrganizationsProvider: ({ children }: OrganizationsProviderProps) => any = ({ children }) => {
  const queryClient = useQueryClient();
  const t = useTranslations();
  const [params, setParams] = useUrlParams(defaultParams);
  const updateParams = useCallback((updatedParams: OrganizationsUrlParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...updatedParams,
    }));
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const clearParams = useCallback(() => {
    setParams(defaultParams);
    document.body.dispatchEvent(new Event('params-changed'));
  }, [setParams]);

  const queryParams = useMemo(() => {
    const newParams: any = {
      ...params
    };
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null || newParams[key] === '') {
        delete newParams[key];
      }
    });

    return newParams;
  }, [params]);

  // const isFiltered = params !== defaultParams;

  const organizationData = useOrganizations(queryParams);
  const {
    create: createOrganization,
    update: updateOrganization,

  } = useManageOrganizations();

  const organizationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.name')} is required`),
    country: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.country')} is required`),
    state: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.state')} is required`),
    city: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.city')} is required`),
  });

  const updateOrganizationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.name')} is required`),
    country: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.country')} is required`),
    state: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.state')} is required`),
    city: Yup.string().trim()
      .transform((value: string, originalValue: string) => {
        return value.replace(/\s+/g, ' ');
      })
      .required(`${t('organizations.properties.city')} is required`),
  });

  const organizations = useMemo(() => organizationData.data && organizationData.data.pages ?
    organizationData.data.pages.flatMap((x) => x.content) : []
    , [organizationData.data]);
  // console.log('organizations', organizations)

  const selectedOrganization = useMemo(() =>
    organizations.find((x) => x.organization.id === params.selectedId) || null,
    [organizations, params.selectedId]);

  const handleCreateOrganization = useCallback((
    data: NewOrganizationRequest,
    onSuccess?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    createOrganization(
      data,
      (guid: string) => {
        queryClient.resetQueries(['organizations']);
        if (onSuccess) onSuccess(guid);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [createOrganization, queryClient]);

  const handleUpdateOrganization = useCallback((
    guid: string,
    data: UpdateOrganizationRequest,
    onSuccess?: (updatedData: Organization) => void,
    onError?: (errors: ServerErrorResponse) => void,
  ) => {
    updateOrganization(
      guid,
      data,
      (updatedData: Organization) => {
        queryClient.invalidateQueries(['organizations'], { refetchInactive: true });
        if (onSuccess) onSuccess(updatedData);
      },
      (errors: ServerErrorResponse) => {
        if (onError) onError(errors);
      },
    );
  }, [queryClient, updateOrganization])

  const contextValue: OrganizationsProviderResponse = useMemo(() => ({
    clearParams,
    createOrganization: handleCreateOrganization,
    params,
    queryClient,
    selectedOrganization,
    updateParams,
    updateOrganization: handleUpdateOrganization,
    organizationsQuery: organizationData,
    organizations: organizations,
    organizationSchema,
    updateOrganizationSchema,
    validCountries,
  }), [clearParams, handleCreateOrganization, params, queryClient, selectedOrganization, updateParams, handleUpdateOrganization, organizationData, organizations, organizationSchema, updateOrganizationSchema]);

  return (
    <OrganizationsContext.Provider value={contextValue}>
      {children}
    </OrganizationsContext.Provider>
  )
};

export default OrganizationsProvider;