import React from 'react';
import { Link as RouterLink, NavLink, NavLinkProps, LinkProps as RouterLinkProps } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return (
      <RouterLink
        ref={ref}
        to={href}
        {...other}
      />
    );
  }
);

const NavLinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'> & { href: NavLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return (
      <NavLink
        ref={ref}
        to={href}
        {...other}
      />
    );
  }
);

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    neutral: any;
  }

  interface PaletteOptions {
    neutral?: any;
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }

  interface SvgIconPropsColorOverrides {
    neutral: true;
  }

  interface BadgePropsVariantOverrides {
    inline: true;
  }

  interface BadgePropsColorOverrides {
    neutral: true;
  }

  interface IconButtonPropsColorOverrides {
    neutral: true;
  }

  interface TypographyVariants {
    listLink: React.CSSProperties;
    listLinkBold: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    listLink?: React.CSSProperties;
    listLinkBold?: React.CSSProperties;
  }
  interface TypographyPropsVariantOverrides {
    listLink: true;
    listLinkBold: true;
  }
}

const defaultTheme = createTheme();
const shadowsArr = defaultTheme.shadows;
shadowsArr[1] = '0px 1px 2px 0px rgba(0, 0, 0, 0.08)';
// setup palette
console.log('default palette', defaultTheme.palette);
const phoenixTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
    h1: {
      fontSize: '1.8rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '0.875rem',
    },
    listLinkBold: {
      fontWeight: 600,
      cursor: 'pointer',
      color: colors.blue[900],
    },
    listLink: {
      cursor: 'pointer',
      color: colors.blue[900],
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: shadowsArr[1],
          '&.takeover-nav': {
            '.MuiIconButton-root': {
              border: 'solid 1px rgba(255, 255, 255, 0.6)',
              borderRadius: 4,
              background: 'rgba(255, 255, 255, 0.1)',
              padding: 4,
              '.MuiSvgIcon-root': {
                fill: '#FFF',
              },
            },
            '.MuiTypography-h3': {
              fontSize: '1.1rem',
              lineHeight: '34px',
            },
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-inline': {
            transform: 'none',
            position: 'static',
            fontWeight: 600,
            fontSize: '0.8rem',
            height: '1.5rem',
            lineHeight: '1.25rem',
            padding: '4px 12px',
            borderRadius: '9999px',
            '&.MuiBadge-colorNeutral': {
              backgroundColor: colors.grey[100],
            },
            '&.MuiBadge-colorSuccess': {
              backgroundColor: colors.green[100],
              color: colors.green[900],
            },
            '&.MuiBadge-colorError': {
              backgroundColor: colors.red[100],
              color: colors.red[900],
            },
          },
          '& .MuiBadge-colorNeutral': {
            backgroundColor: colors.grey[300],
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          'li, li>div': {
            maxWidth: 180,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableElevation: true,
        sx: {
          minWidth: 30,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&.MuiButton-sizeSmall': {
            padding: '8px 16px',
            fontSize: 16,
            lineHeight: '18px',
            '&.icon-only': {
              minWidth: 0,
              padding: '0px !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 36,
              height: 36,
            },
            '&.icon-only.xs': {
              width: 30,
              height: 30,
            },
          },
          '&.MuiButton-sizeMedium': {
            padding: '12px 24px',
            fontSize: 18,
            lineHeight: '20px',
            '&.icon-only': {
              minWidth: 0,
              padding: '0px !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 46,
              height: 46,
            },
          },
          '&.MuiButton-sizeLarge': {
            borderRadius: 8,
            padding: '16px 32px',
            fontSize: 22,
            lineHeight: '24px',
            '&.icon-only': {
              minWidth: 0,
              padding: '0px !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 58,
              height: 58,
            },
          },
          '&.MuiButton-containedNeutral': {
            backgroundColor: colors.grey[200],
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#004B87',
            color: '#FFF',
            border: 'solid 1px #004B87',
            '&:hover': {
              borderColor: '#04294D',
            },
            '&.Mui-disabled': {
              backgroundColor: '#EEF2F6',
              color: '#9AA4B2',
              border: 'solid 1px #E3E8EF',
              '&:hover': {
                borderColor: '#E3E8EF',
              },
            },
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: '#FFF',
            color: '#121926',
            border: 'solid 1px #E3E8EF',
            '.MuiButton-icon': {
              color: '#697586',
            },
            '&:hover': {
              borderColor: '#CDD5DF',
            },
            '&.Mui-disabled': {
              backgroundColor: '#EEF2F6',
              color: '#9AA4B2',
              border: 'solid 1px #E3E8EF',
              '&:hover': {
                borderColor: '#E3E8EF',
              },
            },
          },
          '&.MuiButton-containedError': {
            backgroundColor: '#DC2626',
            color: '#FFF',
            border: 'solid 1px #DC2626',
            '&.Mui-disabled': {
              backgroundColor: '#EEF2F6',
              color: '#9AA4B2',
              border: 'solid 1px #E3E8EF',
              '&:hover': {
                borderColor: '#E3E8EF',
              },
            },
          },
          '&.MuiButton-containedSuccess': {
            backgroundColor: '#059669',
            color: '#FFF',
            border: 'solid 1px #059669',
            '&.Mui-disabled': {
              backgroundColor: '#EEF2F6',
              color: '#9AA4B2',
              border: 'solid 1px #E3E8EF',
              '&:hover': {
                borderColor: '#E3E8EF',
              },
            },
          },
          '&.MuiButton-containedWarning': {
            backgroundColor: '#D97706',
            color: '#FFF',
            border: 'solid 1px #D97706',
            '&.Mui-disabled': {
              backgroundColor: '#EEF2F6',
              color: '#9AA4B2',
              border: 'solid 1px #E3E8EF',
              '&:hover': {
                borderColor: '#E3E8EF',
              },
            },
          },
          '&.MuiButton-textPrimary': {
            backgroundColor: 'transparent',
            color: '#004B87',
            border: 'solid 1px transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#04294D',
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              color: '#697586',
              border: 'solid 1px transparent',
              '&:hover': {
                borderColor: 'transparent',
              },
            },
          },
          '&.MuiButton-root:focus': {
            boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 6px rgba(0,78,235,0.50)',
          },
          '&.MuiButton-containedPrimary .MuiBadge-badge': {
            backgroundColor: '#FFF',
            color: '#004B87',
            padding: 4,
            minWidth: '1.5rem',
          },
          '&.MuiButton-textPrimary .MuiBadge-badge': {
            border: `solid 1px ${colors.grey[500]}`,
            color: colors.grey[800],
            padding: 4,
            minWidth: '1.5rem',
          },
          '&.icon-only': {
            minWidth: 0,
            '.MuiButton-icon': {
              margin: 0,
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
        sx: {
          borderRadius: 3,
          borderColor: colors.grey[200],
        },
      },
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          '& .MuiCardHeader-title': {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
        sx: {
          p: 4,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          paddingTop: '1rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '4px 0 0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontSize: '0.95rem',
        },
        shrink: {
          transform: 'translate(0px, -8px) scale(1) !important',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: NavLinkBehavior,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '& .MuiTypography-root': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          boxShadow: shadowsArr[1],
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: '50vh',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-thumb': {
            backgroundColor: 'primary.light',
          },
          '& > .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: 'primary.main',
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.phx-toolbar': {
            backgroundColor: 'primary.light',
            borderRadius: 3,
            padding: '6px 0',
            display: 'flex',
          },
          '&.phx-toolbar--mobile': {
            display: 'grid',
            '& *': {
              flexGrow: 1,
            },
          },
        },
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderColor: colors.grey[200],
          borderStyle: 'solid',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
      },
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          '&.phx-toolbar-view': {
            height: 40,
            paddingLeft: 12,
          },
          '&.phx-toolbar-edit': {
            borderWidth: 1,
            backgroundColor: colors.grey[50],
            borderColor: colors.grey[100],
            borderStyle: 'solid',
            borderRadius: 12,
            padding: 16,
          },
          '&.phx-panel-header': {
            borderBottom: `solid 1px ${colors.grey[200]}`,
            backgroundColor: '#FFF',
            padding: '0 16px',
          },
          '&.phx-tree-toolbar': {
            padding: '0 8px',
            minHeight: 0,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        component: 'div',
      },
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#004B87',
      dark: '#04294D',
      light: '#EFF7FF',
    },
    neutral: {
      main: '#939496',
      dark: '#E3E4E6',
      light: '#F3F4F6',
      contrastText: '#333436',
    },
    error: {
      main: '#EF4444',
    },
  },
  shadows: shadowsArr,
  spacing: 4,
});

export default phoenixTheme;
