import { Group } from '../../types/group';
import { baseApiUrl, fetcher, handleError, ServerErrorResponse } from '../constants';

export default function useManageGroup() {
  const create = async (organizationId: string, data: Group, callback?: (guid: string) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/user-group/v1/orgs/${organizationId}/groups`;
    await fetcher({
      url,
      method: 'post',
      body: data,
    }).then((data) => {
      if (callback) callback(data.id);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const update = async (organizationId: string, id: string, data: Group, callback?: (data: Group) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/user-group/v1/orgs/${organizationId}/groups/${id}`;
    await fetcher({
      url,
      method: 'put',
      body: data,
    }).then((data) => {
      if (callback) callback(data);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  const destroy = async (organizationId: string, id: string, callback?: (data: Group) => void, onError?: (errors: ServerErrorResponse) => void) => {
    const url = `${baseApiUrl()}/lexid/user-group/v1/organizations/${organizationId}/groups/${id}`;
    await fetcher({
      url,
      method: 'delete',
    }).then((data) => {
      if (callback) callback(data);
    })
      .catch((err) => {
        if (onError) handleError(err, { onError })
      });
  }

  return {
    create,
    destroy,
    update,
  }
}