import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalEditor } from "lexical";

type PhxOnChangePluginProps = {
  onChange: (editor: LexicalEditor, editorState: any) => void;
}

function PhxOnChangePlugin({ onChange }: PhxOnChangePluginProps) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editor, editorState);
    });
  }, [editor, onChange]);
  return null;
}

export default PhxOnChangePlugin;