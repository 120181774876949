import {
  ManualAcknowledgementScheduleRequest,
  NewManualAcknowledgementScheduleRequest,
} from '../../types/acknowledgements';
import {
  baseApiUrl,
  fetcher,
  handleError,
  ServerErrorResponse,
} from '../constants';

export default function useManageAcknowledgementSchedules() {
  const create = async (
    manualId: string,
    data: NewManualAcknowledgementScheduleRequest,
    callback?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexipub/acknowledgements/v1/admin/manuals/${manualId}/schedules`;
    await fetcher({
      url,
      method: 'post',
      body: data,
    })
      .then((data) => {
        if (callback) callback(data.id);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  const update = async (
    manualId: string,
    scheduleId: string,
    data: ManualAcknowledgementScheduleRequest,
    callback?: (guid: string) => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexipub/acknowledgements/v1/admin/manuals/${manualId}/schedules/${scheduleId}`;
    await fetcher({
      url,
      method: 'put',
      body: data,
    })
      .then((data) => {
        if (callback) callback(data.id);
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  const destroy = async (
    manualId: string,
    scheduleId: string,
    callback?: () => void,
    onError?: (errors: ServerErrorResponse) => void
  ) => {
    const url = `${baseApiUrl()}/lexipub/acknowledgements/v1/admin/manuals/${manualId}/schedules/${scheduleId}`;
    await fetcher({
      url,
      method: 'delete',
    })
      .then(() => {
        if (callback) callback();
      })
      .catch((err) => {
        if (onError) handleError(err, { onError });
      });
  };

  return {
    create,
    update,
    destroy,
  };
}
