import { useQuery } from 'react-query';
import { baseApiUrl, fetcher, ServerErrorResponse } from '../constants';
import { Group } from 'types/group';

export default function useGroup(
  organizationId?: string,
  id?: string,
  options = {},
  onError?: (errors: ServerErrorResponse) => void
) {
  const url = `${baseApiUrl()}/lexid/user-group/v1/orgs/${organizationId}/groups`;
  return useQuery<Group, Error>(
    ['group', organizationId, id],
    async () => {
      if (organizationId && id) {
        const data = await fetcher({
          url: `${url}/${id}`,
        });
        return data;
      }
    },
    {
      ...options,
      keepPreviousData: false,
      enabled: !!organizationId && !!id,
    }
  );
}
