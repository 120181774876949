import styled from '@emotion/styled';
import {
  colors,
  TextField
} from '@mui/material';

export const TitleTextField = styled(TextField)({
  paddingTop: 0,
  '& .MuiOutlinedInput-root': {
    borderColor: `${colors.grey[50]} !important`,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 20,
    fontWeight: 600,
    padding: 8,
  }
});