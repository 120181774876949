import * as React from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';

import {
  $setBlocksType,
} from '@lexical/selection';

import {
  $createHeadingNode,
  $createQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text';

import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';

import {
  $createCodeNode,
} from '@lexical/code';

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

type BlockFormatMenuProps = {
  blockType: keyof typeof blockTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
};

export const blockTypeToBlockName: any = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

export default function BlockFormatMenu({
  editor,
  blockType,
  rootType,
  disabled = false,
}: BlockFormatMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
      handleClose();
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
    handleClose();
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
    handleClose();
  };

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
    handleClose();
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
    handleClose();
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
    handleClose();
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    }
    handleClose();
  };

  return (
    <Box>
      <Button
        id="block_format_menu_button"
        aria-controls={open ? 'block_format_menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        size='small'
        color='neutral'
        disabled={disabled}
      >
        {blockTypeToBlockName[blockType]}
      </Button>
      <Menu
        id="block_format_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

      >
        <MenuItem
          selected={blockType === 'paragraph'}
          onClick={formatParagraph}>
          <Typography>Normal</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'h1'}
          onClick={() => formatHeading('h1')}>
          <Typography>Heading 1</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'h2'}
          onClick={() => formatHeading('h2')}>
          <Typography>Heading 2</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'h3'}
          onClick={() => formatHeading('h3')}>
          <Typography>Heading 3</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'bullet'}
          onClick={formatBulletList}>
          <Typography>Bullet List</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'number'}
          onClick={formatNumberedList}>
          <Typography>Numbered List</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'check'}
          onClick={formatCheckList}>
          <Typography>Check List</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'quote'}
          onClick={formatQuote}>
          <Typography>Quote</Typography>
        </MenuItem>
        <MenuItem
          selected={blockType === 'code'}
          onClick={formatCode}>
          <Typography>Code Block</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
