import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { $getRoot, $insertNodes, LexicalEditor } from 'lexical';
import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
// lexical plugins
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
// custom plugins
import AutocompletePlugin from './plugins/AutocompletePlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListPlugin from './plugins/ListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import PhxOnChangePlugin from './plugins/PhxOnChangePlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizerPlugin from './plugins/TableCellResizer';
import TextEntitiesPlugin from './plugins/TextEntityPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
// editor contexts
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
// editor nodes
import PhoenixNodes from './nodes/PhoenixNodes';

import { EditorPlaceholder, MuiContentEditable } from './styles';
import PhxEditorTheme from './themes/PhxEditorTheme';
import TextColorPlugin from './plugins/ColorPlugin';
import OnPastePlugin from '../../lexical/plugins/OnPastePlugin';

type ContentEditorProps = {
  id: string;
  contentHTML: string;
  onSave: (contentHTML: string) => void;
  isEditable?: boolean;
};

export default function ContentEditor({
  id,
  contentHTML,
  onSave,
  isEditable = false,
}: ContentEditorProps) {
  function onChange(editor: LexicalEditor, editorState: any) {
    editor.update(() => {
      const content = $generateHtmlFromNodes(editor);
      onSave(content);
    });
  }

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLDivElement | undefined
  >();
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport = window.matchMedia(
        '(max-width: 1025px)'
      ).matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <Box className="editor-shell">
      <LexicalComposer
        initialConfig={{
          namespace: id,
          nodes: PhoenixNodes,
          theme: PhxEditorTheme,
          editable: isEditable,
          onError: (error) => {
            console.error(error);
          },
          editorState: (editor) => {
            const parser = new DOMParser();
            const content = parser.parseFromString(
              contentHTML ?? '',
              'text/html'
            );
            const contentNodes = $generateNodesFromDOM(editor, content);
            $getRoot().select();
            $insertNodes(contentNodes);
          },
        }}
      >
        <SharedHistoryContext>
          <SharedAutocompleteContext>
            {isEditable && (
              <>
                <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
              </>
            )}
            <Box
              className="editor-container"
              sx={{
                position: 'relative',
                background: '#FFF',
                pt: 2,
              }}
            >
              <RichTextPlugin
                contentEditable={
                  <div className="editor-scroller">
                    <div className="editor" ref={onRef}>
                      <MuiContentEditable />
                    </div>
                  </div>
                }
                placeholder={
                  <EditorPlaceholder>Enter some text...</EditorPlaceholder>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <AutocompletePlugin />
              <AutoLinkPlugin />
              <CheckListPlugin />
              <ClearEditorPlugin />
              <ExcalidrawPlugin />
              <HistoryPlugin />
              <HorizontalRulePlugin />
              <ListPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <LinkPlugin />
              <MarkdownShortcutPlugin />
              <PhxOnChangePlugin onChange={onChange} />
              <TabFocusPlugin />
              <TabIndentationPlugin />
              <TablePlugin
                hasCellMerge={false}
                hasCellBackgroundColor={false}
              />
              <TableCellResizerPlugin />
              <TextEntitiesPlugin />
              <TextColorPlugin />
              <OnPastePlugin />
              {floatingAnchorElem && !isSmallWidthViewport && (
                <>
                  <FloatingLinkEditorPlugin
                    anchorElem={floatingAnchorElem}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <TableCellActionMenuPlugin
                    anchorElem={floatingAnchorElem}
                    cellMerge={true}
                  />
                  <FloatingTextFormatToolbarPlugin
                    anchorElem={floatingAnchorElem}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                </>
              )}
            </Box>
          </SharedAutocompleteContext>
        </SharedHistoryContext>
      </LexicalComposer>
    </Box>
  );
}
