import React, { useContext } from 'react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Badge,
  Button,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import useTranslations from 'hooks/useTranslations';
import { AppContext } from 'views/App/context';
import { UsersContext } from '../context';
import { ActionMenu, QuickSearch } from 'components/_atoms';
import ListView from 'components/_layouts/ListView';
import { User } from 'types/user';
import { SORT_OPTIONS } from '../context';

const columnHelper = createColumnHelper<User>();

const UsersList: React.FC = () => {
  const t = useTranslations();
  const { currentOrganizationId, hasPermission } = useContext(AppContext);
  const { users, usersQuery, params, updateParams } = useContext(UsersContext);

  const columns = [
    columnHelper.accessor('lastName', {
      cell: (info) => (
        <Stack>
          <Link href={`./${info.row.original.id}`}>
            <Typography variant='h6' sx={{ fontWeight: 'semibold' }}>{[
              info.row.original.firstName,
              info.row.original.middleName,
              info.row.original.lastName,
            ].join(' ')}</Typography>
          </Link>
          {info.row.original.email && (
            <Link href={`mailto:${info.row.original.email}`}>{info.row.original.email}</Link>
          )}
        </Stack>
      ),
      header: () => t('users.properties.name'),
      size: 9999,
    }),
    columnHelper.accessor('active', {
      cell: (info) => (
        <Badge variant='inline' badgeContent={info.getValue() ? 'Active' : 'Inactive'} color={info.getValue() ? 'success' : 'error'} sx={{ textTransform: 'uppercase' }} />
      ),
      header: () => t('users.properties.active'),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <ActionMenu
          id={`action_menu_${info.row.original.id}`}
          items={[
            {
              key: `edit_${info.row.original.id}`, label: t('common.edit'), href: `./edit/${info.row.original.id}`
            },
          ]
          }
        />
      ),
      header: () => '',
      size: 60,
    }),
  ];

  const actions = (
    (
      <Stack direction='row' spacing={4}>
        <Select
          id='sort'
          label={t('common.sort')}
          value={params.sort}
          onChange={({ target }) => updateParams({ sort: target.value })}
        >
          {SORT_OPTIONS.map((x) => (
            <MenuItem key={`sort_${x.key}`} value={x.key}>{`${t('common.sort')}: ${x.label}`}</MenuItem>
          ))}
        </Select>
        {currentOrganizationId && hasPermission('createUser', currentOrganizationId) && (
          <Button
            variant="contained"
            href='./new'
          >
            {t('users.actions.create')}
          </Button>
        )}
      </Stack>
    )
  );

  return (
    <ListView
      columns={columns}
      data={users}
      query={usersQuery}
      title={t('users.title')}
      toolbarLeft={<QuickSearch
        id='user_keyword_search'
        initialValue={params.search || ''}
        onChange={(val) => updateParams({ search: val })}
        placeholder={t('users.search.placeholder')}
      />}
      toolbarRight={actions}>
    </ListView>
  );
}

export default UsersList;
