import React, { useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ColorPicker from './ColorPicker';
import { SvgIcon, SvgIconProps } from '@mui/material';

function CustomFormatColorTextIcon({
  colorBar,
  colorIcon,
  ...props
}: { colorBar: string; colorIcon: string } & SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M2,20h20v4H2V20z" fill={colorBar} />{' '}
      <path
        d="M5.49,17h2.42l1.27-3.58h5.65L16.09,17h2.42L13.25,3h-2.5L5.49,17z M9.91,11.39l2.03-5.79h0.12l2.03,5.79 H9.91z"
        fill={colorIcon}
      />
    </SvgIcon>
  );
}

function CustomFormatColorFillIcon({
  colorFill,
  colorIcon,
  ...props
}: { colorFill: string; colorIcon: string } & SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M2,20h20v4H2V20z" fill={colorFill} />
      <path
        d="M16.56,8.94L7.62,0L6.21,1.41l2.38,2.38L3.44,8.94c-0.59,0.59-0.59,1.54,0,2.12l5.5,5.5C9.23,16.85,9.62,17,10,17 s0.77-0.15,1.06-0.44l5.5-5.5C17.15,10.48,17.15,9.53,16.56,8.94z M5.21,10L10,5.21L14.79,10H5.21z M19,11.5c0,0-2,2.17-2,3.5 c0,1.1,0.9,2,2,2s2-0.9,2-2C21,13.67,19,11.5,19,11.5z"
        fill={colorIcon}
      />
    </SvgIcon>
  );
}

type Props = {
  disabled?: boolean;
  buttonAriaLabel?: string;
  buttonClassName?: string;
  buttonIconClassName?: string;
  buttonLabel?: string;
  title?: string;
  color: string;
  onChange?: (color: string, skipHistoryStack: boolean) => void;
};

export default function DropdownColorPicker({
  disabled = false,
  buttonAriaLabel,
  buttonClassName,
  buttonIconClassName,
  buttonLabel,
  title,
  color,
  onChange,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        aria-label={buttonAriaLabel || buttonLabel}
        title={title}
        onClick={handleOpen}
        style={{ color: 'primary' }}
      >
        {buttonIconClassName === 'font-color-icon' && (
          <CustomFormatColorTextIcon colorBar={color} colorIcon="primary" />
        )}
        {buttonIconClassName === 'bg-color-icon' && (
          <CustomFormatColorFillIcon colorFill={color} colorIcon="primary" />
        )}
        <ArrowDropDown />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ColorPicker
          color={color}
          onChange={(newColor, skipHistoryStack) => {
            if (onChange) {
              onChange(newColor, skipHistoryStack);
            }
          }}
        />
      </Popover>
    </>
  );
}
