import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import {
  FormatListBulleted,
  FormatListNumbered,
  FormatListNumberedRtl,
  FormatIndentDecrease,
  FormatIndentIncrease,
} from '@mui/icons-material';
import {
  LexicalEditor,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '../../nodes/PhxListNode';

import { listTypeToBlockName } from './index';
import { translate as t } from 'hooks/useTranslations';

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

type ListFormatProps = {
  blockType: keyof typeof listTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
};

export default function ListFormat({
  editor,
  blockType,
  rootType,
  disabled = false,
}: ListFormatProps) {
  // console.log('blockType', blockType);

  return (
    <Box>
      <IconButton
        disabled={disabled}
        onClick={() => {
          if (blockType === 'bullet') {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          }
        }}
        className='toolbar-item'
        color={blockType === 'bullet' ? 'primary' : 'neutral'}
        title={t('contentEditor.toolbar.list.bullet.title')}
        aria-label={t('contentEditor.toolbar.list.bullet.label')}
      >
        <FormatListBulleted />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={() => {
          if (blockType === 'number') {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, 'number');
          }
        }}
        className='toolbar-item'
        color={blockType === 'number' ? 'primary' : 'neutral'}
        title={t('contentEditor.toolbar.list.number.title')}
        aria-label={t('contentEditor.toolbar.list.number.label')}
      >
        <FormatListNumbered />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={() => {
          if (blockType === 'number') {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, 'alpha');
          }
        }}
        className='toolbar-item'
        color={blockType === 'alpha' ? 'primary' : 'neutral'}
        title={t('contentEditor.toolbar.list.alpha.title')}
        aria-label={t('contentEditor.toolbar.list.alpha.label')}
      >
        <FormatListNumberedRtl />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
        className='toolbar-item'
        title={t('contentEditor.toolbar.list.outdent.title')}
        aria-label={t('contentEditor.toolbar.list.outdent.label')}
      >
        <FormatIndentDecrease />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
        className='toolbar-item'
        title={t('contentEditor.toolbar.list.indent.title')}
        aria-label={t('contentEditor.toolbar.list.indent.label')}
      >
        <FormatIndentIncrease />
      </IconButton>
    </Box>
  );
}
